import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Product } from '../../../../models/product.type';
import { FineArtSchedule, ScheduleInfo } from '../../../../models/sov.model';
import { SVFArtSchedule } from '../../../../models/typing';
import { ScheduleOfValuesBaseComponent } from '../schedule-of-values-base/schedule-of-values-base.component';

@Component({
  selector: 'app-schedule-of-values-list-fine-art',
  templateUrl: './schedule-of-values-list-fine-art.component.html',
  styleUrls: ['./schedule-of-values-list-fine-art.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class ScheduleOfValuesListFineArtComponent implements OnInit, AfterViewInit {
  @ViewChild(ScheduleOfValuesBaseComponent) sovBase: ScheduleOfValuesBaseComponent
  @Input() preForm: any;
  @Input() status: boolean;
  fineArtSchedules: ScheduleInfo[]
  scheduleOfValues = new UntypedFormArray([])
  constructor(private parent: FormGroupDirective) {
    this.parent.form.addControl('scheduleOfValues', this.scheduleOfValues)
  }

  ngAfterViewInit(): void {
    this.setupSchedule()
    this.sovBase.onValueChanges()
  }

  ngOnInit(): void {
    const productData = new Product().findProductType(this.preForm?.product)
    const addressInfo = productData.addressInfo?.[this.preForm?.proposalForm?.insuredAddress?.country] ?? productData.addressInfo.defaultAddress
    this.fineArtSchedules = new FineArtSchedule(addressInfo).getScheduleRow()
  }

  setupSchedule(data ?: SVFArtSchedule[]) {
    if(data){
      this.scheduleOfValues.clear()
    }
    let schedules = data ?? this.preForm?.proposalForm?.scheduleOfValues
    if (schedules) {
      schedules.forEach((schedule: SVFArtSchedule, i: number) => {
        this.scheduleOfValues.push(this.initSchedule())
        setTimeout(() => {
          this.scheduleOfValues.at(i).patchValue({
            copeInformation : {
              period: schedule?.copeInformation?.period,
              floodZone: schedule?.copeInformation?.floodZone,
              constructionType: schedule?.copeInformation?.constructionType,
              occupancy: schedule?.copeInformation?.occupancy,
              basementUsed: schedule?.copeInformation?.basementUsed,
              burglarAlarm: schedule?.copeInformation?.burglarAlarm,
              fireAlarm: schedule?.copeInformation?.fireAlarm,
            },
            address: {
              stateOrCounty: schedule?.address?.stateOrCounty,
              line: schedule?.address?.line,
              city: schedule?.address?.city,
              county: schedule?.address?.county,
              zipOrPostCode: schedule?.address?.zipOrPostCode,
            },
            valuesAtRisk: {
              currency: schedule?.valuesAtRisk?.currency,
              figure: schedule?.valuesAtRisk?.figure,
            },
          });
        })
      });
    }
    while (this.scheduleOfValues.length < 5) {
      this.scheduleOfValues.push(this.initSchedule())
    }
  }

  initSchedule() {
    return new UntypedFormGroup({
      address: new UntypedFormGroup({
        city: new UntypedFormControl(''),
        zipOrPostCode: new UntypedFormControl(''),
        stateOrCounty: new UntypedFormControl(''),
        county: new UntypedFormControl(''),
        line: new UntypedFormControl(''),
      }),
      copeInformation: new UntypedFormGroup({
        period: new UntypedFormControl(''),
        floodZone: new UntypedFormControl(''),
        constructionType: new UntypedFormControl(''),
        occupancy: new UntypedFormControl(''),
        basementUsed: new UntypedFormControl(''),
        burglarAlarm: new UntypedFormControl(''),
        fireAlarm: new UntypedFormControl(''),
      }),
      valuesAtRisk: new UntypedFormGroup({
        currency: new UntypedFormControl({value: 'USD', disabled: true}),
        figure: new UntypedFormControl(''),
      }),
    });
  }

}