import { UntypedFormControl } from "@angular/forms";
import { Contract } from "./models";

export interface PTMessage {
    message?: string,
    messages?: string[],
    title?: string,
    status?: string,
    call?: PTCall,
    nextEndpoint?: string,
    errorDescription?: string,
    icon?: string,
}

export interface PTCall {
    endpoint: string,
    contract: Contract,
}

export interface PTFigure extends PTAmount {
    identifier: string,
}

export interface PTAmount {
    currency: string,
    figure: number,
}

export interface PTTerms { 
    document: string,
    version: string,
}

export interface PTResponse {
    error: boolean,
    errorDescription: string,
}

export interface PTAddress {
    city: string,
    country: string,
    lines: string[],
    stateOrCounty: string,
    zipOrPostCode: string,
}

export interface PTAttachments {
    atchID: string,
    count: number,
    files: PTFile[],
}

export interface PTFile {
    mimetype: string, 
    name: string,
    size: number
}

export interface PTDisplayVal {
    display: string, 
    value: string,
}

export interface PTCheckboxInput {
    key: string,
    display: string,
    control: UntypedFormControl,
    value: number
}

export interface PTEnvironment{
    production: boolean,
    forceOIDCProvider: string,
    baseapi:string,
}

export interface PTUser {
    activated: string,
    created: string,
    email: string,
    firstName: string,
    id: string,
    lastLogin: string,
    lastName: string,
    status: string,
}

export interface PTHandledStatus {
    all: PTValQ,
    submitted: PTValQ,
    quoted: PTValQ,
    bound: PTValQ,
    declined: PTValQ,
    notTakenUp: PTValQ,
    accepted: PTValQ,
    expired: PTValQ,
}

export interface PTValQ {
    value: string,
    quantity: number,
}

export interface PTQuote {
    quoteID: string,
    createdAt: string,
    status: string,
}

export interface PTLoginResponse {
    userID: string;
    email: string;
}

export interface PTLoginRedirectResponse {
    redirect: string;
}

export interface PTPortalSaveForm {
    id?: string,
    url?: string,
    prod?: string
}

export interface PTPortalSaveComplete {
    id: string,
    docID: string,
}

export interface ContractsServiceResponse {
    filteredContracts: (Contract|undefined)[];
    contracts: (Contract|undefined)[];
    numberblank: number;
}

export type LineageNode = {[key:string]:LineageNode|null}

export type LineageAuxData = {
    status?:string,
    contractName:string,
    createdAt:string,
    period:{startDate:string, endDate:string},
    product:string
  }

export class SVLineage{
  type:"SVLineage"
  id:string
  //the first key in this should be the original risk as the key
  children:LineageNode

  auxData:LineageAuxData
}
