<div class="interestType" [ngClass]="{'ng-invalid' : interestControl?.errors}" domControl>
    <div class="interestTypeWrapper">
        <mat-error class="typeError" *ngIf="interestControl.errors">
            *
        </mat-error>
        <div class="row g-0">
            <div class="col interest text" *ngFor="let item of interestTypes" (click)="chooseInterest(item.value)"
                [ngClass]="{'active' : interestControl.value === item.value}">
                <div id={{item.key}}>
                </div>
                <label class="interestLabel nowrap">{{item.display}}</label>
            </div>
        </div>
    </div>
</div>