import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.scss']
})
export class BusinessDetailComponent implements OnInit {
  @Input() overviewOfBusiness: UntypedFormGroup
  @Input() data: { yearInIndustry: number, yearInBusiness: number}

  yearInIndustry = new UntypedFormControl('', [Validators.required])
  yearInBusiness = new UntypedFormControl('', [Validators.required])
  constructor(public pUtil : PortalUtilService) { }

  ngOnInit(): void {
    this.setupBusinessDetail()
  }

  setupBusinessDetail(){
    this.yearInIndustry.setValue(this.data?.yearInIndustry)
    this.yearInBusiness.setValue(this.data?.yearInBusiness)

    this.overviewOfBusiness.addControl('yearInIndustry', this.yearInIndustry)
    this.overviewOfBusiness.addControl('yearInBusiness', this.yearInBusiness)
  }
}
