import { Component, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { PortalDataService, ComponentCommunicationService, PortalUtilService, PTResponse, PTAttachments } from 'portal';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upload-document-base',
  templateUrl: './upload-document-base.component.html',
  styleUrls: ['./upload-document-base.component.scss']
})
export class UploadDocumentBaseComponent implements OnChanges, OnDestroy {
  @Input() id : string;
  @Input() label: string;
  @Input() docID: string;
  @Input() dontHandleAttachments: boolean;
  @Output() attachmentCount = new EventEmitter<number>();
  attachments: PTAttachments; spin = false;
  @ViewChild('uploadButton') uploadButton: ElementRef<HTMLElement>;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public parent: FormGroupDirective,private portalDataService: PortalDataService, 
    private pUtil: PortalUtilService, private comm: ComponentCommunicationService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.docID?.firstChange) {
      this.getAttachments();
    }
  }

  getAttachments() {
    this.portalDataService.getAttachments(this.docID,this.id).
    pipe(
      takeUntil(this.unsub),
      finalize(() => this.spin = false)
    )
    .subscribe((response: PTResponse | PTAttachments) => {
      this.attachments = (response as PTAttachments);
      this.attachmentCount.emit(this.attachments?.count);
    });
  }

  openFile() {
    if(!this.spin && !this.docID){
      this.spin = true;
      this.checkIfSaveComplete();
      this.comm.saveForm({id:this.id ?? 'additionalDocs'});
    } else if (this.docID){
      this.uploadButton.nativeElement.click();
    }
  }

  checkIfSaveComplete() {
    this.comm.saveCompleteListener().pipe(
      takeUntil(this.unsub)
    ).subscribe((response) => {
      if(response.id === this.id || (response.id === 'additionalDocs' && !this.id)){
        this.spin = false;
        this.docID = response.docID;
        this.uploadButton.nativeElement.click();
      }
    });
  }

  fileChange(event: Event) {
    const fileList = (<HTMLInputElement>event.target).files;
    if (fileList && fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);
      if(file.size > (10 * ((2**10) ** 2))){
        this.pUtil.errorPopup({status:'While trying to attach file', message: 'Size exceeded, files cannot be larger than 10MB'});
      } else {
        this.spin = true;
        this.portalDataService.postAttachment(this.docID, formData, this.id).
        pipe(
          takeUntil(this.unsub),
        )
        .subscribe(() => {
          this.getAttachments();
          (this.uploadButton.nativeElement as HTMLInputElement).value = '';
        },
        error => {
          this.spin = false;
        });
      }
    }
  }

  deleteAttachment(name : string) {
    this.portalDataService.deleteAttachment(this.docID, name, this.id).
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      this.getAttachments();
    });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
