import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContractsServiceResponse, PTHandledStatus } from '../../../../model/typings';
import { ContractsService } from '../../../../services/contracts.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { modelUtils } from '../../../../model/models';

@Component({
  selector: 'pt-contract-list-filter-base',
  templateUrl: './contract-list-filter-base.component.html',
  styleUrls: ['./contract-list-filter-base.component.css']
})
export class ContractListFilterBaseComponent implements OnInit, OnDestroy {
  listFilter: string | null;
  riskTab: PTHandledStatus = new modelUtils().riskTab;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public contractsService: ContractsService) {}

  ngOnInit(){
    this.updateValues();
  }

  updateValues(){
    this.listFilter = this.contractsService?.contractsFilter;
    this.contractsService.listenToUserContracts()
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((resp: ContractsServiceResponse) => {
      this.clearList();
      resp.contracts.map((contract) => {
        contract?.status ? this.tabItem(contract.status) : this.tabItem('');
      })
    })
  }

  tabItem(status: string) {
    status = status.toLowerCase();
    if (!status.includes('arc')) {
      this.riskTab.all.quantity++;
    }
    if (status.includes(this.riskTab.submitted.value)) {
      this.riskTab.submitted.quantity++;
    } else if (status.includes(this.riskTab.quoted.value)) {
      this.riskTab.quoted.quantity++;
    } else if (status.includes(this.riskTab.bound.value)) {
      this.riskTab.bound.quantity++;
    } else if (status.includes(this.riskTab.declined.value)) {
      this.riskTab.declined.quantity++;
    } else if (status.includes(this.riskTab.notTakenUp.value)) {
      this.riskTab.notTakenUp.quantity++;
    } else if (status.includes(this.riskTab.accepted.value)) {
      this.riskTab.accepted.quantity++;
    }
  }

  clearList() {
    for (let i in this.riskTab){
      this.riskTab[i].quantity = 0;
    }
  }

  filterList(s: string | null) {
    this.listFilter = s;
    this.contractsService.setContractsFilter(this.listFilter);
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
