import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-business-overview-fine-art-detail',
  templateUrl: './business-overview-fine-art-detail.component.html',
  styleUrls: ['./business-overview-fine-art-detail.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class BusinessOverviewFineArtDetailComponent implements OnInit {
  @Input() preForm: any;
  public fineArt = new UntypedFormGroup({
    catLimitRequired : new UntypedFormGroup ({
      earthquakeLimit : new UntypedFormControl(''),
      windstormLimit : new UntypedFormControl(''),
    }),
    deductibleRequired : new UntypedFormControl('', [Validators.required]),
  })
  figures = [1000,2500,5000,10000,15000,20000];
  constructor(private parent: FormGroupDirective, public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('overviewOfBusiness') as UntypedFormGroup)
    .addControl('fineArtDetail', this.fineArt);
    this.setup(this.fineArt);
  }


  setup(form: UntypedFormGroup) {
    const detail = this.preForm.proposalForm?.overviewOfBusiness?.fineArtDetail;
    if (detail) {
      form.patchValue({
        deductibleRequired : detail?.deductibleRequired,
        catLimitRequired: {
          earthquakeLimit : detail?.catLimitRequired?.earthquakeLimit,
          windstormLimit: detail?.catLimitRequired?.windstormLimit,
        }
      });
    }
  }

  returnFigure(figure: number){
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(figure);
  }
}
