<div class='d-flex boundContract'>
    <div class="col-xxl-2 col-xl-1 d-none d-lg-block p-0 m-0">
        <div class="boundHeader d-flex">
        </div>
    </div>
    <div class="boundContent col-xxl-8 col-xl-10 col p-0 m-0">
        <div class="boundColWrapper">
            <div class="boundHeader d-flex">
                <div id="insuredName">
                </div>
                <label>Insured Name</label>
            </div>
            <div class="content medium" name="insuredName">
                {{preForm.contractName}}
            </div>
        </div>
        <div class="boundColWrapper">
            <div class="boundHeader d-flex">
                <div id="period">
                </div>
                <label>Period</label>
            </div>
            <div class="content medium" name="period">
                {{startDate ?? preForm.proposalForm?.period?.startDate | portalDatePipe:''}} to {{endDate ?? preForm.proposalForm?.period?.endDate | portalDatePipe:''}}
            </div>
        </div>
        <div class="boundColWrapper" *ngIf="!prod.typeInArrToCheck(preForm.product, prod.supportedProducts.UEquipmentBinder.typeArr)">
            <div class="boundHeader d-flex">
                <div id="quotePrem">
                </div>
                <label>Premium</label>
            </div>
            <div class="content medium" name="quotePrem">
                {{prem}}
            </div>
        </div>
        <div class="boundColWrapper" *ngIf="!prod.typeInArrToCheck(preForm.product, prod.supportedProducts.UEquipmentBinder.typeArr)">
            <div class="boundHeader d-flex">
                <div id="deductible">
                </div>
                <label>Deductible Clause</label>
            </div>
            <div [innerHtml]="deduc" class="content" name="deductible">
            </div>
        </div>
    </div>
    <div class="col-xxl-2 col-xl-1 d-none d-lg-block p-0 m-0">
        <div class="boundHeader d-flex">
        </div>
    </div>
</div>