import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { PortalUtilService, ComponentCommunicationService } from 'portal';
import { pipe, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Enums } from '../../../models/contract.model';
import { Product } from '../../../models/product.type';
import { BourbonSchedule, CargoSchedule, FineArtSchedule } from '../../../models/sov.model';

@Component({
  selector: 'app-schedule-of-values',
  templateUrl: './schedule-of-values.component.html',
  styleUrls: ['./schedule-of-values.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class ScheduleOfValuesComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() preForm: any;
    @Input() sectionHeader: string;
    @Input() productType: string;
    form: UntypedFormGroup; sov: string;
    enum = new Enums();
    scheduleOfValues = new UntypedFormControl('');
    unsub: Subject<boolean> = new Subject<boolean>();
    constructor(private parent: FormGroupDirective, private comm: ComponentCommunicationService,
      private pUtil: PortalUtilService) {
      this.form = this.parent.form;
    }

    ngAfterViewInit(){
      this.scrollToSchedule()
      const prod = new Product()
      const sov = this.preForm?.proposalForm?.scheduleOfValues
      if(prod.typeInArrToCheck(this.productType, prod.supportedProducts.UCargo.typeArr)){
        CargoSchedule.cargoScheduleOptions.valueChanges.pipe(takeUntil(this.unsub), startWith(this.form.value))
        .subscribe(() => {
          const cargoType = CargoSchedule.cargoScheduleOptions.get('cargoType')?.value;
          (CargoSchedule.isSovInvalid(sov) || !sov) && cargoType !== this.enum.cargoType.TransitOnly ? 
          this.scheduleOfValues.setErrors({missingSchedules: true}) : this.scheduleOfValues.setErrors(null)
        })
      }
      if(prod.typeInArrToCheck(this.productType, prod.supportedProducts.UFineArt.typeArr)){
        FineArtSchedule.fineArtScheduleOptions.valueChanges.pipe(takeUntil(this.unsub), startWith(this.form.value))
        .subscribe(() => {
          (FineArtSchedule.isSovInvalid(sov) || !sov) ? this.scheduleOfValues.setErrors({missingSchedules: true}) : this.scheduleOfValues.setErrors(null)
        })
      }
      if(prod.typeInArrToCheck(this.productType, prod.supportedProducts.UBourbon.typeArr)){
        BourbonSchedule.bourbonScheduleOptions.valueChanges.pipe(takeUntil(this.unsub), startWith(this.form.value))
        .subscribe(() => {
          (BourbonSchedule.isSovInvalid(sov) || !sov) ? this.scheduleOfValues.setErrors({missingSchedules: true}) : this.scheduleOfValues.setErrors(null)
        })
      }
    }

    ngOnInit(): void {
      (this.form.get('proposalForm') as UntypedFormGroup).addControl('scheduleOfValues', this.scheduleOfValues)
    }

    schedule() {
      const product = this.pUtil.rmvSpace(this.productType);
      const direct = {url: 'contracts/updateContract/scheduleOfValues', prod: product};
      this.comm.saveForm(direct);
    }

    scrollToSchedule(){
      setTimeout(() => {
        if (sessionStorage.getItem('scheduleSave')) {
          window.scroll({top: parseFloat(sessionStorage.getItem('scroll') || '0'), behavior: 'smooth'});
          sessionStorage.removeItem('scheduleSave');
        }
      });
    }

    ngOnDestroy() {
      this.unsub.next(true);
      this.unsub.complete();
    }

}

