import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Product } from 'projects/mcgill/src/app/contracts/shared-resources/models/product.type';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PTFigure } from '../../../model/typings';
import { ComponentCommunicationService } from '../../../services/component-communication.service';
import { PortalDataService } from '../../../services/portal-data.service';
import { PortalUtilService } from '../../../services/portal-util.service';

@Component({
  selector: 'pt-confirmed-risk-base',
  templateUrl: './confirmed-risk-base.component.html',
  styleUrls: ['./confirmed-risk-base.component.css']
})
export abstract class ConfirmedRiskBaseComponent implements OnInit, OnDestroy {
  @Input() docID: string;
  @Input() preForm: any;
  prem: string;
  startDate?:Date;
  endDate?:Date;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService, public sanitizer: DomSanitizer,
    public comm: ComponentCommunicationService, public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.getPremium();
    this.comm.setDownloadMRC(true);
  }

  getPremium() {
    const mrcHeadings : string[] = (new Product()).findProductType(this.preForm.product).platformQuoteHeaders
    
    forkJoin([
      this.portalDataService.getPremium(this.preForm.id),
      this.portalDataService.getDeducPremInfo(`/quote/lineItems/${this.preForm.id}`, mrcHeadings)
    ]).pipe(
      takeUntil(this.unsub)
    )
    .subscribe(([response, lineItems]: any) => {
      if(response?.Premium){
        this.prem = this.pUtil.getDecimalFigureFormat(response.Premium.figure, response.Premium.currency);
      }
      this.setupFigures(response.figures, lineItems);
      if(response.period){
        this.startDate = response.period.startDate ? new Date(response.period.startDate) : undefined
        this.endDate = response.period.startDate ? new Date(response.period.endDate) : undefined
      }
    });
  }

  abstract setupFigures(figures: PTFigure[], lineItems: any[]) : void;

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
