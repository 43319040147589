import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-operation-totals',
  templateUrl: './operation-totals.component.html',
  styleUrls: ['./operation-totals.component.scss']
})
export class OperationTotalsComponent {
  @Input() totals: UntypedFormGroup;
  constructor() { }
}
