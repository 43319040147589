import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PortalDataService } from 'portal';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CleanProductPipe } from '../../pipes/clean-product.pipe';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download-popup',
  templateUrl: './download-popup.component.html',
  styleUrls: ['./download-popup.component.scss'],
  providers: [CleanProductPipe]
})
export class DownloadPopupComponent implements OnInit, OnDestroy {
  isDisabled: boolean = false;
  spin: string | null = null;
  availableDownloads: string[] = [];
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public ref: MatDialogRef<DownloadPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private portalDataService: PortalDataService, 
              private cleanProd: CleanProductPipe) { }

  ngOnInit(): void {
    this.getAllAvailableFileNames();
  }

  getAllAvailableFileNames(){
    this.portalDataService.listadditional(this.data.ids.docID).
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: string[]) => {
      this.availableDownloads = response;
    })
  }

  mrcDownload(){
    this.disableButtons('mrc');
    this.portalDataService.downloadPDF(this.data.ids.docID, this.data.ids.quoteID)
    .pipe(
      takeUntil(this.unsub),
      finalize(() => this.enableButtons())
    )
    .subscribe((response: any) => {
      const filename = `${this.getFileName('Contract')}.pdf`
      saveAs(response, filename);
    })
  }

  additionalFileDownload(name: string){
    this.disableButtons(name);
    this.portalDataService.namedadditional(this.data.ids.docID, name)
    .pipe(
      takeUntil(this.unsub),
      finalize(() => this.enableButtons())
    )
    .subscribe((response: any) => {
      const filename = `${this.getFileName(name)}.pdf`
      saveAs(response, filename);
    })
  }

  disableButtons(button: string){
    this.isDisabled = true;
    this.spin = button;
  }

  enableButtons(){
    this.isDisabled = false;
    this.spin = null;
  }

  spaceBeforeCap(s: string){
    return s?.replace(/([A-Z])/g, ' $1')?.trim()
  }

  getFileName(filename: string): string {
    return (this.data.preform.contractName + '_' + this.cleanProd.transform(this.data.preform.product) + '_' + filename)
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
