import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Enums, Interest } from '../../../models/contract.model';

@Component({
  selector: 'app-casualty-type-detail',
  templateUrl: './casualty-type-detail.component.html',
  styleUrls: ['./casualty-type-detail.component.scss']
})
export class CasualtyTypeDetailComponent implements OnInit, OnChanges {
  @Input() casualtyType: UntypedFormControl;
  @Input() otherDetail: UntypedFormControl;
  @Input() policyType: UntypedFormControl;
  enums = new Enums();
  casualtyIndustry= new Interest().type.casualtyIndustry;
  constructor() { }

  ngOnInit(): void {
    this.otherDetail.setValidators(Validators.required);
    this.casualtyType.setValidators(Validators.required);
    this.casualtyType.updateValueAndValidity();
  }

  ngOnChanges(changes: SimpleChanges){
    this.roofingExists(changes.policyType.currentValue)
  }

  chooseIndustry(occupancy: string) {
    this.casualtyType.setValue(occupancy);
    this.checkIndustry()
  }

  roofingExists(currentPolicy: string){
    const index = this.casualtyIndustry.findIndex(e => e.key === 'casualtyRoofing');
    if(currentPolicy !== this.enums.policyType.pcgl.value && index > 0){
      this.casualtyIndustry.splice(index, 1);
      if(this.casualtyType.value === 'Roofing Contractors'){
        this.chooseIndustry('');
      }
    } else if(currentPolicy === this.enums.policyType.pcgl.value && index < 0){
      const item = {key: 'casualtyRoofing', display: 'Roofing Contractors', value: 'Roofing Contractors'};
      this.casualtyIndustry.splice(this.casualtyIndustry.length - 1, 0, item);
    }
  }

  checkIndustry(){
    this.casualtyType.value === 'Other' ? this.otherDetail.enable() : this.otherDetail.disable();
  }

}
