<div [formGroup]="address">
  <div class= "address">
    <div class= "row">
      <div class="col-12">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfErrorArr(getLines(),0)}" class="controlInput">
          <div formArrayName="lines">
            <div *ngFor="let line of getLines(); let i=index">
              <input class="controlFull"  type="text" [formControlName]="i" placeholder="Line" domControl>
              <label class="controlLabel">
                Line Address*
              </label>
           </div>
          </div>
        </div>
      </div>
      <div class="col-8 pe-1">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(address,'city')}" class="controlInput">
          <input class="controlFull"  type="text" formControlName="city" placeholder="City" domControl>
          <label class="controlLabel">
            City*
          </label>
        </div>
      </div>
      <div class="col-4 ps-0">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(address,'zipOrPostCode')}" class="controlInput"> 
          <input class="controlFull"  type="text" formControlName="zipOrPostCode" [placeholder]="addressInfo?.placeholder2" domControl>
          <label class="controlLabel">
            <span>{{addressInfo?.placeholder2}}*</span>
          </label>
        </div>
      </div>
      <div class="col-6 pe-1">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(address,'stateOrCounty')}" class="controlInput customSelect">
            <mat-select formControlName="stateOrCounty" domControl>
              <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
            </mat-select>
          <label class="controlLabel">
            <span>{{addressInfo?.placeholder1}}*</span>
          </label>
        </div>
      </div>
      <div class="col-6 ps-0">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(address,'country')}" class="controlInput customSelect"> 
            <mat-select formControlName="country" (selectionChange)= "setState(formType,$event.value)" domControl>
              <mat-option *ngFor="let c of countries" [value]="c">{{c}}</mat-option>
            </mat-select>
          <label class="controlLabel">
            Country*
          </label>
        </div>
      </div>
    </div>
    </div>
  </div>