import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Interest } from '../../../models/contract.model';
@Component({
  selector: 'app-occupancy-type-detail',
  templateUrl: './occupancy-type-detail.component.html',
  styleUrls: ['./occupancy-type-detail.component.scss']
})
export class OccupancyTypeDetailComponent implements OnInit {
  @Input() occupancyType: UntypedFormControl;
  @Input() otherDetail: UntypedFormControl;
  occupancy: { key: string; display: string; value: string; }[];
  constructor() { }

  ngOnInit(): void {
    this.occupancy = new Interest().type.occupancy;
    this.otherDetail.setValidators(Validators.required);
    this.occupancyType.setValidators(Validators.required);
    this.occupancyType.updateValueAndValidity();
  }

  chooseOccupancy(occupancy: string) {
    this.occupancyType.setValue(occupancy);
    this.checkOccupancy()
  }

  checkOccupancy(){
    this.occupancyType.value === 'Other' ? this.otherDetail.enable() : this.otherDetail.disable();
  }

}