import { ModuleWithProviders, NgModule } from '@angular/core';
import { BoolControlDirective } from './angular/directives/bool-control.directive';
import { CurrencyDirective } from './angular/directives/currency.directive';
import { DisableAllDirective } from './angular/directives/disable-all.directive';
import { DomControlDirective } from './angular/directives/dom-control.directive';
import { PercentageDirective } from './angular/directives/percentage.directive';
import { DatePipe } from './angular/pipes/date.pipe';
import { ListStatusPipe } from './angular/pipes/list-status.pipe';
import { LoginBaseComponent } from './angular/components/login-base/login-base.component';
import { CreateProductTypeBaseComponent } from './angular/components/create-product-type-base/create-product-type-base.component';
import { SubmitCheckPopupComponent } from './popups/submit-check-popup/submit-check-popup.component';
import { MaterialModule } from './modules/material-module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ErrorPopupComponent } from './popups/error-popup/error-popup.component';
import { ContractListBaseComponent } from './angular/components/homepage-contractList/contract-list-base/contract-list-base.component';
import { ContractListDetailBaseComponent } from './angular/components/homepage-contractList/contract-list-detail-base/contract-list-detail-base.component';
import { ContractListFilterBaseComponent } from './angular/components/homepage-contractList/contract-list-filter-base/contract-list-filter-base.component';
import { TopNavBaseComponent } from './angular/components/top-nav-base/top-nav-base.component';
import { DatePickerBaseComponent } from './angular/formComponents/date-picker-base/date-picker-base.component';
import { AdminPortalBaseComponent } from './angular/components/adminComponents/admin-portal-base/admin-portal-base.component';
import { AdminUserAccountBaseComponent } from './angular/components/adminComponents/user/admin-user-account-base/admin-user-account-base.component';
import { UserListDisplayBaseComponent } from './angular/components/adminComponents/user/user-list-display-base/user-list-display-base.component';
import { UserProductAssignBaseComponent } from './angular/components/adminComponents/user/user-product-assign-base/user-product-assign-base.component';
import { UpdateUsersBaseComponent } from './angular/components/adminComponents/user/update-users-base/update-users-base.component';
import { RegisterUsersBaseComponent } from './angular/components/adminComponents/user/register-users-base/register-users-base.component';
import { MonthlyReportBaseComponent } from './angular/components/adminComponents/risk/monthly-report-base/monthly-report-base.component';
import { AdminDisplayRiskBaseComponent } from './angular/components/adminComponents/risk/admin-display-risk-base/admin-display-risk-base.component';
import { PTEnvironment } from './model/typings';
import { CloneAsDraftComponent } from './angular/components/clone-as-draft/clone-as-draft.component';
import { CloneAsDraftPopupComponent } from './popups/clone-as-draft-popup/clone-as-draft-popup.component';
import { RiskOptionsPopupComponent } from './popups/risk-options-popup/risk-options-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLogoSetComponent } from './angular/components/adminComponents/admin-logo-set/admin-logo-set.component';
import { IconStatusSwitcherComponent } from './angular/components/icon-status-switcher/icon-status-switcher.component';
import { ChatUIComponent } from './angular/components/chat-ui/chat-ui.component';
import { InformationBoxComponent } from './angular/components/information-box/information-box.component';

@NgModule({
  declarations: [
    BoolControlDirective,
    CurrencyDirective,
    DisableAllDirective,
    DomControlDirective,
    PercentageDirective,
    DatePipe,
    ListStatusPipe,
    LoginBaseComponent,
    CreateProductTypeBaseComponent,
    SubmitCheckPopupComponent,
    ErrorPopupComponent,
    ContractListBaseComponent,
    ContractListDetailBaseComponent,
    ContractListFilterBaseComponent,
    TopNavBaseComponent,
    DatePickerBaseComponent,
    AdminPortalBaseComponent,
    AdminUserAccountBaseComponent,
    UserListDisplayBaseComponent,
    UserProductAssignBaseComponent,
    UpdateUsersBaseComponent,
    RegisterUsersBaseComponent,
    MonthlyReportBaseComponent,
    AdminDisplayRiskBaseComponent,
    CloneAsDraftComponent,
    CloneAsDraftPopupComponent,
    RiskOptionsPopupComponent,
    AdminLogoSetComponent,
    IconStatusSwitcherComponent,
    ChatUIComponent,
    InformationBoxComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    BoolControlDirective,
    CurrencyDirective,
    DisableAllDirective,
    DomControlDirective,
    PercentageDirective,
    DatePipe,
    ListStatusPipe,
    LoginBaseComponent,
    CreateProductTypeBaseComponent,
    ContractListBaseComponent,
    ContractListDetailBaseComponent,
    ContractListFilterBaseComponent,
    SubmitCheckPopupComponent,
    ErrorPopupComponent,
    TopNavBaseComponent,
    DatePickerBaseComponent,
    AdminPortalBaseComponent,
    AdminUserAccountBaseComponent,
    UserListDisplayBaseComponent,
    UserProductAssignBaseComponent,
    UpdateUsersBaseComponent,
    RegisterUsersBaseComponent,
    MonthlyReportBaseComponent,
    AdminDisplayRiskBaseComponent,
    CloneAsDraftComponent,
    CloneAsDraftPopupComponent,
    RiskOptionsPopupComponent,
    AdminLogoSetComponent,
    IconStatusSwitcherComponent,
    ChatUIComponent,
    InformationBoxComponent
  ]
})

export class PortalModule {
  public static forRoot(environment: PTEnvironment): ModuleWithProviders<PortalModule> {
    return {
      ngModule: PortalModule,
      providers: [{ provide: 'env', useValue: environment }]
    };
  }
}
