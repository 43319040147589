<div class="listContainer">
    <div class="d-flex justify-content-end me-4 mb-5">
        <input type="text" placeholder="Find user" [(ngModel)]="filterValue">
        <button (click)="selectAllUsers()">
            <ng-container *ngIf="!_allSelected;else deselectAll">Select All</ng-container>
            <ng-template #deselectAll>Deselect All</ng-template>
        </button>
    </div>
    <table mat-table [dataSource]="dataSource"
      matSort matSortActive="updatedAt" matSortDirection="desc"
      class="matTable mb-5">

        <!-- Position Column -->
        <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Check</th>
            <td mat-cell *matCellDef="let user">
                <div>
                    <label class="container">
                        <input (click)="checkUser(user)" class="splitCheckBox" type="checkbox" [checked]="userIsChecked(user) ? true : null"> 
                        <span class="checkmark"></span>
                    </label> 
                </div>
            </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let user" (click)="viewUser(user)"> {{user.id}} </td>
        </ng-container>

        <!-- First Name Column-->
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
            <td mat-cell *matCellDef="let user" (click)="viewUser(user)"> {{user.firstName}} </td>
        </ng-container>      
        
        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let user" (click)="viewUser(user)"> {{user.lastName}} </td>
        </ng-container>

        <!-- User Status Column -->
        <ng-container matColumnDef="userStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>User Status</th>
            <td mat-cell *matCellDef="let user" (click)="viewUser(user)"> {{user.status}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let user" (click)="viewUser(user)"> {{user.email}} </td>
        </ng-container>

        <!-- Last Login Column -->
        <ng-container matColumnDef="lastLogin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</th>
            <td mat-cell *matCellDef="let user" (click)="viewUser(user)"> {{user.lastLogin | portalDatePipe:'lastLogin':true}} </td>
        </ng-container>

        <tr
           mat-header-row 
           class="contracts-header-row" 
           *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr 
            mat-row 
            *matRowDef="let row; columns: displayedColumns;"
            class="contracts-row pointer"
        ></tr>
    </table>
</div>