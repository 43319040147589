<div class="underscorePopup">
    <span><h1 mat-dialog-title class="dialogTitle dialogText header">Clone Options</h1></span>
    <div class="line mb-4 mt-4"></div>
    <mat-dialog-content>
        <div class="col-12 ps-0">
            <div class="controlInput" [formGroup]="cloneRiskForm">
              <input  class="controlFull" type="text" placeholder="Insured's name" formControlName="contractName">
              <label class="controlLabel labelBackground">
                Insured's Name
              </label>
            </div> 
        </div>

        <div [formGroup]="cloneRiskForm">

            <h4 class="mt-4 mb-2">Would you like to clone attachments?</h4>
            <div class="radioButtonYesNo d-flex text">
                <label class="radioOption me-3" for="cloneAttachmentsNo">
                    <input type="radio" id="cloneAttachmentsNo" name="atch" value="false"
                        (click)="cloneRiskForm.get('atch')?.setValue(false)">
                    <label for="cloneAttachmentsNo">No</label>
                </label>
                <label class="radioOption me-3" for="cloneAttachmentsYes">
                    <input type="radio" id="cloneAttachmentsYes" name="atch" value="true"
                        (click)="cloneRiskForm.get('atch')?.setValue(true)">
                    <label for="cloneAttachmentsYes">Yes</label>
                </label>
            </div>

            <h4 class="mt-4 mb-2">Is the new risk a renewal?</h4>
            <div class="radioButtonYesNo d-flex text">
                <label class="radioOption me-3" for="renawalRiskNo">
                    <input type="radio" id="renawalRiskNo" name="renawalRisk" value="false"
                        (click)="isRenewal(false)">
                    <label for="renawalRiskNo">No</label>
                </label>
                <label class="radioOption me-3" for="renawalRiskYes">
                    <input type="radio" id="renawalRiskYes" name="renawalRisk" value="true"
                        (click)="isRenewal(true)">
                    <label for="renawalRiskYes">Yes</label>
                </label>
            </div>

            <ng-container *ngIf="cloneRiskForm.get('extendExpiringDate')?.enabled">
                <h4 class="mt-4 mb-2">Add 12 months to the expiring dates?</h4>
                <div class="radioButtonYesNo d-flex text">
                    <label class="radioOption me-3" for="expiringDatesNo">
                        <input type="radio" id="expiringDatesNo" name="extendExpiringDate" value="false"
                            (click)="cloneRiskForm.get('extendExpiringDate')?.setValue(false)">
                        <label for="expiringDatesNo">No</label>
                    </label>
                    <label class="radioOption me-3" for="expiringDatesYes">
                        <input type="radio" id="expiringDatesYes" name="extendExpiringDate" value="true"
                            (click)="cloneRiskForm.get('extendExpiringDate')?.setValue(true)">
                        <label for="expiringDatesYes">Yes</label>
                    </label>
                </div>
            </ng-container>
        </div>

    </mat-dialog-content>

    <div class="line mb-3 mt-4"></div>

    <div class="d-flex justify-content-end mt-4 me-4">
        <button mat-dialog-close mat-button class= "roboto declineButton me-3">Cancel</button>
        <button mat-button class= "roboto createButton" (click)="cloneRisk()">Confirm</button>
    </div>
</div>