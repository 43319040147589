<div class="col-12 buttonWrapper p-0">
    <div class="tableButtons btn-group">
      <button (click)= 'filterList(null)' [ngClass]="{'active' : !listFilter}">
        All
      </button>
      <button (click)= 'filterList(riskTab.submitted.value)' [ngClass]="{'active' : listFilter === riskTab.submitted.value}">
        Submitted
      </button>
      <button (click)= 'filterList(riskTab.quoted.value)' [ngClass]="{'active' : listFilter === riskTab.quoted.value}">
        Quoted
      </button>
      <button (click)= 'filterList(riskTab.accepted.value)' [ngClass]="{'active' : listFilter === riskTab.accepted.value}">
        Quote Accepted
      </button>
      <button (click)= 'filterList(riskTab.bound.value)' [ngClass]="{'active' : listFilter === riskTab.bound.value}">
        Bound
      </button>
      <button (click)= 'filterList(riskTab.notTakenUp.value)' [ngClass]="{'active' : listFilter === riskTab.notTakenUp.value}">
          Not Taken Up
      </button>
      <button (click)= 'filterList(riskTab.declined.value)' [ngClass]="{'active' : listFilter === riskTab.declined.value}">
        Quote Declined
      </button>
      <button (click)= 'filterList(riskTab.expired.value)' [ngClass]="{'active' : listFilter === riskTab.expired.value}">
        Expired
      </button>
    </div>
</div>