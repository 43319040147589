import { Injectable } from '@angular/core';
import { PortalDataService } from './portal-data.service';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SVCargoSchedule } from 'projects/mcgill/src/app/contracts/shared-resources/models/typing';

@Injectable({
  providedIn: 'root'
})
export class RiskService {
  pdfRiskJSON = new BehaviorSubject<PTFileToJson | null>(null);

  constructor(private dataService: PortalDataService) { }

  getJSONFromFile(file: File, product: string, part: string) : Observable<PTFileToJson | SVCargoSchedule[]>{
    const formData: FormData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)
    formData.append('product', product)
    formData.append('part', part)

    return this.dataService.fileToJson(formData)
  }

  downloadTemplate(product: string, part: string, type: string){
    this.dataService.getFileToRiskTemplates(product, part)
    .subscribe((response: any) => {
      const filename = `${product}_${part}_Template.${type}`
      saveAs(response, filename)
    })
  }

  pdfRiskJSONComplete(){
    this.pdfRiskJSON.complete()
    this.pdfRiskJSON = new BehaviorSubject<PTFileToJson | null>(null)
  }

}

export interface PTFileToJson {
  contractName:string,
  proposalForm: any
}