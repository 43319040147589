import { Component } from '@angular/core';

@Component({
  selector: 'app-user-agreement-text',
  templateUrl: './user-agreement-text.component.html',
  styleUrls: ['./user-agreement-text.component.scss']
})
export class UserAgreementTextComponent {

  constructor() { }

}
