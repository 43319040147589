import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, Type, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalDataService, PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChooseProdComponentDirective } from '../../../directives/choose-prod-component.directive';
import { Product } from '../../../models/product.type';
import { SVCargoSchedule, SVFArtSchedule } from '../../../models/typing';

@Component({
  selector: 'app-schedule-of-values-detail',
  templateUrl: './schedule-of-values-detail.component.html',
  styleUrls: ['./schedule-of-values-detail.component.scss']
})
export class ScheduleOfValuesDetailComponent implements OnInit {
  @ViewChild(ChooseProdComponentDirective, {static: true}) appChooseProd!: ChooseProdComponentDirective;
  preForm: any;
  docID: string;
  status: string;
  prod: string;
  form = new UntypedFormGroup({});
  products = new Product();
  componentRef : any
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private activeRoute: ActivatedRoute, private router: Router,
    private portalDataService: PortalDataService, private pUtil: PortalUtilService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef) {}
  
  ngOnInit() {
    this.getProduct();
  }

  async getProduct() {
    this.activeRoute.queryParams.subscribe(params => {
      this.status = params?.status;
    });
    this.activeRoute.paramMap.subscribe(params => {
      this.docID = params.get('doc') || '';
      this.prod = this.pUtil.addSpace(params.get('prod'));
    });
    await this.getID();
  }
  
  getID() {
    return new Promise<void> (resolve => {
      if (this.docID) {
        this.portalDataService.getRisk(this.docID).
        pipe(
          takeUntil(this.unsub)
        )
        .subscribe((response) => {
          this.preForm = JSON.parse(JSON.stringify(response));
          this.loadScheduleOfValues()
          resolve();
        });
      }
    });
  }

  updateContract(json: SVCargoSchedule | SVFArtSchedule) {
    return new Promise<void> (resolve => {
        this.portalDataService.updateContract(this.docID, json).
        pipe(
          takeUntil(this.unsub)
        )
        .subscribe((response: any) => {
          if(response.ok && !response.error){
            resolve();
          } else {
            this.pUtil.errorPopup({status:'While trying to save', message:response?.msg});
          }
        },
        error => {
          this.pUtil.errorPopup({status:'While trying to save', message:error})
        });
    });
  }

  async saveSchedule() {
    const schedule = JSON.parse(JSON.stringify(this.form.get('scheduleOfValues')?.value));
    this.preForm.updatedAt = this.pUtil.formattedDate();
    let formToSend = this.scheduleToJson(schedule);
    console.log(JSON.stringify(formToSend));
    await this.updateContract(formToSend);
    this.handleRoute();
  }

  scheduleToJson(schedule: SVCargoSchedule | SVFArtSchedule) {
    if (!this.preForm?.proposalForm) {
      const scheduleOfValues = {};
      this.preForm['proposalForm'] = scheduleOfValues;
    }
    this.preForm.proposalForm.scheduleOfValues = schedule;
    this.preForm.proposalForm = this.pUtil.extractJSON(this.preForm.proposalForm);
    return this.preForm;
  }

  handleRoute() {
    sessionStorage.setItem('scheduleSave', 'saved');
    this.router.navigate(['/contracts/updateContract', this.pUtil.rmvSpace(this.prod) , this.docID]);
  }

  loadScheduleOfValues() {
    if(!this.componentRef){
      const component : Type<any> = new Product().findProductType(this.prod).scheduleOfValues
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const viewContainerRef = this.appChooseProd.viewContainerRef;
      this.componentRef = viewContainerRef.createComponent<ScheduleOfValues>(componentFactory);
    }

    this.componentRef.instance.preForm = this.preForm
    this.componentRef.instance.status = this.status
    this.cdr.detectChanges();
  }

}

export interface ScheduleOfValues {
  preForm: any;
  status: boolean
}
