import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ZipCodes } from '../../models/contract.model';

@Component({
  selector: 'app-exposure',
  templateUrl: './exposure.component.html',
  styleUrls: ['./exposure.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class ExposureComponent implements OnInit, OnDestroy {
  @Input() preForm: any;
  form: UntypedFormGroup;
  public exposure = new UntypedFormGroup({
    limitRequired: new UntypedFormControl(''),
    totalInsuredValue: new UntypedFormControl(''),
    restrictedZip: new UntypedFormControl('' , [Validators.required]),
  });
  zip = new ZipCodes().zip;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective) {
    this.form = this.parent.form;
  }

  ngOnInit(): void {
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('exposure', this.exposure);
    this.setUpExposure(this.exposure);
    this.checkLimit();
  }

  setUpExposure(form: UntypedFormGroup) {
    const exposure = this.preForm.proposalForm?.exposure;
    if (exposure) {
      form.patchValue({
        limitRequired : exposure?.limitRequired,
        totalInsuredValue : exposure?.totalInsuredValue,
        restrictedZip : exposure?.restrictedZip
      });
    }
  }

  checkLimit(){
    this.exposure.get('limitRequired')?.valueChanges.
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe((changes) => {
      if(changes?.figure > 250000000){
        this.exposure.get('limitRequired')?.setErrors({overLimit:true})
      } else {
        this.exposure.get('limitRequired')?.setErrors(null)
      }
    });
  }

  setZip(s: boolean) {
    this.exposure.get('restrictedZip')?.setValue(s);
  }

  returnEmpty() {
    return 0;
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
