import { Component, OnInit } from '@angular/core';
import { AdminDisplayRiskBaseComponent, ContractsService, PortalDataService } from 'portal';

@Component({
  selector: 'app-admin-risk-list',
  templateUrl: './admin-risk-list.component.html',
  styleUrls: ['./admin-risk-list.component.scss']
})

export class AdminRiskListComponent extends AdminDisplayRiskBaseComponent implements OnInit {
  constructor(public portalDataService: PortalDataService,
    public contractsService: ContractsService
  ) { super(portalDataService) }

  ngOnInit(){
    super.ngOnInit();
  }
}
