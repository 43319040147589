import { Component, OnInit } from '@angular/core';
import { PortalUtilService, DatePickerBaseComponent } from 'portal';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})

export class DatePickerComponent extends DatePickerBaseComponent implements OnInit{
  constructor(public pUtil: PortalUtilService
  ) { super(pUtil) }

  ngOnInit(){
    super.ngOnInit();
  }

}

