<div [formGroup]="operation">
    <div *ngIf="type === 'figure'" class="salesRow">
        <div class="totalSalesSelect d-flex  align-items-center operationLabel">
            {{name}}
        </div>
        <div class="totalSalesFigure">
            <app-amount [additionalType]="withPerc?['%']:[]" [required]="required" [name]="'Expiring Projected'" formControlName="expiringProjected"></app-amount>
        </div>
        <div class="totalSalesFigure">
            <app-amount [additionalType]="withPerc?['%']:[]" [required] = "required" [name]="'Expiring Actual'" formControlName="expiringActual"></app-amount>
        </div>
        <div class="totalSalesFigure">
            <app-amount [additionalType]="withPerc?['%']:[]" [required] = "required" [name]="'Renewal Projected'" formControlName="renewalProjected"></app-amount>
        </div>
    </div>
    <div *ngIf="type === 'percentage'" class="salesRow">
        <div class="totalSalesSelect d-flex align-items-center operationLabel">
            {{name}}
        </div>
        <div class="totalSalesFigure">
            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(operation,'expiringProjected')}" class="controlInput">
                <input name="expiringProjected" [inputControl]="operation.get('expiringProjected')" pt-appPercentage>
                <label class="controlPercentageLabel">
                    %
                </label>
                <label class="controlLabel">
                    Expiring Projected<span *ngIf="required">*</span>
                </label>
            </div>
        </div>
        <div class="totalSalesFigure">
            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(operation,'expiringActual')}" class="controlInput">
                <input name="expiringActual" [inputControl]="operation.get('expiringActual')" pt-appPercentage>
                <label class="controlPercentageLabel">
                    %
                </label>
                <label class="controlLabel">
                    Expiring Actual<span *ngIf="required">*</span>
                </label>
            </div>
        </div>
        <div class="totalSalesFigure">
            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(operation,'renewalProjected')}" class="controlInput">
                <input name="renewalProjected" [inputControl]="operation.get('renewalProjected')" pt-appPercentage>
                <label class="controlPercentageLabel">
                    %
                </label>
                <label class="controlLabel">
                    Renewal Projected<span *ngIf="required">*</span>
                </label>
            </div>
        </div>
    </div>
</div>