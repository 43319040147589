<div [formGroup]="userdata">
  <div class="controlInput">
      <input class="controlFull" type="text" formControlName="companyName" placeholder="Company Name">
      <label class="controlLabel">
        Company Name*
      </label>
  </div>
  <div class="controlInput">
      <input class="controlFull" type="text" formControlName="clientIdentifier" placeholder="Client Identifier">
      <label class="controlLabel">
        Client Identifier*
      </label>
  </div>
  <div class="colMargin" formArrayName="linesForMRC">
      <div *ngFor="let line of getLines(); let i=index">
          <div class="controlInput">
              <input class="controlFull" type="text" [formControlName]="i" 
              [placeholder]="i=== 0 ? 'No and Street' : i=== 1 ? 'City' : 'State and Zip' ">
              <label class="controlLabel">
                  Line {{i + 1}}*
              </label>
          </div>
      </div>
  </div>
  <div class="text pt-5 pb-3">
    <app-toggle [toggleControl]="fineArt"
      toggleText="Does/will the user have access to Fine Art?"
      (toggleEmitter)="setSurplusLine()"
    ></app-toggle>
  </div>
  <app-user-surplus-lines formControlName='surplusLines'></app-user-surplus-lines>
</div>