import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UtilService } from 'projects/mcgill/src/app/app-services/util.service';

@Component({
  selector: 'app-transit-limits',
  templateUrl: './transit-limits.component.html',
  styleUrls: ['./transit-limits.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class TransitLimitsComponent implements OnInit {
  @Input() preForm: any;
  private form: UntypedFormGroup;
  public transitLimits = new UntypedFormGroup({
    limitAverageAnyOneConveyance: new UntypedFormControl(''),
    limitMaximumValueAnyOneConveyance: new UntypedFormControl(''),
 });
  constructor(private parent: FormGroupDirective, public util: UtilService) { }

  ngOnInit(): void {
    this.form = this.parent.form;
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('transitLimits', this.transitLimits);
    this.setupTransitLimits(this.transitLimits);
  }

  setupTransitLimits(form: UntypedFormGroup) {
    const transitLimits = this.preForm.proposalForm?.transitLimits;
    if (transitLimits) {
      form.patchValue({
        limitAverageAnyOneConveyance: transitLimits?.limitAverageAnyOneConveyance,
        limitMaximumValueAnyOneConveyance: transitLimits?.limitMaximumValueAnyOneConveyance,
    });
    }
  }

}
