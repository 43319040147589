import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-coverage-required-logs-while-on-land',
  templateUrl: './coverage-required-logs-while-on-land.component.html',
  styleUrls: ['./coverage-required-logs-while-on-land.component.scss']
})
export class CoverageRequiredLogsWhileOnLandComponent {
  @Input() control: UntypedFormControl

  constructor() { }

}
