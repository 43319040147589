import { Injectable } from '@angular/core';
import { Enums, Interest } from '../contracts/shared-resources/models/contract.model';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Contract, PTFigure, PortalUtilService } from 'portal';
import { Product } from '../contracts/shared-resources/models/product.type';

@Injectable({
  providedIn: 'root'
})

export class UtilService {
  enum = new Enums();
  prod = new Product();
  constructor(private portalUtil: PortalUtilService) { }

  safeDeducHTML(arr: PTFigure[], preForm: any, lineItems: any[]){
    const perishable = preForm?.proposalForm?.overviewOfBusiness?.interestType === Interest.Perishable;
    const deductibles = this.portalUtil.retrieveAllDeductibles(arr).map((deduc: PTFigure) => {return this.portalUtil.getDecimalFigureFormat(deduc.figure, deduc.currency)})
    let deduc;
    if(preForm.proposalForm.cargoType === this.enum.cargoType.StockOnly){
      deduc = `
        <div>
          Stock: <span class='medium'>${deductibles[0]}<br>
          ${perishable ? `<span class='medium'>${deductibles[1]},</span> <span class='book'>Locn’s without Back-up Generators</span><br>` : ''}
          ${deductibles[perishable ? 2 : 1]} in respect of CAT</span>
        </div>`
    } else if (preForm.proposalForm.cargoType === this.enum.cargoType.Both){
      deduc = `
        <div>
          Stock: <span class='medium'>${deductibles[1]}<br>
          ${perishable ? `<span class='medium'>${deductibles[2]},</span> <span class='book'>Locn’s without Back-up Generators</span><br>` : ''}
          ${deductibles[perishable ? 3 : 2]} in respect of CAT<br></span>
          <span>Transit: </span> <span class='medium'>${deductibles[0]}</span>
        </div>`
    } else if (this.prod.typeInArrToCheck(preForm.product, this.prod.supportedProducts.UFineArt.typeArr)){
      deduc = `
        <div>
          <span class='medium'>${deductibles[0]}<br> ${deductibles[1]} in respect of CAT</span>
        </div>`
    } else if (this.prod.typeInArrToCheck(preForm.product, this.prod.supportedProducts.UConstruction.typeArr)) {
      deduc = `
        <span class="medium">${lineItems.find(item => item.header == "Deductibles")?.text.join("<br>")}</span>`
    } else {
      deduc = `
        <div>
          <span class='medium'>${deductibles[0]}</span>
        </div>`
    }
    return deduc;
  }

  expired(contract: Contract): boolean {
    if(!this.prod.typeInArrToCheck(contract.product, this.prod.supportedProducts.UTerrorism.typeArr)){
      return false;
    }
    const created = moment(contract.updatedAt);
    const currentDate = moment(new Date());
    return currentDate.diff(created, 'days') > environment.quoteValidityDays;
  }
  
}
