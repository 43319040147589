import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sales2',
  templateUrl: './sales2.component.html',
  styleUrls: ['./sales2.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class Sales2Component implements OnInit, OnDestroy{
  @Input() preForm: any;
  @Input() set disabled(value: boolean) {
    if(value || value === false) {
      this.setState(value)
    }
   }
  public optionTwo = new UntypedFormGroup({
    isMemberOfListedOrg: new UntypedFormControl('', [Validators.required]),
  });
  public descriptionTotals = new UntypedFormGroup({
    expiringProjected: new UntypedFormControl(''),
    expiringActual: new UntypedFormControl(''),
    renewalProjected: new UntypedFormControl(''),
  })
  public operationTotals = new UntypedFormGroup({
    expiringProjected: new UntypedFormControl(''),
    expiringActual: new UntypedFormControl(''),
    renewalProjected: new UntypedFormControl(''),
  })
  public saleSkeleton : any = {
    descriptionOfOperations:[
      {display: 'Industrial', key: 'industrial', type:'percentage'},
      {display: 'Commercial', key: 'commercial', type:'percentage'},
      {display: 'Residential', key: 'residential', type:'percentage'},
    ],
    operationsBreakdown:[
      {display: 'New', key: 'new', type:'percentage'},
      {display: 'Re-roof', key: 'reRoof', type:'percentage'},
    ],
    additionalOperations:[
      {display: 'Hot Air Membrane', key: 'hotAirMembrane', type:'figure'},
      {display: 'Built up roofing/ Hot asphalt', key: 'builtUpRoofingHot', type:'figure'},
      {display: 'Modified bitumen (torch applied)', key: 'modifiedBitumenTorch', type:'figure'},
      {display: 'Other Open Flame', key: 'otherOpenFlame', type:'figure'},
      {display: 'Built Up Roofing (cold)', key: 'builtUpRoofingCold', type:'figure'},
      {display: 'Modified bitumen (no torch)', key: 'modifiedBitumen', type:'figure'},
      {display: 'EPDM/TPO/PVC', key: 'epdm', type:'figure'},
      {display: 'Liquid Applied', key: 'liquidApplied', type:'figure'},
      {display: 'Sprayed Foam', key: 'sprayedFoam', type:'figure'},
      {display: 'Shakes, Shingles, Tiles, Slate Metal', key: 'slateMetal', type:'figure'},
      {display: 'Insulation, Metal, Work, Vapour Barrier', key: 'insulation', type:'figure'},
      {display: 'Cladding', key: 'cladding', type:'figure'},
      {display: 'Repairs, and Maintenance', key: 'repairsAndMaintenance', type:'figure'},
    ]
  }
  unsub: Subject<boolean> = new Subject<boolean>();
  ready: boolean;
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('totalSalesRevenues') as UntypedFormGroup)?.addControl('optionTwo', this.optionTwo);
    this.setupSales()
  }

  setupSales(){
    const totalSales = this.preForm.proposalForm?.totalSalesRevenues?.optionTwo;
    if(totalSales){
      this.optionTwo.patchValue({ 
/*         totalGrossSales: totalSales?.totalGrossSales, */
        isMemberOfListedOrg: totalSales?.isMemberOfListedOrg,
      });
    }
/*     if(totalSales){
      this.optionTwo.patchValue({ 
        totalGrossSales: totalSales?.totalGrossSales,
        isMemberOfListedOrg: totalSales?.isMemberOfListedOrg,
      });
      for (const [key, value] of Object.entries(totalSales)) {
        if(this.saleSkeleton?.[key]){
          this.setupFormControl(key,value);
        }
      }
    } */
  }

  setupFormControl(key:string, val: any){
    const formGroup = this.optionTwo.get(key);
    const data = this.preForm.proposalForm?.totalSalesRevenues?.optionTwo?.[key];
    for (const [key, value] of Object.entries(val)) {
      formGroup?.get(key)?.patchValue({
        expiringProjected: data?.[key]?.expiringProjected,
        expiringActual: data?.[key]?.expiringActual,
        renewalProjected: data?.[key]?.renewalProjected,
      })
    }
  }

  setupControls(){
    for (const [key, value] of Object.entries(this.saleSkeleton)) {
      this.addControl(key,value);
    }
  }

  addControl(key:string, value: any){
    const group = new UntypedFormGroup({});
    this.disable(group);
    this.optionTwo.addControl(key,group);
    value.forEach((entry: { key: string; }) => {
      const control = new UntypedFormControl({});
      this.disable(control);
      group.addControl(entry.key,control);
    });
  }

  descriptionChange(): void {
    this.optionTwo.get('descriptionOfOperations')?.valueChanges.pipe(
      takeUntil(this.unsub)
    )
    .subscribe((val)=> {
      this.getTotals(val, this.descriptionTotals, this.optionTwo.get('descriptionOfOperations'));
    });
  }

  operationChange(): void {
    this.optionTwo.get('operationsBreakdown')?.valueChanges.pipe(
      takeUntil(this.unsub)
    )
    .subscribe((val) => {
      this.getTotals(val, this.operationTotals, this.optionTwo.get('operationsBreakdown'));
    });
  }

  getTotals( obj:{} , formGroup: any , parentGroup: any){
    let eProjected = undefined; let eActual = undefined; let rProjected = undefined;

    for (const key of Object.keys(obj)) {
      const value = obj[key];
      eProjected = value?.['expiringProjected'] ?? "" !== "" ? (eProjected ?? 0) + value?.['expiringProjected'] : eProjected;
      eActual = value?.['expiringActual'] ?? "" !== "" ? (eActual ?? 0) + value?.['expiringActual'] : eActual;
      rProjected = value?.['renewalProjected'] ?? "" !== "" ? (rProjected ?? 0) + value?.['renewalProjected'] : rProjected;
    }

    formGroup.patchValue({
      expiringProjected : eProjected !== undefined ? Number.parseFloat(eProjected.toString()).toFixed(2) : 0,
      expiringActual: eActual !== undefined ? Number.parseFloat(eActual.toString()).toFixed(2) : 0,
      renewalProjected: rProjected !== undefined ? Number.parseFloat(rProjected.toString()).toFixed(2) : 0,
    })

    for (const c in formGroup.controls) {
      const abstractControl = formGroup.controls[c];
      abstractControl.value == 100 || !abstractControl.value ? formGroup.get(c).setErrors(null) : formGroup?.setErrors({'percentageNotHundred': true});
    }
    formGroup.valid ? parentGroup?.setErrors(null,{disabled: this.optionTwo.disabled}) : parentGroup?.setErrors({'percentageNotHundred': true},{disabled: this.optionTwo.disabled});
  }

  disable(control: UntypedFormGroup | UntypedFormControl){
    if(this.optionTwo.disabled){
      control.disable();
    }
  }

  setState(disabled: boolean){
    disabled ? this.optionTwo.disable() : this.optionTwo.enable();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
