import { Directive, ElementRef, Input, Renderer2, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pt-appPercentage]'
})
export class PercentageDirective implements AfterViewInit, OnDestroy {
  @Input() inputControl: UntypedFormControl;
  regEx = new RegExp('^(100(\.00?)?)$|^(([0-9]{1,2}?)([\.][0-9]{0,2})?)$');
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private el: ElementRef,  private renderer: Renderer2) {
    fromEvent(el.nativeElement, 'input').
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
        this.transformToDec(el.nativeElement.value);
    });
  }

  transformToDec(val: string) {
    val = val.toString();
    if(val.length > 6){ // not allowed
          this.resetControl()
    } else if (!this.regEx.test(val) && val && this.el.nativeElement.id !== 'total') { // doesnt match regex
        let subStr = val.substring(0, val.length - 1);
        if(!this.regEx.test(subStr)){ //check if substr matches
          this.resetControl()
        } else {
          this.el.nativeElement.value = val.substring(0, val.length - 1);
          this.setFigure(val.substring(0, val.length - 1));
        }
    } else if (val) {  // matches regex
        this.el.nativeElement.value = val;
        this.setFigure(val);
    } else {  // empty
        this.resetControl();
    }
  }

  setFigure(val: string) {
    if (parseFloat(val)) {
      this.el.nativeElement.id === 'total' ? this.inputControl.setValue(parseFloat(parseFloat(val).toFixed(2)), { emitEvent: false }) :
      this.inputControl.setValue(parseFloat(parseFloat(val).toFixed(2)));
    } else {
      this.el.nativeElement.id === 'total' ? this.inputControl.setValue(0, { emitEvent: false }) : this.inputControl.setValue(0);
    }
  }

  resetControl(){
    this.inputControl.setValue(null);
    this.el.nativeElement.value = '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.el.nativeElement.id === 'total') {
        this.subscribeToTotal();
      }
      if (this.inputControl.value || this.inputControl.value === 0) {
        const i = parseFloat(this.inputControl.value).toFixed(2);
        this.transformToDec(i);
      }
    });
  }

  subscribeToTotal() {
    this.inputControl.valueChanges.
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe( (changes) => {
      this.transformToDec(this.inputControl.value);
    });
  }

  @HostListener('focusout', ['$event']) onFocus() {
    this.inputControl.markAsTouched();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
