import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { Interest, Enums } from '../../../shared-resources/models/contract.model';
import { ProductComponent } from '../update-contract.component';

@Component({
  selector: 'app-fine-art-form',
  templateUrl: './fine-art-form.component.html',
  styleUrls: ['./fine-art-form.component.scss']
})
export class FineArtFormComponent implements AfterViewInit, ProductComponent {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;
  typeOfValue = false;
  fineArtType: UntypedFormControl; fineArtTypes = new Interest().fineArtTypes; cargoTypes = new Enums().cargoType;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef) {
  }
  
  ngAfterViewInit(){
    this.fineArtType = this.parent.form.get('proposalForm')?.get('overviewOfBusiness')?.get('fineArtType') as UntypedFormControl;
    this.cdr.detectChanges();
  }
  
  isDealer(){
    return this.fineArtType?.value === this.fineArtTypes.dealer;
  }
}
