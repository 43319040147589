<form [formGroup]="updateUserDetails">
  <div class="col-xl-5 col-lg-6 col-md-7 col-sm-8 col-12 col-center">
    <div class="pb-4 registerUserHeader">
      <h1>Update user details</h1>
      <p>....please complete all of the fields</p>
    </div>
    <label class="pb-5">Users being updated : {{updateUserDetails?.get('emails')?.value | json}}</label>
    <app-update-user-cva formControlName="userdata"></app-update-user-cva>
    <span class="d-flex justify-content-end pt-2 pb-5">
      <button class="button smallButton" (click)="updateSelectedUserDetails()" [disabled]="!updateUserDetails.valid">Update Users</button>
    </span>
  </div>
</form>