import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroupDirective, ControlContainer, UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SVSale } from '../../models/typing';

@Component({
  selector: 'app-sales-revenues',
  templateUrl: './sales-revenues.component.html',
  styleUrls: ['./sales-revenues.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class SalesRevenuesComponent implements OnInit, OnDestroy {
  @Input() preForm: any;
  @Input() set disabled(value: boolean) {
    if(value || value === false) {
      this.setState(value)
    }
   }
  form: UntypedFormGroup;
  saleYear: number = 0;
  unsub: Subject<boolean> = new Subject<boolean>();
  public salesRevenues = new UntypedFormArray([
  ]);
  constructor(private parent: FormGroupDirective) {
    this.form = this.parent.form;
  }

ngOnInit(): void {
  this.saleYear = new Date().getFullYear();
  (this.form.get('proposalForm') as UntypedFormGroup).addControl('salesRevenues', this.salesRevenues);
  this.push(2);
  this.onValueChanges();
  this.setupSales();
}

onValueChanges(): void {
  this.complete();
  this.unsub = new Subject<boolean>();
  const sales = this.salesRevenues;
  merge(...sales.controls.map((control: AbstractControl, index: number) =>
  control.valueChanges.pipe(map(value => ({ rowIndex: index, value })))))
  .pipe(
    takeUntil(this.unsub)
  )
  .subscribe(changes => {
    const sale = sales.at(changes.rowIndex);
    const cl = changes.value;
    cl?.amount?.figure ? sale.get('year')?.enable({ emitEvent: false }) :
    sale.get('year')?.disable({ emitEvent: false });
  });
}

addSales(state: boolean) {
  const year = this.saleYear - this.salesRevenues.length;
  const newSale = new UntypedFormGroup({
    year: new UntypedFormControl({value: year, disabled: state}),
    amount: new UntypedFormControl(''),
  });
  this.salesRevenues.push(newSale);
  this.onValueChanges();
}

removeSales(i: number) {
  this.salesRevenues.removeAt(i);
}

getSales() {
  return this.salesRevenues.controls;
}

setupSales() {
  const salesArray = this.preForm.proposalForm?.salesRevenues;
  const yearsReq = salesArray?.reduce((min: number, period: { year: number; }) => period.year < min ? period.year : min, salesArray?.[0].year);
  this.push((this.saleYear - yearsReq) - 1);
  salesArray?.forEach ((sale: SVSale) => {
    this.handleSetup(sale, this.salesRevenues);
  });
  this.getSales().forEach(element => {
    if (!element?.get('amount')?.get('figure')?.value) {
      element.get('year')?.disable();
    }
  });
}

handleSetup(sale: SVSale, form: UntypedFormArray) {
  const filterSale = form.controls.filter(s => s.get('year')?.value === sale.year);
  filterSale?.[0]?.patchValue({
    year : sale.year,
    amount: sale.amount
  });
}

push(no: number) {
  while (no > 0) {
    this.addSales(false);
    no--;
  }
}

setState(disabled: boolean){
  disabled ? this.salesRevenues.disable() : this.salesRevenues.enable();
}

complete() {
  this.unsub.next(true);
  this.unsub.complete();
}

ngOnDestroy() {
  this.complete();
}
}
