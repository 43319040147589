<div [ngClass]="{'hide' : surplusLines.disabled}" [formGroup]="surplusLines">
    <div class="controlInput">
      <input class="controlFull" type="text" formControlName="surplusLineBrokerName" placeholder="Broker Name">
      <label class="controlLabel">
        Surplus Line Broker Name*
      </label>
    </div>
    <div class="controlInput">
      <input class="controlFull" type="text" formControlName="licenseNo" placeholder="License No">
      <label class="controlLabel">
        License No*
      </label>
    </div>
    <div class="controlInput">
      <input class="controlFull" type="text" formControlName="stateOfFiling" placeholder="State of Filing">
      <label class="controlLabel">
        State of Filing*
      </label>
    </div>
    <div class="controlInput">
      <input class="controlFull" type="text" formControlName="city" placeholder="City">
      <label class="controlLabel">
        City*
      </label>
    </div>
    <div class="controlInput">
      <input class="controlFull" type="text" formControlName="zipOrPostCode" placeholder="Zip">
      <label class="controlLabel">
        Zip*
      </label>
    </div>
    <div class="controlInput customSelect">
      <mat-select formControlName="stateOrCounty">
        <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
      </mat-select>
      <label class="controlLabel">
        State*
      </label>
    </div>
    <div class="controlInput customSelect">
      <mat-select formControlName="country">
        <mat-option *ngFor="let c of countries" [value]="c">{{c}}</mat-option>
      </mat-select>
      <label class="controlLabel">
        Country*
      </label>
    </div>
    <div class="colMargin">
      <div class="controlInput">
        <input class="controlFull" type="text" formControlName="lines" placeholder="Lines">
        <label class="controlLabel">
          Line Address*
        </label>
      </div>
    </div>
</div>