import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegisterUsersBaseComponent, PortalDataService, PortalUtilService } from 'portal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-user-form',
  templateUrl: './new-user-form.component.html',
  styleUrls: ['./new-user-form.component.scss']
})

export class NewUserFormComponent extends RegisterUsersBaseComponent implements OnInit {
  user = new UntypedFormGroup ({
    email : new UntypedFormControl('', [Validators.required]),
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
    userdetails: new UntypedFormControl('', [Validators.required]),
  })

  constructor(public portalDataService: PortalDataService, public pUtil: PortalUtilService
    ) { super(portalDataService, pUtil) }

  ngOnInit(){
    this.onValueChanges();
  }

  onValueChanges(): void {
    this.user.get('email')?.valueChanges
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((changes: string) => {
      this.user?.get('email')?.setValue(changes?.replace(/\s/g,''), { emitEvent: false })
    });
  }

  registerUser(){
    const rawData = this.user.getRawValue();
    delete rawData["userdetails"];
    const detailsToSend = this.pUtil.combineDefault(rawData, this.user.get('userdetails')?.value);
    super.registerUserOnSovapi(detailsToSend);
  }

}
