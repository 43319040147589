<h1 class="header">5. EQUIPMENT SCHEDULE</h1>

<div formGroupName="proposalForm" class="exposure colMargin">
    <div formGroupName="equipmentScheduleTIV">
        <div class="col-lg-6 col-md-7 col-sm-8 col-xs-12 col-xss-12">
            <div class="colMargin pt-3">
                <app-amount [required]="true" formControlName='stored' name="Stored TIV"></app-amount>
            </div>
        </div>
        <div class="col-lg-6 col-md-7 col-sm-8 col-xs-12 col-xss-12">
            <div class="colMargin pt-3">
                <app-amount [required]="true" formControlName='operational' name="Operational TIV"></app-amount>
            </div>
        </div>
    </div>
</div>

<div class="equipmentSOV">
    <div class="text">
        Please attach a complete Schedule of Equipment including the following details:
        <ul>
            <li>Year, Make, Model, Serial Number, Current Market Value, and Loss Payee for each unit</li>
            <li>If any units purchased new within the current year, advise full Cost to Replace and Purchase Date</li>
        </ul>
    </div>      
    <div class="ps-3" [ngClass]="{'ng-invalid' : equipmentScheduleAttachment?.errors}" domControl>
        <app-upload-document-base (attachmentCount)="checkAttachment($event)" [docID]="preForm.id" [id]="'SOV'"></app-upload-document-base>
    </div>
    <ng-container *ngIf="equipmentScheduleAttachment.errors && equipmentScheduleAttachment.touched">
        <div class="ps-5 pt-3">
            <mat-error>
                Please provide a Schedule of Values with your submission as a minimum.
            </mat-error>
        </div>
    </ng-container>
</div>