<div formArrayName="scheduleOfValues" class="scheduleForm" pt-appDisableAll [disableAll] = 'status ? true : false'>
    <div *ngIf="scheduleInfo" class="responsiveWidth col-md-12 container-fluid">
        <div class="col stickyColumn">
            <div class="row filler">
                <app-sov-get-json-from-file *ngIf="!status && importSupported" [preForm]="preForm" (setupSchedule)="setupSchedule($event); onValueChanges()"></app-sov-get-json-from-file>
            </div>
            <div *ngFor="let scheduleItem of scheduleInfo" class="row primary medium">
                {{scheduleItem.display}}
                <div *ngIf="scheduleItem.type === sovTypes.Dropdown" class="ms-auto" id='arrowDown'></div>
                <span class="smallScheduleText ms-2" *ngIf="scheduleItem?.description">
                    {{scheduleItem.description}}
                </span>
            </div>
        </div>
        <div class="col book scheduleCol" *ngFor="let schedule of getSchedule() ; let i=index" [formGroupName] = "i">
            <div class="row primary">
                <div class ="d-flex align-items-center ms-auto medium">
                    Schedule Item {{i + 1}}
                    <span class="remove pointer ms-auto" (click)="removeSchedule(i)">
                        <div id="removeIcon"></div>
                    </span>
                </div>
            </div>
            <div class="row validation" *ngFor="let scheduleItem of scheduleInfo ; let j=index">
                <div *ngIf="scheduleItem.type === sovTypes.Input" formGroupName="{{scheduleItem.formGroupName}}">
                    <input type="text" formControlName="{{scheduleItem.key}}"
                    pt-appSovListener [control]="schedule.get(scheduleItem.formGroupName).get(scheduleItem.key)"
                    [scheduleItem]="scheduleItem">

                    <ng-container *ngIf="schedule.get(scheduleItem.formGroupName)?.get(scheduleItem.key).errors?.nonValidZip;else inputInvalid">
                        <label class="text-danger"><span class="requiredText">
                            {{schedule.get(scheduleItem.formGroupName)?.get(scheduleItem.key).errors?.nonValidZip}}
                        </span><span class="required">*</span></label>
                    </ng-container>
                    
                    <ng-template #inputInvalid>
                        <label class="text-danger"><span class="requiredText">required</span><span class="required">*</span></label>
                    </ng-template>
                </div>
                <ng-container *ngIf="scheduleItem.type === sovTypes.Radio">
                    <div class="d-flex spaceEvenly text" [ngClass]="{'ng-invalid' : schedule.get(scheduleItem.formGroupName).get(scheduleItem.key).errors}">
                        <div class="d-flex align-items-center">
                            <input [name]="scheduleItem.display+i" [id]="scheduleItem.display+i+'YES'" type="radio" value="yes"
                            pt-appBoolControl [boolControl]="schedule.get(scheduleItem.formGroupName).get(scheduleItem.key)">
                            <label class="ms-1" [for]="scheduleItem.display+i+'YES'">Yes</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input [name]="scheduleItem.display+i" [id]="scheduleItem.display+i+'NO'" type="radio" value="no"
                            pt-appBoolControl [boolControl]="schedule.get(scheduleItem.formGroupName).get(scheduleItem.key)">
                            <label class="ms-1" [for]="scheduleItem.display+i+'NO'">No</label>
                        </div>
                    </div>
                    <label class="text-danger"><span class="requiredText">required</span><span class="required">*</span></label>
                </ng-container>
                <div class="scheduleSelect" formGroupName="{{scheduleItem.formGroupName}}" *ngIf="scheduleItem.type === sovTypes.Dropdown">
                    <select class="dropdown" formControlName="{{scheduleItem.key}}">
                        <option value=''></option>
                        <option *ngFor="let el of scheduleItem.data" [ngValue]="el">{{el}}</option>
                    </select>
                    <label class="text-danger"><span class="requiredText">required</span><span class="required">*</span></label>
                </div>
                <ng-container *ngIf="scheduleItem.type === sovTypes.Amount">
                    <input type="text" pt-appCurrency [inputControl]="schedule.get(scheduleItem.formGroupName).get('figure')"
                    [ngClass]="{'ng-invalid' : schedule.get(scheduleItem.formGroupName).get('figure').errors}">

                    <ng-container *ngIf="schedule.get(scheduleItem.formGroupName).get('figure').errors?.scheduleOverLimit;else invalid">
                        <label class="text-danger"><span class="requiredText">
                            {{schedule.get(scheduleItem.formGroupName).get('figure').errors?.scheduleOverLimit}}
                        </span><span class="required">*</span></label>
                    </ng-container>
                    
                    <ng-template #invalid>
                        <label class="text-danger"><span class="requiredText">required</span><span class="required">*</span></label>
                    </ng-template>
                </ng-container>
            </div>
        </div>
        <button class="button smallButton secondary" (click)="addSchedule()">Add More</button>
    </div>
</div>