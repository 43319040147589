import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { UploadDocumentBaseComponent } from '../additional-docs/upload-document-base/upload-document-base.component';

@Component({
  selector: 'app-equipment-schedule',
  templateUrl: './equipment-schedule.component.html',
  styleUrls: ['./equipment-schedule.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class EquipmentScheduleComponent implements OnInit {
  @Input() preForm: any
  equipmentScheduleAttachment = new UntypedFormControl()
  equipmentScheduleTIV = new UntypedFormGroup({
    stored : new UntypedFormControl(),
    operational : new UntypedFormControl()
  })

  constructor(private parent : FormGroupDirective) {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('equipmentScheduleTIV', this.equipmentScheduleTIV);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('equipmentScheduleAttachment', this.equipmentScheduleAttachment);
  }

  ngOnInit(): void {
    this.equipmentScheduleTIV.patchValue({
      stored : this.preForm?.proposalForm?.equipmentScheduleTIV?.stored,
      operational : this.preForm?.proposalForm?.equipmentScheduleTIV?.operational,
    })
  }
  
  checkAttachment(att: any){
    const attControl = this.parent.form.get('proposalForm')?.get('equipmentScheduleAttachment')
    if(att > 0){
      attControl?.setErrors(null)
    } else {
      attControl?.setErrors({missingSov:true})
    }
  }

}
