import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-required-in-house-mecs',
  templateUrl: './coverage-required-in-house-mecs.component.html',
  styleUrls: ['./coverage-required-in-house-mecs.component.scss']
})
export class CoverageRequiredInHouseMecsComponent {
  @Input() group: UntypedFormGroup
  condition = [{display: 'Excellent', value: 'Excellent'}, {display: 'Good', value: 'Good'}, {display: 'Fair', value: 'Fair'}]

  constructor(public pUtil: PortalUtilService) { }

  setCondition(condition: string){
    this.group.get('condition')?.setValue(condition)
  }

}