import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { PortalUtilService } from 'portal';


@Component({
  selector: 'app-insurers-declined-to-renew',
  templateUrl: './insurers-declined-to-renew.component.html',
  styleUrls: ['./insurers-declined-to-renew.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class InsurersDeclinedToRenewComponent implements OnInit {
  @Input() preForm: any;
  @Input() coverageRequired: UntypedFormGroup;
  previouslyDeclinedCoverage = new UntypedFormControl('', [Validators.required])
  toggleInformation : any = {};

  constructor(public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.coverageRequired.addControl('previouslyDeclinedCoverage', this.previouslyDeclinedCoverage)
    this.coverageRequired.get('previouslyDeclinedCoverage')?.setValue(this.preForm.proposalForm?.coverageRequired?.previouslyDeclinedCoverage)
    this.setupToggle()
  }

  setupToggle(){
    this.toggleInformation['previouslyDeclinedCoverage'] = {
      text : "Has any Insurer (including Lloyd's) declined to renew Equipment Coverage in the past?",
      listOfEnabledToggles: [],
      childControlName: 'previouslyDeclinedCoverage',
      data : this.preForm.proposalForm?.coverageRequired?.previouslyDeclinedCoverage,
      parentGroup : this.coverageRequired,
      clonedParentGroup: _.cloneDeep(this.coverageRequired)
    }
  }

}
