<div class="loginPage">
    <i *ngIf="hidden" class="loginSpinner"><mat-spinner></mat-spinner></i>
    <div *ngIf="!hidden" class="loginContainer">
        <div class="loginLogos">
            <div class="col-lg-9 col-md-10 col-sm-11 logoWrapper">
                <div id="underLogo"></div>
                <div class="d-flex">
                    <span class="mcgillPowered">Powered by</span>
                    <div id="loginMLogo"></div>
                </div>
            </div>
        </div>
        <div class="loginButton">
            <div class="loginButtonWrapper">
                <button *ngIf='isSupportedBrowser' id="loginButton" (click)="clickLoginButton()">
                    Log In
                </button>
            </div>
        </div>
    </div>
</div>