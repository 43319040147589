import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalDataService } from '../../../../services/portal-data.service';
import { PortalUtilService } from '../../../../services/portal-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pt-admin-portal-base',
  templateUrl: './admin-portal-base.component.html',
  styleUrls: ['./admin-portal-base.component.css']
})
export class AdminPortalBaseComponent implements OnInit, OnDestroy {
  adminFunctions = {
    userManagement: 'User Accounts',
    riskManagement: 'Risk Management',
    adminPage: 'Admin Page',
    logoManagement: 'Logo Management'
  }
  adminFunction = this.adminFunctions.adminPage;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService, public router: Router,
    public activeRoute: ActivatedRoute, public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.isManager();
  }

  showAdminFunction(func: string){
    this.router.navigate(['/contracts/admin'],{ queryParams: { page: this.pUtil.rmvSpace(func)} });
  }

  setupAdmin(){
    this.activeRoute.queryParams.subscribe(params => {
      params?.['page'] ? this.adminFunction = this.pUtil.addSpace(params['page'])
      :this.adminFunction = this.adminFunctions.adminPage;
    });
  }

  isManager() {
    this.portalDataService.isManager()
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) => {
      if(!response.allowed){
        this.router.navigate(['/contracts'])
      } else {
        this.setupAdmin();
      }
    },
    error => {
      this.router.navigate(['/contracts'])
    });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
