import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-interest-type',
  templateUrl: './interest-type.component.html',
  styleUrls: ['./interest-type.component.scss']
})
export class InterestTypeComponent implements OnInit {
  @Input() interestControl: UntypedFormControl
  @Input() interestTypes: InterestType[]
  @Input() required:boolean = false
  constructor() { }

  ngOnInit(): void {
    if(this.required){
      this.interestControl.setValidators(Validators.required)
      this.interestControl.updateValueAndValidity()
    }
  }

  chooseInterest(interest: string) {
    this.interestControl.setValue(interest)
  }

}

export interface InterestType {
  key: string
  display: string
  value: string
}