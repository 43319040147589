import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract, ContractsService, LineageAuxData, LineageNode, PortalDataService, PortalUtilService } from 'portal';
import { BehaviorSubject, Subject } from 'rxjs';
// import { map } from "rxjs/operators"

@Component({
  selector: 'app-family-tree-level',
  templateUrl: './family-tree-level.component.html',
  styleUrls: ['./family-tree-level.component.scss']
})

export class FamilyTreeLevelComponent implements OnInit{
  @Input() tree: LineageNode;
  @Input() nottoplevel?: boolean;
  @Input() auxData: {[key:string]:LineageAuxData}
  treeRiskInfo: BehaviorSubject<{[key:string]: Contract}> = new BehaviorSubject({})
  docID: string;
  
  constructor(
    public portalDataService: PortalDataService,
    public router: Router,
    public pUtil: PortalUtilService,
    private activeRoute: ActivatedRoute
  ) {
    //
  }

  ngOnInit(): void {
    this.getProduct();
  }
  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      const docID =  params.get('doc')
      if(docID){
        this.docID = docID
      }
    });
  }
  parents(): string[]{
    return Object.keys(this.tree)
  }
  docFromId(id:string){
    return this.auxData[id]
  }
  periodFormat(aux: LineageAuxData) {
    return (new Date(aux.period?.startDate)).toLocaleDateString("en-GB", {month:"short", year:"numeric"}) +
    " - " + (new Date(aux.period?.endDate)).toLocaleDateString("en-GB", {month:"short", year:"numeric"})
  }
  clickContract(id:string, aux: LineageAuxData) {
    this.router.navigate(['/contracts/updateContract', this.pUtil.rmvSpace(aux.product) , id], { queryParams: { status: aux.status }}).then(() => {
      window.location.reload();
    })
  }
}
