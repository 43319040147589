import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { Contract } from '../model/models';
import { ContractsServiceResponse, PTEnvironment } from '../model/typings';
import { debounce, tap, takeWhile } from 'rxjs/operators';
import { MessagesService } from './messages.service';

@Injectable({
  providedIn: 'root'
})

export class ContractsService implements OnDestroy {
  private alive = true
  public userRisksSubject = new BehaviorSubject<ContractsServiceResponse>({filteredContracts:[], contracts:[], numberblank: 0})
  private userRisks: (Contract|undefined)[]
  private numRisks: number
  public contractsFilter: string | null;
  public searchNameFilter: string | null;
  public sortCategory: keyof Contract
  public sortOrderAscending: boolean
  private mostRecentOffset = 0
  public DownloadChunkSize = 100
  
  constructor(
    @Inject('env') private environment: PTEnvironment,
    private _http: HttpClient,
    private zone: NgZone,
    private messagesService: MessagesService
  ) {
    this.listenForUpdatesToContracts()
  }

  ngOnDestroy(): void {
      this.alive = false
  }

  numberOfUserContracts(){
    const params = {
      filterString: this.searchNameFilter,
      filterStatus: this.contractsFilter
    }
    return this._http.post<{count:number}>(this.environment.baseapi + '/numrisks', params, { headers: new HttpHeaders().set('X-POPUP', 'NO') , withCredentials: true }).pipe( tap((response: {count:number}) => {
      //console.log(response)
      this.numRisks = response.count;
    }))
  }

  getUserContracts(offset?:number, forceReload = false){
    offset = offset ?? this.mostRecentOffset ?? 0
    this.mostRecentOffset = offset
    const testDate = (new Date())
    testDate.setMinutes(testDate.getMinutes()-1)
    if(forceReload || !this.userRisks?.length || this.userRisks?.slice(offset, offset! + this.DownloadChunkSize).some(item => !item || !item.downloadedAt || item.downloadedAt < testDate)){
      // console.log("AAAA", offset)
      const params = {
        offset,
        limit:this.DownloadChunkSize,
        sort:this.sortCategory,
        asc:this.sortOrderAscending,
        filterString: this.searchNameFilter,
        filterStatus: this.contractsFilter
      }
      this._http.post<{rows:Contract[], total:number}>(this.environment.baseapi + '/risks', params, { headers: new HttpHeaders().set('X-POPUP', 'NO') , withCredentials: true })
      .subscribe((response: {rows:Contract[], total:number}) => {
        this.numRisks = response.total
        if(!this.userRisks || this.userRisks?.length !== this.numRisks){
          this.userRisks = (new Array(this.numRisks)).fill(undefined)
        }
        const now = new Date()
        this.userRisks.splice(offset!, this.DownloadChunkSize, ...response.rows.map(item => {
          item.downloadedAt = now
          return item
        }));
        this.setContracts();
      })
    }
  }
  updateSingleUserContract(riskID:string){
    const cachemostRecentOff = this.mostRecentOffset
    this._http.get<Contract>(this.environment.baseapi + '/risk/'+riskID, { headers: new HttpHeaders().set('X-POPUP', 'NO') , withCredentials: true })
    .subscribe((response: Contract) => {
      // console.log("***********", response)
      if(response.id){
        const indextoedit = this.userRisks.findIndex(risk => risk && risk.id == response.id);
        if(indextoedit >= 0){
          this.userRisks[indextoedit] = response
          this.setContracts();
        } else {
          this.getUserContracts(cachemostRecentOff, true)
        }
      }
    })
  }

  listenToUserContracts(): Observable<ContractsServiceResponse> {
    return this.userRisksSubject.asObservable();
  }

  setContracts(){
    // let contracts: (Contract|undefined)[] = this.userRisks?.filter((contract?: Contract) => {
    //     if(contract === undefined){
    //       return true
    //     }
    //     if(this.notArchived(contract) && this.nameMatch(contract) && this.statusMatch(contract)){
    //       return contract;
    //     }
    // });
    this.userRisksSubject.next({
      filteredContracts: this.userRisks,
      contracts: this.userRisks,
      numberblank: this.userRisks.length - this.userRisks.map(item => item === undefined).lastIndexOf(false) - 1
    });
  }

  notArchived(contract: Contract){
    return !contract.status?.toLowerCase()?.includes('arc');
  }

  nameMatch(contract: Contract){
    return !this.searchNameFilter ? true : contract.contractName?.toLowerCase()?.indexOf(this.searchNameFilter.toLowerCase()) !== -1
  }

  statusMatch(contract: Contract){
    return !this.contractsFilter ? true : contract.status?.toLowerCase()?.includes(this.contractsFilter);
  }


  setContractsSort(key:keyof Contract, orderAsc:boolean){
    if(key === this.sortCategory && orderAsc !== this.sortOrderAscending){
      this.userRisks.reverse()
    } else {
      this.userRisks = []
    }
    this.sortOrderAscending = orderAsc
    this.sortCategory = key
    this.getUserContracts()
  }
  setContractsFilter(contractsFilter: string | null){
    this.contractsFilter = contractsFilter;
    this.userRisks = []
    this.getUserContracts();
  }

  setSearchNameFilter(searchNameFilter: string | null){
    this.searchNameFilter = searchNameFilter;
    this.userRisks = []
    this.getUserContracts();
  }

  clearFilters(){
    this.contractsFilter = null;
    this.searchNameFilter = null;
    this.setContracts();
  }

  private listenForUpdatesToContracts() {
    // primarily we'll be getting updates from sse when activities from the platform has been processed
    // but there might be some situations where updates originate elsewhere for example: unlocking, cloning
    this.setupSSE()
    this.setupPolling()
  }

  private pollSubscription?: Subscription
  private setupPolling() {
    this.pollSubscription = timer(undefined, 5 * 60 * 1000).pipe(
      takeWhile(()=>this.alive),
      tap(_ => console.log('contract poll fired'))
    )
    .subscribe(_ => this.getUserContracts())
  }

  private setupSSE(counter?: number) {
    this.fromEventSource(`${this.environment.baseapi}/events`).pipe(
      takeWhile(()=>this.alive),
      tap((msg) => console.log('got new data', JSON.parse(msg.data))),
      // debounce(() => timer(500))
    )
    .subscribe({
      next: (msg:MessageEvent) => {
        const data = JSON.parse(msg.data)
        if(data.type === "Risk"){
          this.updateSingleUserContract(data.data)
        }
        if(data.type === "Message"){
          this.updateSingleUserContract(data.data.riskid)
          this.messagesService.receiveMessageMaybe(data.data.riskid, data.data.SVComments)
        }
        this.pollSubscription?.unsubscribe()
        this.setupPolling()
      },
      error: () => setTimeout(() => {console.log("onerrorhello"); this.setupSSE()}, 5 * 1000),
      complete: () => setTimeout(() => {console.log("oncompletehello")}, 5 * 1000)
      // error: () => {
      //   counter ? counter = counter + 1 : counter = 1;
      //   setTimeout(() => this.setupSSE(counter), ((10 * 1000) * (counter > 6 ? 6 : counter)))
      // },
      // complete: () => setTimeout(() => this.setupSSE(), 10 * 1000)
    })
  }

  private fromEventSource(url: string): Observable<MessageEvent> {
    return new Observable<MessageEvent>(subscriber => {
      const sse = new EventSource(url, { withCredentials: true });

      sse.onmessage = e => this.zone.run(() => subscriber.next(e));
      sse.onerror = e => this.zone.run(() => subscriber.error(e));

      return () => {
        // if (sse.readyState === 1) {
            sse.close();
            this.zone.run(() => subscriber.complete())
        //  }
       };
    })
  }
}
