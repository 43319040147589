import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloneAsDraftPopupComponent, Contract } from 'projects/portal/src/public-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContractsService } from '../../../services/contracts.service';

@Component({
  selector: 'pt-clone-as-draft',
  templateUrl: './clone-as-draft.component.html',
  styleUrls: ['./clone-as-draft.component.css']
})
export class CloneAsDraftComponent implements OnDestroy {
  @Input() contract: Contract;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public dialog: MatDialog, private contractsService: ContractsService) { }

  cloneRisk(){
    const dialogRef = this.dialog.open(CloneAsDraftPopupComponent, {
      data: {contract: {id: this.contract.id, contractName: this.contract.contractName}},
      panelClass: ['custom-dialog-container', 'roundedCorners'],
      autoFocus: false
    });
    dialogRef.afterClosed()
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((resp: any) => {
      this.contractsService.updateSingleUserContract(resp.id)
    });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
