<app-top-nav></app-top-nav>
<div *ngIf='adminFunction !== adminFunctions.adminPage' class="d-flex justify-content-end mt-3 me-4">
  <button (click)="showAdminFunction(adminFunctions.adminPage)">Back to admin page</button>
</div>

<div class="adminPage" *ngIf='adminFunction === adminFunctions.adminPage'>
  <h1>Welcome to Underscore Admin Page</h1>
  <button class="mt-4 adminPageButtons" (click)="showAdminFunction(adminFunctions.userManagement)">User Management</button>
  <button class="mt-2 adminPageButtons" (click)="showAdminFunction(adminFunctions.riskManagement)">Risk Management</button>
  <button class="mt-2 adminPageButtons" (click)="showAdminFunction(adminFunctions.logoManagement)">Logo Management</button>
</div>

<div *ngIf='adminFunction === adminFunctions.userManagement'>
  <app-admin-user-account-management-functions></app-admin-user-account-management-functions>
</div>

<div *ngIf='adminFunction === adminFunctions.riskManagement'>
  <app-admin-risk-management-functions></app-admin-risk-management-functions>
</div>

<div *ngIf='adminFunction === adminFunctions.logoManagement'>
  <pt-admin-logo-set></pt-admin-logo-set>
</div>
