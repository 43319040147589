<h1 class="header">{{secName}}</h1>
<div [ngClass]="{'ng-invalid' : additionalInformation.hasError('missingSchedules')}" domControl>
    <app-upload-document-base [docID]="docID" [label]="label" (attachmentCount)="hasLoss($event)"></app-upload-document-base>
    <div class="ps-5 pt-3">
        <mat-error *ngIf="additionalInformation.hasError('missingSchedules')" [innerHTML]="attachValidationText"></mat-error>
    </div>
</div>
<div formGroupName="proposalForm" class="col-xs-8 col-xxs-10 pt-4 p-0 colMargin">
    <div class="controlInput">
        <textarea id="informationBox" type="text" formControlName="additionalInformation" placeholder="Further Information..."></textarea>
        <label class="controlLabel">
            Please provide any additional information
        </label>
    </div>
</div>