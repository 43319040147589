<h1 class="header">{{secName}}</h1>

<div class="col-12 p-0 pb-1 text colMargin">
    <div class="mt-5 mb-3 text">
        <p class="mb-4">Has there been any losses in the last five years?</p>
        <div class="d-flex">
            <span>No</span>
            <label class="switch me-3 ms-3">
                <input #lossHistory (click)="toggleIfLossExists()" type="checkbox" [attr.checked]="hasLosses.value ? true : null">
                <span class="slider"></span>
            </label>
            <span>Yes</span>
        </div>
    </div>
</div>
<div [ngClass]="{'hide' : !hasLosses.value, 'ng-invalid' : hasLosses.hasError('missingLossHistory')}" domControl>
    <app-upload-document-base [docID]="docID" [label]="'Please provide any supporting Loss documentation'" [id]="'LossExperience'"
    (attachmentCount)="hasLoss($event)">
    </app-upload-document-base>
    <div class="ps-5 pt-3">
        <mat-error *ngIf="hasLosses.hasError('missingLossHistory')" [innerHTML]="attachValidationText"></mat-error>
    </div>
</div>