import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UpdateUsersBaseComponent, PortalDataService, PortalUtilService } from 'portal';

@Component({
  selector: 'app-update-details-form',
  templateUrl: './update-details-form.component.html',
  styleUrls: ['./update-details-form.component.scss']
})

export class UpdateDetailsFormComponent extends UpdateUsersBaseComponent implements OnInit {
  @Input() userdata: any;
  updateUserDetails = new UntypedFormGroup({
    emails : new UntypedFormArray([
    ]),
    userdata : new UntypedFormControl(),
  })

  constructor(public portalDataService: PortalDataService, public pUtil: PortalUtilService
    ) { super(portalDataService, pUtil) }

  ngOnInit(){
    super.updateUserDetails = this.updateUserDetails;
    super.ngOnInit();
    if(this.userdata){
      this.updateUserDetails.get('userdata')?.patchValue(this.userdata);
    }
  }

}
