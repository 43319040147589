<ng-container *ngIf = 'preForm'>
    <section pt-appDisableAll [disableAll] = '!platformStatus' class="formSection" id="section1">
        <app-insured-address [preForm]='preForm' [productType]='productType'></app-insured-address>
        <app-business-overview [preForm]='preForm'></app-business-overview>
        <app-upload-document-base [docID]="docID" [label]="'Please provide any supporting documentation'" [id]="'BusinessOverview'">
        </app-upload-document-base>
        <app-policy-type [policyType]='policyType' [preForm]='preForm'></app-policy-type>
        <span *ngIf='casualtyType && otherDetailCasualty'>
            <app-casualty-type-detail [policyType]='policyType.value' [otherDetail]='otherDetailCasualty' 
                [casualtyType]='casualtyType'></app-casualty-type-detail>
                
                <div *ngIf="option === 2" class="col-lg-4 col-md-5 col-sm-7 col-10 ps-0 pt-5 buttonWrapper tableButtonPadding text colMargin memberOfListedOrg">
                    <label class="pb-3 pt-4">Are you an RCABC or ARCA Member?</label>
                    <mat-error class="typeValueError" *ngIf="isMember?.errors?.required">
                        *
                    </mat-error>
                    <div class="tableButtons btn-group text" [ngClass]="{'ng-invalid' : isMember?.errors}" domControl>
                      <button (click)= 'setMember(true)'
                            [ngClass]="{'active' : isMember?.value === true}">YES</button>
                      <button (click)= 'setMember(false)'
                            [ngClass]="{'active' : isMember?.value === false}">NO</button>
                    </div>
                </div>

                <span [ngClass]="{'hide' : isMember?.value !== true && isMember?.enabled}">
                    <div *ngIf="salesType && option">
                        <app-total-sales-revenues [preForm]="preForm" [salesType]="salesType"></app-total-sales-revenues>
                        <app-coverage-required [preForm]="preForm" [option]="option"></app-coverage-required> 
                    </div>
                    
                    <app-loss-experience-upload [docID]="docID" [secName]="'6. Loss Experience'"></app-loss-experience-upload>
                    <app-additional-docs [docID]='docID' [preForm]="preForm" [secName]="'7. Additional Information'"></app-additional-docs>
                    <app-disclaimer [platformStatus]='platformStatus'></app-disclaimer>
                </span>
                <div class="colMargin isNotAMember" *ngIf="isMember?.value !== true && isMember?.enabled">
                    <label class="pt-5 ps-5 text">Currently, we are not able to provide coverage for Roofing Contractors who are not a member of the RCABC or ARCA.</label>
                </div>
        </span>
    </section>
</ng-container>