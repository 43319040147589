import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contract } from '../../model/models';
import { PTEnvironment, PTMessage } from '../../model/typings';
import { PortalDataService } from '../../services/portal-data.service';

@Component({
  selector: 'pt-risk-options-popup',
  templateUrl: './risk-options-popup.component.html',
  styleUrls: ['./risk-options-popup.component.css']
})

export class RiskOptionsPopupComponent implements OnInit, OnDestroy {
  message: PTMessage;
  contract: Contract | undefined;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public ref: MatDialogRef<RiskOptionsPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: PTMessage,
              private portalDataService: PortalDataService, @Inject('env') private environment: PTEnvironment) {
  }

  ngOnInit(): void {
    this.contract = this.data?.call?.contract;
    const call = this.data.call;
    if(call?.endpoint){
      this.call(call.endpoint);
    }
  }

  call(endpoint: string) {
    if(this.contract?.id){
      this.portalDataService.dropdownCall(this.contract.id, endpoint)
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe((response: any) => {
        this.message = response;
      },
      error => {
        this.ref.close();
      });
    }
  }

  furtherCall(endpoint: string) {
    this.portalDataService.dropdownFurtherCalls(endpoint)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      this.message = response;
    });
  }

  userCall() {
    this.furtherCall(this.environment.baseapi + this.message.nextEndpoint);
  }

  closePopup() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
    this.unsub.next(true);
    this.unsub.complete();
  }

}
