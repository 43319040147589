import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PTUser } from 'portal';

@Component({
  selector: 'app-update-products-on-multiple-users',
  templateUrl: './update-products-on-multiple-users.component.html',
  styleUrls: ['./update-products-on-multiple-users.component.scss']
})
export class UpdateProductsOnMultipleUsersComponent implements OnInit {
  @Input() selectedUsers: PTUser[];
  updateUserDetails = new UntypedFormGroup({
    emails : new UntypedFormArray([
    ]),
    userdata : new UntypedFormControl(),
  })
  constructor() { }

  ngOnInit(): void {
    this.selectedUsers.map((user) => {
      const email = new UntypedFormControl(user.email);
      (this.updateUserDetails.get('emails') as UntypedFormArray).push(email);
    });
  }

}
