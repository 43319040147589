import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { ContractListFilterBaseComponent, ContractsService } from 'portal';

@Component({
  selector: 'app-total-risk-detail',
  templateUrl: './total-risk-detail.component.html',
  styleUrls: ['./total-risk-detail.component.scss']
})

export class TotalRiskDetailComponent extends ContractListFilterBaseComponent {
  constructor(public contractsService: ContractsService) {
    super(contractsService)
  }
  
}
