

<!-- <div class="d-flex text pt-3">
    <span>No</span>
    <div class="neutral-toggle me-3 ms-3" [ngClass]="{'on' : toggleControl === true, 'off' : toggleControl === false, 'neutral': toggleControl == null}">
        <label class="neutral-switch no" [for]="toggleInformation?.childControlName + 'no'">
            <input type="checkbox" [name]="toggleInformation?.childControlName + 'no'" [id]="toggleInformation?.childControlName + 'no'" value="false" (click)='toggle(false)' >
        </label>
        <label class="neutral-switch na" [for]="toggleInformation?.childControlName + 'na'">
            <input type="checkbox" [name]="toggleInformation?.childControlName + 'na'" [id]="toggleInformation?.childControlName + 'na'" value="" (click)='toggle()'>
        </label>
        <label class="neutral-switch yes" [for]="toggleInformation?.childControlName + 'yes'">
            <input type="checkbox" [name]="toggleInformation?.childControlName + 'yes'" [id]="toggleInformation?.childControlName + 'yes'" value="true" (click)='toggle(true)'>
        </label>
        <span class="neutral-slider"></span>
    </div>
    <span>Yes</span>
</div> -->

<h4>{{toggleInformation?.text}}
    <span *ngIf="toggleInformation.parentGroup?.get(toggleInformation?.childControlName)?.invalid">*</span>
</h4>

<div class="radioButtonYesNo d-flex text" [ngClass]="{'ng-invalid' : toggleInformation.parentGroup?.get(toggleInformation?.childControlName)?.invalid}" domControl>
    <label class="radioOption me-3" [for]="toggleInformation?.childControlName + 'yes'">
        <input type="radio" [id]="toggleInformation?.childControlName + 'yes'" [name]="toggleInformation?.childControlName" (click)="toggle(true)" [attr.checked]="toggleControl === true ? true : null">
        <label [for]="toggleInformation?.childControlName + 'yes'">Yes</label>
    </label>
    <label class="radioOption" [for]="toggleInformation?.childControlName + 'no'">
        <input type="radio" [id]="toggleInformation?.childControlName + 'no'" [name]="toggleInformation?.childControlName" (click)='toggle(false)' [attr.checked]="toggleControl === false ? true : null">
        <label [for]="toggleInformation?.childControlName + 'no'">No</label>
    </label>
    <label *ngIf="toggleInformation.parentGroup?.get(toggleInformation?.childControlName)?.invalid
            && toggleInformation.parentGroup?.get(toggleInformation?.childControlName)?.touched"
            class="radioButtonValidation ms-5"></label>
</div>