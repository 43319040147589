import { Component, OnInit, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EMPTY, interval, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import * as moment_ from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { PortalUtilService } from '../../../services/portal-util.service';
import { PortalDataService } from '../../../services/portal-data.service';
import { ComponentCommunicationService } from '../../../services/component-communication.service';
import { Contract, CreatePost, modelUtils } from '../../../model/models';
import { SubmitCheckPopupComponent } from '../../../popups/submit-check-popup/submit-check-popup.component';
import { RiskService } from 'portal/services/risk.service';

@Component({
  selector: 'pt-update-contract-base',
  templateUrl: './update-contract-base.component.html',
  styleUrls: ['./update-contract-base.component.css']
})


export abstract class UpdateContractBaseComponent implements OnInit, OnDestroy {

  form = new UntypedFormGroup({proposalForm: new UntypedFormGroup({})}); 
  preForm: any; contractDetails: Contract; docID: string; revID: string;
  status: boolean; canSubmit = false; submitting = false; saving = false; userAction = new modelUtils().userAction
  lastSaveDate: Date; lastSaveVerbose: string;
  formType: string;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public activeRoute: ActivatedRoute, public portalDataService: PortalDataService, public router: Router,
    public el: ElementRef, public cdr: ChangeDetectorRef, public comm : ComponentCommunicationService, public dialog: MatDialog,
    public pUtil: PortalUtilService, public riskService: RiskService
  ){ }

  ngOnInit() {
    this.getProduct();
  }
  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
        this.formType = this.pUtil.addSpace(params.get('prod'));
        params.get('doc')?.includes('new') ? this.docID = '' : this.docID = params.get('doc') || '';
    });
    this.activeRoute.queryParams.subscribe(params => {
      this.isAllowed(params.status)
    });
  }
  getID() {
    return new Promise<void> (resolve => {
      if (this.docID) {
        this.portalDataService.getRisk(this.docID).
        pipe(
          takeUntil(this.unsub),
          finalize(() => this.loadComponent())
        )
        .subscribe((response: any) => {
          this.preForm = JSON.parse(JSON.stringify(response));
          this.preForm?.status ? this.status = false : this.status = true;
          delete response['proposalForm'];
          this.contractDetails = response;
          this.revID = response['_rev'];
          if(response?.errorDescripton === 'timeout'){
            this.router.navigate(['/contracts/timeout']);
          }
          resolve();
        });
      } else {
        this.riskService.pdfRiskJSON
        .pipe(takeUntil(this.unsub), finalize(() => {this.status = true, this.loadComponent(), resolve()}))
        .subscribe((resp) => {
          if(resp?.proposalForm || resp?.contractName){
            this.preForm = JSON.parse(JSON.stringify(resp))
            this.preForm.proposalForm.importedParts = ['WHOLE']
          } else {
            this.preForm = 'ready'
          }
          this.riskService.pdfRiskJSONComplete()
        })
      }
    });
  }
  async sendForm(userClick?: string) {
    return new Promise<void> (async (resolve) => {
      const json: JSON = JSON.parse(JSON.stringify(this.form.value));
      let accepted: boolean = false;
      await this.handleSendForm(json, userClick || '');
      if (userClick === this.userAction.submit) {
        accepted = await this.continueSubmit();
        if(accepted){
          this.submitting = true;
          await this.sendToPlatform();
        }
      }
      if (userClick === this.userAction.save || accepted) {
        this.status = false;
        this.router.navigate(['contracts']); 
      }
      resolve();
    });
  }
  async handleSendForm(json: any, userClick: string) {
    this.saving = true;
    const riskData = this.preForm.proposalForm
    if (!this.docID) {
      await this.createRisk(json.contractName, userClick);
    }
    json = this.beforeHandleJsonSave(json, riskData);
    json = this.pUtil.combine(json, this.contractDetails as Contract);
    console.log(JSON.stringify(json));
    await this.updateContract(json, userClick);
  }
  createRisk(contractName: string, userClick: string) {
    return new Promise<void> (resolve => {
      const post = new CreatePost(contractName, '', this.formType);
      this.portalDataService.createNewRisk(post).
      pipe(
        takeUntil(this.unsub)
      )
      .subscribe(async (response: any) => {
        if((response?.error || !response.ok)){
          userClick ? this.pUtil.errorPopup({status:'While trying to save', message:response?.errorDescription}) : resolve();
          this.saving = false;
        } else {
          this.docID = response.id;
          await this.getID();
          resolve();
        }
      },
      error => {
        userClick ? this.pUtil.errorPopup({status:'While trying to save', message:error}) : resolve();
        this.saving = false;
      });
    });
  }
  updateContract(json: any, userClick: string) {
    return new Promise<void> (resolve => {
        this.portalDataService.updateContract(this.docID, json).
        pipe(
          takeUntil(this.unsub),
          finalize(() => this.saving = false)
        )
        .subscribe((response: any) => {
          if((response?.error || !response.ok)){
            this.submitting = false;
            userClick ?
            this.pUtil.errorPopup({status:'While trying to save', message:response?.msg}) : resolve();
          } else {
            this.lastSaveDate = new Date();
            const moment = moment_;
            this.lastSaveVerbose = moment(this.lastSaveDate).fromNow();
            this.revID = response['_rev'];
            resolve();
          }
        },
        error => {
          this.submitting = false;
          userClick ? this.pUtil.errorPopup({status:'While trying to save', message:error}) : resolve();
        });
    });
  }
  sendToPlatform() {
    return new Promise<void> (resolve => {
      this.portalDataService.sendToPlatform(this.docID).
      pipe(
        takeUntil(this.unsub),
        finalize(() => this.submitting = false)
      )
      .subscribe((response: any) => {
        if(response?.error){
          this.pUtil.errorPopup({status:'While trying to submit', message:response?.errorDescription});
        } else {
          resolve();
        }
      });
    });
  }
  autoSave() {
    if (this.status) {
      const time = interval(60000);
      time.pipe(
        takeUntil(this.unsub)
      )
      .subscribe(() => {
        this.form.get('contractName')?.value ? this.sendForm() : EMPTY;
      });
    }
  }
  submitListener() {
    this.comm.canSubmitListener().
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe((submit: boolean) => {
        this.canSubmit = submit;
    });
  }
  saveAndDirect() {
    this.comm.saveFormListener().
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(async (response) => {
      if (this.status) {
        await this.sendForm();
      }
      if (response.url){
        sessionStorage.setItem('scroll', window.pageYOffset.toString());
        this.status = false;
        this.router.navigate([response.url, this.pUtil.rmvSpace(response.prod), this.docID], { queryParams: { status: this.preForm?.status } });
      } else if(response.id){
        this.comm.saveComplete({id:response.id,docID:this.docID})
      }
    });
  }
  continueSubmit(){
    return new Promise<boolean> (resolve => {
      const dialogRef = this.dialog.open(SubmitCheckPopupComponent, {
        data: this.getSubmitPopupData(),
        panelClass: 'custom-dialog-container',
        width: '500px',
        autoFocus: false
      });
      dialogRef.afterClosed()
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe((response) => {
        resolve(response.accept);
      });
    });
  }
  invalidControlScroll() {
    this.form.markAllAsTouched();
    this.comm.setMarkAllAsTouched(true);
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "[domControl].ng-invalid"
    )
    if(firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' }); //without smooth behavior
    }
  }
  async isAllowed(status: string){
    this.portalDataService.getUserDetails()
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe(async(response: any) => {
      if((!this.pUtil.prodAllowed(this.formType, response?.products) || response.error) && !status){
        this.router.navigate(["/contracts"]);
      } else {
        await this.getID();
        this.autoSave();
        this.submitListener();
        this.saveAndDirect();
        this.cdr.detectChanges();
      }
    },
    error => {
      this.router.navigate(["/contracts"]);
    });
  }
  abstract getSubmitPopupData(): any;
  abstract beforeHandleJsonSave(formData: any, riskData: any): any;
  abstract loadComponent(): any;
  
  async ngOnDestroy() {
    if (this.form?.get('contractName')?.value && this.status) {
      await this.sendForm();
    }
    this.unsub.next(true);
    this.unsub.complete();
  }

}
