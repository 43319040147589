import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PortalDataService } from '../../../../../services/portal-data.service';
import { PortalUtilService } from '../../../../../services/portal-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PTUser } from 'projects/portal/src/lib/model/typings';

@Component({
  selector: 'pt-admin-user-account-base',
  templateUrl: './admin-user-account-base.component.html',
  styleUrls: ['./admin-user-account-base.component.css']
})
export class AdminUserAccountBaseComponent implements OnDestroy {
  selectedUsers: PTUser[] = [];
  userAccountFunctions = { registerUser: 'Register User', editUserDetails: 'Edit User', userPage:'Home Page', editUserProducts:'Edit Product Information'};
  userAccountFunction = this.userAccountFunctions.userPage;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService,
    public pUtil: PortalUtilService, public cdr : ChangeDetectorRef) { }

  resendInvitation(){
    const userIDs = this.getSelectedUserIDAsArray();
    const payload = {userIDs: userIDs};
    this.portalDataService.sendInviteEmailAgainToUsers(payload)
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) =>{
      if(response.error){
        this.pUtil.errorPopup({status:'While trying to resend invitations', message:response?.errorDescription});
      } else{
        alert(response?.messages?.[0]);
      }
    })
  }

  getSelectedUserIDAsArray(){
    const tempArr : string[] = [];
    this.selectedUsers.map((user: PTUser) => {
      tempArr.push(user.id);
    });
    return tempArr;
  }

  updateUsers(users: PTUser[]){
    this.selectedUsers = users;
  }

  updateUserFunction(userFunc: string){
    this.userAccountFunction = userFunc;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
