import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PortalDataService } from '../../../../../services/portal-data.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, debounceTime } from 'rxjs/operators';
import { PTUser } from 'projects/portal/src/lib/model/typings';

@Component({
  selector: 'pt-user-list-display-base',
  templateUrl: './user-list-display-base.component.html',
  styleUrls: ['./user-list-display-base.component.css']
})

export class UserListDisplayBaseComponent implements OnInit, OnDestroy {
  @Output() userListEmitter = new EventEmitter<any>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['id','firstName','lastName','userStatus','lastLogin','email','check'];
  dataSource = new MatTableDataSource<PTUser>(); users: PTUser[] = []; selectedUsers : PTUser[] = [];
  _filterValue:string
  _allSelected:boolean = false
  filterDebouncer = new Subject<string>();
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService, public router: Router) { }

  ngOnInit(): void {
    this.userListEmitter.emit([])
    this.getRegisteredUsers()
    this.filterDebouncer.pipe(
      distinctUntilChanged(),
      debounceTime(100),
      takeUntil(this.unsub)
    ).subscribe((val)=>{
      this.dataSource.filter = val
    })
  }

  get filterValue(){
    return this._filterValue
  }

  set filterValue(val:string){
    this._filterValue = val
    this.filterDebouncer.next(val)
  }

  checkUser(user: PTUser){
    const index = this.selectedUsers.findIndex(e => e.id === user.id)
    if(index >= 0){
      this.selectedUsers.splice(index, 1)
    } else {
      this.selectedUsers.push(user)
    }
    this.updateParentUsers()
  }

  selectAllUsers(){
    this._allSelected = !this._allSelected
    this._allSelected ? this.selectedUsers = [...this.dataSource.filteredData] : this.selectedUsers = []
    this.updateParentUsers()
  }

  userIsChecked(user: PTUser){
    return this.selectedUsers.filter(x => x.email === user.email).length > 0
  }

  updateParentUsers(){
    this.userListEmitter.emit(this.selectedUsers)
  }

  getRegisteredUsers(){
    this.portalDataService.getRegisteredUsers()
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) =>{
      this.users = response.users
      this.setupDataSource(this.users)
    })
  }

  setupDataSource(users: PTUser[]){
    this.dataSource.data = users
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (data: PTUser, sortHeaderId: string) => data[sortHeaderId]?.toLocaleLowerCase()
  }

  viewUser(user: PTUser){
    this.router.navigate(['contracts/admin/userProduct'], { queryParams: { user: user.email } })
  }

  ngOnDestroy() {
    this.unsub.next(true)
    this.unsub.complete()
  }

}
