import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ControlContainer, FormGroupDirective, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService } from 'portal';
import { Enums } from '../../models/contract.model';
import { Product } from '../../models/product.type';
import { SVLimit, SVLimits } from '../../models/typing';

@Component({
  selector: 'app-loss-experience-array',
  templateUrl: './loss-experience-array.component.html',
  styleUrls: ['./loss-experience-array.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class LossExperienceArrayComponent implements OnInit, OnChanges {
  private form: UntypedFormGroup;
  @Input() preForm: any;
  @Input() cargoType: string;
  @Input() secName: string;
  @Input() productType: string;
  lossPeriod= new Date().getFullYear();
  prod = new Enums();
  product = new Product();
  riskTypes: string[] = [];
  lossTypes: string[] = [];
  equipmentTypes: string[] = [];
  claim = true;
  public lossExperience = new UntypedFormGroup({
    lossExperienceArray : new UntypedFormArray([
    ]),
    noOfYears: new UntypedFormControl(0),
  });
  lossExperienceArray = this.lossExperience.get('lossExperienceArray') as UntypedFormArray;
  constructor(private parent: FormGroupDirective, private activeRoute: ActivatedRoute,
    public pUtil: PortalUtilService, private cdr: ChangeDetectorRef) {
      this.form = this.parent.form;
    }

  ngOnInit(): void {
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('lossExperience', this.lossExperience);
    this.findYearsReq();
    this.handleValidation();
    this.setLossArrays();
  }

  setLossArrays(){
    this.activeRoute.paramMap.subscribe(params => {
      let data = this.product.findProductType(this.pUtil.addSpace(params.get('prod')));
      this.riskTypes = data?.lossExperience?.riskTypes;
      this.lossTypes = data?.lossExperience?.lossTypes;
      this.equipmentTypes = data?.lossExperience?.equipmentTypes;
    });
  }

  selectRiskType(type: string, lossType: AbstractControl){
    if(type.toLowerCase().includes('transit')){
      lossType.setValue('N/A');
    } else {
      lossType.setValue('');
    }
  }

  toggleClaim() {
    this.claim = !this.claim;
    if (this.claim) {
      this.lossExperience.enable();
      this.handleValidation();
    } else {
      this.lossExperience.disable();
    }
    this.cdr.detectChanges()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cargoType) {
      this.handleValidation();
    }
  }

  handleValidation() {
    if (this.claim) {
      if (this.cargoType === this.prod.cargoType.Both) {
        this.handleLossArr({riskType: false, lossType: false, equipmentType: true});
      } else if (this.cargoType === this.prod.cargoType.StockOnly) {
        this.handleLossArr({riskType: true, lossType: false, equipmentType: true});
      } else if (this.cargoType === this.prod.cargoType.EquipmentBinder) {
        this.handleLossArr({riskType: true, lossType: false, equipmentType: false});
      } else {
        this.handleLossArr({riskType: true, lossType: true, equipmentType: true});
      }
    }
  }

  handleLossArr( optionals : {riskType: boolean, lossType: boolean, equipmentType: boolean}) {
    for (const f in this.lossExperienceArray.controls) {
      const arr = this.lossExperienceArray.get(f) as UntypedFormArray;
      for (const field in arr?.controls) {
        const claim = arr.get(field)?.get('claim')
        optionals['riskType'] ? claim?.get('riskType')?.disable() : claim?.get('riskType')?.enable()
        optionals['lossType'] ? claim?.get('lossType')?.disable() : claim?.get('lossType')?.enable()
        optionals['equipmentType'] ? claim?.get('equipmentType')?.disable() : claim?.get('equipmentType')?.enable()
      }
    }
  }

  addLoss() {
    const year = this.lossPeriod - this.lossExperienceArray.length;
    const newLoss = new UntypedFormArray([
    ]);
    this.addClaim(year, newLoss);
    this.lossExperienceArray.push(newLoss);
    this.incrementNoOfYears();
    this.handleValidation();
  }

  addClaim(year: number, arr: UntypedFormArray) {
    const newClaim = new UntypedFormGroup ({
      claim : new UntypedFormGroup({
        period: new UntypedFormControl(year),
        outstandingClaim: new UntypedFormControl('' , [Validators.required]),
        paidClaim: new UntypedFormControl('' , [Validators.required]),
        riskType: new UntypedFormControl('' , [Validators.required]),
        lossType: new UntypedFormControl('' , [Validators.required]),
        equipmentType: new UntypedFormControl('' , [Validators.required]),
      }),
    });
    arr.push(newClaim);
    this.handleValidation();
    return newClaim;
  }

  findYearsReq() {
    const lossArray = this.preForm?.proposalForm?.lossExperience?.lossExperienceArray;
    if (lossArray) {
      let minPeriod = this.lossPeriod;
      lossArray?.map((c: SVLimits[]) => {
        const claimPeriod = c?.[0]?.claim?.period;
        if (claimPeriod) {
          minPeriod > claimPeriod ? minPeriod = claimPeriod : minPeriod;
        }
      });
      this.push((this.lossPeriod - minPeriod) + 1);
      this.setupLossArr(lossArray);
    } else {
      this.setupArray();
    }
    if (this.preForm !== 'ready' && !lossArray){
      this.claim = false;
      this.lossExperience.disable();
    } 
  }

  setupArray(){
    for (let i = 0; i < 5; i++) {
      this.addLoss();
    }
  }

  setupLossArr(lossArray:[]) {
    lossArray?.forEach ((loss: SVLimits[]) => {
      loss?.forEach ((c: SVLimits) => {
        this.setupClaim(c.claim, c.claim.period);
      });
    });
    this.lossExperienceArray?.controls.forEach ((loss: any, index: number) => {
      if(loss.controls.length === 0){
        this.noClaim(this.lossPeriod - index,loss);
      }
    });
  }

  setupClaim(claim: SVLimit , period: number) {
    const pos = this.lossPeriod - period;
    let control;
    if(!claim?.noClaim){
      control = this.addClaim(period, this.lossExperienceArray.controls[pos] as UntypedFormArray);
      control.get('claim')?.patchValue({
        outstandingClaim: claim?.outstandingClaim,
        paidClaim: claim?.paidClaim,
        riskType: claim?.riskType,
        lossType: claim?.lossType,
        equipmentType: claim?.equipmentType,
      });
    } else {
      control = this.noClaim(period, this.lossExperienceArray.controls[pos] as UntypedFormArray);
    }
  }

  AddEmptyLoss() {
    const newLoss = new UntypedFormArray([
    ]);
    this.lossExperienceArray.push(newLoss);
    this.incrementNoOfYears();
  }

  push(no: number) {
    while (no > 0) {
      this.AddEmptyLoss();
      no--;
    }
  }

  insertClaim(claim: number, loss: UntypedFormArray) {
    if(loss.controls?.[0]?.get('claim')?.get('noClaim')?.value){
      loss.controls.pop();
    }
    const year = this.lossPeriod - claim;
    this.addClaim(year, loss);
    this.cdr.detectChanges()
  }

  removeClaim(i: number, j: number, loss: UntypedFormArray) {
    if(loss.length === 1){
      this.noClaim(this.lossPeriod - i, loss);
    }
    loss.removeAt(j);
  }

  removeLoss(i: number) {
    this.lossExperienceArray.removeAt(i);
    this.decrementNoOfYears();
  }

  noClaim(year: number, arr: UntypedFormArray){
    const newClaim = new UntypedFormGroup ({
      claim : new UntypedFormGroup({
        period: new UntypedFormControl(year),
        noClaim: new UntypedFormControl(true),
        outstandingClaim: new UntypedFormGroup({
          figure: new UntypedFormControl(0),
          currency: new UntypedFormControl('USD'),
        }),
        paidClaim: new UntypedFormGroup({
          figure: new UntypedFormControl(0),
          currency: new UntypedFormControl('USD'),
        }),
        riskType: new UntypedFormControl(''),
        lossType: new UntypedFormControl(''),
        equipmentType: new UntypedFormControl(''),
      }),
    });
    arr.push(newClaim);
    return newClaim;
  }

  getLoss() {
    return this.lossExperienceArray.controls;
  }

  incrementNoOfYears() {
    this.lossExperience.get('noOfYears')?.setValue(this.lossExperience.get('noOfYears')?.value + 1);
  }

  decrementNoOfYears() {
    this.lossExperience.get('noOfYears')?.setValue(this.lossExperience.get('noOfYears')?.value - 1);
  }
  
  check(loss: UntypedFormArray){
    return loss.controls?.[0]?.get('claim')?.get('noClaim')?.value;
  }

}
