import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-coverage-required-for-logs',
  templateUrl: './coverage-required-for-logs.component.html',
  styleUrls: ['./coverage-required-for-logs.component.scss']
})
export class CoverageRequiredForLogsComponent implements AfterViewInit {
  @Input() array: UntypedFormArray
  logs = [{display: 'Logs while in Water', key: 'Logs while in Water'}, {display: 'Logs while on Land', key: 'Logs while on Land'}]


  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    if(this.array.value){
      for(const log of this.array.value){
        (document?.getElementById(log) as HTMLInputElement).checked = true;
      }
    }
  }

  selectLogType(type: string, inp: HTMLInputElement){
    inp.checked ? this.addLog(type) : this.removeLog(type)
  }

  addLog(key: string){
    if(!this.findLogPos(key)){
      const control = new UntypedFormControl(key)
      this.array.push(control)
    }
  }

  removeLog(key: string){
    const pos = this.findLogPos(key)
    if(pos){
      this.array.removeAt(parseInt(pos))
    }
  }

  findLogPos(key: string){
    const products = this.getLogs();
    for(let log in products){
      if(products[log].value === key){
        return log
      }
    }
  }

  getLogs(){
    return this.array.controls;
  }

}
