import { Component } from '@angular/core';

@Component({
  selector: 'app-user-agreement-casualty',
  templateUrl: './user-agreement-casualty.component.html',
  styleUrls: ['./user-agreement-casualty.component.scss']
})
export class UserAgreementCasualtyComponent {

  constructor() { }

}
