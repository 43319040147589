<div class="d-flex colMargin pt-5">
    <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'estimatedNoOfSending')}">
        <input pt-appCurrency [inputControl]="group?.get('estimatedNoOfSending')" type="text">
        <label class="controlLabel">
            Estimated number of Sending
        </label>
    </div>

    <div class="amount requiredColor">
        <app-amount [required]="true" [name]="'Average Values per sending'" [formControl]="group?.get('avgValuesPerSending')"></app-amount>
    </div>

    <div class="amount">
        <app-amount [required]="true" [name]="'Maximum Values per sending'" [formControl]="group?.get('maxValuesPerSending')"></app-amount>
    </div>

</div>