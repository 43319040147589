import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-general-questions',
  templateUrl: './general-questions.component.html',
  styleUrls: ['./general-questions.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class GeneralQuestionsComponent implements OnInit, OnDestroy {
  private form: UntypedFormGroup;
  private unsub: Subject<boolean> = new Subject<boolean>();
  @Input() preForm: any;

  public generalQuestions = new UntypedFormGroup({
    anyWaiversOfSubrogationYN: new UntypedFormControl(null),
    anyWaiversOfSubrogationDetails: new UntypedFormControl({value: '', disabled: 'true'})
  });
  constructor(private parent: FormGroupDirective) {
    this.form = this.parent.form;
  }

  ngOnInit() {
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('generalQuestions', this.generalQuestions);
    this.onValueChanges();
    this.setUpGeneralQuestions(this.generalQuestions);
  }

  waiverControl() {
    if (!this.generalQuestions.get('anyWaiversOfSubrogationYN')?.value) {
      this.generalQuestions.get('anyWaiversOfSubrogationYN')?.setValue('Yes');
      this.generalQuestions.get('anyWaiversOfSubrogationDetails')?.enable();
    } else {
      this.generalQuestions.get('anyWaiversOfSubrogationYN')?.setValue('', { emitEvent: false });
      this.generalQuestions.get('anyWaiversOfSubrogationDetails')?.disable();
    }
  }

  onValueChanges(): void {
    this.generalQuestions.valueChanges.
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(val => {
      if (val.anyWaiversOfSubrogationYN && val?.anyWaiversOfSubrogationDetails || !val?.anyWaiversOfSubrogationYN) {
        this.generalQuestions.setErrors(null);
      } else {
        this.generalQuestions.setErrors({required: true});
      }
    });
  }

  setUpGeneralQuestions(form: UntypedFormGroup) {
    const general = this.preForm.proposalForm?.generalQuestions;
    if (general) {
      form.patchValue({
        anyWaiversOfSubrogationYN : general?.anyWaiversOfSubrogationYN,
        anyWaiversOfSubrogationDetails: general?.anyWaiversOfSubrogationDetails
      });
   }
    this.generalQuestions.get('anyWaiversOfSubrogationYN')?.value ?
    this.generalQuestions.get('anyWaiversOfSubrogationDetails')?.enable() :
    this.generalQuestions.get('anyWaiversOfSubrogationDetails')?.disable();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}
