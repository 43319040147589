<div formGroupName="proposalForm">
    <h1 class="header">2. Project Summary</h1>
    <div class="colMargin">
        <div formGroupName="projectSummary">
            <app-period [parent]="parent.form.get('proposalForm')" [periodData]="preForm?.proposalForm?.period"
                [required]="true" startLabel="Project Start Date" endLabel="Anticipated Project Completion Date"></app-period>
            <div class="row">
                <div class="col-12">
                    <div class="controlInput"
                        [ngClass]="{'requiredColor' : pUtil.checkIfError(projectSummary,'contractorName')}">
                        <input class="controlFull" type="text" formControlName="contractorName" domControl
                            placeholder="Contractor Name">
                        <label class="controlLabel">
                            Contractor Name*
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="controlInput"
                        [ngClass]="{'requiredColor' : pUtil.checkIfError(projectSummary,'description')}">
                        <textarea id="overviewOfBusiness" type="text" formControlName="description" domControl
                            placeholder="Description"></textarea>
                        <label class="controlLabel">
                            Description of the project*
                        </label>
                    </div>
                </div>
                <div class="col-md-4 col-8">
                    <div class="controlInput customSelect"
                        [ngClass]="{'requiredColor' : pUtil.checkIfError(projectSummary,'contractorExperience')}">
                        <mat-select formControlName="contractorExperience" domControl>
                            <mat-option *ngFor="let experience of contractorExperienceDropdown" [value]="experience">
                                {{experience}}</mat-option>
                        </mat-select>
                        <label class="controlLabel">
                            <span>Contractor's Experience*</span>
                        </label>
                    </div>
                </div>
                <div class="col-12 mt-5 d-flex flex-column gap">
                    <h3 class="listHeader m-0">Project Location:</h3>
                    <app-address type="project" [data]='preForm?.proposalForm?.projectSummary?.projectLocation'
                        formControlName='projectLocation'></app-address>
                </div>
            </div>

            <div class="col-lg-8 col-12">
                <div class="mt-5 d-flex flex-column gap">
                    <h3 class="listHeader m-0">TYPE OF PROJECT:</h3>
                    <app-interest-type [interestControl]="typeOfProject.get('type')" [interestTypes]="typeOfProjects"
                        [required]="true"></app-interest-type>
                    <app-styled-buttons [buttonControl]="typeOfProject.get('project')" [types]="projectOptions"
                        [required]="true"></app-styled-buttons>
                </div>
                <div class="mt-5 d-flex flex-column gap" [ngClass]="{'ng-invalid' : constructionMaterial?.errors}" domControl>
                    <div class="d-flex align-items-baseline">
                        <h3 class="listHeader m-0 me-2">CONSTRUCTION MATERIAL:</h3>
                        <mat-error *ngIf="constructionMaterial.errors?.exceededThreshold">{{constructionMaterial.errors?.exceededThreshold}}</mat-error>
                    </div>
                    <app-interest-type [interestControl]="constructionMaterial.get('type')"
                        [interestTypes]="constructionMaterials" [required]="true"></app-interest-type>
                    <app-styled-buttons [buttonControl]="constructionMaterial.get('protection')"
                        [types]="protectionOptions" [required]="true"></app-styled-buttons>
                </div>
            </div>

            <div class="mt-5 d-flex flex-column gap">
                <h3 class="listHeader m-0">COSTS:</h3>
                <div class="col-md-6 col-12">
                    <app-amount required='true' name="Hard Cost" formControlName="hardCost"></app-amount>
                    <app-amount name="Soft Cost" formControlName="softCost"></app-amount>
                    <app-amount *ngIf="!projectSummary.get('existingPropertyValue')?.disabled" required='true'
                        name="Existing Property" formControlName="existingPropertyValue"></app-amount>
                </div>
                <label class="text">Total Estimated Contract Value: {{getTotalCost()|
                    currency:'CAD':'symbol-narrow':'0.2-2'}} CAD</label>
                <app-neutral-toggle *ngIf="membraneWorksInformation"
                    [toggleInformation]="membraneWorksInformation?.membraneWorksAboveThreshold"></app-neutral-toggle>
            </div>
        </div>
    </div>
</div>