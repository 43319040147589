import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AdminUserAccountBaseComponent, PortalDataService, PortalUtilService } from 'portal';

@Component({
  selector: 'app-admin-user-account-management-functions',
  templateUrl: './admin-user-account-management-functions.component.html',
  styleUrls: ['./admin-user-account-management-functions.component.scss']
})

export class AdminUserAccountManagementFunctionsComponent extends AdminUserAccountBaseComponent{
  constructor(public portalDataService: PortalDataService,
    public pUtil: PortalUtilService, public cdr : ChangeDetectorRef
  ) { super(portalDataService, pUtil, cdr) }

}
