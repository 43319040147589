import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UtilService } from 'projects/mcgill/src/app/app-services/util.service';
import { PortalDataService, PortalUtilService, ComponentCommunicationService, ConfirmedRiskBaseComponent, PTFigure } from 'portal';
import { Product } from '../../../shared-resources/models/product.type';

@Component({
  selector: 'app-confirmed-risk-detail',
  templateUrl: './confirmed-risk-detail.component.html',
  styleUrls: ['./confirmed-risk-detail.component.scss']
})

export class ConfirmedRiskDetailComponent extends ConfirmedRiskBaseComponent implements OnInit {
  deduc: SafeHtml;
  prod = new Product();
  constructor(public util: UtilService, public portalDataService: PortalDataService,
    public sanitizer: DomSanitizer, public comm: ComponentCommunicationService, public pUtil: PortalUtilService
  ) { super(portalDataService, sanitizer, comm, pUtil) }

  ngOnInit(): void {
    super.ngOnInit();
  }

  handleDeductibleSetup(arr: PTFigure[], lineItems: any[]){
    this.deduc = this.sanitizer.bypassSecurityTrustHtml(this.util.safeDeducHTML(arr, this.preForm, lineItems));
  }

  setupFigures(figures: PTFigure[], lineItems: any[]){
    this.handleDeductibleSetup(figures, lineItems);
  }

}
