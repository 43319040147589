import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
@Directive({
  selector: '[pt-appDisableAll]'
})
export class DisableAllDirective implements  AfterViewInit {

  @Input() disableAll = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.disableAll) {
        this.el.nativeElement.style.pointerEvents = 'none';
        this.disableElement(this.el.nativeElement);
      }
    });
  }

  private disableElement(el: any) {
    if (el?.id !== 'scheduleButton') {
      this.renderer.setAttribute(el, 'disabled', 'true');
      this.renderer.addClass(el, 'disabled');
      // disabling anchor tab keyboard event
      if (el.tagName.toLowerCase() === 'a') {
        this.renderer.setAttribute(el, 'tabindex', '-1');
      }
      if (el.classList.contains('button') || el.classList.contains('remove')) {
        el.style.display = 'none';
      }
    }
    if (el.children) {
      for (const element of el.children) {
        this.disableElement(element);
      }
    }
  }
}
