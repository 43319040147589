import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-coverage-required-loss-of-use',
  templateUrl: './coverage-required-loss-of-use.component.html',
  styleUrls: ['./coverage-required-loss-of-use.component.scss']
})
export class CoverageRequiredLossOfUseComponent implements AfterViewInit{
  @Input() control: UntypedFormControl
  otherDetail = false

  figures = [
    {key: 40000, display: '$40,000'}, {key: 50000, display: '$50,000'},
    {key: 60000, display: '$60,000'}, {key: 75000, display: '$75,000'}
  ]

  constructor() { }

  ngAfterViewInit(): void {
    if(this.control?.value?.figure){
        if((document?.getElementById(`Figure:${this.control.value.figure}`) as HTMLInputElement)){
          (document?.getElementById(`Figure:${this.control.value.figure}`) as HTMLInputElement).checked = true;
        } else {
          this.otherDetail = true;
          (document?.getElementById(`lossOfUseOther`) as HTMLInputElement).checked = true;
        }
    } /* else {
      this.control.setValue({figure: 20000, currency: 'CAD'});
      (document?.getElementById(`Figure:${20000}`) as HTMLInputElement).checked = true;
    } */
  }

  selectFigure(figure: number){
    this.otherDetail = false
    this.control.setValue({figure: figure, currency: 'CAD'})
  }

  selectOther(){
    this.control.reset()
    this.otherDetail = true
  }

}
