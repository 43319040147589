import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-risk-mitigation',
  templateUrl: './risk-mitigation.component.html',
  styleUrls: ['./risk-mitigation.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class RiskMitigationComponent implements OnInit, OnDestroy{
  @Input() preForm: any;
  uploads = [
    { display: 'Project Schedule', key: ConstructionUploads.ProjectSchedule, required: true, control: new UntypedFormControl() },
    { display: 'Site Plan', key: ConstructionUploads.SitePlan, required: true, control: new UntypedFormControl() },
    { display: 'Breakdown of Hard Costs', key: ConstructionUploads.BreakdownHardCosts, required: true, control: new UntypedFormControl() },
    { display: 'Breakdown of Soft Costs', key: ConstructionUploads.BreakdownSoftCosts, required: true, control: new UntypedFormControl(), count: 0},
    { display: 'Quality Control Measures', key: ConstructionUploads.QualityControlMeasures, required: true, control: new UntypedFormControl() },
  ]
  riskMitigation = new UntypedFormGroup({
    siteSecurityMeasures: new UntypedFormArray([])
  })
  siteSecurityMeasureList = [
    { display: 'Fenced', key: 'Fenced' },
    { display: 'Watchman', key: 'Watchman' },
    { display: 'CCTV', key: 'CCTV' }
  ]
  unsub: Subject<boolean> = new Subject<boolean>()
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('riskMitigation', this.riskMitigation);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('uploads', new UntypedFormGroup({}));
    this.uploads.forEach((upload) => {
      (this.parent.form.get('proposalForm')?.get('uploads') as UntypedFormGroup).addControl(upload.key, upload.control)
    })
    this.parent.form.get('proposalForm')?.get('projectSummary')?.get('softCost')!.valueChanges
    .pipe(
      takeUntil(this.unsub),
      debounceTime(100)
    )
    .subscribe((softCost) => {
      const softCostUpload = this.uploads.find(x => x.key === ConstructionUploads.BreakdownSoftCosts)
      if(softCost.figure){
        softCostUpload!.required = true
        if(!softCostUpload?.count)
        softCostUpload!.control.setErrors({ missingUpload: true })
      } else {
        softCostUpload!.required = false
        softCostUpload!.control.setErrors(null)
      }
    })
  }

  handleValidation(count: number, upload: { required: boolean, control: UntypedFormControl, count? : number}) {
    if (upload.required) {
      !count ? upload.control?.setErrors({ missingUpload: true }) : upload.control?.setErrors(null);
    }
    if(upload.count || upload.count === 0)
    upload.count = count
  }

  ngOnDestroy(){
    this.unsub.next(true);
    this.unsub.complete();
  }

}

enum ConstructionUploads {
  ProjectSchedule = 'ProjectSchedule',
  SitePlan = 'SitePlan',
  BreakdownHardCosts = 'BreakdownHardCosts',
  BreakdownSoftCosts = 'BreakdownSoftCosts',
  QualityControlMeasures = 'QualityControlMeasures',
}