import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { debounce } from 'lodash';
import { PortalUtilService } from 'portal';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Interest, StyledButtons } from '../../../models/contract.model';
import { InterestType } from '../../_shared-form/interest-type/interest-type.component';
import { ButtonType } from '../../_shared-form/styled-buttons/styled-buttons.component';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class ProjectSummaryComponent implements OnInit, OnDestroy {
  @Input() preForm: any;

  projectSummary = new UntypedFormGroup({
    description: new UntypedFormControl('', [Validators.required]),
    contractorName: new UntypedFormControl('', [Validators.required]),
    contractorExperience: new UntypedFormControl('', [Validators.required]),
    projectLocation: new UntypedFormControl(),
    existingPropertyValue: new UntypedFormControl(),
    membraneWorksAboveThreshold: new UntypedFormControl('', [Validators.required]),
    hardCost: new UntypedFormControl(),
    softCost: new UntypedFormControl(),
  })

  typeOfProject = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    project: new UntypedFormControl('')
  })

  constructionMaterial = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    protection: new UntypedFormControl('')
  })

  typeOfProjects: InterestType[] = new Interest().type.typeOfProject
  constructionMaterials: InterestType[] = new Interest().type.constructionMaterial
  projectOptions: ButtonType[] = new StyledButtons().data.project
  protectionOptions: ButtonType[] = new StyledButtons().data.constructionMaterial
  contractorExperienceDropdown = ['0-3 Years', '4-9 Years', '10+ Years']
  membraneWorksInformation: any = {}

  unsub: Subject<boolean> = new Subject<boolean>()
  constructor(public parent: FormGroupDirective, public pUtil: PortalUtilService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('projectSummary', this.projectSummary);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('typeOfProject', this.typeOfProject);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('constructionMaterial', this.constructionMaterial);
    this.setupProjectSummary()
    this.typeOfProject!.get('project')!.valueChanges.pipe().subscribe((val) => {
      val === StyledButtons.Renovation ? this.projectSummary.get('existingPropertyValue')!.enable() : this.projectSummary.get('existingPropertyValue')!.disable();
      this.cdr.detectChanges()
    })
    merge(
        this.projectSummary!.get("existingPropertyValue")!.valueChanges,
        this.projectSummary!.get("hardCost")!.valueChanges,
        this.projectSummary!.get("softCost")!.valueChanges,
        this.constructionMaterial!.valueChanges,
    )
    .pipe(
      takeUntil(this.unsub),
      debounceTime(100)
    )
    .subscribe(() => {
      const total = this.getTotalCost()
      const typeVal = this.constructionMaterial.get('type')?.value
      const typesToCheck = [Interest.Frame, Interest.FireResistive, Interest.NonCombustible]
      for(const type of typesToCheck){
        const err = `Total Estimated Contract Value cannot exceed ${this.pUtil.getDecimalFigureFormat(type.threshold, 'CAD')}`
        if(typeVal === type.value)
        total > type.threshold ? this.constructionMaterial.setErrors({exceededThreshold: err}) : this.constructionMaterial.setErrors(null)
      }
    });
  }

  getTotalCost() {
    const parent = this.projectSummary
    return this.getAmountNumber(parent.get('hardCost')) + this.getAmountNumber(parent!.get('softCost')) + this.getAmountNumber(parent!.get('existingPropertyValue'))
  }

  getAmountNumber(amountControl: AbstractControl | null) {
    if (amountControl?.disabled) {
      return 0
    }
    return parseFloat(amountControl?.value?.figure) || 0
  }

  isRenovation() {
    return this.parent.form.get('proposalForm')?.get('typeOfProject')?.get('project')?.value === StyledButtons.Renovation
  }

  setupProjectSummary() {
    if (this.preForm?.proposalForm?.projectSummary) {
      const project = this.preForm?.proposalForm?.projectSummary
      this.projectSummary.patchValue({
        description: project?.description,
        contractorName: project?.contractorName,
        contractorExperience: project?.contractorExperience,
        projectLocation: project?.projectLocation,
        existingPropertyValue: project?.existingPropertyValue,
        hardCost: project?.hardCost,
        softCost: project?.softCost,
      })
    }
    if (this.preForm?.proposalForm?.typeOfProject) {
      const project = this.preForm?.proposalForm?.typeOfProject
      this.typeOfProject.patchValue({
        type: project?.type,
        project: project?.project,
      })
    }
    if (this.preForm?.proposalForm?.constructionMaterial) {
      const project = this.preForm?.proposalForm?.constructionMaterial
      this.constructionMaterial.patchValue({
        type: project?.type,
        protection: project?.protection,
      })
    }
    this.setupToggle()
  }

  setupToggle() {
    this.membraneWorksInformation['membraneWorksAboveThreshold'] = {
      text: "Membrane Works more than 10% of Hard Costs Value?",
      listOfEnabledToggles: [],
      childControlName: 'membraneWorksAboveThreshold',
      data: this.preForm?.proposalForm?.projectSummary?.membraneWorksAboveThreshold,
      parentGroup: this.projectSummary,
      onlyBool: true,
      clonedParentGroup: _.cloneDeep(this.projectSummary),
    }
  }

  ngOnDestroy(){
    this.unsub.next(true);
    this.unsub.complete();
  }

}