import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormGroupDirective, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-transit-risk-outgoing',
  templateUrl: './transit-risk-outgoing.component.html',
  styleUrls: ['./transit-risk-outgoing.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class TransitRiskOutgoingComponent implements OnInit {

  private form: UntypedFormGroup;
  @Input() preForm: any;
  outgoingValues = true;
  toggle = true;
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    const transitRiskOutgoing = new UntypedFormControl('');
    this.form = this.parent.form;
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('transitRiskOutgoing', transitRiskOutgoing);
  }

  controlOutgoingValues() {
    this.outgoingValues = !this.outgoingValues;
    const section = document?.getElementById('outgoing');
    if(section){
      this.outgoingValues ? section.style.display = 'block' : section.style.display = 'none';
      this.toggle = !this.toggle;
    }
  }
}
