import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { PortalDataService } from '../../../../../services/portal-data.service';

@Component({
  selector: 'pt-monthly-report-base',
  templateUrl: './monthly-report-base.component.html',
  styleUrls: ['./monthly-report-base.component.css']
})
export class MonthlyReportBaseComponent implements OnDestroy {
  to: string = (new Date()).getFullYear().toString() + '-' + ((new Date()).getMonth()+1).toString().padStart(2, '0');
  from: string;
  spin: boolean;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService) { }

  downloadMIReport() {
    const year = this.to.split('-')[0];
    const month = this.to.split('-')[1];
    const duration = this.getDuration(this.from, this.to);
    this.spin = true;
    this.portalDataService.managerDownloadMIs(
      year, month, duration
    ).pipe(
      takeUntil(this.unsub),
      finalize(() => this.spin = false)
    ).subscribe((response) =>{
      const filename = `MI-Report ${this.from}/${this.to}.xlsx`;
      saveAs(response, filename);
    });
  }

  getDuration(dateFrom:string , dateTo:string){
    const diff = parseInt(dateTo.split('-')[1]) - parseInt(dateFrom.split('-')[1]) + 
      (12 * (parseInt(dateTo.split('-')[0]) - parseInt(dateFrom.split('-')[0])));
    return diff + 1;
  }

  ngOnDestroy(): void {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
