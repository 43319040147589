<div class="pb-5 d-flex align-items-center">
    <label class="pe-3 ps-3">
        MI-Report of:
    </label>

    <span class="me-3">From:<input class="reportinput" [(ngModel)]="from" type="month" pattern="[0-9]{4}-(1[0-2]|0[1-9])" placeholder="YYYY-MM"/></span>

    <span class="me-3">Up until and including<input class="reportinput" [(ngModel)]="to" type="month" pattern="[0-9]{4}-(1[0-2]|0[1-9])" placeholder="YYYY-MM"/></span>


    <div class="downloadButtonWrapper">
        <button (click)="downloadMIReport()" class="downloadButton" [disabled]="!to?.match('[0-9]{4}-(1[0-2]|0[1-9])') || !from?.match('[0-9]{4}-(1[0-2]|0[1-9])') || spin">
            <span class="d-flex" *ngIf="!spin">DOWNLOAD<div id="downloadIcon"></div></span>
            <i *ngIf="spin"><mat-spinner diameter="27"></mat-spinner></i>
        </button>
    </div>

</div>