import { Component, OnInit } from '@angular/core';
import { AuthService, LoginBaseComponent } from 'portal';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent extends LoginBaseComponent implements OnInit {
    constructor(
        public route: ActivatedRoute,
        public authService: AuthService,
        public location: Location
    ) { super(route, authService, location) }

    ngOnInit(){
        super.ngOnInit();
    }
}
