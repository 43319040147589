import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective, ControlContainer, UntypedFormGroup, UntypedFormControl, FormArray, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Enums } from '../../../models/contract.model';

@Component({
  selector: 'app-policy-type',
  templateUrl: './policy-type.component.html',
  styleUrls: ['./policy-type.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})

export class PolicyTypeComponent implements OnInit {
  @Input() preForm: any;
  @Input() policyType: UntypedFormControl;
  form: UntypedFormGroup;
  enum = new Enums();
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective) {
    this.form = this.parent.form;
  }

  ngOnInit(): void {
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('policyType', this.policyType);
    this.setPolicy(this.enum.policyType.pcgl.value)
  }

  setPolicy(policy: string){
    this.policyType.setValue(policy)
  }

}
