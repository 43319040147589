import { Component, ViewChild, OnDestroy, OnInit, HostListener, NgZone } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, takeWhile } from 'rxjs/operators';
import { Contract } from '../../../../model/models';
import { ComponentCommunicationService } from '../../../../services/component-communication.service';
import { PortalUtilService } from '../../../../services/portal-util.service';
import { RiskOptionsPopupComponent } from '../../../../popups/risk-options-popup/risk-options-popup.component';
import { ContractsService } from '../../../../services/contracts.service';
import { ContractsServiceResponse } from '../../../../model/typings';

@Component({
  selector: 'pt-contract-list-detail-base',
  templateUrl: './contract-list-detail-base.component.html',
  styleUrls: ['./contract-list-detail-base.component.css']
})

export class ContractListDetailBaseComponent implements OnDestroy, OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // @HostListener("window:scroll", ["$event"])
  // @HostListener("window:resize", ["$event"])
  scrollObservable = new Subject<Window>()

  sortAsc = false
  sortKey = "updatedAt"

  contracts:ContractsServiceResponse
  displayedColumns: string[] = ['contractName', 'product', 'createdAt', 'updatedAt', 'status', 'button', 'dots'];
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public router: Router, public dialog: MatDialog,
    public comm: ComponentCommunicationService, public pUtil: PortalUtilService,
    public contractsService: ContractsService
  ) { }


  //these should be static but it makes the names long...
  private deadspace = 450

  ngOnInit(): void {
    this.getContracts();
    

    //need to wrap these up somehow
    window.addEventListener('scroll', () => ContractListDetailBaseComponent.onScroll(this));
    window.addEventListener('resize', () => ContractListDetailBaseComponent.onScroll(this));
    // window.addEventListener('resize', this.onResize);
    
    this.scrollObservable.pipe(
      takeUntil(this.unsub),
      debounceTime(200)
    ).subscribe((res)=>{
      //console.log(res.scrollY - this.deadspace, document.body.scrollHeight-this.deadspace, res.innerHeight)
      const offset = Math.max(((res.scrollY - this.deadspace + res.innerHeight) / (document.body.scrollHeight-this.deadspace))*this.contracts.contracts.length, 0)
      //console.log(offset)
      const roundedOffset = Math.floor(offset/this.contractsService.DownloadChunkSize)*this.contractsService.DownloadChunkSize
      //console.log(roundedOffset)
      this.contractsService.getUserContracts(roundedOffset)
    })
    this.contractsService.numberOfUserContracts().subscribe(()=>{})
    this.contractsService.getUserContracts()
    this.contractsService.userRisksSubject.subscribe((risks) => {
      // const currentscroll = window.scrollY
      this.contracts = risks

    })
    this.contractsReload();
  }

  static onScroll(base:ContractListDetailBaseComponent){
    base.scrollObservable?.next(window)
  }
  // onResize() {
  //   this.scrollObservable?.next(window)
  // }

  contractsReload(){
    const time = interval(60000 * 2);
    time.pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      this.contractsService.getUserContracts()
    });
  }

  getContracts() {
    this.contractsService.listenToUserContracts()
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((resp: ContractsServiceResponse) => {
      // this.setupDataSource(resp.filteredContracts);
    })
  }

  updateContract(contract: Contract) {
    this.router.navigate(['contracts/updateContract', this.pUtil.rmvSpace(contract.product) , contract.id], { queryParams: { status: contract.status }});
  }

  // setupDataSource(contracts: (Contract|undefined)[]) {
  //   this.dataSource.data = contracts;
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sortingDataAccessor = (data: Contract, sortHeaderId: string) => data[sortHeaderId]?.toLocaleLowerCase();
  // }

  setSort(key: keyof Contract){
    this.sortKey = key
    this.sortAsc = !this.sortAsc
    this.contractsService.setContractsSort(key, this.sortAsc)
  }

  runServerProcess(contract: Contract, call: { label: string; endpoint: string; }) {
    if (call.label.toLowerCase().includes('download')) {
      this.comm.setJsonToDownload(contract.id);
    } else {
      const endpoint = call.endpoint;
      const message = {contract, endpoint};
      const dialogRef = this.dialog.open(RiskOptionsPopupComponent, {
        data: {call : message},
        panelClass: 'custom-dialog-container',
        autoFocus: false
      });
      dialogRef.afterClosed()
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(() => {
        this.contractsService.getUserContracts();
      });
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
