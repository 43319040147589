<div class="productOptions">
    <div class="pdfHeaderContent">
        <div [id]="productOptions?.icons?.PDFToContract"></div>
        <h2 class="ms-4">Select a method to create your risk ...</h2>
    </div>
    <div class="pdfMainContent mainContent">
        <div class="d-flex">
            <div class="item">
                <div class="itemIcon" id="pdfImportIcon"></div>
                <div class="ms-5">
                    <h2>Upload Completed PDF</h2>
                    <div>
                        <div class="d-flex align-items-center">
                            <button type="button" (click)="openFile()" class="button largeButton d-flex align-items-center primary">
                                <span class *ngIf="!spin">Upload PDF</span>
                                <i *ngIf="spin"><mat-spinner diameter="20"></mat-spinner></i>
                            </button>
                            <input #uploadButton type="file" style="display:none" (change)="fileChange($event)"
                                    accept="application/pdf">
                        </div>
                        <div>
                            <label class="downloadTemplateDescription mt-4">
                                Please ensure your PDF is the same template provided by the Underscore Team
                            </label>
                            <label class="downloadTemplateDescription mt-2">
                                To download a blank version of the file, <span (click)="downloadTemplate()">click here</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashedSplit left">
                <label>OR</label>
            </div>
            <div class="item">
                <div class="itemIcon" id="pdfFormIcon"></div>
                <div class="ms-5">
                    <h2>Fill Out Questionnaire</h2>
                    <button (click)="navigate()" class="button largeButton primary">Questionnaire</button>
                    <div>
                        <label class="downloadTemplateDescription mt-4">
                            You can still provide the data by manually filling out the questionnaire
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="productOptionButtons">
        <button [routerLink]="['/contracts/riskType']" class="button largeButton me-3">BACK</button>
    </div>
</div>