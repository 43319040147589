import { Component, OnInit, forwardRef, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, ValidationErrors, ControlValueAccessor,
  NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormArray, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoverageList } from '../../models/contract.model';
import { ComponentCommunicationService, PortalUtilService } from 'portal';

@Component({
  selector: 'app-transit-risk',
  templateUrl: './transit-risk.component.html',
  styleUrls: ['./transit-risk.component.scss'],
  providers: [
    {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => TransitRiskComponent),
   multi: true
 },
  {
   provide: NG_VALIDATORS,
   useExisting: forwardRef(() => TransitRiskComponent),
   multi: true
 }
]
})
export class TransitRiskComponent implements OnInit , ControlValueAccessor, OnDestroy {
  @Input() data: any;
  @Input() type: string;
  splits =  new CoverageList().transit.splits;
  public total = new UntypedFormControl();
  public transitRisk: UntypedFormGroup = new UntypedFormGroup({
    internationalContainerised: new UntypedFormControl('' , [Validators.required]),
    domesticContainerised: new UntypedFormControl('' , [Validators.required]),
    internationalActualValues: new UntypedFormControl(''),
    domesticActualValues: new UntypedFormControl(''),
    domesticPercentageAtInsuredsRisk: new UntypedFormControl('' , [Validators.required]),
    internationalPercentageAtInsuredsRisk: new UntypedFormControl('' , [Validators.required]),
    internationalSplit: new UntypedFormArray([
    ]),
  });

  unsub: Subject<boolean> = new Subject<boolean>();
  constructor( private comm: ComponentCommunicationService, public pUtil: PortalUtilService) { }

  ngOnInit() {
    this.setupInternationalSplit();
    this.touchListener();
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val ? this.setupTransitRisk(val) : this.setupTransitRisk(this.data);
  }
  registerOnChange(fn: any): void {
    this.onTouched = fn;
    this.transitRisk.valueChanges.pipe(
      takeUntil(this.unsub)
    )
    .subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.transitRisk.disable() : this.transitRisk.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.transitRisk.valid ? null : { invalidForm: {valid: false, message: ''}};
  }

  touchListener() {
    this.comm.markAllAsTouchedListener().pipe(
     takeUntil(this.unsub)
   ).subscribe((bool) => {
     if (bool) {
       this.transitRisk.markAllAsTouched();
     }
   });
  }

  getValue(id: { value: string | (string | number)[]; }) {
    this.transitRisk.get('internationalSplit')?.get(id.value);
  }

  isChecked(id: { checked: boolean; value: string; }) {
    if (!id.checked) {
      this.filterLoc(id.value).get('percentage')?.reset();
    }
  }

  onValueChanges(): void {
    this.transitRisk.get('internationalSplit')?.valueChanges.
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(val => {
      let float = 0;
      for (const i in val) {
        if (val?.[i] && val[i]?.percentage) {
          float += val[i].percentage;
        }
      }
      float <= 100
      ? this.transitRisk.get('internationalSplit')?.setErrors(null)
      : this.transitRisk.get('internationalSplit')?.setErrors({incorrectPercentage: true});
      this.total.setValue(Number.parseFloat(float.toString()).toFixed(2));
    });
  }

  setupInternationalSplit() {
    this.splits.forEach((split) => {
      (this.transitRisk.get('internationalSplit') as UntypedFormArray).push(this.setLoc(split.loc));
    });
    this.onValueChanges();
  }

  setLoc(loc: string) {
    return new UntypedFormGroup({
      location: new UntypedFormControl(loc),
      percentage: new UntypedFormControl(''),
    });
  }

  setupTransitRisk(transitRisk: any) {
    this.transitRisk.patchValue({
        internationalActualValues: transitRisk?.internationalActualValues,
        internationalPercentageAtInsuredsRisk: transitRisk?.internationalPercentageAtInsuredsRisk,
        domesticActualValues: transitRisk?.domesticActualValues,
        domesticPercentageAtInsuredsRisk: transitRisk?.domesticPercentageAtInsuredsRisk,
        internationalContainerised: transitRisk?.internationalContainerised,
        domesticContainerised: transitRisk?.domesticContainerised,
     });
    if (transitRisk?.internationalSplit) {
        this.patchInternationalSplit(transitRisk?.internationalSplit);
    }
  }

  patchInternationalSplit(data: any[]) {
    data.forEach(sp => {
      if(sp?.location){
        this.filterLoc(sp.location).patchValue({
          location: sp?.location,
          percentage: sp?.percentage,
        });
      }
    });
  }

  filterLoc(loc: string) {
    const control = (this.transitRisk.get('internationalSplit') as UntypedFormArray).controls;
    const filterLoc = control.filter(s => s.get('location')?.value === loc);
    return filterLoc?.[0];
  }

  getPreForm(val: string | number) {
    if (this.data?.[val]) {
      return this.data[val];
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}

