import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup,
  AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validators, UntypedFormArray } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-user-cva',
  templateUrl: './update-user-cva.component.html',
  styleUrls: ['./update-user-cva.component.scss'],
  providers: [
       {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UpdateUserCVAComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UpdateUserCVAComponent),
      multi: true
    }
  ]
})
export class UpdateUserCVAComponent implements OnInit, ControlValueAccessor, OnDestroy {
  public userdata = new UntypedFormGroup({
    surplusLines: new UntypedFormControl(''),
    companyName: new UntypedFormControl('', [Validators.required]),
    clientIdentifier: new UntypedFormControl('', [Validators.required]),
    linesForMRC: new UntypedFormArray([
    ]),
  })
  fineArt = new UntypedFormControl(false)
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  ngOnInit(): void {
    for(let i=0; i < 3;i++){ this.newLine() };
  }

  setSurplusLine(){
    const surplusControl = this.userdata.get('surplusLines')
    if(this.fineArt.value && surplusControl?.disabled){
      surplusControl.enable()
    } else if (!this.fineArt.value && surplusControl?.enabled){
      surplusControl.disable()
    }
  }

  newLine(){
    const control = new UntypedFormControl('', [Validators.required]);
    (this.userdata.get('linesForMRC') as UntypedFormArray).push(control)
  }

  getLines(){
    return (this.userdata.get('linesForMRC') as UntypedFormArray).controls;
  }

  getSelectedUserEmailsArray(users: any[]){
    users?.map((user) => {
      const email = new UntypedFormControl(user.email);
      (this.userdata.get('emails') as UntypedFormArray).push(email);
    });
  }

  writeValue(val: any): void {
    if(val){
      this.userdata.patchValue({
        companyName: val?.companyName,
        clientIdentifier: val?.clientIdentifier,
      })
      if(val.linesForMRC){
        val.linesForMRC.forEach((line : string, index: number)=> {
          (this.userdata.get('linesForMRC') as UntypedFormArray).at(index).setValue(line)
        });
      }
      if(val.surplusLines){
        this.userdata.get('surplusLines')?.setValue(val.surplusLines)
        this.fineArt.setValue(true)
      }
    }
    this.setSurplusLine()
  }

  registerOnChange(fn: any): void {
    this.userdata.valueChanges.pipe(
      takeUntil(this.unsub)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  validate(c: AbstractControl): ValidationErrors | null {
    return this.userdata.valid ? null : { invalidForm: {valid: false, message: ''}};
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.userdata.disable() : this.userdata.enable();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
