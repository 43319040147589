<h3 class="boldListHeader pb-1">Retroactive date in respect of :</h3>
<div class="d-flex flex-wrap ps-4 coverageDates">
    <div class="pb-1">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(coverageDates,'employeeBenefit')}" class="controlInput matDatePicker" name="employeeBenefit">
          <app-date-picker [dateControl] = 'coverageDates.get("employeeBenefit")'></app-date-picker>
          <label class="controlLabel">
            Employee Benefit*
          </label>
        </div>
        <mat-error *ngIf="pUtil.checkIfError(coverageDates,'employeeBenefit')">
          Please enter valid date MM/DD/YYYY
        </mat-error>
    </div>
    <div class="pb-1">
        <div class="controlInput matDatePicker" name="fWLiability">
          <app-date-picker [dateControl] = 'coverageDates.get("fWLiability")'></app-date-picker>
          <label class="controlLabel">
            Faulty Workmanship Liability (if applicable)
          </label>
        </div>
        <mat-error *ngIf="pUtil.checkIfError(coverageDates,'fWLiability')">
          Please enter valid date MM/DD/YYYY
        </mat-error>
    </div>
    <div class="pb-1">
        <div class="controlInput matDatePicker" name="eAndOLiability">
          <app-date-picker [dateControl] = 'coverageDates.get("eAndOLiability")'></app-date-picker>
          <label class="controlLabel">
            Error & Omission Liability (if applicable)
          </label>
        </div>
        <mat-error *ngIf="pUtil.checkIfError(coverageDates,'eAndOLiability')">
          Please enter valid date MM/DD/YYYY
        </mat-error>
    </div>
</div>