import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService, ComponentCommunicationService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../../models/product.type';

@Component({
  selector: 'app-default-currency',
  templateUrl: './default-currency.component.html',
  styleUrls: ['./default-currency.component.scss']
})

export class DefaultCurrencyComponent implements OnInit {
  @Input() preForm : any;
  @Input() form : UntypedFormGroup;
  currencyData: string[];
  prod = new Product();
  defaultCurrency = new UntypedFormControl();
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private comm: ComponentCommunicationService, private activeRoute: ActivatedRoute,
    private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.getProduct();
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('defaultCurrency', this.defaultCurrency);
    this.setCurrencyListener();
  }

  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      setTimeout(() => {
        let data = new Product().findProductType(this.pUtil.addSpace(params.get('prod')));
        this.currencyData = data?.defaultCurrency?.currencyData;
        this.defaultCurrency.setValue(this.preForm?.proposalForm?.defaultCurrency ?? data?.defaultCurrency?.defaultCur);
        this.comm.setCurrencyArr(data?.defaultCurrency?.currencyData);
        this.comm.setCurrencyType(this.defaultCurrency.value);
      })
    });
  }

  setCurrency() {
    this.comm.setCurrencyType(this.defaultCurrency.value);
  }

  setCurrencyListener(){
    this.comm.defaultCurrenyListener().pipe(
      takeUntil(this.unsub)
    ).subscribe((defaultCurrency: string) => {
      this.defaultCurrency.setValue(defaultCurrency);
      this.setCurrency();
    })
  }
}
