<div class="buttonWrapper col-lg-8 col-md-9 col-sm-10 col-xs-12 col-xxs-12 pt-5">
    <div class="colMargin">
        <p class="text">General condition of Equipment:</p>
        <div class="tableButtons btn-group text">
            <button *ngFor = 'let c of condition; let i=index' (click)= 'setCondition(c.value)'
            [ngClass]="{'active' : group.get('condition')?.value === c.value}">{{c.display}}</button>
            <mat-error class="typeValueError" *ngIf="group.get('condition')?.errors">
                *
            </mat-error>
        </div>

        <div class="pt-5">
            <p class="text">How often are Engine, Fuel, Brake & Oil Compartments, and belly pan thoroughly 
                cleaned of debris and lubricant accumulation?</p>
        
            <div class="controlInput me-4" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'cleaningFrequency')}">
                <input type="text" [formControl]="group?.get('cleaningFrequency')">
                <label class="controlLabel">
                    Please specify in detail
                </label>
            </div>
        </div>
    </div>
</div>