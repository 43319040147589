import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Contract, PortalDataService, SVLineage } from 'portal';
import { of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-family-tree',
  templateUrl: './family-tree.component.html',
  styleUrls: ['./family-tree.component.scss']
})
export class FamilyTreeComponent implements OnInit, OnDestroy {
  @Input() contractDetails: Contract;
  familyTree?: SVLineage;

  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private portalDataService: PortalDataService) { }

  ngOnInit(): void {
    this.checkFamilyTree()
  }

  checkFamilyTree(){
    this.portalDataService.getFamilyTree(this.contractDetails.lineage?.[0] ?? this.contractDetails.id).pipe(
      catchError((err:any)=>{
        return of(undefined)
      }),
      first(),
      takeUntil(this.unsub)
    ).subscribe((response?:SVLineage) => {
      this.familyTree = response
    })
  }

  ngOnDestroy(): void {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
