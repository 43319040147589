import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-required-maintenance-over-haul',
  templateUrl: './coverage-required-maintenance-over-haul.component.html',
  styleUrls: ['./coverage-required-maintenance-over-haul.component.scss']
})
export class CoverageRequiredMaintenanceOverHaulComponent {
  @Input() control: UntypedFormControl

  constructor(public pUtil : PortalUtilService) { }

}
