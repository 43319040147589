<div class="underscorePopup" *ngIf='message'>
    <span><h1 mat-dialog-title class="dialogTitle dialogText header">Error: {{message.status}}</h1></span>
    <button mat-dialog-close class= "closeButton"><div class="xIcon"></div></button>    
    <div class="line mb-4 mt-4"></div>
    <mat-dialog-content>
        <h2 class="dialogText dialogTitle">{{message.message}}</h2>
    </mat-dialog-content>
    <div class="line mb-3 mt-4"></div>

    <span class="d-flex justify-content-end mt-4 me-4">
        <button mat-button class= "roboto createButton" (click)="closePopup()">OK</button>
    </span>
</div>
