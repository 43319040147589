<div class="mb-5" formGroupName="proposalForm">
    <div formGroupName="lossExperience">
        <div class="mb-5" formArrayName="lossExperienceArray">
            <h1 class="header">{{secName}}</h1>
            <div class="lossExperience text">
                <p *ngIf='product.typeInArrToCheck(productType, product.supportedProducts.UTerrorism.typeArr)' class="mb-3">Has the Insured sustained any losses, incidents or threats in the last 5 years?</p>
                <p *ngIf='product.typeInArrToCheck(productType, product.supportedProducts.UCargo.typeArr) 
                    || product.typeInArrToCheck(productType, product.supportedProducts.UFineArt.typeArr)' 
                class="mb-3">Have there been any losses in the last 5 years?</p>
                <div class="d-flex colMargin pb-5">
                    <span>No</span>
                    <label class="switch me-3 ms-3">
                        <input (click)="toggleClaim()" type="checkbox" [attr.checked]="claim ? true : null">
                        <span class="slider"></span>
                    </label>
                    <span>Yes</span>
                </div>
                <div class="lossExperienceArray colMargin" [ngClass]="{'hide' : !claim}">
                    <p *ngIf='product.typeInArrToCheck(productType, product.supportedProducts.UTerrorism.typeArr)' class="subText pb-2">
                        Please provide full loss experience for the last 5 years minimum (gross of deductible),
                        including where possible full details around the nature of the loss(es)</p>
                    <p *ngIf='product.typeInArrToCheck(productType, product.supportedProducts.UCargo.typeArr)' class="subText pb-2">Please provide loss experience for both Transit and Inventory related 
                        losses for the last 5 years minimum (gross of deductible), including where possible full details around the nature of the loss(es)</p>
                    <p *ngIf='product.typeInArrToCheck(productType, product.supportedProducts.UFineArt.typeArr)' class="subText pb-2">
                        Please provide full details of all losses during the last 5 years relevant to the nature of the interest being insured hereunder,
                        whether covered by insurance or not, including any remedial action taken to prevent recurrence of such loss(es)</p>

                        <div *ngFor="let loss of getLoss(); let i=index">
                            <div class="pb-2 pt-3 listHeader d-flex">
                                <span *ngIf="i===0">Current Policy Year</span>
                                <span *ngIf="i===1">Previous Policy Year</span>
                                <span *ngIf="i===2">Two Years Ago</span>
                                <span *ngIf="i===3">Three Years Ago</span>
                                <span *ngIf="i===4">Four Years Ago</span>
                                <span *ngIf="i>4">{{i}} Years Ago</span>
                                <button *ngIf="loss.length === 0 || check(loss)" class="arrayButton ms-3" (click)="insertClaim(i, loss)">+</button>
                            </div>
                            <div class="d-flex justify-content-center align-items-center" *ngIf="loss.length === 0 || check(loss)">
                                No Claims For Given Year
                            </div>
                            <div [formGroupName] = "i" class='lossRow'>
                                <div *ngFor="let control of loss.controls; let j=index" [formGroupName] = "j">
                                    <div *ngIf="control?.get('claim')?.get('noClaim')?.value !== true" formGroupName="claim">
                                        <div class="row lossExperienceGap">
                                            <div class="col-md-1 col-sm-0 order-md-1 order-xxs-3 order-xxs-last d-flex align-items-center justify-content-center">
                                                <button class="arrayButton me-1" *ngIf="j + 1 === loss.length" (click)="removeClaim(i,j, loss)">-</button>
                                                <button class="arrayButton" *ngIf="j + 1 === loss.length" (click)="insertClaim(i, loss)">+</button>
                                            </div>
                                            <div [ngClass]= "cargoType === prod.cargoType.Both || cargoType === prod.cargoType.EquipmentBinder ? 'col-md-4 col-sm-12 d-flex order-2'
                                                            : cargoType === prod.cargoType.StockOnly ? 'col-md-2 col-sm-4 d-flex order-2' 
                                                            : 'col-0 d-flex'"
                                                            class="lossExperienceGap p-0">
                                                <div *ngIf="control?.get('claim')?.get('riskType')?.enabled" class="col">
                                                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(control.get('claim'),'riskType')}" class="controlInput customSelect">
                                                        <mat-select class="dropdown text" formControlName="riskType" (selectionChange)= "selectRiskType(risk.value,control.get('claim').get('lossType'))" #risk domControl>
                                                            <mat-option *ngFor="let riskType of riskTypes" [value]="riskType">{{riskType}}</mat-option>
                                                        </mat-select>
                                                        <label class="controlLabel">
                                                            Risk Type
                                                        </label>
                                                    </div> 
                                                </div>
                                                <div *ngIf="control?.get('claim')?.get('equipmentType')?.enabled" class="col">
                                                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(control.get('claim'),'equipmentType')}" class="controlInput customSelect">
                                                        <mat-select class="dropdown text" formControlName="equipmentType" #equipmentType domControl>
                                                            <mat-option *ngFor="let equipmentType of equipmentTypes" [value]="equipmentType">{{equipmentType}}</mat-option>
                                                        </mat-select>
                                                        <label class="controlLabel">
                                                            Equipment Type
                                                        </label>
                                                    </div> 
                                                </div>
                                                <div *ngIf="control?.get('claim')?.get('lossType')?.enabled" [ngClass]="{'noClick' : control?.get('claim')?.get('riskType')?.value === 'Transit'}" class="col">
                                                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(control.get('claim'),'lossType')}" class="controlInput customSelect">
                                                        <mat-select class="dropdown text" formControlName="lossType" [tabindex]="control?.get('claim')?.get('riskType')?.value === 'Transit' ? -1 : 0" domControl>
                                                            <mat-option *ngFor="let lossType of lossTypes" [value]="lossType">{{lossType}}</mat-option>
                                                        </mat-select>
                                                      <label class="controlLabel">
                                                        Loss Type
                                                      </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md col-sm-12 d-flex order-md-3 order-xxs-1 p-0 lossExperienceGap">
                                                <div *ngIf="control?.get('claim')?.get('paidClaim')?.enabled" class="col">
                                                    <app-amount required='true' [name]= "'Paid Claims'"
                                                            formControlName="paidClaim"></app-amount>
                                                </div>
                                                <div *ngIf="control?.get('claim')?.get('outstandingClaim')?.enabled" class="col">
                                                        <app-amount required='true' [name]= "'Outstanding Claims'"
                                                                formControlName="outstandingClaim"></app-amount>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mt-4">
                                <button class="button smallButton me-1" *ngIf="i + 1 === lossExperienceArray.length" (click)="addLoss()">Add Loss Year</button>
                                <button class="button smallButton" *ngIf="i + 1 === lossExperienceArray.length && i !== 0" (click)="removeLoss(i)">Remove Loss Year</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>