import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() toggleText: string;
  @Input() toggleControl: UntypedFormControl;
  @Output() toggleEmitter = new EventEmitter();
  @ViewChild('toggleControlCheck') someInput: ElementRef;
  constructor() { }

  toggle(){
    this.toggleControl.setValue(!this.toggleControl.value)
    this.toggleEmitter.emit(this.toggleControl.value)
  }

  disable(){
    this.toggleControl.setValue(false);
    this.toggleEmitter.emit(false);
    this.someInput.nativeElement.checked = false;
  }

}
