<div class="totalSales">
    <h1 class="header">4. Total Sales Revenues</h1>
    <span [ngClass]="{'hide' : salesType !== 1}"><app-sales1 [disabled]="salesType !== 1  ? true : false" [preForm]="preForm"></app-sales1></span>
    <span [ngClass]="{'hide' : salesType !== 2}"><app-sales2 [disabled]="salesType !== 2  ? true : false" [preForm]="preForm"></app-sales2></span>
    <app-upload-document-base [ngClass]="{'ng-invalid' : salesUploadControl?.errors}" domControl
        [docID]="preForm.id"
        [label]="'Please provide any supporting Sales Revenue documentation'"
        [id]="'TotalSalesRevenues'"
        (attachmentCount)="checkAttachment($event)">
    </app-upload-document-base>
    <div class="colMargin mt-3" *ngIf="salesUploadControl?.errors && salesUploadControl?.touched">
        <mat-error>Please upload supporting Sales Revenue documentation</mat-error>
    </div>
</div>