import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pt-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.css']
})
export class InformationBoxComponent implements OnInit{
  @Input() infoId: HTMLElement;
  show = false
  top: string
  left: string

  constructor(private host: ElementRef<HTMLElement>) { }

  ngOnInit(): void {
    let bounds = this.infoId.getBoundingClientRect();
    this.top = bounds.top + 'px'
    this.left = (bounds.left - bounds.width - 200) + 'px'

    if(!sessionStorage.getItem(this.infoId.id) && !localStorage.getItem(this.infoId.id)){
      sessionStorage.setItem(this.infoId.id, 'seen')
      this.show = true
      setTimeout (() => {
        this.show = false
        this.host?.nativeElement?.remove()
     }, 10000);
    } else {
      this.host?.nativeElement?.remove()
    }
  }

  acknowledgeInfo(){
    localStorage.setItem(this.infoId.id, 'acknowledged')
    this.host?.nativeElement?.remove()
  }


}
