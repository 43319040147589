<div formGroupName="proposalForm">
    <div formGroupName="overviewOfBusiness">
        <div formGroupName="brokerInformation">
            <div *ngIf="toggleInformation" class="brokerInformation">
                <app-neutral-toggle [toggleInformation]="toggleInformation?.brokerInformation"></app-neutral-toggle>
                <ng-container *ngIf='toggleInformation?.brokerInformation?.listOfEnabledToggles?.includes("brokerInformation")'>
                    <div class="d-flex flex-md-row flex-column mt-4 ps-4">
                        <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group?.get('brokerInformation'),'subBroker')}">
                            <input class="controlFull" type="text" formControlName="subBroker" placeholder="Sub Broker">
                            <label class="controlLabel">
                                Sub Broker
                            </label>
                        </div>
                        <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group?.get('brokerInformation'),'epicClient')}">
                            <input class="controlFull" type="text" formControlName="epicClient" placeholder="Epic Client Number">
                            <label class="controlLabel">
                                Epic Client
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>