import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminPortalBaseComponent, PortalDataService, PortalUtilService } from 'portal';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})

export class AdminPortalComponent extends AdminPortalBaseComponent implements OnInit{
  constructor(public portalDataService: PortalDataService, public router: Router,
    public activeRoute: ActivatedRoute, public pUtil: PortalUtilService
  ) { super(portalDataService, router, activeRoute, pUtil) }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
