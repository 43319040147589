<div class="col-xxl-8 col-xl-10 col-lg-12 bg-white col-center d-flex align-items-center spaceBetween px-2">
  <div class="smallText">* required information</div>
  <div class="d-flex align-items-center">
    <label class="listHeader me-4">
      Default Currency
    </label>
    <div class="controlInput customSelect defaultCurrency">
      <mat-select class="centerText text" (selectionChange)= "setCurrency()"  [formControl]="defaultCurrency">
        <mat-option *ngFor="let c of currencyData" [value]="c">{{c}}</mat-option>
      </mat-select>
    </div>
  </div>
</div>