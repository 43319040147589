<div class="col-lg-8 col-md-9 col-sm-10 col-xs-12 col-xxs-12 pt-5">
    <div class="colMargin">
        <p class="text" *ngIf='text'>{{text}}</p>
        <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfErrorControl(control)}">
            <textarea id="informationBox" type="text" [formControl]="control" placeholder="Further Information..."></textarea>
            <label class="controlLabel">
                Describe
            </label>
        </div>
    </div>
</div>