import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ContractListComponent } from './contracts/contract-components/contract-list/contract-list.component';
import { UpdateContractComponent } from './contracts/contract-components/update-contract/update-contract.component';
import { RiskTypeDetailComponent } from './contracts/contract-components/new-contract-options/risk-type-detail/risk-type-detail.component';
import { ScheduleOfValuesDetailComponent } from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-detail.component';
import { TimeoutPageComponent } from './timeout-page/timeout-page.component';
import { AdminPortalComponent } from './contracts/contract-components/admin-portal/admin-portal.component';
import { UserAssignProductComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/update-user/user-assign-product/user-assign-product.component';
import { AuthGuard } from 'portal';
import { ContractAdditionalOptionsComponent } from './contracts/contract-components/new-contract-options/contract-additional-options/contract-additional-options.component';


const routes: Routes = [
  {path: 'contracts', component: ContractListComponent, canActivate: [AuthGuard]},
  {path: 'contracts/updateContract/:prod/:doc', component: UpdateContractComponent, canActivate: [AuthGuard]},
  {path: 'contracts/riskType', component: RiskTypeDetailComponent, canActivate: [AuthGuard]},
  {path: 'contracts/additionalOptions', component: ContractAdditionalOptionsComponent, canActivate: [AuthGuard]},
  {path: 'contracts/updateContract/scheduleOfValues/:prod/:doc',
        component: ScheduleOfValuesDetailComponent, canActivate: [AuthGuard]},
  {path: 'contracts/admin', component: AdminPortalComponent, canActivate: [AuthGuard]},
  {path: 'contracts/admin/userProduct', component: UserAssignProductComponent, canActivate: [AuthGuard]},
  {path: 'contracts/timeout', component: TimeoutPageComponent},
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }