import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-agreement-equipment-binder',
  templateUrl: './user-agreement-equipment-binder.component.html',
  styleUrls: ['./user-agreement-equipment-binder.component.scss']
})
export class UserAgreementEquipmentBinderComponent {

  constructor() { }

}
