<div formGroupName="proposalForm">
    <div class="transitRiskDropDown col-6 ps-0">
        <h2 class="subHeader">4.3 Outgoing Values</h2>
        <button class="triskButton" [ngClass]="{'triskButtonToggle' : toggle === false}" (click)="controlOutgoingValues()"></button>
    </div>
    <span id="outgoing">
        <app-transit-risk [data]='preForm?.proposalForm?.transitRiskOutgoing' formControlName='transitRiskOutgoing'
        [type]='"outgoing"'
        ></app-transit-risk>
    </span>
</div>