<div class="topNav">
    <span [routerLink]="['/contracts']" id= "underscoreLogo"></span>
    <div class="search-container">
        <div class ="inputWrapper" *ngIf ="search" >
            <input (input)='setName(input.value)' class = "search" type="text" placeholder = "Search for a contract" aria-describedby="basic-addon2" #input/>
        </div>
        <div class="navButtons btn-group me-1">
            <span *ngIf ="!search && navType=== 'home'"><button class="navButton" (click)="searchClick()"><div id="searchIcon"></div></button></span>
            <span *ngIf ="search && navType=== 'home'"><button class="navButton"  (click)="searchClick()"><div id="xIcon"></div></button></span>
            <span id='admin' [innerHtml]="button"></span>
            <span><button class="navButton" (click) = "logout()"><div id="logout"></div></button></span>
        </div>
        <div id='brokerLogo'>
            <img *ngIf="customlogo; else oldlogo" [src]="customlogo" class="logoimg">
            <ng-template #oldlogo>
                <div [id]='logo'></div>
            </ng-template>
        </div>
    </div>
</div>