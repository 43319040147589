import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Product } from 'projects/mcgill/src/app/contracts/shared-resources/models/product.type';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { PTFigure, PTQuote } from '../../../model/typings';
import { ComponentCommunicationService } from '../../../services/component-communication.service';
import { PortalDataService } from '../../../services/portal-data.service';
import { PortalUtilService } from '../../../services/portal-util.service';

@Component({
  selector: 'pt-quoted-risk-base',
  templateUrl: './quoted-risk-base.component.html',
  styleUrls: ['./quoted-risk-base.component.css']
})

export abstract class QuotedRiskBaseComponent implements OnDestroy {
  @Input() preForm: any;
  prem: string; detail = false; quoteIDs : {quotes: PTQuote[]}; spin: string | null;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService, public router: Router, public dialog: MatDialog, 
    public comm : ComponentCommunicationService, public pUtil: PortalUtilService
  ) { }

  async setupQuote(supportMultipleQuote: boolean){
    this.getPremium();
    supportMultipleQuote ? await this.getListOfQuotes() : this.quoteIDs={quotes:[]};
    this.comm.setDownloadMRC(this.quoteIDs.quotes.length <= 1);
  }

  getPremium() {
    const mrcHeadings : string[] = (new Product()).findProductType(this.preForm.product).platformQuoteHeaders
    
    forkJoin([
      this.portalDataService.getPremium(this.preForm.id),
      this.portalDataService.getDeducPremInfo(`/quote/lineItems/${this.preForm.id}`, mrcHeadings)
    ]).pipe(
      takeUntil(this.unsub)
    )
    .subscribe(([response, lines]: any) => {
      if(response?.Premium){
        this.prem = this.pUtil.getDecimalFigureFormat(response.Premium.figure, response.Premium.currency);
      }
      this.setupFigures(response.figures, lines)
    });
  }

  acceptQuote(quote: string, tria?: UntypedFormControl, ratesControl?: UntypedFormGroup, quotedIC?: string) {
    let body = {}
    if(tria?.value || tria?.value === false){
      body['acceptTRIA'] = tria.value
    }

    if(ratesControl?.get('rateControl')?.value === false){
      const commission = ratesControl?.get('rates')?.get('commission')?.value
      const brokerage = ratesControl?.get('rates')?.get('brokerage')?.value
      if(commission){
        body['commission'] = commission
      }
      if(brokerage){
        body['brokerage'] = brokerage
      }
    }

    this.spin = quote;
    this.portalDataService.acceptQuote(this.preForm.id, body, quotedIC).
    pipe(
      takeUntil(this.unsub),
      finalize(() => this.spin = null)
    )
    .subscribe((response: any) => {
      if (!response.error) {
        this.router.navigate(['contracts']);
      } else {
        const message = {status:'While trying to Accept Quote', message:response?.errorDescription};
        this.pUtil.errorPopup(message);
      }
    });
  }

  rejectQuote(quote: string, quotedIC?: string) {
    this.spin = quote;
    this.portalDataService.rejectQuote(this.preForm.id, quotedIC).
    pipe(
      takeUntil(this.unsub),
      finalize(() => this.spin = null)
    )
    .subscribe((response: any) => {
      if (!response.error) {
        this.router.navigate(['contracts']);
      } else {
        const message = {status:'While trying to Reject Quote', message:response?.errorDescription};
        this.pUtil.errorPopup(message);
      }
    });
  }

  getListOfQuotes(){
    return new Promise<void> (resolve => {
      this.portalDataService.getListOfQuotes(this.preForm.id).
      pipe(
        takeUntil(this.unsub),
        finalize(() => resolve())
      )
      .subscribe((response: any) => {
        this.quoteIDs = response;
      });
    });
  }

  isQuoteRejected(quoteIC: string) {
    return this.preForm.declinedICs?.includes(quoteIC) || false;
  }

  isQuoteAccepted(quoteIC: string) {
    return this.preForm.acceptedIC === quoteIC;
  }

  toggleDetail() {
    this.detail = !this.detail;
  }

  abstract setupFigures(figures: PTFigure[], lineItems: any[]) : void;

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
