<div class='underscorePopup'>
    <span><h1 mat-dialog-title class="dialogTitle dialogText header">{{data?.title}}</h1></span>
    <div class="line mb-3 mt-3"></div>
    <mat-dialog-content class="dialogText">
        <div class="d-flex align-items-center submitPopup">
            <div [id]="data?.icon"></div>
            <div>
                <div class="mb-3" *ngFor="let m of data?.message">
                    {{m}}
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div class="line mb-3 mt-4"></div>
    <div class="d-flex justify-content-end mt-4 me-4">
        <button mat-button class= "roboto declineButton me-3" (click)="reject()">Return to Form</button>
        <button mat-button class= "roboto createButton" (click)="accept()">Submit</button>
    </div>
</div>