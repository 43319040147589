<ng-container *ngIf = 'preForm'>
    <section pt-appDisableAll [disableAll] = '!platformStatus' class="formSection" id="section1">
        <app-insured-address [preForm]='preForm' [productType]='productType'></app-insured-address>

        <app-surplus-risk [docID]='docID' [productType]='productType' [preForm]='preForm'></app-surplus-risk>

        <app-business-overview [preForm]='preForm'></app-business-overview>

        <span *ngIf='fineArtType'>
            <app-fine-art-type-detail [fineArtType]='fineArtType'></app-fine-art-type-detail>
        </span>

        <div [ngClass]="{'hide' : !fineArtType?.value}" class="colMargin pt-4 pb-4">
            <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0 buttonWrapper tableButtonPadding">
                <app-basis-of-valuation [preForm]='preForm' [basisOfValuationArray]='fineArtType?.value'></app-basis-of-valuation>
            </div>
        </div>

        <app-business-overview-fine-art-detail [preForm]='preForm'></app-business-overview-fine-art-detail>

        <div [ngClass]="{'hide' : !isDealer()}">
            <app-sales-revenues [preForm]='preForm' [disabled]="isDealer() ? false : true"></app-sales-revenues>
        </div>

        <app-risk-information [formType]='fineArtType?.value' sectionHeader='{{isDealer() ? "4" : "3"}}. Risk Information' [preForm]='preForm'></app-risk-information>

        <app-transit-information [formType]='fineArtType?.value' sectionHeader='{{isDealer() ? "5" : "4"}}. Transit Information' [preForm]='preForm'></app-transit-information>

        <app-schedule-of-values [productType]='productType' sectionHeader='{{isDealer() ? "6" : "5"}}. INVENTORY / STORAGE RISK' [preForm]='preForm'></app-schedule-of-values>

        <app-loss-experience-array [cargoType]='cargoTypes.StockOnly' secName='{{isDealer() ? "7" : "6"}}. Loss Experience' [preForm]='preForm' [productType]='productType'></app-loss-experience-array>

        <app-additional-docs [docID]='docID' secName='{{isDealer() ? "8" : "7"}}. ADDITIONAL INFORMATION' [preForm]='preForm'></app-additional-docs>

        <app-disclaimer [platformStatus]='platformStatus'></app-disclaimer>
        
    </section>
</ng-container>