<div class="buttonWrapper" [ngClass]="{'ng-invalid' : buttonControl?.errors}" domControl>
    <div class="tableButtons btn-group">
        <mat-error class="typeError" *ngIf="buttonControl.errors">
            *
        </mat-error>
        <ng-container *ngFor="let item of types">
            <button (click)= 'chooseVal(item.value)' [ngClass]="{'active' : buttonControl.value === item.value}">
                {{item.display}}
            </button>
        </ng-container>
    </div>
</div>