import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-styled-buttons',
  templateUrl: './styled-buttons.component.html',
  styleUrls: ['./styled-buttons.component.scss']
})
export class StyledButtonsComponent implements OnInit {
  @Input() buttonControl: UntypedFormControl
  @Input() types: ButtonType[]
  @Input() required:boolean = false
  constructor() { }

  ngOnInit(): void {
    if(this.required){
      this.buttonControl.setValidators(Validators.required)
      this.buttonControl.updateValueAndValidity()
    }
  }

  chooseVal(val: string) {
    this.buttonControl.setValue(val)
  }

}

export interface ButtonType {
  display: string
  value: string
}
