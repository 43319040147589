import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { PTMessage } from '../../model/typings';

@Component({
  selector: 'pt-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.css']
})

export class ErrorPopupComponent implements OnInit, OnDestroy {
  message: PTMessage;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public ref: MatDialogRef<ErrorPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: PTMessage) {
  }

  ngOnInit(): void {
    this.message = this.data;
  }

  closePopup() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.ref.close();
    this.unsub.next(true);
    this.unsub.complete();
  }
}
