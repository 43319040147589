<div formGroupName="proposalForm">
  <h1 class="header">2. Business Overview</h1>
  <div class="business colMargin">
    <div class="row" formGroupName="period">
      <div class="col-xl-4 col-lg-4 col-md-5 col-sm-6">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(period,'startDate')}" class="controlInput matDatePicker p-0 ps-2">
          <app-date-picker [dateControl] = 'period.get("startDate")' name="startDate"></app-date-picker>
          <label class="controlLabel">
            Contract Start Date<span *ngIf="period.get('startDate')?.errors?.required">*</span>
          </label>
        </div>
        <mat-error *ngIf="pUtil.checkIfError(period,'startDate')">
          Please enter valid date MM/DD/YYYY
        </mat-error>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-5 col-sm-6">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(period,'endDate')}" class="controlInput matDatePicker p-0 ps-2">
          <app-date-picker [date] = 'end' [dateControl] = 'period.get("endDate")' name="endDate"></app-date-picker>
          <label class="controlLabel">
            Contract End Date<span *ngIf="period.get('endDate')?.errors?.required">*</span>
          </label>
        </div>
        <mat-error *ngIf="pUtil.checkIfError(period,'endDate')">
          Please enter valid date MM/DD/YYYY
        </mat-error>
      </div>
    </div>
    <div formGroupName="overviewOfBusiness">
      <div class="row">
        <div class="col-12">
          <div [ngClass]="{'requiredColor' : pUtil.checkIfError(overviewOfBusiness,'description')}" class="controlInput">
            <textarea id="overviewOfBusiness" type="text" formControlName="description" placeholder="Description" domControl></textarea>
            <label class="controlLabel">
              Description of business and of the Interest to be Insured*
            </label>
          </div>
        </div>
        <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12">
          <div class="controlInput">
            <input class="controlFull" type="text" formControlName="websiteAddress" placeholder="Website">
            <label class="controlLabel">
              Website
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>