<div [formGroup]="user" class="col-xl-5 col-lg-6 col-md-7 col-sm-8 col-12 col-center">
  <div class="pb-4 registerUserHeader">
    <h1>Register User</h1>
    <p>....please complete all of the fields</p>
  </div>
  <div class="controlInput">
      <input class="controlFull" type="text" formControlName="email" placeholder="Email">
      <label class="controlLabel">
        Email*
      </label>
  </div>
  <div class="controlInput">
      <input class="controlFull" type="text" formControlName="firstName" placeholder="First Name">
      <label class="controlLabel">
        First Name*
      </label>
  </div>
  <div class="controlInput">
      <input class="controlFull" type="text" formControlName="lastName" placeholder="Last Name">
      <label class="controlLabel">
        Last Name*
      </label>
  </div>
  <app-update-user-cva formControlName="userdetails"></app-update-user-cva>
  <span class="d-flex justify-content-end pt-2 pb-5">
    <button class="button smallButton" (click)="registerUser()" [disabled]="!user.valid">Register User</button>
  </span>
</div>