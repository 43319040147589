import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PortalDataService } from 'portal';
import { environment } from 'projects/mcgill/src/environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../../../shared-resources/models/product.type';

@Component({
  selector: 'app-quote-pane',
  templateUrl: './quote-pane.component.html',
  styleUrls: ['./quote-pane.component.scss']
})

export class QuotePaneComponent implements OnInit, OnDestroy {
  @Input() preForm: any;
  show: string;
  paneData : {header: string, text: string[]}[];
  prod = new Product()
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private portalDataService : PortalDataService) { }

  ngOnInit(): void {
    const mrcHeadings : string[] = this.prod.findProductType(this.preForm.product).platformQuoteHeaders
    this.portalDataService.getDeducPremInfo(`/quote/lineItems/${this.preForm.id}`, mrcHeadings)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((resp: {header: string, text: string[]}[]) => {
      this.paneData = resp.sort((a: {header: string}, b: {header: string})=> 
      mrcHeadings.indexOf(a.header) - mrcHeadings.indexOf(b.header))
      this.show = resp?.[0]?.header
    });
  }

  premium(s: string) {
    this.show = s;
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
