import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PortalDataService, PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../models/product.type';
import { ToggleComponent } from '../_shared-form-components/toggle/toggle.component';

@Component({
  selector: 'app-surplus-risk',
  templateUrl: './surplus-risk.component.html',
  styleUrls: ['./surplus-risk.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class SurplusRiskComponent implements OnInit {
  @Input() preForm: any;
  @Input() productType : string;
  @Input() docID: string;
  @ViewChild(ToggleComponent ) toggle: ToggleComponent; 
  @Input() set disabled(value: any) {
    this._disabled = value;
    if(value && this.surplusInfEnabled.value){
      this.toggle.disable();
    }
  }
  prod = new Product();
  surplusInfEnabled = new UntypedFormControl(false);
  _disabled : boolean;
  public surplusRisk = new UntypedFormGroup({
    surplusLineBrokerName: new UntypedFormControl('', [Validators.required]),
    address: new UntypedFormControl(''),
    license: new UntypedFormControl('', [Validators.required]),
    filing: new UntypedFormControl('', [Validators.required]),
  });
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, public pUtil: PortalUtilService, private portalDataService: PortalDataService) {
  }
  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('surplusRisk', this.surplusRisk);
    this.prepareSurplusRisk();
  }
  toggleSurplusRisk() {
    for (const field in this.surplusRisk.controls) {
      const control = this.surplusRisk.get(field);
      this.surplusInfEnabled.value ? control?.enable() : control?.disable();
    }
  }
  prepareSurplusRisk(){
    const data = this.preForm.proposalForm?.surplusRisk;
    if(this.prod.typeInArrToCheck(this.productType, this.prod.supportedProducts.UFineArt.typeArr)){
      this.loadSurplusLine(this.surplusRisk, data);
      !this.docID || data ? this.surplusInfEnabled.setValue(true) : this.surplusInfEnabled.setValue(false);
    } else {
      this.setupSurplusRisk(this.surplusRisk, data);
      data ? this.surplusInfEnabled.setValue(true) : this.surplusInfEnabled.setValue(false);
    }
    this.toggleSurplusRisk();
  }
  setupSurplusRisk(form: UntypedFormGroup, data: any) {
    if (data) {
      form.patchValue({
        surplusLineBrokerName : data?.surplusLineBrokerName,
        license : data?.license,
        filing : data?.filing,
        address: data?.address,
      });
    } else {
      this.surplusRisk.disable();
    }
  }
  loadSurplusLine(form: UntypedFormGroup, data: any){
    this.portalDataService.getUserDetails()
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) => {
      const ud = response.surplusLines;
      if(ud?.lines){
        ud.lines = [ud.lines];
      }
      form.patchValue({
        surplusLineBrokerName : data?.surplusLineBrokerName || ud?.surplusLineBrokerName,
        license : data?.license || ud?.licenseNo,
        filing : data?.filing || ud?.stateOfFiling,
        address : {...ud, ...data?.address},
      });
    });
  }
  
}
