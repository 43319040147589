import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Interest } from '../../../models/contract.model';

@Component({
  selector: 'app-fine-art-type-detail',
  templateUrl: './fine-art-type-detail.component.html',
  styleUrls: ['./fine-art-type-detail.component.scss']
})
export class FineArtTypeDetailComponent implements OnInit {
  @Input() fineArtType: UntypedFormControl;
  type: { key: string; display: string; value: string; }[];
  constructor() { }

  ngOnInit(): void {
    this.type = new Interest().type.fineArt;
    this.fineArtType.setValidators(Validators.required);
    this.fineArtType.updateValueAndValidity();
  }

  chooseInterest(interest: string) {
    this.fineArtType.setValue(interest);
  }

}
