import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { PortalDataService, PortalUtilService, PTAmount } from 'portal';
import { merge, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { operators, ScheduleInfo, sovChangesListener, sovTypes, validation, validators } from '../../../../models/sov.model';

@Component({
  selector: 'app-schedule-of-values-base',
  templateUrl: './schedule-of-values-base.component.html',
  styleUrls: ['./schedule-of-values-base.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class ScheduleOfValuesBaseComponent implements OnInit, OnDestroy {
  @Input() preForm: any;
  @Input() status: boolean;
  @Input() initSchedule: any;
  @Input() setupSchedule: any;
  @Input() scheduleOfValues = new UntypedFormArray([]);
  @Input() scheduleInfo: ScheduleInfo[]
  @Input() importSupported: boolean
  changesUnsubscribe = new Subject()
  scheduleAmountControls: string[]
  scheduleInfoAsObject: ScheduleInfo
  sovTypes = sovTypes
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private pUtil: PortalUtilService, private portalDataService: PortalDataService) { }

  ngOnInit(): void {
    this.scheduleAmountControls = this.scheduleInfo.filter(obj => obj.type === sovTypes.Amount).map(({formGroupName}) => formGroupName)
    this.scheduleInfoAsObject = this.pUtil.arrayToObject(this.scheduleInfo, 'key')
  }

  onValueChanges(){
    this.changesUnsubscribe.next()
    const schedules = this.scheduleOfValues
    merge(...schedules.controls.map((control: AbstractControl, index: number) =>
    control.valueChanges.pipe(map(value => ({ rowIndex: index, value })))))
    .pipe(
      takeUntil(this.changesUnsubscribe)
    )
    .subscribe((changes: any) => {
      const schedule = schedules.at(changes.rowIndex);
      const cl = changes.value
      if (schedule) {
        const empty = this.pUtil.isEmpty(cl, true)
        this.loopSchedule(schedule, empty, cl)
      }
    });
  }

  loopSchedule(schedule: any, empty: boolean, data: any) {
    const sc = schedule.controls
    for (const contractProp in sc) {
      if(this.scheduleAmountControls.includes(contractProp)){
        data[contractProp]?.figure ? sc[contractProp]?.get('currency')?.enable({ emitEvent: false }) : sc[contractProp]?.get('currency')?.disable({ emitEvent: false })
        if(data[contractProp]?.figure > this.scheduleInfoAsObject[contractProp]?.threshold){
          sc[contractProp]?.get('figure')?.setErrors({scheduleOverLimit: `Value cannot be greater than ${this.preForm.proposalForm.defaultCurrency} ${new Intl.NumberFormat().format(this.scheduleInfoAsObject[contractProp]?.threshold)}`})
          return
        }
        this.validateControl(sc[contractProp].get('figure'), this.scheduleInfoAsObject[contractProp], data, empty)
      }
      else if (sc[contractProp]?.controls) {
        this.loopSchedule(sc[contractProp], empty, data)
      } 
      else if (this.scheduleInfoAsObject[contractProp]){
        this.validateControl(sc[contractProp], this.scheduleInfoAsObject[contractProp], data, empty)
      }
    }
  }

  checkIfValueExists(control: { value: boolean | string | number }){
    return (control?.value || control?.value === false || control?.value === 0)
  }

  validateControl(control: AbstractControl, scheduleInfo: ScheduleInfo, data: any, empty: boolean){
    if (!this.checkIfValueExists(control) && scheduleInfo?.required && !empty) {
      control?.setErrors({scheduleRequired: true})
    }
    else if(this.checkIfValueExists(control) && scheduleInfo.changeToValidate && !empty){
    }
    else if (scheduleInfo?.validation?.length) {
      for(const s of scheduleInfo.validation){
        let dataCoppy = data
        s.control.split('.').forEach((b: any) => {
          dataCoppy = dataCoppy?.[b]
        })
        if(s.operator === operators.Greater && dataCoppy > s.value){
          this.controlValidator(control, s)
          return
        }
        if(s.operator === operators.Less && dataCoppy < s.value){
          this.controlValidator(control, s)
          return
        }
        if(s.operator === operators.Equals && dataCoppy === s.value){
          this.controlValidator(control, s)
          return
        }
        control?.setErrors(null)
      }
    } 
    else {
      control?.setErrors(null)
    }
  }

  controlValidator(control: AbstractControl, validation: validation){
    if(validation.message === validators.Required && control.value){
      control?.setErrors(null)
      return
    } 
    control?.setErrors({customError: validation.message})
  }

  addSchedule() {
    this.scheduleOfValues.push(this.initSchedule());
    this.onValueChanges();
    this.scheduleOfValues.enable();
  }

  getSchedule() {
    return this.scheduleOfValues.controls;
  }

  removeSchedule(i: number) {
    this.scheduleOfValues.removeAt(i);
    this.onValueChanges();
  }

  complete() {
    this.unsub.next(true);
    this.unsub.complete();
  }

  ngOnDestroy(){
    this.complete();
  }
  

}