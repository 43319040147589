import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopupComponent, PTMessage } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-timeout-page',
  templateUrl: './timeout-page.component.html',
  styleUrls: ['./timeout-page.component.scss']
})
export class TimeoutPageComponent implements OnInit, OnDestroy {
  dialogOpen = false;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openErrorPopup({status: 'Timeout', 
    message: 'Server is taking too long to respond, can be caused by either poor connection or an error with our servers.'});
  }

  openErrorPopup(message: PTMessage) {
    if (!this.dialogOpen) {
      this.dialogOpen = true;
      const dialogRef = this.dialog.open(ErrorPopupComponent, {
        data: message,
        panelClass: 'custom-dialog-container',
        autoFocus: false
      });
      dialogRef.afterClosed()
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(() => {
        this.dialogOpen = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}
