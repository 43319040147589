import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DownloadPopupComponent } from '../../../popups/download-popup/download-popup.component';

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent {
  @Input() quoteID: string;
  @Input() preForm : any;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public dialog: MatDialog) { }
  downloadPopup(){
    this.dialog.open(DownloadPopupComponent, {
      data: {ids: {docID: this.preForm.id, quoteID: this.quoteID}, preform: this.preForm},
      panelClass: 'custom-dialog-container',
      autoFocus: false
    });
  }
}
