import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalUtilService } from 'portal';
import { CoverageList } from '../../../shared-resources/models/contract.model';
import { Product } from '../../../shared-resources/models/product.type';

@Component({
  selector: 'app-contract-additional-options',
  templateUrl: './contract-additional-options.component.html',
  styleUrls: ['./contract-additional-options.component.scss']
})
export class ContractAdditionalOptionsComponent implements OnInit {
  option: string
  coverageList = new CoverageList().list
  product: string
  productOptions: string[]
  constructor(public activeRoute: ActivatedRoute, private pUtil: PortalUtilService, private router: Router) { }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.option = params.option
      this.product = this.pUtil.addSpace(params.product)
      this.productOptions = new Product().findProductType(this.product).newRiskOptions
    });
  }

  navigate() {
    const indexOfOption = this.productOptions.indexOf(this.option)
    if(indexOfOption + 1 < this.productOptions.length){
      this.option = this.productOptions[indexOfOption + 1]
      this.router.navigate(['.'], { relativeTo: this.activeRoute, queryParams: { option: this.option, product: this.pUtil.rmvSpace(this.product)}})
    } else {
      this.router.navigate(['/contracts/updateContract', this.pUtil.rmvSpace(this.product), 'newRisk'])
    }
  }

}
