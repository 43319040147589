import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentCommunicationService, modelUtils, PortalDataService, PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../../models/product.type';

@Component({
  selector: 'app-download-risk',
  templateUrl: './download-risk.component.html',
  styleUrls: ['./download-risk.component.scss']
})
export class DownloadRiskComponent implements OnInit, OnDestroy{
  @Input() secName: string;
  @Input() preForm : any;
  requiredDownload: boolean;
  additionalInfoText: string;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private comm: ComponentCommunicationService, private portalDataService: PortalDataService,
    private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.downloadRequired()
    this.checkCommDetails()
  }

  checkCommDetails(){
    this.portalDataService.getUserDetails().
    pipe(
      takeUntil(this.unsub)
    ).
    subscribe((response: any) => {
      if(new Product().findProductType(this.preForm.product)?.commissionToUserDisplayed){
        const commission = this.pUtil.prodDetails(response.products, this.preForm.product)?.commission
        if(this.secName === new modelUtils().risk.Quoted && commission){
          this.additionalInfoText = `(Total brokerage to you: ${commission}%)`
        }
      }
    })
  }

  downloadRequired() {
    this.comm.downloadMRCListener().
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response) => {
      this.requiredDownload = response;
    })
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
