<div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 col-xxs-12 pt-5">
    <div class="d-flex colMargin">
        <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'area')}">
            <input class="controlFull" type="text" [formControl]="group?.get('area')" placeholder="Specify Area">
            <label class="controlLabel">
                Specify Area
            </label>
        </div>
    
        <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'annualFrequency')}">
            <input class="controlFull" type="text" [formControl]="group?.get('annualFrequency')" placeholder="How often per year">
            <label class="controlLabel">
                How often per year?
            </label>
        </div>
    </div>
</div>