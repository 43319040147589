<h1 class="header">3. Risk Mitigations</h1>
<div class="colMargin">
    <div class="mb-5">
        <h3 class="listHeader">Site Security Measures:</h3>
        <app-checkbox-picker [formArr]="riskMitigation.get('siteSecurityMeasures')"
            [formArrList]="siteSecurityMeasureList"
            [data]="preForm?.proposalForm?.riskMitigation?.siteSecurityMeasures"></app-checkbox-picker>
    </div>
    <div>
        <h3 class="listHeader">Information:</h3>
        <ng-container *ngFor="let upload of uploads">
            <div class="col-md-6 col-12 mt-4 d-flex align-items-center justify-content-between text"
                [ngClass]="{'ng-invalid' : upload.control.errors}" domControl>
                <label>{{upload.display}}
                    <span *ngIf="upload.required">*</span>
                </label>
                <app-upload-document-base [id]="upload.key" [docID]="preForm?.id" [dontHandleAttachments]="true"
                    (attachmentCount)="handleValidation($event, upload)" #app></app-upload-document-base>
                <ng-template #validationErrorMessage>
                    <ng-container *ngIf="upload.control.errors && upload.control.touched">
                        <mat-error>
                            Please provide the relevant information
                        </mat-error>
                    </ng-container>
                </ng-template>
            </div>
            <ng-container *ngIf="app.attachments?.files?.length > 0; else validationErrorMessage">
                <div class="col-md-6 col-12 mt-2 mb-5">
                    <div *ngFor="let file of app.attachments?.files" class="d-flex align-items-center">
                        <div class="docIcon"></div>
                        <label class="p-0 m-0 overflow-hidden me-2 text">{{file.name}}</label>
                        <div (click)="app.deleteAttachment(file.name)" class="xIcon ms-auto pointer"></div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>