<div class="d-flex status">
  <div *ngIf="status?.includes('submitted')" id="submittedIcon"></div>
  <div *ngIf="status?.includes('quoted')" id="quotedIcon"></div>
  <div *ngIf="status?.includes('bound')" id="confirmedIcon"></div>
  <div *ngIf="status?.includes('declined')" id="declinedIcon"></div>
  <div *ngIf="status?.includes('not taken up')" id="notTakenUpIcon"></div>
  <div *ngIf="status?.includes('quote accepted')" id="quotedIcon"></div>
  <div *ngIf="status?.includes('expired')" id="notTakenUpIcon"></div>
  <div *ngIf="!status" id="draftIcon"></div>
  <span>{{status | portalListStatusPipe : pipeRequired}}</span>
</div>