<div [ngClass]="{'hide' : _disabled}" class="surplusRisk colMargin">
    <div class="row">
        <div class="col-12 mb-4 mt-4">
            <app-toggle [toggleControl]="surplusInfEnabled"
            toggleText="Surplus Lines Risk?"
            (toggleEmitter)="toggleSurplusRisk()"
            ></app-toggle>
        </div>
        <div class="col-12">
            <div formGroupName="proposalForm">
                <div [ngClass]="{'hide' : surplusRisk.disabled}" formGroupName="surplusRisk">
                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(surplusRisk,'surplusLineBrokerName')}" class="controlInput">
                        <input class="controlFull" type="text" formControlName="surplusLineBrokerName" placeholder="Broker Name" domControl>
                        <label class="controlLabel">
                          Surplus Line Broker Name*
                        </label>
                    </div>
                    <app-address [type]="'surplus'" formControlName='address'></app-address>
                    <div class="row">
                        <div class="col-4 pe-1">
                            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(surplusRisk,'license')}" class="controlInput">
                                <input class="controlFull" type="text" formControlName='license'  placeholder="License No" domControl>
                                <label class="controlLabel">
                                    License No*
                                </label>
                            </div>
                        </div>
                        <div class="col-6 ps-0">
                            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(surplusRisk,'filing')}" class="controlInput">
                                <input class="controlFull" type="text" formControlName='filing'  placeholder="State of Filing" domControl>
                                <label class="controlLabel">
                                    State of Filing*
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
