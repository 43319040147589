import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { TopNavComponent } from './contracts/shared-resources/shared-html-components/navigation/top-nav.component';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './modules/material-module';
import { AppModules } from './modules/app-modules';
import { BusinessOverviewComponent } from './contracts/shared-resources/forms/business-overview/business-overview.component';
import { DisclaimerComponent } from './contracts/shared-resources/forms/disclaimer/disclaimer.component';
import { GeneralQuestionsComponent } from './contracts/shared-resources/forms/general-questions/general-questions.component';
import { InsuredAddressComponent } from './contracts/shared-resources/forms/insured-address/insured-address.component';
import { MachineEquipmentLimitsComponent
} from './contracts/shared-resources/forms/machine-equipment-limits/machine-equipment-limits.component';
import { SalesRevenuesComponent } from './contracts/shared-resources/forms/sales-revenues/sales-revenues.component';
import { TransitLimitsComponent } from './contracts/shared-resources/forms/transit-limits/transit-limits.component';
import { TransitRiskIncomingComponent
} from './contracts/shared-resources/forms/transit-risk/transit-risk-incoming/transit-risk-incoming.component';
import { TransitRiskOutgoingComponent
} from './contracts/shared-resources/forms/transit-risk/transit-risk-outgoing/transit-risk-outgoing.component';
import { TransitRiskComponent } from './contracts/shared-resources/forms/transit-risk/transit-risk.component';
import { AddressComponent } from './contracts/shared-resources/forms/_CVA-components/address/address.component';
import { AmountComponent } from './contracts/shared-resources/forms/_CVA-components/amount/amount.component';
import { DatePickerComponent } from './contracts/shared-resources/forms/_shared-form-components/date-picker/date-picker.component';
import { ErrorHandlerComponent } from './contracts/shared-resources/forms/_shared-form-components/error-handler/error-handler.component';
import { ContractListComponent } from './contracts/contract-components/contract-list/contract-list.component';
import { ContractListDetailComponent
} from './contracts/contract-components/contract-list/contract-list-detail/contract-list-detail.component';
import { TotalRiskDetailComponent
} from './contracts/contract-components/contract-list/total-risk-detail/total-risk-detail.component';
import { UpdateContractComponent
} from './contracts/contract-components/update-contract/update-contract.component';
import { DefaultCurrencyComponent
} from './contracts/shared-resources/forms/_shared-form-components/default-currency/default-currency.component';
import { PlatformRiskDetailComponent
} from './contracts/contract-components/platform-risk-components/quoted-risk/platform-risk-detail.component';
import { ConfirmedRiskDetailComponent
} from './contracts/contract-components/platform-risk-components/confirmed-risk/confirmed-risk-detail.component';
import { CargoComponent } from './contracts/contract-components/update-contract/cargo-form/cargo/cargo.component';
import { DownloadRiskComponent } from './contracts/shared-resources/forms/_shared-form-components/download-risk/download-risk.component';
import { RiskTypeDetailComponent } from './contracts/contract-components/new-contract-options/risk-type-detail/risk-type-detail.component';
import { SurplusRiskComponent } from './contracts/shared-resources/forms/surplus-risk/surplus-risk.component';
import { AdditionalDocsComponent } from './contracts/shared-resources/forms/additional-docs/additional-docs.component';
import { InterestTypeDetailComponent
} from './contracts/shared-resources/forms/business-overview/interest-type-detail/interest-type-detail.component';
import { TerrorismFormComponent } from './contracts/contract-components/update-contract/terrorism-form/terrorism-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPInterceptor } from './app-services/http.interceptor';
import { OccupancyTypeDetailComponent
} from './contracts/shared-resources/forms/business-overview/occupancy-type-detail/occupancy-type-detail.component';
import { BusinessInterruptionValueComponent
} from './contracts/shared-resources/forms/business-overview/business-interruption-value/business-interruption-value.component';
import { ScheduleOfValuesComponent
} from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values/schedule-of-values.component';
import { ScheduleOfValuesDetailComponent
} from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-detail.component';
import { ScheduleOfValuesListComponent
} from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-list/schedule-of-values-list.component';
import { LossExperienceArrayComponent } from './contracts/shared-resources/forms/loss-experience-array/loss-experience-array.component';
import { ExposureComponent } from './contracts/shared-resources/forms/exposure/exposure.component';
import { UserAgreementPopupComponent } from './contracts/shared-resources/popups/user-agreement-popup/user-agreement-popup.component';
import { UserAgreementTextComponent } from './contracts/shared-resources/popups/user-agreement-popup/user-agreement-text/user-agreement-text.component';
import { BasisOfValuationComponent } from './contracts/shared-resources/forms/basis-of-valuation/basis-of-valuation.component';
import { TimeoutPageComponent } from './timeout-page/timeout-page.component';
import { AdminPortalComponent } from './contracts/contract-components/admin-portal/admin-portal.component';
import { MonthlyReportComponent } from './contracts/contract-components/admin-portal/admin-risk-management-functions/monthly-report/monthly-report.component';
import { TerrorismAgreementPopupComponent } from './contracts/shared-resources/popups/terrorism-agreement-popup/terrorism-agreement-popup.component';
import { AdminRiskListComponent } from './contracts/contract-components/admin-portal/admin-risk-management-functions/admin-risk-list/admin-risk-list.component';
import { FineArtFormComponent } from './contracts/contract-components/update-contract/fine-art-form/fine-art-form.component';
import { CasualtyFormComponent } from './contracts/contract-components/update-contract/casualty-form/casualty-form.component';
import { TotalSalesRevenuesComponent } from './contracts/shared-resources/forms/total-sales-revenues/total-sales-revenues.component';
import { CasualtyTypeDetailComponent } from './contracts/shared-resources/forms/business-overview/casualty-type-detail/casualty-type-detail.component';
import { Sales1Component } from './contracts/shared-resources/forms/total-sales-revenues/sales1/sales1.component';
import { Sales2Component } from './contracts/shared-resources/forms/total-sales-revenues/sales2/sales2.component';
import { OperationsComponent } from './contracts/shared-resources/forms/_CVA-components/operations/operations.component';
import { CoverageRequiredComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required/coverage-required.component';
import { CoverageComponentOneComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required/coverage-component-one/coverage-component-one.component';
import { CoverageComponentTwoComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required/coverage-component-two/coverage-component-two.component';
import { CoverageComponentThreeComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required/coverage-component-three/coverage-component-three.component';
import { CoverageComponentFourComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required/coverage-component-four/coverage-component-four.component';
import { LossExperienceUploadComponent } from './contracts/shared-resources/forms/additional-docs/loss-experience-upload/loss-experience-upload.component';
import { AdminRiskManagementFunctionsComponent } from './contracts/contract-components/admin-portal/admin-risk-management-functions/admin-risk-management-functions.component';
import { AdminUserAccountManagementFunctionsComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/admin-user-account-management-functions.component';
import { UserListComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/user-list/user-list.component';
import { NewUserFormComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/register-user/new-user-form/new-user-form.component';
import { UpdateDetailsFormComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/update-user/update-details-form/update-details-form.component';
import { OperationTotalsComponent } from './contracts/shared-resources/forms/total-sales-revenues/sales2/operation-totals/operation-totals.component';
import { UserAssignProductComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/update-user/user-assign-product/user-assign-product.component';
import { UploadDocumentBaseComponent } from './contracts/shared-resources/forms/additional-docs/upload-document-base/upload-document-base.component';
import { UserAgreementCasualtyComponent } from './contracts/shared-resources/popups/user-agreement-popup/user-agreement-casualty/user-agreement-casualty.component';
import { DownloadButtonComponent } from './contracts/shared-resources/forms/_shared-form-components/download-button/download-button.component';
import { QuotePaneComponent } from './contracts/contract-components/platform-risk-components/quoted-risk/quote-pane/quote-pane.component';
import { FineArtTypeDetailComponent } from './contracts/shared-resources/forms/business-overview/fine-art-type-detail/fine-art-type-detail.component';
import { BusinessOverviewFineArtDetailComponent } from './contracts/shared-resources/forms/business-overview/business-overview-fine-art-detail/business-overview-fine-art-detail.component';
import { RiskInformationComponent } from './contracts/shared-resources/forms/risk-information/risk-information.component';
import { TransitInformationComponent } from './contracts/shared-resources/forms/transit-information/transit-information.component';
import { ToggleComponent } from './contracts/shared-resources/forms/_shared-form-components/toggle/toggle.component';
import { InsuranceResponsibleComponent } from './contracts/shared-resources/forms/transit-information/insurance-responsible/insurance-responsible.component';
import { ScheduleOfValuesListFineArtComponent } from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-list-fine-art/schedule-of-values-list-fine-art.component';
import { environment } from '../environments/environment';
import { PortalModule } from 'portal';
import { UserSurplusLinesComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/shared-components/user-surplus-lines/user-surplus-lines.component';
import { UpdateUserCVAComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/shared-components/update-user-cva/update-user-cva.component';
import { DownloadPopupComponent } from './contracts/shared-resources/popups/download-popup/download-popup.component';
import { InformationDisplayPopupComponent } from './contracts/shared-resources/popups/information-display-popup/information-display-popup.component';
import { CleanProductPipe } from './contracts/shared-resources/pipes/clean-product.pipe';
import { CleanProdCommPipe } from './contracts/shared-resources/pipes/clean-prod-comm.pipe';
import { UserProductCvaComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/shared-components/user-product-cva/user-product-cva.component';
import { ChooseProdComponentDirective } from './contracts/shared-resources/directives/choose-prod-component.directive';
import { EquipmentBinderFormComponent } from './contracts/contract-components/update-contract/equipment-binder-form/equipment-binder-form.component';
import { BusinessDetailComponent } from './contracts/shared-resources/forms/business-overview/business-detail/business-detail.component';
import { PolicyTypeEQComponent } from './contracts/shared-resources/forms/policyType/policy-type-eq/policy-type-eq.component';
import { PolicyTypeComponent } from './contracts/shared-resources/forms/policyType/policy-type/policy-type.component';
import { BusinessContactDetailComponent } from './contracts/shared-resources/forms/business-overview/business-contact-detail/business-contact-detail.component';
import { CoverageRequiredEQComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-eq.component';
import { NeutralToggleComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/neutral-toggle/neutral-toggle.component';
import { CoverageRequiredDescribeComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-describe/coverage-required-describe.component';
import { CoverageRequiredBargingComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-barging/coverage-required-barging.component';
import { CoverageRequiredMaintenanceOverHaulComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-maintenance-over-haul/coverage-required-maintenance-over-haul.component';
import { CoverageRequiredInHouseMecsComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-in-house-mecs/coverage-required-in-house-mecs.component';
import { CoverageRequiredForLogsComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-for-logs/coverage-required-for-logs.component';
import { CoverageRequiredLossOfUseComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-loss-of-use/coverage-required-loss-of-use.component';
import { CoverageRequiredLogsWhileOnLandComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-logs-while-on-land/coverage-required-logs-while-on-land.component';
import { EquipmentScheduleComponent } from './contracts/shared-resources/forms/equipment-schedule/equipment-schedule.component';
import { FamilyTreeLevelComponent } from './contracts/contract-components/renewals/family-tree-level/family-tree-level.component';
import { IconStatusSwitcherComponent } from 'portal/angular/components/icon-status-switcher/icon-status-switcher.component';
import { CoverageRequiredMotorTruckCargoComponent } from './contracts/shared-resources/forms/coverageRequired/coverage-required-eq/coverage-required-motor-truck-cargo/coverage-required-motor-truck-cargo.component';
import { InsurersDeclinedToRenewComponent } from './contracts/shared-resources/forms/loss-experience-array/insurers-declined-to-renew/insurers-declined-to-renew.component';
import { SuggestedRatingComponent } from './contracts/shared-resources/forms/suggested-rating/suggested-rating.component';
import { FamilyTreeComponent } from './contracts/contract-components/renewals/family-tree/family-tree.component';
import { WholeSaleBrokerComponent } from './contracts/shared-resources/forms/business-overview/business-detail/whole-sale-broker/whole-sale-broker.component';
import { UserAgreementEquipmentBinderComponent } from './contracts/shared-resources/popups/user-agreement-popup/user-agreement-equipment-binder/user-agreement-equipment-binder.component';
import { ContractAdditionalOptionsComponent } from './contracts/contract-components/new-contract-options/contract-additional-options/contract-additional-options.component';
import { PdfToContractComponent } from './contracts/contract-components/new-contract-options/contract-additional-options/pdf-to-contract/pdf-to-contract.component';
import { SovGetJsonFromFileComponent } from './contracts/shared-resources/forms/table-of-schedule-items/sov-get-json-from-file/sov-get-json-from-file.component';
import { BourbonFormComponent } from './contracts/contract-components/update-contract/bourbon-form/bourbon-form.component';
import { ScheduleOfValuesListBourbonComponent } from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-list-bourbon/schedule-of-values-list-bourbon.component';
import { ScheduleOfValuesBaseComponent } from './contracts/shared-resources/forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-base/schedule-of-values-base.component';
import { UpdateProductsOnMultipleUsersComponent } from './contracts/contract-components/admin-portal/admin-user-account-management-functions/update-user/update-products-on-multiple-users/update-products-on-multiple-users.component';
import { SovListenerDirective } from './contracts/shared-resources/directives/sov-listener.directive';
import { ConstructionFormComponent } from './contracts/contract-components/update-contract/construction-form/construction-form.component';
import { RiskMitigationComponent } from './contracts/shared-resources/forms/constructionForms/risk-mitigation/risk-mitigation.component';
import { InterestTypeComponent } from './contracts/shared-resources/forms/_shared-form/interest-type/interest-type.component';
import { StyledButtonsComponent } from './contracts/shared-resources/forms/_shared-form/styled-buttons/styled-buttons.component';
import { ProjectSummaryComponent } from './contracts/shared-resources/forms/constructionForms/project-summary/project-summary.component';
import { PeriodComponent } from './contracts/shared-resources/forms/_shared-form/period/period.component';
import { CheckboxPickerComponent } from './contracts/shared-resources/forms/_shared-form/checkbox-picker/checkbox-picker.component';


@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    ContractListComponent,
    ContractListDetailComponent,
    LoginComponent,
    TotalRiskDetailComponent,
    UpdateContractComponent,
    BusinessOverviewComponent,
    DisclaimerComponent,
    GeneralQuestionsComponent,
    InsuredAddressComponent,
    MachineEquipmentLimitsComponent,
    SalesRevenuesComponent,
    ScheduleOfValuesComponent,
    TransitLimitsComponent,
    TransitRiskIncomingComponent,
    TransitRiskOutgoingComponent,
    TransitRiskComponent,
    AddressComponent,
    AmountComponent,
    DatePickerComponent,
    ErrorHandlerComponent,
    DefaultCurrencyComponent,
    PlatformRiskDetailComponent,
    ConfirmedRiskDetailComponent,
    CargoComponent,
    DownloadRiskComponent,
    RiskTypeDetailComponent,
    SurplusRiskComponent,
    AdditionalDocsComponent,
    InterestTypeDetailComponent,
    ScheduleOfValuesDetailComponent,
    ScheduleOfValuesListComponent,
    TerrorismFormComponent,
    OccupancyTypeDetailComponent,
    BusinessInterruptionValueComponent,
    ExposureComponent,
    LossExperienceArrayComponent,
    UserAgreementPopupComponent,
    UserAgreementTextComponent,
    BasisOfValuationComponent,
    TimeoutPageComponent,
    AdminPortalComponent,
    MonthlyReportComponent,
    TerrorismAgreementPopupComponent,
    AdminRiskListComponent,
    FineArtFormComponent,
    CasualtyFormComponent,
    PolicyTypeComponent,
    CasualtyTypeDetailComponent,
    TotalSalesRevenuesComponent,
    Sales1Component,
    Sales2Component,
    OperationsComponent,
    CoverageRequiredComponent,
    CoverageComponentOneComponent,
    CoverageComponentTwoComponent,
    CoverageComponentThreeComponent,
    CoverageComponentFourComponent,
    UploadDocumentBaseComponent,
    LossExperienceUploadComponent,
    AdminRiskManagementFunctionsComponent,
    AdminUserAccountManagementFunctionsComponent,
    UserListComponent,
    NewUserFormComponent,
    UpdateDetailsFormComponent,
    OperationTotalsComponent,
    UserAssignProductComponent,
    UserAgreementCasualtyComponent,
    DownloadButtonComponent,
    QuotePaneComponent,
    FineArtTypeDetailComponent,
    BusinessOverviewFineArtDetailComponent,
    RiskInformationComponent,
    TransitInformationComponent,
    ToggleComponent,
    InsuranceResponsibleComponent,
    ScheduleOfValuesListFineArtComponent,
    UserSurplusLinesComponent,
    CleanProductPipe,
    DownloadPopupComponent,
    UpdateUserCVAComponent,
    UserProductCvaComponent,
    InformationDisplayPopupComponent,
    CleanProductPipe,
    CleanProdCommPipe,
    ChooseProdComponentDirective,
    EquipmentBinderFormComponent,
    BusinessDetailComponent,
    PolicyTypeEQComponent,
    BusinessContactDetailComponent,
    CoverageRequiredEQComponent,
    NeutralToggleComponent,
    CoverageRequiredDescribeComponent,
    CoverageRequiredBargingComponent,
    CoverageRequiredMaintenanceOverHaulComponent,
    CoverageRequiredInHouseMecsComponent,
    CoverageRequiredForLogsComponent,
    CoverageRequiredLossOfUseComponent,
    CoverageRequiredLogsWhileOnLandComponent,
    EquipmentScheduleComponent,
    CoverageRequiredMotorTruckCargoComponent,
    InsurersDeclinedToRenewComponent,
    SuggestedRatingComponent,
    FamilyTreeLevelComponent,
    CoverageRequiredMotorTruckCargoComponent,
    FamilyTreeComponent,
    WholeSaleBrokerComponent,
    UserAgreementEquipmentBinderComponent,
    ContractAdditionalOptionsComponent,
    PdfToContractComponent,
    SovGetJsonFromFileComponent,
    BourbonFormComponent,
    ScheduleOfValuesListBourbonComponent,
    ScheduleOfValuesBaseComponent,
    UpdateProductsOnMultipleUsersComponent,
    SovListenerDirective,
    ConstructionFormComponent,
    RiskMitigationComponent,
    InterestTypeComponent,
    StyledButtonsComponent,
    ProjectSummaryComponent,
    PeriodComponent,
    CheckboxPickerComponent,
  ],
  imports: [
    MaterialModule,
    AppModules,
    PortalModule,
    PortalModule.forRoot(environment),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})



export class AppModule { }