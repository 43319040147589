<div formGroupName="proposalForm">
    <div formArrayName="salesRevenues">
        <h1 class="header">3. Sales Revenues</h1>
        <div class="colMargin">
            <div *ngFor="let sales of getSales(); let i=index">
                <div [formGroupName] = "i">
                        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0">
                            <app-amount [name]="i" formControlName="amount"></app-amount>
                        </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0 d-flex justify-content-end">
                <span *ngIf="getSales().length > 1">
                    <button  class="button smallButton" (click)="removeSales(getSales().length - 1)">Remove  -</button>
                </span>
                <span class="ms-1" *ngIf="getSales().length < 5">
                    <button class="button largeButton" (click)="addSales(true)">Add  +</button>
                </span>
            </div>
        </div>
    </div>
</div>