<ng-container *ngIf="preForm && setupComplete">
    <h1 class="header">4. Coverage Required</h1>

    <div>
        <app-neutral-toggle [toggleInformation]="toggleInformation?.transportationByWater"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("transportationByWater")'>
            <app-coverage-required-describe [control]='coverageRequired.get("transportationByWater")'></app-coverage-required-describe>
        </ng-container>
    </div>
    
    <div class="pt-5" [ngClass]="{'hide' : allText.getList(policyType)?.list !== 'listOne'}">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.bargingNotInProtectedWater"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("bargingNotInProtectedWater")'>
            <app-coverage-required-barging [group]='coverageRequired.get("bargingNotInProtectedWater")'></app-coverage-required-barging>
        </ng-container>
    </div>
    
    <div class="pt-5" [ngClass]="{'hide' : allText.getList(policyType)?.list !== 'listTwo'}">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.bargingNotOnFreshwater"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("bargingNotOnFreshwater")'>
            <app-coverage-required-barging [group]='coverageRequired.get("bargingNotOnFreshwater")'></app-coverage-required-barging>
        </ng-container>
    </div>
    
    <div class="pt-5">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.usedByApplicant"></app-neutral-toggle>
    </div>
    
    <div class="pt-5">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.maintenanceScheduleBasis"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("maintenanceScheduleBasis")'>
            <app-coverage-required-maintenance-over-haul [control]='coverageRequired.get("maintenanceScheduleBasis")'></app-coverage-required-maintenance-over-haul>
        </ng-container>
    </div>
    
    <div class="pt-5">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.inHouseMechanics"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("inHouseMechanics")'>
            <app-coverage-required-in-house-mecs [group]='coverageRequired.get("inHouseMechanics")'></app-coverage-required-in-house-mecs>
        </ng-container>
    </div>
    
    <div class="pt-5">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.inBoardFireSuppressionOnHarvesters"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("inBoardFireSuppressionOnHarvesters")'>
            <app-coverage-required-describe [control]='coverageRequired.get("inBoardFireSuppressionOnHarvesters")'></app-coverage-required-describe>
        </ng-container>
    </div>
    
    <div class="pt-5 pb-5">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.otherPortableFireExtinguishersInboard"></app-neutral-toggle>
    </div>
    
    <p class="listHeader pt-5">OPTIONAL COVERAGE</p>
    
    <app-neutral-toggle [toggleInformation]="toggleInformation?.lossOfUseHigherLimit"></app-neutral-toggle>
    <ng-container *ngIf='listOfEnabledToggles?.includes("lossOfUseHigherLimit")'>
        <app-coverage-required-loss-of-use [control]='coverageRequired.get("lossOfUseHigherLimit")'></app-coverage-required-loss-of-use>
    </ng-container>
    
    <div class="pt-5" [ngClass]="{'hide' : allText.getList(policyType)?.list !== 'listOne'}">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.coverageForForestFirefighting"></app-neutral-toggle>
    </div>
    
    <div class="pt-5" [ngClass]="{'hide' : allText.getList(policyType)?.list !== 'listTwo'}">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.relatedWatercraftDescription"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("relatedWatercraftDescription")'>
            <app-coverage-required-describe [control]='coverageRequired.get("relatedWatercraftDescription")'
            [text]="'Please include description in your Equipment Schedule and describe Operating Areas below'"></app-coverage-required-describe>
        </ng-container>
    </div>
    
    <div class="pt-5" [ngClass]="{'hide' : allText.getList(policyType)?.list !== 'listOne'}">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.coverageForLogs"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("coverageForLogs")'>
            <app-coverage-required-for-logs [array]='coverageRequired.get("coverageForLogs")'></app-coverage-required-for-logs>
        </ng-container>
    </div>
    
    <div class="pt-5" [ngClass]="{'hide' : allText.getList(policyType)?.list !== 'listTwo'}">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.logsWhileOnLand"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("logsWhileOnLand")'>
            <app-coverage-required-logs-while-on-land [control]='coverageRequired.get("logsWhileOnLand")'></app-coverage-required-logs-while-on-land>
        </ng-container>
    </div>

    <div class="pt-5">
        <app-neutral-toggle [toggleInformation]="toggleInformation?.motorTruckCargo"></app-neutral-toggle>
        <ng-container *ngIf='listOfEnabledToggles?.includes("motorTruckCargo")'>
            <app-coverage-required-motor-truck-cargo [group]='coverageRequired.get("motorTruckCargo")'></app-coverage-required-motor-truck-cargo>
        </ng-container>
    </div>

</ng-container>
