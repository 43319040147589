import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PortalDataService } from '../../../services/portal-data.service';
import { MessagesService, SVComments } from '../../../services/messages.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { first, map, distinctUntilChanged, tap, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'pt-chat-ui',
  templateUrl: './chat-ui.component.html',
  styleUrls: ['./chat-ui.component.css']
})
export class ChatUIComponent implements OnInit, OnDestroy {
  @Input() docID: string;
  chatBox = new UntypedFormControl()
  chatToggle: boolean;
  messages?:SVComments
  // [
  //   {sender:'Underscore', text:'hello', time: new Date().toISOString()},
  //   {sender:'UW', text:'hi', time: new Date().toISOString()},
  //   {sender:'UW', text:'f', time: new Date().toISOString()},
  //   {sender:'Underscore', text:`It is a long established fact that a reader will be distracted by the readable content of, It is a long established fact that a reader will be distracted by the readable content of ,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)`, time: new Date().toISOString()},
  //   {sender:'UW', text:`It is a long established fact that a reader will be distracted by the readable content of, It is a long established fact that a reader will be distracted by the readable content of ,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)`, time: new Date().toISOString()},
  // ];

  unsub: Subject<boolean> = new Subject<boolean>()

  constructor(private messageService : MessagesService, private dataService: PortalDataService) { }

  ngOnInit(): void {
    this.timerFallback()
    this.messageService.userMessagesSubject.pipe(
      map(messageMap => messageMap.get(this.docID)),
      takeUntil(this.unsub)
      // distinctUntilChanged()
    ).subscribe(messages => {
      if(messages){
        this.handleMessages(messages)
        this.scrollBottom()
      }
    })
  }

  timerFallback(){
    timer(0, 20000).pipe(
      takeUntil(this.unsub)
    ).subscribe(() => {
      this.messageService.getMessagesForRisk(this.docID).pipe(first(), takeUntil(this.unsub)).subscribe((messages) => {
        if(messages){
          this.handleMessages(messages)
        }
      })
    });
  }

  handleMessages(chat: SVComments){
    if(!this.messages || this.messages.messages.length < chat.messages.length){
      this.messages = chat
      this.messages.unRead = true
      this.scrollBottom()
      this.checkNotification()
    }
  }

  toggleChat(): void{
    this.chatToggle = !this.chatToggle
    if(this.chatToggle){
      this.checkNotification()
      this.scrollBottom()
    }
  }

  checkNotification(){
    if(this.chatToggle && this.messages){
      this.messages.unRead = false
    }
  }

  pad(n: string | number) {
    return String('0' + n).slice(-2);
  }

  getDate(isoString: string): string {
    const date = new Date(isoString)
    return date.getFullYear() + '/' + this.pad((date.getMonth() + 1)) + '/' + this.pad(date.getDate()) ;
  }

  getTime(isoString: string): string{
    const date = new Date(isoString)
    return `${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`
  }

  messageSend(){
    this.messageService.sendMessage(this.docID, this.chatBox.value)
    // .push({sender:'Underscore', text:this.chatBox.value, time: new Date().toISOString()})

    this.chatBox.setValue('')
  }

  scrollBottom(){
    if(this.chatToggle){
      setTimeout(() => {
        const element = document.getElementById("commentDisplay")
        if(element){
          element.scrollTop = element.scrollHeight
        }
      })
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
