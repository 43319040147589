<div class="" formGroupName="proposalForm">
    <h1 class="header">{{sectionHeader}}</h1>
    
    <div formGroupName="transitInformation">
        <div class="colMargin">
            <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0">
                <app-amount [required]="true" [name]="'What Transit Limit Do You Require?'" 
                formControlName="transitLimitsRequired"></app-amount>
            </div>

            <div [ngClass]="{'hide' : transitInformation.get('showFairsParticipatedIn')?.disabled}">
                <h2 class="ps-0 subHeader">Art Shows / Fairs participated in</h2>
                <div formArrayName='showFairsParticipatedIn'>
                    <div class="d-flex showsAndFairs  pb-xs-0 pb-xxs-5" *ngFor="let limit of getShowFairControls(); let i=index" [formGroupName] = "i">
                        <div class="col-lg-3 col-md-4 col-xs-5 col-xxs-12 p-0">
                            <div class="controlInput">
                                <input class="controlFull" type="text" formControlName="nameOfShowFair" placeholder="Name of Show/Fair">
                                <label class="controlLabel">
                                    Name of Show/Fair
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-7 col-xxs-12 p-0">
                            <app-amount [required]="false" [name]="'Amount'" formControlName="showFairLimit"></app-amount>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-end">
                    <span *ngIf="getShowFairControls().length > 1">
                        <button  class="button smallButton" (click)="removeShowFairControl(getShowFairControls().length - 1)">Remove  -</button>
                    </span>
                    <span class="ms-1">
                        <button class="button largeButton" (click)="addShowFairControl()">Add  +</button>
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="{'hide' : transitInformation.get('loanInformation')?.disabled}">

            <div class="col-12 p-0 colMargin">
                <div class="mt-5 mb-3 text">
                    <app-toggle [toggleControl]="transitInformation.get('loanInformation')?.get('loan')"
                        toggleText="Do you lend works to museums and/or galleries 
                            for exhibitions?"
                        (toggleEmitter)="setInsuranceResponsible($event)"
                    ></app-toggle>
                </div>
            </div>
            <div class="colMargin pt-5" *ngIf="transitInformation.get('loanInformation')?.get('loan')?.value">
                <p class="listHeader pb-3">Who is responsible for Insurance in these instances (Transit plus on-site):</p>
                <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0 buttonWrapper tableButtonPadding">
                    <app-insurance-responsible [insuranceResponsible]="transitInformation.get('loanInformation')?.get('whoIsResponsible')"></app-insurance-responsible>
                </div>
            </div>
        </div>
    </div>
</div>