import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PortalDataService } from '../../../services/portal-data.service';
import { PortalUtilService } from '../../../services/portal-util.service';

@Component({
  selector: 'pt-create-product-type-base',
  templateUrl: './create-product-type-base.component.html',
  styleUrls: ['./create-product-type-base.component.css']
})
export class CreateProductTypeBaseComponent implements OnInit, OnDestroy {
  riskType: string;
  productList: string[];
  unsub: Subject<boolean> = new Subject<boolean>();

    constructor(
      public router: Router,
      public portalDataService: PortalDataService,
      public pUtil: PortalUtilService,
  ) { }

  ngOnInit(): void {
    this.getUserDetails();
  }

  chooseRisk(type: string) {
    this.riskType = type;
  }

  getUserDetails(){
    this.portalDataService.getUserDetails()
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      response.error ? this.router.navigate(["/contracts"]) :
      this.productList = response.products;
    },
    error => {
      this.router.navigate(["/contracts"]);
    });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
