import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-total-sales-revenues',
  templateUrl: './total-sales-revenues.component.html',
  styleUrls: ['./total-sales-revenues.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class TotalSalesRevenuesComponent implements OnInit {
  @Input() salesType: number;
  @Input() preForm: any;
  public totalSalesRevenues = new UntypedFormGroup({
  });
  salesUploadControl = new UntypedFormControl({})
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('totalSalesRevenues', this.totalSalesRevenues);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('salesUploadControl', this.salesUploadControl);
  }

  checkAttachment(att: number){
    att > 0 ? this.salesUploadControl.setErrors(null) : this.salesUploadControl.setErrors({missingUpload:true});
  }
  
}
