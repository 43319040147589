import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PortalUtilService } from '../../../../services/portal-util.service';
import { PortalDataService } from '../../../../services/portal-data.service';
import { Subject } from 'rxjs';
import { ContractsService } from '../../../../services/contracts.service';

@Component({
  selector: 'pt-contract-list-base',
  templateUrl: './contract-list-base.component.html',
  styleUrls: ['./contract-list-base.component.css']
})

export class ContractListBaseComponent implements OnDestroy {
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor( public router: Router, 
    public dialog: MatDialog, public pUtil: PortalUtilService, public portalDataService: PortalDataService,
    public contractsService: ContractsService
  ) { }

  navigateToForm() {
    this.router.navigate(['contracts/riskType']);
  }

  ngOnDestroy() {
    this.contractsService.clearFilters();
    this.unsub.next(true);
    this.unsub.complete();
  }

}