<div class="row">
    <div class="col-lg-5 col-md-6">
      <div [ngClass]="{'requiredColor' : pUtil.checkIfError(period,'startDate')}" class="controlInput matDatePicker p-0 ps-2">
        <app-date-picker [dateControl] = 'period.get("startDate")' name="startDate"></app-date-picker>
        <label class="controlLabel">
          {{startLabel}}<span *ngIf="period.get('startDate')?.errors?.required">*</span>
        </label>
      </div>
      <mat-error *ngIf="pUtil.checkIfError(period,'startDate')">
        Please enter valid date MM/DD/YYYY
      </mat-error>
    </div>
    <div class="col-lg-5 col-md-6">
      <div [ngClass]="{'requiredColor' : pUtil.checkIfError(period,'endDate')}" class="controlInput matDatePicker p-0 ps-2">
        <app-date-picker [date] = 'end' [dateControl] = 'period.get("endDate")' name="endDate"></app-date-picker>
        <label class="controlLabel">
          {{endLabel}}<span *ngIf="period.get('endDate')?.errors?.required">*</span>
        </label>
      </div>
      <mat-error *ngIf="pUtil.checkIfError(period,'endDate')">
        Please enter valid date MM/DD/YYYY
      </mat-error>
    </div>
</div>