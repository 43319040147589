import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Interest } from '../../../models/contract.model';
import { CargoSchedule } from '../../../models/sov.model';

@Component({
  selector: 'app-interest-type-detail',
  templateUrl: './interest-type-detail.component.html',
  styleUrls: ['./interest-type-detail.component.scss']
})
export class InterestTypeDetailComponent implements OnInit, OnDestroy{
  @Input() interestType: UntypedFormControl;
  @Input() otherDetail: UntypedFormControl;
  interest: { key: string; display: string; value: string; }[];
  constructor() { }

  ngOnInit(): void {
    this.interest = new Interest().type.interest;
    this.otherDetail.setValidators(Validators.required);
    this.interestType.setValidators(Validators.required);
    this.interestType.updateValueAndValidity();
    CargoSchedule.cargoScheduleOptions.get('interestType')?.setValue(this.interestType.value)
  }

  chooseInterest(interest: string) {
    this.interestType.setValue(interest);
    CargoSchedule.cargoScheduleOptions.get('interestType')?.setValue(interest)
    this.checkOccupancy()
  }

  checkOccupancy(){
    this.interestType.value === 'Other' ? this.otherDetail.enable() : this.otherDetail.disable();
  }

  ngOnDestroy(): void {
    CargoSchedule.cargoScheduleOptions.get('interestType')?.reset()
  }
}
