<div formGroupName="proposalForm">
    <div formGroupName="coverageRequired">
        <div class="col-lg-10 col-12 p-0">
            <h3 class="boldListHeader pb-1">Underlying Insurance :</h3>
            <div class="ps-4" formArrayName='underlyingInsurance'>
                <div class="d-flex underlyingInsurance" *ngFor="let limit of getInsurance(); let i=index" [formGroupName] = "i">
                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(limit,'type')}" class="controlInput customSelect">
                        <mat-select formControlName="type" domControl>
                            <mat-option *ngFor="let type of typeArr" [value]="type">{{type}}</mat-option>
                        </mat-select>
                        <label class="controlLabel">
                            Type*
                        </label>
                    </div>
                    <div><app-amount [required] = 'true' [name]="'Limit'" formControlName="insuranceLimit"></app-amount></div>
                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(limit,'insurer')}" class="controlInput">
                        <input class="controlFull" type="text" formControlName="insurer" placeholder="Insurer" domControl>
                        <label class="controlLabel">
                          Insurer*
                        </label>
                    </div>
                </div>
            </div>
            <div  class="col-12 d-flex justify-content-end">
                <span *ngIf="getInsurance().length > 1">
                    <button  class="button smallButton" (click)="removeInsuranceControl(getInsurance().length - 1)">Remove  -</button>
                </span>
                <span class="ms-1">
                    <button class="button largeButton" (click)="addInsuranceControl()">Add  +</button>
                </span>
            </div>
        </div>
    </div>
</div>