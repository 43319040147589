import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalDataService, PortalUtilService, UserProductAssignBaseComponent } from 'portal';

@Component({
  selector: 'app-user-assign-product',
  templateUrl: './user-assign-product.component.html',
  styleUrls: ['./user-assign-product.component.scss']
})

export class UserAssignProductComponent extends UserProductAssignBaseComponent implements OnInit {
  constructor(public router: Router, public portalDataService: PortalDataService,
    public activeRoute: ActivatedRoute, public pUtil: PortalUtilService
    ) { super(router, portalDataService, activeRoute, pUtil) }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
