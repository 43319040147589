import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, PortalDataService } from 'portal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Enums } from '../../models/contract.model';
import { Product } from '../../models/product.type';

@Component({
  selector: 'app-user-agreement-popup',
  templateUrl: './user-agreement-popup.component.html',
  styleUrls: ['./user-agreement-popup.component.scss']
})
export class UserAgreementPopupComponent implements OnInit, OnDestroy {
  userDetails$: Observable<Object>;
  prod = new Product()
  unsub: Subject<boolean> = new Subject<boolean>();
  enums = new Enums(); isCasualty: boolean;
  constructor(public ref: MatDialogRef<UserAgreementPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private portalDataService: PortalDataService, private auth: AuthService) {
                ref.disableClose = true;
  }

  ngOnInit(): void {
    this.userDetails$ = this.portalDataService.getUserDetails()
  }

  closePopup() {
    this.ref.close();
  }

  acceptTerms(){
    const json = {document:'Underscore user agreement.docx',version:'November 2020'}
    this.portalDataService.acceptTerms(json)
    .pipe(
      takeUntil(this.unsub)
    ).subscribe(() => {
    });
  }

  logout(){
    this.auth.logout();
  }

  ngOnDestroy() {
    this.ref.close();
    this.unsub.next(true);
    this.unsub.complete();
  }

}
