<h1 class="header">7. SUGGESTED RATING</h1>

<h3 class="listHeader mb-5 colMargin">Additional Options:</h3>
<div class="ps-5">
    <div *ngFor="let item of suggestedRatingEntries | keyvalue:dontOrder ; let i=index" class="suggestedEntry colMargin">
        <ng-container *ngIf="item.value?.enabled">
            <div class="d-flex">
                <div class="checkBox">
                    <label class="container pointer">
                        <input (click)="entryClick(item.key, inp)" class="splitCheckBox" type="checkbox" [id]='item.key' [checked]='item.value.get("value").value' #inp> 
                        <span class="checkmark"></span>
                        <label class="pointer" [for]='item.key'>{{item.value.get('lineName').value}}</label>
                    </label> 
                </div>
            </div>
    
            <ng-container *ngIf='inp.checked'>
                <div class="row d-flex mt-2 pe-2 ps-5 suggestedEntryAmounts" [formGroup]="item.value">
                    <ng-container *ngIf='item.value?.get("flatOrFullyEarnedRates")'>
                        <div class="col-lg-4 col-md-6">
                            <app-amount [required]="item.key === 'broadWateborne' ? false : true" name= "Flat or Fully Earned Rates" formControlName="flatOrFullyEarnedRates"></app-amount>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='item.value?.get("limitsofLiability")'>
                        <div class="col-lg-4 col-md-6">
                            <app-amount [required]="true" name= "Limits of Liability" formControlName="limitsofLiability"></app-amount>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='item.value?.get("deductibiltyOrRetentions")'>
                        <div class="col-lg-4 col-md-6">
                            <app-amount [required]="true" name= "Deductible/Retention" formControlName="deductibiltyOrRetentions"></app-amount>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='item.value?.get("flatDeductibleRate")'>
                        <div class="col-lg-4 col-md-6">
                            <app-amount [required]="true" name= "Flat Deductible" formControlName="flatDeductibleRate"></app-amount>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='item.value?.get("flatDeductibleRef")'>
                        <div class="col-lg-7 col-md-6 d-flex align-items-center">
                            <label class="text ms-4 me-3">In respect of</label>
                            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(item.value,'flatDeductibleRef')}" class="controlInput flex-grow-1">
                                <input type="text" placeholder="Flat Deductible Description" formControlName="flatDeductibleRef" domControl>
                                <label class="controlLabel">
                                    Flat Deductible Description*
                                </label>
                            </div>                        
                        </div>
                    </ng-container>
                    <ng-container *ngIf='item.value?.get("depositPremiumRate")'>
                        <div class="col-lg-4 col-md-6">
                            <app-amount [required]="true" name= "Deposit Premium" formControlName="depositPremiumRate"></app-amount>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf='item.value?.get("premiumAdjustmentRatesStored") && item.value?.get("premiumAdjustmentRatesOperational")'>
                    <label class="premiumAdjustmentLabel ps-5 mt-4 mb-2">Premium Adjustment Rates:</label>
                    <div class="row d-flex pe-2 premiumAdjustmentRates">
                        <div class="controlInput col-lg-2 col-sm-4">
                            <input class="splitInput" [inputControl]='item.value?.get("premiumAdjustmentRatesStored")' placeholder="Stored" pt-appPercentage>
                            <label class="controlPercentageLabel">
                              %
                            </label>
                            <label class="controlLabel">
                              Stored
                            </label>
                        </div>
                        <div class="controlInput col-lg-2 col-sm-4">
                            <input class="splitInput" [inputControl]='item.value?.get("premiumAdjustmentRatesOperational")' placeholder="Operational" pt-appPercentage>
                            <label class="controlPercentageLabel">
                              %
                            </label>
                            <label class="controlLabel">
                              Operational
                            </label>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<div class="suggestedEntry colMargin" [formGroup]="lossOfUseRR">
    <h3 class="listHeader mt-5 mb-3">{{lossOfUseRR.get("lineName")?.value}}</h3>
    <div class="row d-flex pe-2 ps-5 suggestedEntryAmounts lossOfUseRR">
        <div class="col-lg-4 col-md-6">
            <app-amount name= "Flat or Fully Earned Rates" formControlName='flatOrFullyEarnedRates'></app-amount>
        </div>
        <div class="col-lg-4 col-md-6">
            <app-amount [required]="true" name= "Limits of Liability" formControlName='limitsofLiability'></app-amount>
        </div>
        <div class="col-lg-4 col-md-6">
            <div [ngClass]="{'requiredColor' : pUtil.checkIfError(lossOfUseRR,'deductibiltyOrRetentionsOpen')}" class="controlInput">
                <input type="text" [inputControl]='lossOfUseRR?.get("deductibiltyOrRetentionsOpen")' pt-appCurrency pt-ngInvalid>
                <label class="controlLabel">
                    Deductible/Retention (hours)*
                </label>
            </div> 
        </div>
    </div>
</div>

<div formGroupName="proposalForm" class="suggestedEntry colMargin">
    <div formArrayName='flatDeductible'>
        <h3 class="listHeader mt-5 mb-3">Flat Deductible:</h3>
        <div class="row d-flex pe-2 ps-5 suggestedEntryAmounts lossOfUseRR" *ngFor="let flatDeductible of getFlatDeductibleControls(); let i=index" [formGroupName] = "i" domControl>
            <div class="col-lg-4 col-md-6" [ngClass]="{'required' : pUtil.checkIfError(flatDeductible,'flatDeductibleRate')}">
                <app-amount name= "Flat Deductible" formControlName="flatDeductibleRate"></app-amount>
            </div>
            <div class="col-lg-8 col-md-6 d-flex align-items-center">
                <label class="text ms-4 me-3">In respect of</label>
                <div [ngClass]="{'requiredColor' : pUtil.checkIfError(flatDeductible,'flatDeductibleRef')}" class="controlInput flex-grow-1">
                    <input type="text" placeholder="Flat Deductible Description" formControlName="flatDeductibleRef" domControl>
                    <label class="controlLabel">
                        Flat Deductible Description
                    </label>
                </div>                        
            </div>
<!--             <ng-container *ngIf="flatDeductible.errors">
                <mat-error class="pb-3 d-flex justify-content-end">
                    *Please complete missing fields
                </mat-error>
            </ng-container> -->
        </div>
        <div class="col-12 d-flex justify-content-end mt-4">
            <span *ngIf="getFlatDeductibleControls().length > 1">
                <button  class="button smallButton" (click)="removeFlatDeductible(getFlatDeductibleControls().length - 1)">Remove  -</button>
            </span>
            <span class="ms-1">
                <button class="button largeButton" (click)="addFlatDeductible()">Add  +</button>
            </span>
        </div>
    </div>
</div>

<div class="tradingLimit colMargin">
    <h3 class="listHeader mt-5 mb-3">Trading Limit Number:</h3>
    <div [formGroup]="tradingLimits" class="col-lg-5 col-md-7 ps-5">
        <div class="controlInput customSelect">
            <mat-select formControlName="broadWaterborne" domControl>
                <mat-option value="">Not Selected</mat-option>
                <mat-option *ngFor="let option of tradingLimitOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
            <label class="controlLabel">
                Broad Waterborne
            </label>
        </div>
        <div class="controlInput customSelect">
            <mat-select formControlName="marineHulls" domControl>
                <mat-option value="">Not Selected</mat-option>
                <mat-option *ngFor="let option of tradingLimitOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
            <label class="controlLabel">
                Marine Hulls
            </label>
        </div>
        <div class="controlInput customSelect">
            <mat-select formControlName="logsInWaterAndInTransit" domControl>
                <mat-option value="">Not Selected</mat-option>
                <mat-option *ngFor="let option of tradingLimitOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
            <label class="controlLabel">
                Logs in Water and In Transit
            </label>
        </div>
    </div>
</div>