import { AfterViewInit, Component, Input } from '@angular/core';
import { ComponentCommunicationService } from 'portal';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements AfterViewInit {
  haveRead = false;
  @Input() platformStatus: boolean;
  constructor(private comm: ComponentCommunicationService) { }

  ngAfterViewInit(){
    const disclaimer = document.getElementById('disclaimer') as HTMLInputElement;
    if(this.platformStatus === false){
      disclaimer.checked = true;
    }
  }

  readAndAgreed() {
    this.haveRead = !this.haveRead;
    this.comm.setSubmit(this.haveRead);
  }
}
