import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { PortalUtilService, PTAmount } from 'portal';
import { merge, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-suggested-rating',
  templateUrl: './suggested-rating.component.html',
  styleUrls: ['./suggested-rating.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class SuggestedRatingComponent implements OnInit, OnDestroy{
  @Input() preForm: any;
  @Input() set policyType(type: string) {
    type === 'EFP' ? this.suggestedRatingEntries.forestFirefighting.disable() : this.suggestedRatingEntries.forestFirefighting.enable({emitEvent:false})

    if(type === 'TLA'){
      this.suggestedRatingEntries.noClaimsBonusEndorsement.enable({emitEvent:false})
      this.suggestedRatingEntries.valuationEndorsement.enable({emitEvent:false})
    } else {
      this.suggestedRatingEntries.noClaimsBonusEndorsement.disable()
      this.suggestedRatingEntries.valuationEndorsement.disable()
    }

    this.cdr.detectChanges()
  }

  @Input() set covLossOfUse(amount: any) {
    const control = this.lossOfUseRR?.get('limitsofLiability')
    if(amount?.figure){
      control?.setValue({figure: amount.figure, currency: amount.currency})
    } else {
      control?.setValue({figure: 20000})
    }
    this.cdr.detectChanges()
  }

  flatDeductible = new UntypedFormArray([])

  suggestedRating = new UntypedFormGroup({})

  lossOfUseRR  = new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentionsOpen : new UntypedFormControl(72, [Validators.required]), value : new UntypedFormControl(true), lineName : new UntypedFormControl('Loss of Use/Rental Reimbursement')})

  suggestedRatingEntries = {
    depositPremium                : new UntypedFormGroup({depositPremiumRate : new UntypedFormControl(),                                                                                                                                                       value : new UntypedFormControl(false), lineName : new UntypedFormControl('Deposit Premium')}),
    broadWateborne                : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(),                                                                                                            value : new UntypedFormControl(false), lineName : new UntypedFormControl('Broad Waterborne, Per Tow or Per Vessel')}),
    miscMarineWaterborne          : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(),                                                              value : new UntypedFormControl(false), lineName : new UntypedFormControl('Miscellaneous Marine Waterborne')}),
    machinery                     : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), premiumAdjustmentRatesStored : new UntypedFormControl(), premiumAdjustmentRatesOperational : new UntypedFormControl(),                                               value : new UntypedFormControl(false), lineName : new UntypedFormControl('Land Machinery and/or Equipment and/or Camp Buildings and/or Bridges/Culverts')}),
    marine                        : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(), premiumAdjustmentRatesStored : new UntypedFormControl(), premiumAdjustmentRatesOperational : new UntypedFormControl(), value : new UntypedFormControl(false), lineName : new UntypedFormControl('Marine Hulls')}),
    forestFirefighting            : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(),                                                              value : new UntypedFormControl(false), lineName : new UntypedFormControl('Own Forest Fire Fighting Expenses')}),
    logsShippedByBarge            : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(),                                                              value : new UntypedFormControl(false), lineName : new UntypedFormControl('Logs Shipped by Barge')}),
    logsWhileInWater              : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(),                                                              value : new UntypedFormControl(false), lineName : new UntypedFormControl('Logs in Water and in Transit')}),
    logsWhileOnLand               : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(),                                                              value : new UntypedFormControl(false), lineName : new UntypedFormControl('Logs Stored on Land')}),
    motorTruckCargo               : new UntypedFormGroup({limitsofLiability : new UntypedFormControl(), flatOrFullyEarnedRates : new UntypedFormControl(), deductibiltyOrRetentions : new UntypedFormControl(),                                                              value : new UntypedFormControl(false), lineName : new UntypedFormControl('Motor Truck Cargo')}),
    noClaimsBonusEndorsement      : new UntypedFormGroup({value : new UntypedFormControl(false), lineName : new UntypedFormControl('No Claims Bonus Endorsement')}),
    valuationEndorsement          : new UntypedFormGroup({value : new UntypedFormControl(false), lineName : new UntypedFormControl('Valuation Endorsement')}),
  }

  tradingLimits = new UntypedFormGroup({
    broadWaterborne : new UntypedFormControl(),
    marineHulls: new UntypedFormControl(),
    logsInWaterAndInTransit: new UntypedFormControl()
  })

  tradingLimitOptions = ['Trading Limit No. 1', 'Trading Limit No. 2', 'Trading Limit No. 2A', 'Trading Limit No. 3']

  static ratingEntryControls = ['limitsofLiability', 'flatOrFullyEarnedRates', 'deductibiltyOrRetentions', 'flatDeductibleRate', 'premiumAdjustmentRatesStored',
                                'premiumAdjustmentRatesOperational', 'depositPremiumRate', 'flatDeductibleRef']

  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef, public pUtil: PortalUtilService) {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('suggestedRating', this.suggestedRating);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('flatDeductible', this.flatDeductible);
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('tradingLimits', this.tradingLimits);
  }

  ngOnInit(): void {
    this.setupControls()
    this.setupFlatDeductible();
  }

  setupControls(){
    for(const key in this.suggestedRatingEntries){
      this.disable(this.suggestedRatingEntries[key])
      this.suggestedRating.addControl(key, this.suggestedRatingEntries[key])
    }

    const suggestedEntries = this.preForm.proposalForm?.suggestedRating
    for(const key in suggestedEntries){
      const suggestedEntryControl = this.suggestedRatingEntries?.[key]
      if(suggestedEntryControl){
        this.patchVal(suggestedEntryControl, suggestedEntries?.[key])
      }
    }

    const tradingLimits = this.preForm.proposalForm?.tradingLimits;
    (this.parent.form.get('proposalForm') as UntypedFormGroup)?.get('tradingLimits')?.patchValue({
      broadWaterborne: tradingLimits?.broadWaterborne,
      marineHulls: tradingLimits?.marineHulls,
      logsInWaterAndInTransit: tradingLimits?.logsInWaterAndInTransit,
    })
    this.suggestedRating.addControl('lossOfUseRR', this.lossOfUseRR)
    this.patchVal(this.lossOfUseRR, suggestedEntries?.lossOfUseRR)
  }

  patchVal(control: AbstractControl, data: {flatDeductibleRate?: PTAmount, limitsofLiability?: PTAmount, flatOrFullyEarnedRates?:PTAmount, deductibiltyOrRetentions?: PTAmount | string, value: boolean
                                              premiumAdjustmentRatesStored: string, premiumAdjustmentRatesOperational: string, depositPremiumRate?:PTAmount
                                              flatDeductibleRef : string, deductibiltyOrRetentionsOpen: string}){
    if(data){
      control?.patchValue({
        limitsofLiability: data.limitsofLiability,
        flatOrFullyEarnedRates: data.flatOrFullyEarnedRates,
        deductibiltyOrRetentions: data.deductibiltyOrRetentions,
        deductibiltyOrRetentionsOpen: data.deductibiltyOrRetentionsOpen || control?.value?.deductibiltyOrRetentionsOpen,
        value: data.value,
        flatDeductibleRate: data.flatDeductibleRate,
        premiumAdjustmentRatesStored: data.premiumAdjustmentRatesStored,
        premiumAdjustmentRatesOperational: data.premiumAdjustmentRatesOperational,
        depositPremiumRate :  data.depositPremiumRate,
        flatDeductibleRef : data.flatDeductibleRef
      })
    }

    if(control.get('value')?.value && control.enabled){
      this.enable(control)
    }
  }

  disable(control: AbstractControl){
    for( const c of SuggestedRatingComponent.ratingEntryControls){
      control.get(c)?.disable()
    }
  }

  enable(control: AbstractControl){
    for( const c of SuggestedRatingComponent.ratingEntryControls){
      control.get(c)?.enable()
    }
  }

  entryClick(key: string, input: HTMLInputElement){
    const control = this.suggestedRatingEntries?.[key]
    if(input.checked){
      this.enable(control)
    } else {
      this.disable(control)
    }
    control?.get('value')?.setValue(input.checked)

    this.cdr.detectChanges()
  }

  setupFlatDeductible(){
    this.onFlatDeductibleChanges()
    const flatDeductibles = this.preForm.proposalForm?.flatDeductible
    if(flatDeductibles){
      flatDeductibles.forEach((c: {flatDeductibleRate: PTAmount, flatDeductibleRef: string}) => {
        const control = this.addFlatDeductible();
        control.patchValue({
          flatDeductibleRate : c?.flatDeductibleRate,
          flatDeductibleRef : c?.flatDeductibleRef,
        })
      });
    } else {
      this.addFlatDeductible()
    }
  }

  getFlatDeductibleControls(){
    return (this.flatDeductible as UntypedFormArray).controls;
  }

  addFlatDeductible(){
    const formGroup = new UntypedFormGroup({
      flatDeductibleRate : new UntypedFormControl(),
      flatDeductibleRef : new UntypedFormControl(),
    });
    (this.flatDeductible as UntypedFormArray).push(formGroup);
    this.onFlatDeductibleChanges()
    return formGroup;
  }

  removeFlatDeductible(i: number) {
    (this.flatDeductible as UntypedFormArray).removeAt(i);
    this.onFlatDeductibleChanges()
  }

  onFlatDeductibleChanges(): void {
    this.complete();
    this.unsub = new Subject<boolean>();
    const deductibles = this.flatDeductible as UntypedFormArray;
    merge(...deductibles.controls.map((control: AbstractControl, index: number) =>
    control.valueChanges.pipe(map(value => ({ rowIndex: index, value })))))
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((changes:{value:{flatDeductibleRate: PTAmount, flatDeductibleRef: string},rowIndex:number}) => {
      const deductible = deductibles.at(changes.rowIndex);
      const val = changes.value;
      if(!val.flatDeductibleRate?.figure && val.flatDeductibleRef){
        this.flatDeductible.at(changes.rowIndex)?.get('flatDeductibleRate')?.setErrors({missingDeductibleEntries: true})
      } else if (val.flatDeductibleRate?.figure && !val.flatDeductibleRef){
        this.flatDeductible.at(changes.rowIndex)?.get('flatDeductibleRef')?.setErrors({missingDeductibleEntries: true})
      } else {
        this.flatDeductible.at(changes.rowIndex)?.get('flatDeductibleRef')?.setErrors(null)
        this.flatDeductible.at(changes.rowIndex)?.get('flatDeductibleRate')?.setErrors(null)
      }

/*       if((!val.flatDeductibleRef && !val.flatDeductibleRate?.figure) || (val.flatDeductibleRef && val.flatDeductibleRate?.figure)){
        this.flatDeductible.at(changes.rowIndex).setErrors(null)
      } else {
        this.flatDeductible.at(changes.rowIndex).setErrors({missingDeductibleEntries: true})
      } */
    });
  }

  dontOrder(left: any, right: any): number {
    return 0;
  }

  complete() {
    this.unsub.next(true);
    this.unsub.complete();
  }

  ngOnDestroy() {
    this.complete();
  }

}
