import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-neutral-toggle',
  templateUrl: './neutral-toggle.component.html',
  styleUrls: ['./neutral-toggle.component.scss']
})
export class NeutralToggleComponent implements OnInit {

  @Input() toggleInformation : { parentGroup: UntypedFormGroup, text: string, childControlName: string, listOfEnabledToggles : string[], onlyBool: boolean, data: any, clonedParentGroup: UntypedFormGroup  }
  toggleControl : boolean | undefined;

  childCloned : any;

  constructor(public pUtil: PortalUtilService) {
  }

  ngOnInit(): void {
    this.setupToggle()
  }

  toggle(input: boolean){
    this.updateChildClone()
    if(input){
      this.toggleInformation?.onlyBool ?
      this.toggleInformation.parentGroup.setControl(this.toggleInformation.childControlName, new UntypedFormControl(true)) :
      this.toggleInformation.parentGroup.setControl(this.toggleInformation.childControlName, this.childCloned)
    } else {
      this.toggleInformation.parentGroup.setControl(this.toggleInformation.childControlName, new UntypedFormControl(false))
    }
    this.toggleControl = input
    this.controlStatusUpdate({controlName: this.toggleInformation.childControlName, controlState: this.toggleControl})
  }

  controlStatusUpdate(control: {controlName: string, controlState: boolean | undefined}){
    if(!this.toggleInformation.listOfEnabledToggles.includes(control?.controlName) && control?.controlState){
      this.toggleInformation.listOfEnabledToggles.push(control.controlName)
    } else if (this.toggleInformation.listOfEnabledToggles.includes(control?.controlName) && !control?.controlState){
      this.toggleInformation.listOfEnabledToggles.splice(this.toggleInformation.listOfEnabledToggles.indexOf(control.controlName), 1)
    }
  }

  setupToggle(){
    if(this.toggleInformation?.data){
      this.toggle(true)
    } else if (this.toggleInformation?.data === false){
      this.toggle(false)
    }

    if(!this.toggleInformation?.onlyBool){
      this.childCloned = this.toggleInformation.clonedParentGroup?.get(this.toggleInformation.childControlName)
    }
  }

  updateChildClone(){
    if(!this.toggleInformation?.onlyBool){
      const obj = this.toggleInformation.parentGroup.get(this.toggleInformation.childControlName)?.value
      if( obj && !this.pUtil.isEmpty(obj, true)){
        this.childCloned = this.toggleInformation.parentGroup.get(this.toggleInformation.childControlName)
      }
    }
  }

}
