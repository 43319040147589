import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ControlContainer, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../models/product.type';

@Component({
  selector: 'app-business-overview',
  templateUrl: './business-overview.component.html',
  styleUrls: ['./business-overview.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class BusinessOverviewComponent implements OnInit, OnDestroy {
  @Input() preForm: any;
  start = new Date(); end: Date;
  public form: UntypedFormGroup;
  public overviewOfBusiness = new UntypedFormGroup({
    websiteAddress: new UntypedFormControl(''),
    description: new UntypedFormControl('' , [Validators.required]),
    interestType: new UntypedFormControl(''),
    occupancyType: new UntypedFormControl(''),
    casualtyIndustrySector: new UntypedFormControl(''),
    businessInterruptionValue: new UntypedFormControl(''),
    fineArtType: new UntypedFormControl(''),
    otherDetail: new UntypedFormControl(''),
  });
  public period = new UntypedFormGroup({
    startDate: new UntypedFormControl(''),
    endDate: new UntypedFormControl(''),
  });
  formType: string; prod = new Product();
  unsub: Subject<boolean> = new Subject<boolean>();

  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute, public pUtil: PortalUtilService) {
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.getProduct();
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('period', this.period);
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('overviewOfBusiness', this.overviewOfBusiness);
    this.onValueChanges();
    this.setUpBusinessOverview(this.overviewOfBusiness);
    this.setUpContractDate(this.period);
  }

  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      this.formType = this.pUtil.addSpace(params.get('prod'));
      if(!this.prod.typeInArrToCheck(this.formType, this.prod.supportedProducts.UCasualty.typeArr)){
        this.period.get('endDate')?.setValidators(Validators.required);
        this.period.get('startDate')?.setValidators(Validators.required);
      }
    });
  }

  onValueChanges(): void {
    this.period.get('startDate')?.valueChanges
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((changes: string) => {
      this.end = this.pUtil.returnISOToDate(changes);
      this.end.setFullYear(this.end.getFullYear() + 1);
      this.cdr.detectChanges();
    });
  }

  setUpBusinessOverview(form: UntypedFormGroup) {
    const business = this.preForm.proposalForm?.overviewOfBusiness;
    if (business) {
      form.patchValue({
        websiteAddress : business?.websiteAddress,
        fromTerritory: business?.fromTerritory,
        description: business?.description,
        interestType: business?.interestType,
        occupancyType: business?.occupancyType,
        casualtyIndustrySector: business?.casualtyIndustrySector,
        fineArtType : business?.fineArtType,
        businessInterruptionValue: business?.businessInterruptionValue,
        otherDetail: business?.otherDetail,
      });
    }
  }

  setUpContractDate(form: UntypedFormGroup) {
    const period = this.preForm.proposalForm?.period;
    if (period) {
      form.patchValue({
        endDate : period?.endDate,
        startDate: period?.startDate,
      }, {emitEvent: false, onlySelf: true});
   }
  }

  complete() {
    this.unsub.next(true);
    this.unsub.complete();
  }

  ngOnDestroy() {
    this.complete();
  }
}
