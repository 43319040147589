<h3 class="boldListHeader pb-1">Please select Endorsement Option :</h3>
<div class="coverageList mt-5 ps-4 d-flex">
    <div class="list">
        <div *ngFor="let endorsement of endorsementOptionArr; let i=index">
            <div class="checkBox pb-4">
                <label class="container pointer">
                    <input (click)='selectEndorsement(endorsement.key)' class="splitCheckBox" type="checkbox" [id]='endorsement.key'> 
                    <span class="checkmark"></span>
                    <label class="pointer" [for]="endorsement.key">{{endorsement.display}}</label>
                </label> 
            </div>
        </div>
    </div>
</div>