import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { PTCheckboxInput } from 'projects/portal/src/public-api';
import { Interest } from '../../models/contract.model';

@Component({
  selector: 'app-risk-information',
  templateUrl: './risk-information.component.html',
  styleUrls: ['./risk-information.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class RiskInformationComponent implements OnInit, AfterViewInit {
  @Input() preForm: any;
  @Input() sectionHeader: string;
  @Input() set formType(value: string) {
    if(value) {
      this.setComponents(value);
    }
   }

  riskInformation : UntypedFormGroup = new UntypedFormGroup({
    fragileList : new UntypedFormArray([]),
    nonFragileList : new UntypedFormArray([]),
    appraisalAvailable : new UntypedFormControl(),
    riskInformationTotal : new UntypedFormControl(),
  });

  riskInfoUI : any = {
    fragileList : [
      {key:"Sculpture Fragile", display:"Sculpture Fragile", control: new UntypedFormControl('')},
      {key:"Breakable (i.e Glass / Ceramics)", display:"Breakable (i.e Glass / Ceramics)", control: new UntypedFormControl('')},
    ],
    nonFragileList : [
      {key:"Sculpture Non-Fragile", display:"Sculpture Non-Fragile", control: new UntypedFormControl('')},
      {key:"Sculpture Outdoors", display:"Sculpture Outdoors", control: new UntypedFormControl('')},
      {key:"Tapestries", display:"Tapestries", control: new UntypedFormControl('')},
      {key:"Paintings", display:"Paintings", control: new UntypedFormControl('')},
      {key:"Drawings (inc prints / photos)", display:"Drawings (inc prints / photos)", control: new UntypedFormControl('')},
      {key:"Furniture", display:"Furniture", control: new UntypedFormControl('')},
      {key:"Jewellery", display:"Jewellery", control: new UntypedFormControl('')},
      {key:"Other", display:"Other", control: new UntypedFormControl('')},
    ]
  }

  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('riskInformation', this.riskInformation);
  }

  ngAfterViewInit(){
    this.setupFrags();
    this.listenToValueChanges();
  }

  setComponents(v : string){
    const fineArtType = new Interest().fineArtTypes;
    if(v === fineArtType.dealer){
      this.riskInformation.get('appraisalAvailable')?.disable();
    } else if(v === fineArtType.collector){
      this.riskInformation.get('appraisalAvailable')?.enable();
    }
  }

  setupFrags(){
    const frags = this.preForm?.proposalForm?.riskInformation;
    this.riskInformation.get('riskInformationTotal')?.setErrors({percentageNotHundred : true});
    this.riskInformation.get('appraisalAvailable')?.setValue(frags?.appraisalAvailable ? true : false);
    if(frags){
      for(const arr in frags){
        if(this.riskInfoUI?.[arr]){
          frags[arr]?.map((frag: PTCheckboxInput) => {
            this.handleControlSetup(frag, arr);
          })
        }
      }
    }
  }
  
  handleControlSetup(frag: PTCheckboxInput, arrName: string){
    (this.riskInfoUI?.[arrName]).filter((item: PTCheckboxInput) => {
      if(item.key === frag.key && frag.value){
        (document.getElementById(frag.key) as HTMLInputElement).checked = true;
        item.control.setValue(frag.value);
        this.selectFrag(item, arrName);
      }
    })
  }

  selectFrag(frag: PTCheckboxInput, arrName: string){
    const c = new UntypedFormGroup ({
      key : new UntypedFormControl(frag.key),
      value : frag.control
    });
    this.checkIfFragExists(frag, arrName) ? this.deleteFrag(frag, arrName) : (this.riskInformation.get(arrName) as UntypedFormArray).push(c);
  }

  deleteFrag(frag: PTCheckboxInput, arrName: string){
    (this.riskInformation.get(arrName)?.value)?.filter((f: PTCheckboxInput,index: number) => {
      if(f.key === frag.key){
        (this.riskInformation.get(arrName) as UntypedFormArray).removeAt(index);
      }
    })
  }

  checkIfFragExists(frag: PTCheckboxInput , arrName: string) : boolean{
    const arr = (this.riskInformation.get(arrName)?.value)?.filter((f: {key: string}) => f.key === frag.key);
    return arr.length > 0;
  }

  checkIfChecked(fragKey : string){
    return (document.getElementById(fragKey) as HTMLInputElement).checked;
  }

  listenToValueChanges(){
    this.riskInformation.get('fragileList')?.valueChanges?.subscribe((resp) => {
      this.triggerTotalCheck();
    });
    this.riskInformation.get('nonFragileList')?.valueChanges?.subscribe((resp) => {
      this.triggerTotalCheck();
    });
  }

  triggerTotalCheck(){
    const combined = (this.riskInformation.get('fragileList') as UntypedFormArray).controls.concat((this.riskInformation.get('nonFragileList') as UntypedFormArray).controls);
    let float = 0;
    combined.forEach((control) => {
      if(control.value.value){
        float += control.value.value;
      }
    })
    this.riskInformation.get('riskInformationTotal')?.setValue(Number.parseFloat(float.toString()).toFixed(2));
    float === 100 ? this.riskInformation.setErrors(null) : this.riskInformation.get('riskInformationTotal')?.setErrors({percentageNotHundred : true});
  }

}
