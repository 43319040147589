import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Interest } from '../../models/contract.model';
import { SVShowFair } from '../../models/typing';

@Component({
  selector: 'app-transit-information',
  templateUrl: './transit-information.component.html',
  styleUrls: ['./transit-information.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class TransitInformationComponent implements OnInit {
  @Input() preForm: any;
  @Input() sectionHeader: string;
  @Input() set formType(value: string) {
    if(value) {
      this.setComponents(value);
    }
   }
  transitInformation = new UntypedFormGroup({
    transitLimitsRequired : new UntypedFormControl(),
    showFairsParticipatedIn : new UntypedFormArray([]),
    loanInformation : new UntypedFormGroup({
      loan : new UntypedFormControl(),
      whoIsResponsible : new UntypedFormControl(),
    }),
  });

  constructor( private parent: FormGroupDirective ) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('transitInformation', this.transitInformation);
    this.setupShowsAndFairs();
  }

  setComponents(v : string){
    const fineArtType = new Interest().fineArtTypes;
    if(v === fineArtType.dealer){
      this.transitInformation.get('loanInformation')?.disable();
      this.transitInformation.get('showFairsParticipatedIn')?.enable();
    } else if(v === fineArtType.collector){
      this.transitInformation.get('loanInformation')?.enable();
      this.transitInformation.get('showFairsParticipatedIn')?.disable();
    }
  }

  getShowFairControls(){
    return (this.transitInformation.get('showFairsParticipatedIn') as UntypedFormArray).controls;
  }

  addShowFairControl(){
    const formGroup = new UntypedFormGroup({
      nameOfShowFair : new UntypedFormControl(),
      showFairLimit : new UntypedFormControl(),
    });
    (this.transitInformation.get('showFairsParticipatedIn') as UntypedFormArray).push(formGroup);
    return formGroup;
  }

  removeShowFairControl(i: number) {
    (this.transitInformation.get('showFairsParticipatedIn') as UntypedFormArray).removeAt(i);
  }

  setupShowsAndFairs(){
    const transitInformation = this.preForm?.proposalForm?.transitInformation;
    if(transitInformation){
      this.transitInformation.patchValue({
        transitLimitsRequired: transitInformation?.transitLimitsRequired,
        loanInformation: ({
          loan : transitInformation?.loanInformation?.loan ? true : false,
          whoIsResponsible: transitInformation?.loanInformation?.whoIsResponsible,
        }),
      });
    }
    if(transitInformation?.showFairsParticipatedIn){
      transitInformation.showFairsParticipatedIn.forEach((c: SVShowFair) => {
        const control = this.addShowFairControl();
        control.patchValue({
          nameOfShowFair : c?.nameOfShowFair,
          showFairLimit : c?.showFairLimit,
        })
      });
    } else {
      for(let i = 0; i < 2; i++){this.addShowFairControl();}
    }
  }

  setInsuranceResponsible(enabled: boolean){
    enabled ? this.transitInformation.get('loanInformation')?.get('whoIsResponsible')?.enable() :
    this.transitInformation.get('loanInformation')?.get('whoIsResponsible')?.disable();
  }
}
