<h1 class="header">3. Exposure</h1>
<div formGroupName="proposalForm" class="exposure colMargin">
    <div formGroupName="exposure" class="col-12 p-0">
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0 mt-4">
            <mat-error *ngIf="exposure.get('limitRequired')?.errors?.overLimit">
                Maximum limit exceeded
            </mat-error>
            <app-amount [required]='true' [name]="'Limit Required'" 
                formControlName="limitRequired"></app-amount>
                <p class="text maxCap">*Maximum limit available of USD 250M</p>
        </div>
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0 mt-4">
            <app-amount [required]='true' [name]="'Total Insured Value'" 
                formControlName="totalInsuredValue"></app-amount>
                <p class="text maxCap">*Please upload a complete schedule as additional information</p>
        </div>

        <app-business-interruption-value [businessInterruptionValue]=
        "form.get('proposalForm')?.get('overviewOfBusiness')?.get('businessInterruptionValue')"></app-business-interruption-value>
        
        <div class="col ps-0 pe-4 mt-5 text">
            <span class="ps-0 smallHeader">Please advise if there are currently any exposures located within one or more of the following “Restricted Zips”:</span>

            <ul class="pt-3">
                <li class='pb-2' *ngFor="let item of zip | keyvalue : returnEmpty">
                    <span class="zipHeader">
                        {{item.key}}
                    </span>
                    {{item.value}}
                </li>
            </ul>

            <div class="col-lg-4 col-md-5 col-xs-6 col-xxs-7 ps-0 pe-4 mt-2 pt-2 colMargin buttonWrapper tableButtonPadding restrictedZip"
                        [ngClass]="{'ng-invalid' : exposure.get('restrictedZip')?.errors}" domControl>
                <mat-error class="typeValueError" *ngIf="exposure.get('restrictedZip')?.errors">
                    *
                </mat-error>
                <div class="tableButtons btn-group text">
                  <button (click)= 'setZip(true)'
                        [ngClass]="{'active' : exposure.get('restrictedZip')?.value === true}">YES</button>
                  <button (click)= 'setZip(false)'
                        [ngClass]="{'active' : exposure.get('restrictedZip')?.value === false}">NO</button>
                </div>
            </div>
        </div>
    </div>
</div>