<app-total-risk-detail class="riskTotal"></app-total-risk-detail>
<table>
  <thead class="pointer">
    <tr>
      <th (click)="setSort('contractName')">
        <div class="contractName hl">
          <div class="sortableCol" [ngClass]="{'sortedOn': sortKey === 'contractName', 'sortedAsc': sortAsc}">&#8595;</div>
          Contract Name
        </div>
      </th>
      <th (click)="setSort('type')">
        <div class="sortableCol" [ngClass]="{'sortedOn': sortKey === 'type', 'sortedAsc': sortAsc}">&#8595;</div>
        Product Type
      </th>
      <th (click)="setSort('createdAt')">
        <div class="sortableCol" [ngClass]="{'sortedOn': sortKey === 'createdAt', 'sortedAsc': sortAsc}">&#8595;</div>
        Creation Date
      </th>
      <th (click)="setSort('updatedAt')">
        <div class="sortableCol" [ngClass]="{'sortedOn': sortKey === 'updatedAt', 'sortedAsc': sortAsc}">&#8595;</div>
        Last Update
      </th>
      <th (click)="setSort('status')">
        <div class="sortableCol" [ngClass]="{'sortedOn': sortKey === 'status', 'sortedAsc': sortAsc}">&#8595;</div>
        Status
      </th>
      <th colspan="2"></th>
      
    </tr>
  </thead>
  <!-- {{contracts.numberblank}}-->
  <tbody style="overflow-anchor: none;">
    <ng-container  *ngFor="let risk of contracts.filteredContracts; let i = index">
      <ng-container *ngIf="i < (contracts.filteredContracts.length - contracts.numberblank)">
        <tr>
          <ng-container  *ngIf="risk">
            <!-- ; else unrenderedrow" -->
            <td (click)="risk && updateContract(risk)">
              <div class="contractName hl">
                {{risk?.contractName}}
              </div>
            </td>
            <td (click)="risk && updateContract(risk)">
              <div class="hl">
                {{risk?.product | cleanProduct}}
              </div>
            </td>
            <td (click)="risk && updateContract(risk)">
              <div class="hl">
                {{risk?.createdAt | portalDatePipe:''}}
              </div>
            </td>
            <td (click)="risk && updateContract(risk)">
              <div class="hl">
                {{risk?.updatedAt | portalDatePipe:'update':true}}
              </div>
            </td>
            <td (click)="risk && updateContract(risk)">
              <div class="relatively">
                <div [class.unread]="risk?.unRead"></div>
              </div> 
              <pt-icon-status-switcher [status]="risk?.status?.toLowerCase()" [pipeRequired]='true'></pt-icon-status-switcher>
            </td>
            <td (click)="risk && updateContract(risk)">
              <span *ngIf="risk?.status?.toLowerCase()?.includes(risks.quoted.value)">
                <span *ngIf="!util.expired(risk); else expired">
                  <button class="matTableButton">ACTION NEEDED</button>
                </span>
                <ng-template #expired>
                  <button class="accepted">EXPIRED</button>
                </ng-template>
                </span>
              <span *ngIf="risk?.status?.toLowerCase()?.includes(risks.bound.value)">
                <button class="accepted">ACCEPTED</button>
              </span>
            </td>
            <td [id]="'contract' + i" #contract>
              <div class="dropdown">
                <button class="dotButton" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span id= "dotIcon"></span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <span *ngIf='!risk?.status' class="pointer"><a *ngFor="let call of dropdownArray" (click)='runServerProcess(risk,call)' class="dropdown-item">{{call.label}}</a></span>
                  <span class="pointer">
                    <pt-clone-as-draft (getContracts)="getContracts()" [contract]="risk"></pt-clone-as-draft>
                  </span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-template #unrenderedrow>
            <td>
              <div>{{i}}</div>
            </td>
          </ng-template>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
  <div [ngStyle]="{'height':contracts.numberblank*68+'px'}"></div>
</table>