<div class="row">
    <div class="col-12 mb-3 mt-5">
        <div class="interruption">
            <p class="mb-4">Is coverage required for Business Interruption Add-On?</p>
            <div class="d-flex">
                <span>No</span>
                <label class="switch me-3 ms-3">
                    <input (click)="toggleInterruption()" type="checkbox" [attr.checked]="interruption ? true : null">
                    <span class="slider"></span>
                </label>
                <span>Yes</span>
            </div>
        </div>
        <div *ngIf="interruption" class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0 mt-4">
            <app-amount [required]="true" [name]="'Business Interruption Value'"
                    [formControl]="businessInterruptionValue"></app-amount>
                    <p class="text maxCap">*Business Interruption Values need to be included within the TIV as well</p>
        </div>
    </div>
</div>