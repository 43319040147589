<div class="platformHead">
    <div class="offset-xxl-2 offset-xl-1 col-xxl-8 col-xl-10 col-lg-12 platformRisk">
        <div id="documentLogo">
        </div>
        <div class="quote">
            {{secName}} Contract
        </div>
        <label class="ms-3">
            <ng-container *ngIf="additionalInfoText">{{additionalInfoText}}</ng-container>
        </label>
        <div *ngIf="requiredDownload && preForm" class="ms-auto">
            <app-download-button [preForm]="preForm"></app-download-button>
        </div>
    </div>
</div>