import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PortalDataService } from '../../../../../services/portal-data.service';
import { interval, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Contract } from 'projects/portal/src/lib/model/models';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'pt-admin-display-risk-base',
  templateUrl: './admin-display-risk-base.component.html',
  styleUrls: ['./admin-display-risk-base.component.css']
})
export class AdminDisplayRiskBaseComponent implements OnInit, OnDestroy, OnChanges {
  AR = Array;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  static allColumnsGlobal = ['contractName', 'UID','product', 'createdAt', 'updatedAt', 'status', 'userEmail', 'button'];
  displayedColumns: string[] = AdminDisplayRiskBaseComponent.allColumnsGlobal
  @Input() contracts: Contract[]; currentContract: Contract; unlocking: number | null = null;
  dataSource = new MatTableDataSource<Contract>();
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService) { }

  ngOnInit(){
    this.loadQuotes();
  }

  ngOnChanges(){
    if(!this.displayedColumns.includes('contractName')){
      this.displayedColumns = AdminDisplayRiskBaseComponent.allColumnsGlobal
    }
    this.setupDataSource(this.contracts);
  }

  investigateRisk(contract: Contract){
    this.portalDataService.managerCheckQuotedRisk(contract.id)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      this.currentContract = contract;
      this.setupDataSource(response)
      this.displayedColumns = ['listOfQuotes','unlockRisk'];
    })
  }

  unlock(quoteID: any, unlockID: number){
    this.unlocking = unlockID;
    this.portalDataService.managerUnlockQuote(this.currentContract.id, quoteID?.id ? quoteID.id : quoteID)
    .pipe(
      takeUntil(this.unsub),
      finalize(() => this.unlocking = null)
    )
    .subscribe((response: any) => {
      if (response.error) {
        alert(response.errorDescription);
        this.unlocking = null;
      } else {
        alert('Quote Unlocked');
        if (Array.isArray(this.currentContract.quotedIC)) {
          this.currentContract.quotedIC.push(quoteID);
        } else if (this.currentContract.quotedIC) {
          this.currentContract.quotedIC = [this.currentContract.quotedIC, quoteID];
        } else {
          this.currentContract.quotedIC = quoteID;
        }

        this.unlocking = null;
      }
    });
  }

  setupDataSource(contracts: Contract[]){
    this.dataSource.data = contracts;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data: Contract, sortHeaderId: string) => data[sortHeaderId]?.toLocaleLowerCase();
  }

  loadQuotes() {
    const time = interval(1 * 60 * 1000);
    time.pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      if(this.displayedColumns.includes('listOfQuotes')){
        this.investigateRisk(this.currentContract);
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
