import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Enums } from '../../../shared-resources/models/contract.model';
import { ProductComponent } from '../update-contract.component';

@Component({
  selector: 'app-equipment-binder-form',
  templateUrl: './equipment-binder-form.component.html',
  styleUrls: ['./equipment-binder-form.component.scss']
})
export class EquipmentBinderFormComponent implements AfterViewInit, ProductComponent {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;

  cargoType = new Enums().cargoType.EquipmentBinder

  constructor(public parent: FormGroupDirective, private cdr: ChangeDetectorRef) {
  }
  
  ngAfterViewInit(){
    this.cdr.detectChanges();
  }

}
