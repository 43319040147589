import { Component, OnInit, forwardRef, OnDestroy, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup,
  AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validators, UntypedFormArray } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PortalDataService, PortalUtilService } from 'portal';
import { InformationDisplayPopupComponent } from 'projects/mcgill/src/app/contracts/shared-resources/popups/information-display-popup/information-display-popup.component';

@Component({
  selector: 'app-user-product-cva',
  templateUrl: './user-product-cva.component.html',
  styleUrls: ['./user-product-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserProductCvaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserProductCvaComponent),
      multi: true
    }
  ]
})

export class UserProductCvaComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() email : string;
  @Input() emails : string[];
  public form : UntypedFormGroup = new UntypedFormGroup({
    products : new UntypedFormArray([]),
  });
  availableProducts = [
    {key:'Underscore Terrorism'}, {key:'Underscore Cargo Stock Throughput'}, {key:'Underscore Casualty'}, 
    {key:'Underscore Fine Art'}, {key:'Underscore Equipment Binder'}, {key:'Underscore Bourbon'},
    {key:'Underscore Construction'}
  ];
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private portalDataService: PortalDataService, private cdr: ChangeDetectorRef, private pUtil: PortalUtilService, private dialog: MatDialog) { }

  public onTouched: () => void = () => {};

  ngOnInit(): void {
    this.availableProducts.forEach(product => {
      this.addProduct(product.key);
    });
  }

  writeValue(val: any): void {
    if(val){
      this.setupProducts(val.products);
    }
  }

  setupProducts(productList: any[]){
    setTimeout(() => {
      productList?.forEach((product: any) => {
        if(typeof product === 'object'){
          (document?.getElementById(product?.name) as HTMLInputElement)?.click();
          this.setProductFields(product);
        } else {
          (document?.getElementById(product) as HTMLInputElement)?.click();
        }
      });
    });
  }

  cleanProducts(){
    let products = this.form?.get('products')?.value?.reduce((arr: any, el: any) => {
      if(el?.commission || el?.commission === 0){
        arr.push(el);
      }
      return arr;
    }, [])
    products = this.pUtil.extractJSON(products)
    return products
  }

  setUserProducts(){
    this.portalDataService.setUserProducts(this.email, this.cleanProducts())
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      response.error ? this.pUtil.errorPopup({status:'While trying to update user products', message:response?.errorDescription}) : 
      this.dialog.open(InformationDisplayPopupComponent, {
        data: {header:'Success: User Product updated to', information: response.products},
        panelClass: 'custom-dialog-container',
        width: '500px',
        autoFocus: false
      });;
    })
  }

  setProductForMultipleUsers(){
    const payload = {products: this.cleanProducts(), emails: this.emails}
    this.portalDataService.setUserProductsForMultipleUsers(payload)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      response.error ? this.pUtil.errorPopup({status:'While trying to update user products', message:response?.errorDescription}) : 
      this.dialog.open(InformationDisplayPopupComponent, {
        data: {header:'Success: Users Product updated to', information: response},
        panelClass: 'custom-dialog-container',
        width: '500px',
        autoFocus: false
      });;
    })
  }

  selectProduct(prodName: string, input: HTMLInputElement){
    const products = this.getProducts();
    for(let product of products){
      if(product?.get('name')?.value === prodName){
        if(input.checked){
          product.get('commission')?.enable()
          product.get('brokerage')?.enable()
        } else {
          product.get('commission')?.disable()
          product.get('brokerage')?.disable()
        }      
      }
    }
  }

  setProductFields(field: {name: string, commission: number, brokerage: number}){
    const products = this.getProducts();
    for(let product of products){
      if(product.get('name')?.value === field.name){
        product.get('commission')?.setValue(field?.commission);
        product.get('brokerage')?.setValue(field?.brokerage);
      }
    }
  }

  getProducts(){
    return (this.form.get('products') as UntypedFormArray).controls;
  }

  addProduct(prodName: string){
    const formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(prodName, [Validators.required]),
      commission: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),
      brokerage: new UntypedFormControl({value: null, disabled: true})
    });
    (this.form.get('products') as UntypedFormArray).push(formGroup);
    return formGroup;
  }

  removeProduct(i: number) {
    (this.form.get('products') as UntypedFormArray).removeAt(i);
  }

  registerOnChange(fn: any): void {
    (this.form.get('products') as UntypedFormArray).valueChanges.pipe(
      takeUntil(this.unsub)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return (this.form.get('products') as UntypedFormArray).valid ? null : { invalidForm: {valid: false, message: ''}};
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
