<div formGroupName="proposalForm">
    <div class="policyType interestType" formGroupName="policyType">
        <h1 class="header">3. Policy Type</h1>

        <div class="ps-3" [ngClass]="{'ng-invalid' : policyType?.get('type')?.errors}" domControl>
            <h3 class="listHeader pt-3">Type:</h3>
            <div class="businessOverviewWrapper col-lg-7 col-md-8 col-sm-9 col-12">
                <div class="colMargin">
                    <mat-error class="typeError" *ngIf="policyType?.get('type')?.errors">
                        *
                    </mat-error>
                    <div class="row m-0 row-cols-3 pt-4">
                        <div class="col interest text" *ngFor="let item of types"
                            (click)="chooseInterest(item.value)" [ngClass]="{'active' : policyType?.get('type')?.value === item.value}">
                                <div id={{item.key}}>
                                </div>
                                <label class="interestLabel nowrap">{{item.display}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-9 col-lg-10 col-12">
            <div class="eqUsualOperations d-flex pt-5 colMargin">
                <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(policyType,'usualAreaOfOperations')}">
                    <input class="controlFull" type="text" formControlName="usualAreaOfOperations" domControl>
                    <label class="controlLabel labelBackground">
                      Usual area of operations*
                    </label>
                </div>
    
                <div class="controlInput figureInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(policyType,'typicalActiveMonths')}">
                    <input pt-appCurrency [inputControl]="policyType.get('typicalActiveMonths')" class="amount" type="text" pt-ngInvalid>
                    <label class="controlLabel">
                        Usual number of months per year operations active*
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>