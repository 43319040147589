<ng-container *ngIf = 'preForm'>
    <section pt-appDisableAll [disableAll] = '!platformStatus' class="formSection pb-5" id="section1">
        <app-insured-address [preForm]='preForm' [productType]='productType'></app-insured-address>
        <app-surplus-risk [productType]='productType' [preForm]='preForm'></app-surplus-risk>
        <app-business-overview [preForm]='preForm'></app-business-overview>
        <span *ngIf='occupancyType && otherDetailCasualty'><app-occupancy-type-detail [otherDetail]='otherDetailCasualty' 
                [occupancyType]='occupancyType'></app-occupancy-type-detail></span>
        <app-exposure [preForm]='preForm'></app-exposure>
        <div class="col-12 mt-5 ps-0 text colMargin" name="basisOfValuation">
            <p class="mb-4">Do the values you have provided include stock/inventory?
                <span class="smallText">(If you do not declare the stock / inventory values, stock will not be covered)</span>
            </p>
            <div class="d-flex">
                <span>No</span>
                <label class="switch me-3 ms-3">
                    <input (click)="toggletypeOfValue()" type="checkbox" [attr.checked]="typeOfValue ? true : null">
                    <span class="slider"></span>
                </label>
                <span>Yes</span>
            </div>
        </div>
        <div [ngClass]="{'hide' : typeOfValue === false}" class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 ps-0 pe-4 pt-2 buttonWrapper tableButtonPadding colMargin">
            <app-basis-of-valuation [preForm]='preForm' basisOfValuationArray='arr'></app-basis-of-valuation>
        </div>
        <app-loss-experience-array [secName]='"4. LOSS EXPERIENCE"' [preForm]='preForm' [productType]='productType'></app-loss-experience-array>
        <app-additional-docs [docID]='docID' [secName]='"5. SCHEDULE OF VALUES / ADDITIONAL INFORMATION"' [preForm]='preForm'></app-additional-docs>
        <app-disclaimer [platformStatus]='platformStatus'></app-disclaimer>
    </section>
</ng-container>