import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-required-motor-truck-cargo',
  templateUrl: './coverage-required-motor-truck-cargo.component.html',
  styleUrls: ['./coverage-required-motor-truck-cargo.component.scss']
})
export class CoverageRequiredMotorTruckCargoComponent {
  @Input() group: UntypedFormGroup

  constructor(public pUtil: PortalUtilService) { }

}
