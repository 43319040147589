import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-picker',
  templateUrl: './checkbox-picker.component.html',
  styleUrls: ['./checkbox-picker.component.scss']
})
export class CheckboxPickerComponent implements OnInit {
  @Input() data: string[]
  @Input() formArr: UntypedFormArray
  @Input() formArrList: FormArrListData[]

  constructor() { }

  ngOnInit(): void {
    if (this.data)
      this.data.forEach((item) => {
        this.addVal(item)
      })
  }

  onInputClick(item: string, input: HTMLInputElement) {
    input.checked ? this.addVal(item) : this.deleteItem(item)
  }

  addVal(item: string) {
    const control = new UntypedFormControl(item)
    if (!this.valExists(item))
      this.formArr.push(control)
  }

  deleteItem(item: string) {
    if (this.valExists(item))
      this.formArr.removeAt((this.formArr.value as string[]).indexOf(item))
  }

  valExists(item: string) {
    return (this.formArr.value as string[]).includes(item)
  }

}

export interface FormArrListData {
  key: string
  display: string
}