<div class="interestType colMargin ps-3">
    <h3 class="listHeader pt-3">Interest Type:</h3>
    <div class="col-12 p-0 businessOverviewWrapper" [ngClass]="{'ng-invalid' : interestType?.errors}" domControl>
        <mat-error class="typeError" *ngIf="interestType.errors">
            *
        </mat-error>
        <div class="row m-0 row-cols-4 pt-4">
            <div class="col interest text" *ngFor="let item of interest"
                (click)="chooseInterest(item.value)" [ngClass]="{'active' : interestType.value === item.value}">
                    <div id={{item.key}}>
                    </div>
                    <label class="interestLabel nowrap">{{item.display}}</label>
            </div>
        </div>
    </div>
    <ng-container *ngIf="interestType.value === 'Other'">
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-10 mt-4">
            <div [ngClass]="{'requiredColor' : otherDetail.errors}" class="controlInput">
                <input [formControl]="otherDetail" class="controlFull" type="text" placeholder="Other?" domControl>
                <label class="controlLabel labelBackground">
                  Please give Details*
                </label>
            </div> 
        </div>
    </ng-container>
</div>