import { Inject, Injectable } from '@angular/core';
import { of, ReplaySubject, Observable, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PTEnvironment, PTLoginRedirectResponse, PTLoginResponse } from '../model/typings';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private initialLoginCheckDone = false;
    private userID = new ReplaySubject<string | null>(1);

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject('env') private environment : PTEnvironment,
    ) {}

    private checkWithServerIfWeAreLoggedIn() {
        if (this.initialLoginCheckDone) {
            return;
        }

        this.initialLoginCheckDone = true;

        this.http.get<PTLoginResponse>(this.environment.baseapi + "/validate", { withCredentials: true }).pipe(
            catchError(() => of(null))
        ).subscribe((rsp: PTLoginResponse | null) => {
            if(rsp === null || !rsp.userID) {
                this.userID.next(null);
            } else {
                console.log("logged in as %o", rsp.userID);
                this.userID.next(rsp.userID);
                sessionStorage.setItem('email',rsp.email)

                // refresh cookie every 15 mins (it will last max 1 hour)
                interval(10 * 60 * 1000).pipe(
                    flatMap(() => this.http.get(this.environment.baseapi + "/validate", { withCredentials: true }))
                ).subscribe();
            }
        });
    }

    public resetUserID(){
        this.userID.next(null);
    }

    public get isLoggedIn(): Observable<boolean> {
        this.checkWithServerIfWeAreLoggedIn();

        return this.userID.pipe(
            map(id => id !== null)
        );
    }

    public goToOpenIDLoginPage(): void {
        let url = this.environment.baseapi + "/login";

        if(this.environment.forceOIDCProvider) {
            url += `?with=${this.environment.forceOIDCProvider}`;
        }

        this.http.get<PTLoginRedirectResponse>(url).subscribe((rsp: PTLoginRedirectResponse) => {
            window.location.href = rsp.redirect;
        }, err => {
            console.error("error getting login redirect url", err);
        });
    }

    public finishLoginProcess(code: string, state: string) {

        const data = {
            code: code,
            state: state,
        };

        this.http.post<PTLoginResponse>(this.environment.baseapi + "/login", data).subscribe((rsp: PTLoginResponse) => {
            this.initialLoginCheckDone = true;
            console.log("we are now logged in as %o", rsp.userID);
            sessionStorage.setItem('email',rsp.email)
            this.userID.next(rsp.userID);
            this.redirectAfterLogin();
        }, err => {
            console.error("error finishing login process", err);
        });
    }

    public redirectAfterLogin() {
        this.router.navigate(["/contracts"]);
    }

    public logout() {
        window.location.href = this.environment.baseapi + "/logout";
    }

}