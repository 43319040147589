import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portalListStatusPipe'
})
export class ListStatusPipe implements PipeTransform {

  transform(data?: string, required?: boolean): string {

    if(!data){
      return 'draft'
    } 

    if(required && data?.includes('/')){
      return data.substring(0, data.indexOf('/'))
    }

    return data
  }

}