import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PortalDataService, PortalUtilService, RiskService } from 'portal';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Product } from '../../../../shared-resources/models/product.type';

@Component({
  selector: 'app-pdf-to-contract',
  templateUrl: './pdf-to-contract.component.html',
  styleUrls: ['./pdf-to-contract.component.scss']
})
export class PdfToContractComponent implements OnInit {
  @ViewChild('uploadButton') uploadButton: ElementRef<HTMLElement>;
  @Input() product: string
  @Output() navigateEmitter = new EventEmitter<any>();
  productOptions : any;
  spin: boolean
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private portalDataService: PortalDataService, private riskService: RiskService, private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.productOptions = new Product().findProductType(this.product)
  }

  openFile() {
    this.uploadButton.nativeElement.click()
  }

  fileChange(event: Event) {
    const fileList = (<HTMLInputElement>event.target).files;
    if (fileList && fileList.length > 0) {
      const file: File = fileList[0]
      this.spin = true;
      this.riskService.getJSONFromFile(file, this.product, 'WHOLE')
      .pipe(
        tap((response: any) => {
          if(!response?.error && response){
            (this.uploadButton.nativeElement as HTMLInputElement).value = ''
            this.navigate(response)
          } else {
            this.pUtil.errorPopup({status:'While trying to extract data', message:response?.errorDescription})
          }
        }),
        finalize(() => this.spin = false),
        takeUntil(this.unsub)
      ).subscribe()
    }
  }

  navigate(importData?: any){
    if(importData){
      this.riskService.pdfRiskJSON.next(importData)
    }
    this.navigateEmitter.emit()
  }

  downloadTemplate(){
    this.riskService.downloadTemplate(this.product, 'WHOLE', 'pdf')
  }

  ngOnDestroy() {
    this.unsub.next(true)
    this.unsub.complete()
  }

}
