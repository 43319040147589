import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contract, CreatePost } from '../model/models';
import { PTResponse, PTTerms, PTAttachments, PTEnvironment, SVLineage} from '../model/typings';
import { SVComments } from './messages.service';
import { map } from 'rxjs/operators';
import { PTFileToJson } from './risk.service';
import { SVCargoSchedule } from 'projects/mcgill/src/app/contracts/shared-resources/models/typing';

@Injectable({
  providedIn: 'root'
})

export class PortalDataService {
  constructor(
    private _http: HttpClient,
    @Inject('env') private environment: PTEnvironment,
  ) { }

  listadditional(docID: string) {
    return this._http.get<string[]>(`${this.environment.baseapi}/platform/listadditional/${docID}`, { withCredentials: true })
  }

  namedadditional(docID: string, name: string) {
    return this._http.get(`${this.environment.baseapi}/platform/namedadditional/${docID}/${name}`, { withCredentials: true, responseType: 'blob'});
  }

  getUserDetails(){
    return this._http.get(`${this.environment.baseapi}/user/userdata`, { withCredentials: true});
  }

  getContracts(): Observable<Contract[]> {
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.get<Contract[]>(this.environment.baseapi + '/risk', { headers, withCredentials: true });
  }

  createNewRisk(customerName: CreatePost) {
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.post(this.environment.baseapi + '/risk', customerName, { headers, withCredentials: true });
  }

  updateContract(docID: string, json: any) {
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.post<Contract>(`${this.environment.baseapi}/risk/${docID}`, json, { headers ,withCredentials: true })
  }

  getRisk(docID: string) {
    return this._http.get(`${this.environment.baseapi}/risk/${docID}`, { withCredentials: true });
  }

  getFamilyTree(originID: string): Observable<SVLineage> {
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.get<SVLineage>(`${this.environment.baseapi}/risk/getLineage/${originID}`, { withCredentials: true, headers });
  }

  dropdownCall(docID: string, endpoint: string) {
    return this._http.post(endpoint + docID, { withCredentials: true });
  }

  dropdownFurtherCalls(endpoint: string) {
    return this._http.post(endpoint, { withCredentials: true });
  }

  sendToPlatform(docID: string) {
    return this._http.post(`${this.environment.baseapi}/platform/send/${docID}`, { withCredentials: true });
  }

  acceptQuote(docID: string, body : any , quotedIC?: string) {
    if(quotedIC){
      return this._http.post(`${this.environment.baseapi}/bind/acceptQuote/${docID}/${quotedIC}`, body, { withCredentials: true });
    }
    return this._http.post(`${this.environment.baseapi}/bind/acceptQuote/${docID}`, body, { withCredentials: true });
  }

  rejectQuote(docID: string, quotedIC?: string){
    return this._http.post(`${this.environment.baseapi}/bind/declineQuote/${docID}` + (quotedIC ? "/"+quotedIC : ""), { withCredentials: true });
  }

  getPremium(docID: string) {
    return this._http.get(`${this.environment.baseapi}/premium/fetch/${docID}`, { withCredentials: true });
  }

  downloadPDF(docID: string, quoteID?: string) {
    if(quoteID){
      return this._http.get(`${this.environment.baseapi}/platform/pdf/${docID}/${quoteID}`, { withCredentials: true, responseType: 'blob'});
    }
    return this._http.get(`${this.environment.baseapi}/platform/pdf/${docID}`, { withCredentials: true, responseType: 'blob'});
  }

  additionalFiles(docID: string) {
    return this._http.get(`${this.environment.baseapi}/platform/numberadditional/${docID}`, { withCredentials: true });
  }

  downloadAdditionalFile(docID: string, index: number) {
    return this._http.get(`${this.environment.baseapi}/platform/additional/${docID}/${index}`, { withCredentials: true , responseType: 'blob'});
  }

  getMessages(docID: string): Observable<SVComments>{
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.get<{comments:SVComments}>(`${this.environment.baseapi}/risk/getMessages/${docID}`, { headers, withCredentials: true}).pipe(map(item => item.comments))
  }

  postMessage(docID:string, message:string): Observable<{commentID:string}>{
    return this._http.post<{commentID:string}>(`${this.environment.baseapi}/risk/sendMessage/${docID}`, {message:message}, { withCredentials: true})
  }

  getAttachments(docID: string, section?: string) {
    if(section){
      return this._http.get<PTResponse | PTAttachments>(`${this.environment.baseapi}/risk/attach/${docID}/${section}`, { withCredentials: true});
    }
    return this._http.get<PTResponse | PTAttachments>(`${this.environment.baseapi}/risk/attach/${docID}`, { withCredentials: true});
  }

  postAttachment(docID: string, file: FormData, section?: string) {
    if(section){
      return this._http.post(`${this.environment.baseapi}/risk/attach/${docID}/${section}`, file , { withCredentials: true});
    }
    return this._http.post(`${this.environment.baseapi}/risk/attach/${docID}`, file , { withCredentials: true});
  }

  deleteAttachment(docID: string, filename: string, section?: string) {
    if(section){
      return this._http.delete(`${this.environment.baseapi}/risk/attach/${docID}/${section}/${filename}`, { withCredentials: true});
    }
    return this._http.delete(`${this.environment.baseapi}/risk/attach/${docID}/${filename}`, { withCredentials: true});
  }

  termsAccepted() {
    return this._http.get<PTResponse | PTTerms>(this.environment.baseapi + '/user/hasAcceptedTerms' , { withCredentials: true});
  }

  acceptTerms(json: any) {
    return this._http.post(this.environment.baseapi + '/user/acceptTerms' , json , { withCredentials: true});
  }

  getDeducPremInfo(endpoint: string, headingsArr: string[]) : Observable<{header: string, text: string[]}[]> {
    return this._http.post<{header: string, text: string[]}[]>(this.environment.baseapi + endpoint , {mrcHeadings: headingsArr} ,{ withCredentials: true});
  }

  isManager() {
    return this._http.get(this.environment.baseapi + '/manage/allowed', { withCredentials: true });
  }

  manageRisks() {
    return this._http.get(this.environment.baseapi + '/manage/risks', { withCredentials: true });
  }

  managerSearchForRisk(searchString: string){
    return this._http.get(`${this.environment.baseapi}/manage/risks/${searchString}`, { withCredentials: true});
  }
  
  managerDownloadMI(year: number, month: number){
    return this._http.get(`${this.environment.baseapi}/reports/summary/xlsx/${year}/${month}`, { withCredentials: true, responseType: 'blob'});
  }

  managerDownloadMIs(year: string, month: string, duration: number): Observable<Blob> {
    return this._http.post(
      `${this.environment.baseapi}/reports/summary/term/xlsx`,
      {
        year,
        month,
        duration
      },
      {
        withCredentials: true,
        responseType: 'blob',
      }
    );
  }

  managerCheckQuotedRisk(docID: string){
    return this._http.get(`${this.environment.baseapi}/manage/quotes/${docID}`, { withCredentials: true});
  }

  managerUnlockQuote(docID: string, quoteID: string){
    const json = {portalID: docID, quoteID};
    return this._http.post(this.environment.baseapi + '/manage/unlock', json , { withCredentials: true});
  }

  triaPDF(docID: string){
    return this._http.get(`${this.environment.baseapi}/premium/tria/${docID}`, { withCredentials: true});
  }

  getRegisteredUsers(){
    return this._http.get(this.environment.baseapi + '/accounts/list', { withCredentials: true});
  }

  registerNewUser(json: any) {
    return this._http.post<any>(this.environment.baseapi + '/accounts/create', json, { withCredentials: true })
  }

  sendInviteEmailAgainToUsers(json: any) {
    return this._http.post<any>(this.environment.baseapi + '/accounts/resend', json, { withCredentials: true })
  }

  updateUserDetails(json: any) {
    return this._http.post<any>(this.environment.baseapi + '/accounts/updateUserdata', json, { withCredentials: true })
  }

  getUserDetailsByEmail(email: string) {
    return this._http.get<any>(`${this.environment.baseapi}/accounts/userdata/${email}`, { withCredentials: true })
  }

  setUserProducts(email: string,json: any) {
    return this._http.post<any>(`${this.environment.baseapi}/accounts/userproduct/${email}`, json, { withCredentials: true })
  }

  setUserProductsForMultipleUsers(json:{products: any[], emails: string[]}) {
    return this._http.post<any>(`${this.environment.baseapi}/accounts/userproducts`, json, { withCredentials: true })
  }
  
  getListOfQuotes(docid: string) {
    return this._http.get<any>(`${this.environment.baseapi}/premiums/fetch/${docid}`, { withCredentials: true })
  }

  cloneAsDraft(docID: string, data: any) {
    return this._http.post<Contract>(`${this.environment.baseapi}/cloneAsDraft/${docID}`, data, { withCredentials: true })
  }

  postLogo(email: string, file: any) {
    return this._http.post(`${this.environment.baseapi}/admin/logo/${email}`, file , { withCredentials: true});
  }

  getAdminCorpLogos(email?: string) {
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.get(`${this.environment.baseapi}/admin/logo${email ? '/' + email : ''}`, { headers, withCredentials: true, responseType: 'blob'});
  }

  getUserCorpLogo(){
    let headers = new HttpHeaders().set('X-POPUP', 'NO');
    return this._http.get(`${this.environment.baseapi}/user/logo`, { headers, withCredentials: true, responseType: 'blob'});
  }

  fileToJson(templateInfo: FormData) : Observable<PTFileToJson | SVCargoSchedule[]> {
    return this._http.post<PTFileToJson | SVCargoSchedule[]>(`${this.environment.baseapi}/fileToJSON`, templateInfo, { withCredentials: true});
  }

  getFileToRiskTemplates(product: string, part: string) {
    return this._http.post(`${this.environment.baseapi}/riskTemplateDoc` , { product: product, part: part } , { withCredentials: true, responseType: 'blob'});
  }

  validateZipCodes(zipCodes: string[]) {
    return this._http.post(`${this.environment.baseapi}/validateZipCode` , { zips: zipCodes } , { withCredentials: true});
  }
}
