import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { PortalUtilService } from "portal"
import { Interest } from "./contract.model"
import { SVCargoSchedule, SVFArtCopeInf } from "./typing"

export enum sovTypes {
    Dropdown = 'Dropdown',
    Radio = 'Radio',
    Input = 'Input',
    Amount = 'Amount'
}

export enum operators {
    Greater = 'Greater',
    Less = 'Less',
    Equals = 'Equals',
}

export enum sovChangesListener {
    ZipTesting = 'ZipTest',
}

export enum validators {
    Required = 'required',
}

export interface validation {
    control: string,
    operator: operators,
    value: string | number, 
    message: validators
}

export interface ScheduleInfo {
    display:string,
    type:string,
    data?:any,
    description?: string,
    formGroupName: string,
    key: string,
    required: boolean,
    threshold?: number,
    validation?: validation[]
    changeListener?: boolean,
    changeToValidate?: string
}

interface addressInfo {
    loc:string[],
    placeholder1:string,
    placeholder2:string,
    defaultCurrency:string
}

export class BourbonSchedule {
    private distanceFFDDropdown = ['under 2 miles', 'over 2m', 'over 5m']
    private constructionDropdown = ['Masonry Non-Combustible', 'Non-Combustible', 'Wood Frame', 'Joisted Masonry']
    private rPDropdown = ['Ricked', 'Palletized']
    private yearBuiltDropdown = ['under 5 years', '5-10yrs', '11-20yrs', '21-30yrs', '31-40yrs', 'plus 41 years']
    private distanceFOtherDropdown = ['over 100ft', 'under 100ft']
    private addressInfo?: addressInfo;
    constructor(addressInfo?: addressInfo){
        this.addressInfo = addressInfo
    }
    public getScheduleRow(): ScheduleInfo[]{
        return [
            {display:'Location Name', type:sovTypes.Input, formGroupName: 'copeInformation', key: 'locationName', required: false},
            {display:'Warehouse Street Address', type:sovTypes.Input, formGroupName: 'address', key: 'line', required: true},
            {display:'City', type:sovTypes.Input, formGroupName: 'address', key: 'city', required: true},
            {display: this.addressInfo?.placeholder1 ?? '', type:sovTypes.Dropdown, data: this.addressInfo?.loc, formGroupName: 'address', key: 'stateOrCounty', required: true},
            {display: this.addressInfo?.placeholder2 ?? '', type:sovTypes.Input, formGroupName: 'address', key: 'zipOrPostCode', required: true},
            {display:'County', type:sovTypes.Input, formGroupName: 'address', key: 'county', required: false},
            {display:'Value Per Barrel', type:sovTypes.Amount, formGroupName: 'valuePerBarrel', key: 'valuePerBarrel', required: true},
            {display:'Average Values', type:sovTypes.Amount, formGroupName: 'avgValue', key: 'avgValue', required: true},
            {display:'Max Values', type:sovTypes.Amount, formGroupName: 'maxValue', key: 'maxValue', required: true, threshold: 10000000, description:'(*cannot be greater than 10M)'},
            {display:'Construction Type', type:sovTypes.Dropdown, data: this.constructionDropdown, formGroupName: 'copeInformation', key: 'constructionType', required: true},
            {display:'Year built', type: sovTypes.Dropdown, data: this.yearBuiltDropdown, formGroupName: 'copeInformation', key: 'period', required: true},
            {display:'Sprinklers', description:'(Y/N)', type: sovTypes.Radio, formGroupName: 'copeInformation', key: 'sprinklered', required: true},
            {display:'BERMS', description:'(Y/N)', type: sovTypes.Radio, formGroupName: 'copeInformation', key: 'berms', required: true},
            {display:'Lightning Protection', description:'(Y/N)', type: sovTypes.Radio, formGroupName: 'copeInformation', key: 'lightningProtection', required: true},
            {display:'Ricked OR Palletized', type: sovTypes.Dropdown, data: this.rPDropdown, formGroupName: 'copeInformation', key: 'rickedOrPallet', required: true},
            {display:'Year Renovated', description:'(If older than 30 years)', type:sovTypes.Input, formGroupName: 'copeInformation', key: 'yearRenovated', required: false, validation: [{control:'copeInformation.period', operator: operators.Equals, value: '31-40yrs', message: validators.Required}, {control:'copeInformation.period', operator: operators.Equals, value: 'plus 41 years', message: validators.Required}]},
            {display:'Distance From Fire Dept', type: sovTypes.Dropdown, data: this.distanceFFDDropdown, formGroupName: 'copeInformation', key: 'fireDeptDist', required: true},
            {display:'Camera System', description:'(Y/N)', type: sovTypes.Radio, formGroupName: 'copeInformation', key: 'cameras', required: true},
            {display:'Alarm System', description:'(Y/N)', type: sovTypes.Radio, formGroupName: 'copeInformation', key: 'alarmed', required: true},
            {display:'Distance from other structure', type: sovTypes.Dropdown, data: this.distanceFOtherDropdown, formGroupName: 'copeInformation', key: 'structDist', required: true},
            {display:'Flood Zone', description:'(Y/N)', type: sovTypes.Radio, formGroupName: 'copeInformation', key: 'floodZone', required: true},
        ];
    }
    public static bourbonScheduleOptions = new UntypedFormGroup({
    })
    public static isSovInvalid(sovJson?: BourbonSchedule[]) : boolean {
        const list = new BourbonSchedule().getScheduleRow()
        return SovUtil.getValidity(list, sovJson)
    }
    public static resetOptions() {
        BourbonSchedule.bourbonScheduleOptions.reset()
    }
}

export class FineArtSchedule {
    private constructionType = ['Combustible Inc Wood Frame', 'Non Combustible',
    'Masonry Non Combustible', 'Semi/Modified Fire Resistive',
    'Fire Resistive']
    private occupancyType = ['Residence', 'Gallery', 'Office', 'Storage']
    private burglarAlarmType = ['Connected to Building Alarm', 'Monitored Alarm', 'Rings Locally', 'None']
    private fireAlarmType = ['Connected to Building Alarm', 'Monitored Alarm', 'Rings Locally', 'None']
    private addressInfo?: addressInfo;
    constructor(addressInfo?: addressInfo){
        this.addressInfo = addressInfo
    }
    public getScheduleRow(): ScheduleInfo[]{
        return [
            {display:'Street Address', type:sovTypes.Input, formGroupName: 'address', key: 'line', required: true},
            {display:'City', type:sovTypes.Input, formGroupName: 'address', key: 'city', required: true},
            {display: this.addressInfo?.placeholder1 ?? '', type:sovTypes.Dropdown, data: this.addressInfo?.loc, formGroupName: 'address', key: 'stateOrCounty', required: true},
            {display: this.addressInfo?.placeholder2 ?? '', type:sovTypes.Input, formGroupName: 'address', key: 'zipOrPostCode', required: true},
            {display:'County', type:sovTypes.Input, formGroupName: 'address', key: 'county', required: true},
            {display:'Year Built', type:sovTypes.Input, formGroupName: 'copeInformation', key: 'period', required: true},
            {display:'Flood Zone', type:sovTypes.Radio, formGroupName: 'copeInformation', key: 'floodZone', required: true},
            {display:'Construction Type', type:sovTypes.Dropdown, formGroupName: 'copeInformation', key: 'constructionType', data: this.constructionType, required: true},
            {display:'Occupancy', type:sovTypes.Dropdown, formGroupName: 'copeInformation', key: 'occupancy', data: this.occupancyType, required: true},
            {display:'Basement used for storage?', type:sovTypes.Radio, formGroupName: 'copeInformation', key: 'basementUsed', required: true},
            {display:'Burglar Alarm', type:sovTypes.Dropdown, formGroupName: 'copeInformation', key: 'burglarAlarm', data: this.burglarAlarmType, required: true},
            {display:'Fire Alarm', type:sovTypes.Dropdown, formGroupName: 'copeInformation', key: 'fireAlarm', data: this.fireAlarmType, required: true},
            {display:'Values at Risk', type:sovTypes.Amount, formGroupName: 'valuesAtRisk', key: 'valuesAtRisk', required: true, threshold: 50000000}
        ];
    }
    public static fineArtScheduleOptions = new UntypedFormGroup({
    })
    public static isSovInvalid(sovJson?: SVFArtCopeInf[]) : boolean {
        const list = new FineArtSchedule().getScheduleRow()
        return SovUtil.getValidity(list, sovJson)
    }
    public static resetOptions() {
        FineArtSchedule.fineArtScheduleOptions.reset()
    }
}

export class CargoSchedule {
    public constructionType = ['Combustible Inc Wood Frame', 'Non Combustible',
      'Masonry Non Combustible', 'Semi/Modified Fire Resistive',
      'Fire Resistive'];
    private addressInfo?: addressInfo;
    constructor(addressInfo?: addressInfo){
        this.addressInfo = addressInfo
    }
    public getScheduleRow(perishable?: boolean, isUSA = true): ScheduleInfo[]{
        const res = [
            {display:'Street Address', type:sovTypes.Input, formGroupName: 'address', key: 'line', required: true},
            {display:'City', type:sovTypes.Input, formGroupName: 'address', key: 'city', required: true},
            {display: this.addressInfo?.placeholder1 ?? '', type:sovTypes.Dropdown, data: this.addressInfo?.loc, formGroupName: 'address', key: 'stateOrCounty', required: true},
            {display: this.addressInfo?.placeholder2 ?? '', type:sovTypes.Input, formGroupName: 'address', key: 'zipOrPostCode', required: true, changeListener: isUSA, changeToValidate: isUSA ? sovChangesListener.ZipTesting : undefined},
            {display:'County', type:sovTypes.Input, formGroupName: 'address', key: 'county', required: true},
            {display:'Year Built', type:sovTypes.Input, formGroupName: 'copeInformation', key: 'period', required: false},
            {display:'Construction Type', type:sovTypes.Dropdown, formGroupName: 'copeInformation', key: 'constructionType', data: this.constructionType, required: true},
            {display:'Alarmed', type:sovTypes.Radio, formGroupName: 'copeInformation', key: 'alarmed', required: true},
            {display:'Sprinklered', type:sovTypes.Radio, formGroupName: 'copeInformation', key: 'sprinklered', required: true},
            {display:'Manufacturing/Processing', type:sovTypes.Radio, formGroupName: 'copeInformation', key: 'undertakenThreat', required: true},
            {display:'Average Value', type:sovTypes.Amount, formGroupName: 'avgValue', key: 'avgValue', required: true},
            {display:'Max Value', type:sovTypes.Amount, formGroupName: 'maxValue', key: 'maxValue', required: true, threshold: 25000000, description:'(*cannot be greater than 25M)'}
        ]
        
        if(perishable){
            res.splice(10, 0, {display:'Back-up generators onsite', type:sovTypes.Radio, formGroupName: 'copeInformation', key: 'generatorsOnSite', required: true})
        }
        return res
    }
    public static cargoScheduleOptions = new UntypedFormGroup({
        interestType : new UntypedFormControl(''),
        cargoType : new UntypedFormControl('')
    })
    public static isSovInvalid(sovJson?: SVCargoSchedule[]) : boolean {
        const list = new CargoSchedule().getScheduleRow(CargoSchedule.cargoScheduleOptions.get('interestType')?.value === Interest.Perishable)
        return SovUtil.getValidity(list, sovJson)
    }
    public static resetOptions() {
        CargoSchedule.cargoScheduleOptions.reset()
    }
}

class SovUtil {
    public static getValidity(list: ScheduleInfo[], sovJson?: any[]): boolean{
        const listToCheck = list.map((i) => {
            return {
                key: sovTypes.Amount === i.type ? `${i.key}` : `${i.formGroupName}.${i.key}`,
                required: i.required
            }
        })
        let errors: any = []
        PortalUtilService.flattenObject(sovJson)?.forEach((sovItem: any) => {
            errors = listToCheck.filter((item) => {
                if(item.required && !PortalUtilService.itemExist(sovItem, item.key)){
                    return item
                }
            })
            if (errors?.length > 0) return
        })
        return errors?.length > 0
    }
}