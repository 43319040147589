<div class="col-xl-6 col-lg-7 col-md-8 col-sm-9 col-12 businessDetail">
    <div class="d-flex colMargin" [formGroup]="overviewOfBusiness">
        <div class="controlInput figureInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(overviewOfBusiness,'yearInIndustry')}">
            <input pt-appCurrency [inputControl]="yearInIndustry" class="amount" type="text" pt-ngInvalid>
            <label class="controlLabel">
                Year(s) In Industry*
            </label>
        </div>
        <div class="controlInput figureInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(overviewOfBusiness,'yearInBusiness')}">
            <input pt-appCurrency [inputControl]="yearInBusiness" class="amount" type="text" pt-ngInvalid>
            <label class="controlLabel">
                Year(s) In Business*
            </label>
        </div>
    </div>
</div>