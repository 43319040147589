import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-whole-sale-broker',
  templateUrl: './whole-sale-broker.component.html',
  styleUrls: ['./whole-sale-broker.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class WholeSaleBrokerComponent implements OnInit {
  @Input() group: UntypedFormGroup
  @Input() data: { brokerInformation : { subBroker: string, epicClient: string }}

  toggleInformation : any = {};
  brokerInformation = new UntypedFormGroup({
    subBroker : new UntypedFormControl('', [Validators.required]),
    epicClient : new UntypedFormControl('', [Validators.required])
  })

  constructor(public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.group.addControl('brokerInformation', this.brokerInformation)
    this.brokerInformation.patchValue({
      subBroker : this.data?.brokerInformation?.subBroker,
      epicClient : this.data?.brokerInformation?.epicClient
    })
    this.setupToggle();
  }

  setupToggle(){
    this.toggleInformation['brokerInformation'] = {
      text : "Sub Broker?",
      listOfEnabledToggles: [],
      childControlName: 'brokerInformation',
      data : this.data?.brokerInformation,
      parentGroup : this.group,
      clonedParentGroup: _.cloneDeep(this.group)
    }
  }

}
