import { Pipe, PipeTransform } from '@angular/core';
import { CleanProductPipe } from './clean-product.pipe';

@Pipe({
  name: 'cleanProdComm'
})
export class CleanProdCommPipe extends CleanProductPipe implements PipeTransform {

  transform(data: any): string {
    let transformedString = `${super.transform(data.name)}, Commission : ${data.commission}%`
    if(data.brokerage){
      transformedString += `, Brokerage : ${data.brokerage}%`
    }
    return transformedString
  }
}
