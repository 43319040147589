  <h1 class="header">1. Insured Details</h1>

  <div class="insuredAddress colMargin">
    <div class="row">
      <div class="col-12">
        <mat-error *ngIf="contractName?.errors?.contractNameTooLong" class="d-flex justify-content-end">
          Contract name cannot be longer than 75 characters*
        </mat-error>
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(form,'contractName')}" class="controlInput">
          <input class="controlFull" type="text" placeholder="Insured's name" formControlName="contractName" domControl>
          <label class="controlLabel labelBackground">
            Insured's Name*
          </label>
        </div>
        <ng-container *ngIf="product.typeInArrToCheck(productType, product.supportedProducts.UEquipmentBinder.typeArr)
                && form.get('proposalForm')?.get('overviewOfBusiness')">
          <app-business-contact-detail [group]="form.get('proposalForm')?.get('overviewOfBusiness')"
                [data]='preForm?.proposalForm?.overviewOfBusiness'></app-business-contact-detail>
        </ng-container>
        <div formGroupName="proposalForm">
          <app-address [type]="'insured'" formControlName='insuredAddress'></app-address>
        </div>
        <ng-container *ngIf="product.typeInArrToCheck(productType, product.supportedProducts.UEquipmentBinder.typeArr)
                && form.get('proposalForm')?.get('overviewOfBusiness')">
          <app-whole-sale-broker [group]="form.get('proposalForm')?.get('overviewOfBusiness')"
                [data]='preForm?.proposalForm?.overviewOfBusiness'></app-whole-sale-broker>
        </ng-container>
      </div>
    </div>
  </div>

