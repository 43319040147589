import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, ErrorPopupComponent } from 'portal';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HTTPInterceptor implements HttpInterceptor, OnDestroy {
  dialogOpen = false;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public dialog: MatDialog, public router: Router, private auth: AuthService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            if(!this.router.url.includes('login')){
              this.auth.resetUserID();
              this.router.navigate(['login'])
            }
          }  else if (this.ignoreErrorThrownBy(request)){
            //ignore error
          } else if (error.error instanceof ErrorEvent) {
              this.openErrorPopup({status: 'Client Error', message: error.error.message});
          } else {
              this.openErrorPopup({status: error.status, message: error.message});
          }
          return throwError(error?.message);
        })
      );
  }

  openErrorPopup(message: { status: string | number; message: string; }) {
    if (!this.dialogOpen) {
      this.dialogOpen = true;
      const dialogRef = this.dialog.open(ErrorPopupComponent, {
        data: message,
        panelClass: 'custom-dialog-container',
        autoFocus: false
      });
      dialogRef.afterClosed()
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(() => {
        this.dialogOpen = false;
      });
    }
  }

  ignoreErrorThrownBy(request: HttpRequest<any>){
    const listOfEndpointsToIgnore = [environment.baseapi + '/manage/allowed'];
    return listOfEndpointsToIgnore.includes(request.url) || request?.headers?.get('X-POPUP');
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
