import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subject, throwError, BehaviorSubject } from 'rxjs';
import { catchError, delay, first, retry, takeUntil, debounceTime } from 'rxjs/operators';
import { PTEnvironment } from '../../../model/typings';
import { AuthService } from '../../../services/auth.service';
import { ContractsService } from '../../../services/contracts.service';
import { PortalDataService } from '../../../services/portal-data.service';

@Component({
  selector: 'pt-top-nav-base',
  templateUrl: './top-nav-base.component.html',
  styleUrls: ['./top-nav-base.component.css']
})

export class TopNavBaseComponent implements OnInit, OnDestroy {
  @Input() navType: string;
  @Inject('env') private environment: PTEnvironment
  scrollPosition: number; search= false; logo: string; button: SafeHtml;
  email: string|undefined; customlogo: SafeResourceUrl|undefined;
  unsub: Subject<boolean> = new Subject<boolean>();
  searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  constructor(public router: Router, public auth: AuthService,
    public sanitizer: DomSanitizer, public portalDataService: PortalDataService,
    public contractsService: ContractsService) {}

  ngOnInit(): void {
    this.isManager();
    if (this.navType === 'form') {
      window.addEventListener('scroll', this.scroll, true);
    }

    if(sessionStorage.getItem('email')){
      this.email = sessionStorage.getItem('email') || '';
    }

    this.searchSubject.pipe(debounceTime(300)).subscribe((searchString)=>{
      this.contractsService.setSearchNameFilter(searchString)
    })

    this.portalDataService.getUserCorpLogo()
    .pipe(
      first(),
      retry(3),
      delay(1000),
      catchError(error => this.setBrokerLogo(this.email || '', error))
    )
    .subscribe((data: any) => {
      if (data.type === 'application/octet-stream') {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
          if(reader.result){
            this.customlogo = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
          }
        };
      } else {
        this.setBrokerLogo(this.email || '')
      }
    });
  }

  scroll = (): void => {
    this.scrollPosition = window.pageYOffset;
    const scroll = (document.documentElement.scrollTop /
      (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100;
    const progressBar = document.getElementById('progressBar');
    if (scroll > 15 && progressBar) {
      progressBar.style.width = scroll + '%';
    }
  }

  setBrokerLogo(email: string, error?: ErrorEvent){
    const e = email.slice(email.indexOf("@"));
    if(e.toLowerCase().includes('wspt')){
      this.logo = 'demo';
    }
    if(e.toLowerCase().includes('usi')){
      this.logo = 'usi';
    }
    if(e.toLowerCase().includes('mcgriff')){
      this.logo = 'mcgriff';
    }
    if(e.toLowerCase().includes('crcgroup')){
      this.logo = 'crc';
    }
    if(e.toLowerCase().includes('wmbeck')){
      this.logo = 'wmb';
    }
    if(e.toLowerCase().includes('risk-strategies')){
      this.logo = 'risk-strategies';
    }
    return throwError(error);
  }

  isManager() {
    this.portalDataService.isManager()
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      if(response.allowed){
        const button = '<button class="navButton"><div id="adminPanel"></div></button>';
        this.button = this.sanitizer.bypassSecurityTrustHtml(button);
        this.handleAdminEventListener();
      }
    });
  }

  handleAdminEventListener(){
    setTimeout(() => {
      document.getElementById('admin')?.addEventListener('click', () => {
        this.navigateToAdmin();
      });
    })
  }

  searchClick() {
    this.setName('');
    this.search = !this.search;
  }

  setName(name: string) {
    this.searchSubject.next(name)
  }

  logout() {
    this.auth.logout();
  }

  navigateToAdmin() {
    this.router.navigate(['contracts/admin']);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    this.unsub.next(true);
    this.unsub.complete();
  }

}
