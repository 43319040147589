import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { PortalUtilService, PTAmount } from 'portal';
import { Subject } from 'rxjs';
import { BourbonSchedule, ScheduleInfo } from '../../../../models/sov.model';
import { Product } from '../../../../models/product.type';
import { SVScheduleAddress } from '../../../../models/typing';
import { ScheduleOfValuesBaseComponent } from '../schedule-of-values-base/schedule-of-values-base.component';

@Component({
  selector: 'app-schedule-of-values-list-bourbon',
  templateUrl: './schedule-of-values-list-bourbon.component.html',
  styleUrls: ['./schedule-of-values-list-bourbon.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ],
})

export class ScheduleOfValuesListBourbonComponent implements OnInit, AfterViewInit {
  @ViewChild(ScheduleOfValuesBaseComponent) sovBase: ScheduleOfValuesBaseComponent
  @Input() preForm: any;
  @Input() status: boolean;
  bourbonSchedules: ScheduleInfo[]
  scheduleOfValues = new UntypedFormArray([])
  constructor(private parent: FormGroupDirective) {
    this.parent.form.addControl('scheduleOfValues', this.scheduleOfValues)
  }

  ngAfterViewInit(): void {
    this.setupSchedule()
    this.sovBase.onValueChanges()
  }

  ngOnInit(): void {
    const productData = new Product().findProductType(this.preForm?.product)
    const addressInfo = productData.addressInfo?.[this.preForm?.proposalForm?.insuredAddress?.country] ?? productData.addressInfo.defaultAddress
    this.bourbonSchedules = new BourbonSchedule(addressInfo).getScheduleRow()
  }

  initSchedule() {
    return new UntypedFormGroup({
      address: new UntypedFormGroup({
        city: new UntypedFormControl(''),
        zipOrPostCode: new UntypedFormControl(''),
        stateOrCounty: new UntypedFormControl(''),
        county: new UntypedFormControl(''),
        line: new UntypedFormControl(''),
      }),
      copeInformation: new UntypedFormGroup({
        locationName: new UntypedFormControl(''),
        constructionType: new UntypedFormControl(''),
        period: new UntypedFormControl(''),
        sprinklered: new UntypedFormControl(''),
        berms: new UntypedFormControl(''),
        lightningProtection: new UntypedFormControl(''),
        rickedOrPallet: new UntypedFormControl(''),
        fireDeptDist: new UntypedFormControl(''),
        cameras: new UntypedFormControl(''),
        alarmed: new UntypedFormControl(''),
        structDist: new UntypedFormControl(''),
        floodZone: new UntypedFormControl(''),
        yearRenovated: new UntypedFormControl(''),
      }),
      avgValue: new UntypedFormGroup({
        currency: new UntypedFormControl({value: this.preForm.proposalForm.defaultCurrency, disabled: true}),
        figure: new UntypedFormControl(''),
      }),
      maxValue: new UntypedFormGroup({
        currency: new UntypedFormControl({value: this.preForm.proposalForm.defaultCurrency, disabled: true}),
        figure: new UntypedFormControl(''),
      }),
      valuePerBarrel: new UntypedFormGroup({
        currency: new UntypedFormControl({value: this.preForm.proposalForm.defaultCurrency, disabled: true}),
        figure: new UntypedFormControl(''),
      }),
    });
  }

  setupSchedule(data ?: SVBourbonSchedule[]) {
    if(data){
      this.scheduleOfValues.clear()
    }
    let schedules = data ?? this.preForm?.proposalForm?.scheduleOfValues
    if (schedules) {
      schedules.forEach((schedule: SVBourbonSchedule, i: number) => {
        this.scheduleOfValues.push(this.initSchedule())
        setTimeout(() => {
          this.scheduleOfValues.at(i).patchValue({
            copeInformation : {
              locationName: schedule?.copeInformation?.locationName,
              constructionType: schedule?.copeInformation?.constructionType,
              period: schedule?.copeInformation?.period,
              sprinklered: schedule?.copeInformation?.sprinklered,
              berms: schedule?.copeInformation?.berms,
              lightningProtection: schedule?.copeInformation?.lightningProtection,
              rickedOrPallet: schedule?.copeInformation?.rickedOrPallet,
              fireDeptDist: schedule?.copeInformation?.fireDeptDist,
              cameras: schedule?.copeInformation?.cameras,
              alarmed: schedule?.copeInformation?.alarmed,
              structDist: schedule?.copeInformation?.structDist,
              floodZone: schedule?.copeInformation?.floodZone,
              yearRenovated: schedule?.copeInformation?.yearRenovated
            },
            address: {
              stateOrCounty: schedule?.address?.stateOrCounty,
              line: schedule?.address?.line,
              city: schedule?.address?.city,
              county: schedule?.address?.county,
              zipOrPostCode: schedule?.address?.zipOrPostCode,
            },
            maxValue: {
              currency: schedule?.maxValue?.currency,
              figure: schedule?.maxValue?.figure,
            },
            avgValue: {
              currency: schedule?.avgValue?.currency,
              figure: schedule?.avgValue?.figure,
            },
            valuePerBarrel: {
              currency: schedule?.valuePerBarrel?.currency,
              figure: schedule?.valuePerBarrel?.figure,
            },
          });
        })
      });
    }
    while (this.scheduleOfValues.length < 5) {
      this.scheduleOfValues.push(this.initSchedule())
    }
  }
}

interface SVBourbonSchedule {
  copeInformation: SVBourbonCopeInf,
  address: SVScheduleAddress,
  maxValue: PTAmount,
  avgValue: PTAmount,
  valuePerBarrel: PTAmount
}

interface SVBourbonCopeInf {
  locationName: string,
  constructionType: string,
  period: string,
  sprinklered: boolean,
  berms: boolean,
  lightningProtection: boolean,
  rickedOrPallet: string,
  fireDeptDist: string,
  cameras: boolean,
  alarmed: boolean,
  structDist: string,
  floodZone: boolean,
  yearRenovated: string
}