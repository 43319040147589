<div class="interestType fineArtType colMargin ps-3" [ngClass]="{'ng-invalid' : fineArtType?.errors}" domControl>
    <h3 class="listHeader pt-3">Type:</h3>
    <div class="businessOverviewWrapper col-lg-4 col-sm-8 col-12 p-0">
        <mat-error class="typeError" *ngIf="fineArtType.errors">
            *
        </mat-error>
        <div class="row m-0 row-cols-1 pt-4">
            <div class="col interest text" *ngFor="let item of type"
                (click)="chooseInterest(item.value)" [ngClass]="{'active' : fineArtType.value === item.value}">
                    <div id={{item.key}}>
                    </div>
                    <label class="interestLabel nowrap">{{item.display}}</label>
            </div>
        </div>
    </div>
</div>
