import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalDataService } from '../../../../../services/portal-data.service';
import { PortalUtilService } from '../../../../../services/portal-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'pt-user-product-assign-base',
  templateUrl: './user-product-assign-base.component.html',
  styleUrls: ['./user-product-assign-base.component.css']
})
export class UserProductAssignBaseComponent implements OnInit, OnDestroy {
  email: string;
  userdata: any = {};
  selectedProducts: string[] = [];
  selectedUsers: any[] = [];
  productsControl = new UntypedFormControl();
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public router: Router, public portalDataService: PortalDataService,
    public activeRoute: ActivatedRoute, public pUtil: PortalUtilService
  ) { }

  ngOnInit(): void {
    this.getUserEmail();
  }

  selectProduct(product: string, input: HTMLInputElement){
    if(input.checked){
      if(!this.selectedProducts.includes(product)){
        this.selectedProducts.push(product);
      }
    } else {
      const index = this.selectedProducts.findIndex(p => p === product)
      if(index >= 0){
        this.selectedProducts.splice(index, 1);
      }
    }
  }

  handleUserProducts(action: string){
    const obj = {};
    obj[action] = this.selectedProducts;
    this.portalDataService.setUserProducts(this.email, obj)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response) => {
      this.userdata = response;
    })
  }

  getUserEmail(){
    this.activeRoute.queryParams.subscribe(params => {
      this.email = params['user'];
      this.getUserDetailsByEmail();
    });
  }

  redirectToAdmin(admin: string){
    this.router.navigate(['/contracts/admin'],{ queryParams: { page: this.pUtil.rmvSpace(admin)} });
  }

  getUserDetailsByEmail(){
    this.portalDataService.getUserDetailsByEmail(this.email)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      this.userdata = response;
      this.productsControl.patchValue(response)
      this.selectedUsers.push({email: this.userdata.email || this.email});
    })
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
