<div class="d-flex" [formGroup]="form">
    <div formArrayName="products">
        <h3><strong>Select the allowed product types the user can handle...</strong></h3>
        <div *ngFor="let product of getProducts(); let i=index" class="d-flex align-items-center ps-2" [formGroupName] = "i">
            <div class="checkBox col-6 pt-1">
                <label class="container demi14px">
                    <input #inp (click)="selectProduct(product?.get('name')?.value,inp)" class="splitCheckBox" type="checkbox" [id]="product?.get('name')?.value"> 
                    <span class="checkmark"></span>
                    {{product?.get('name')?.value | cleanProduct}}
                </label>
            </div>
            <div *ngIf="product?.get('commission')?.enabled" class="controlInput col m-2 p-2" [attr.name]="product?.get('name')?.value">
                <input class="splitInput" [inputControl]="product?.get('commission')" pt-appPercentage
                placeholder="Commission" name="commission">
                <label class="controlPercentageLabel">
                  %
                </label>
                <label class="controlLabel">
                  Commission*
                </label>
            </div>
            <div *ngIf="product?.get('brokerage')?.enabled" class="controlInput col m-2 p-2" [attr.name]="product?.get('name')?.value">
                <input class="splitInput" [inputControl]="product?.get('brokerage')" pt-appPercentage
                placeholder="Brokerage" name="brokerage">
                <label class="controlPercentageLabel">
                  %
                </label>
                <label class="controlLabel">
                    Brokerage*
                </label>
            </div>
        </div>
        <div class="d-flex justify-content-end pt-3">
            <button class="button smallButton" (click)="email ? setUserProducts() : setProductForMultipleUsers()" [disabled]="!form.valid">Set User Products</button>
        </div>
    </div>
</div>