import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Interest } from '../../../../models/contract.model';
import { Product } from '../../../../models/product.type';
import { CargoSchedule, ScheduleInfo } from '../../../../models/sov.model';
import { SVCargoSchedule } from '../../../../models/typing';
import { ScheduleOfValuesBaseComponent } from '../schedule-of-values-base/schedule-of-values-base.component';

@Component({
  selector: 'app-schedule-of-values-list',
  templateUrl: './schedule-of-values-list.component.html',
  styleUrls: ['./schedule-of-values-list.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class ScheduleOfValuesListComponent implements OnInit, AfterViewInit {
  @ViewChild(ScheduleOfValuesBaseComponent) sovBase: ScheduleOfValuesBaseComponent
  @Input() preForm: any;
  @Input() status: boolean;
  cargoSchedules: ScheduleInfo[]
  scheduleOfValues = new UntypedFormArray([])
  constructor(private parent: FormGroupDirective) {
    this.parent.form.addControl('scheduleOfValues', this.scheduleOfValues)
  }

  ngAfterViewInit(): void {
    this.setupSchedule()
    this.sovBase.onValueChanges()
  }

  ngOnInit(): void {
    const productData = new Product().findProductType(this.preForm?.product)
    const addressInfo = productData.addressInfo?.[this.preForm?.proposalForm?.insuredAddress?.country] ?? productData.addressInfo.defaultAddress
    this.cargoSchedules = new CargoSchedule(addressInfo).getScheduleRow(this.preForm?.proposalForm?.overviewOfBusiness?.interestType === Interest.Perishable, this.preForm?.proposalForm?.insuredAddress?.country === "USA")
  }

  setupSchedule(data?: SVCargoSchedule[]) {
    if(data){
      this.scheduleOfValues.clear()
    }
    let schedules = data ?? this.preForm?.proposalForm?.scheduleOfValues
    if (schedules) {
      schedules.forEach((schedule: SVCargoSchedule, i: number) => {
        this.scheduleOfValues.push(this.initSchedule())
        setTimeout(() => {
          this.scheduleOfValues.at(i).patchValue({
            copeInformation : {
              period: schedule?.copeInformation?.period,
              constructionType: schedule?.copeInformation?.constructionType,
              alarmed: schedule?.copeInformation?.alarmed,
              sprinklered: schedule?.copeInformation?.sprinklered,
              generatorsOnSite: this.preForm?.proposalForm?.overviewOfBusiness?.interestType === Interest.Perishable ? schedule?.copeInformation?.generatorsOnSite : '',
              undertakenThreat: schedule?.copeInformation?.undertakenThreat,
            },
            address: {
              stateOrCounty: schedule?.address?.stateOrCounty,
              line: schedule?.address?.line,
              city: schedule?.address?.city,
              county: schedule?.address?.county,
              zipOrPostCode: schedule?.address?.zipOrPostCode,
            },
            avgValue: {
              figure: schedule?.avgValue?.figure,
            },
            maxValue: {
              figure: schedule?.maxValue?.figure,
            }
          });
        })
      });
    }
    while (this.scheduleOfValues.length < 5) {
      this.scheduleOfValues.push(this.initSchedule())
    }
  }

  initSchedule() {
    return new UntypedFormGroup({
      address: new UntypedFormGroup({
        city: new UntypedFormControl(''),
        zipOrPostCode: new UntypedFormControl(''),
        stateOrCounty: new UntypedFormControl(''),
        county: new UntypedFormControl(''),
        line: new UntypedFormControl(''),
      }),
      copeInformation: new UntypedFormGroup({
        period: new UntypedFormControl(''),
        constructionType: new UntypedFormControl(''),
        alarmed: new UntypedFormControl(''),
        sprinklered: new UntypedFormControl(''),
        undertakenThreat: new UntypedFormControl(''),
        generatorsOnSite: new UntypedFormControl(''),
      }),
      avgValue: new UntypedFormGroup({
        currency: new UntypedFormControl({value: this.preForm.proposalForm.defaultCurrency, disabled: true}),
        figure: new UntypedFormControl(''),
      }),
      maxValue: new UntypedFormGroup({
        currency: new UntypedFormControl({value: this.preForm.proposalForm.defaultCurrency, disabled: true}),
        figure: new UntypedFormControl(''),
      }),
    });
  }
}
