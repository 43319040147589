import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-coverage-required',
  templateUrl: './coverage-required.component.html',
  styleUrls: ['./coverage-required.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class CoverageRequiredComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() preForm: any;
  @Input() option: number;
  underlyingArr: string[];
  coverageRequired = new UntypedFormGroup({});
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('coverageRequired', this.coverageRequired);
  }

  ngAfterViewInit(){
    this.handleCoverageState();
  }

  ngOnChanges(changes: SimpleChanges){
    if(!changes?.option?.firstChange){
      this.handleCoverageState();
    }
  }

  handleCoverageState(){
    if(this.option === 3 || this.option === 4){
      this.option === 3 ? this.underlyingArr = ['Primary Liability', 'Automobile Liability', 'Excess Liability'] : this.underlyingArr = ['Primary Liability', 'Excess Liability'];
    }
    this.cdr.detectChanges();
  }

}
