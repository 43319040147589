import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ProductComponent } from '../update-contract.component';

@Component({
  selector: 'app-construction-form',
  templateUrl: './construction-form.component.html',
  styleUrls: ['./construction-form.component.scss']
})
export class ConstructionFormComponent implements ProductComponent {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;

  constructor(public parent: FormGroupDirective) { }

}
