<div class="col-lg-7 col-md-8 col-sm-9 col-12 p-0 m-0 fragilesList">
    <h1 class="header">{{sectionHeader}}</h1>
    <span class="text fragileSubheader">Please select the applicable collection breakdown by %:</span>

    <div class="nonFragiles colMargin">
        <label class="listHeader mb-3">Non - Fragile</label>
        <div *ngFor="let nonFrag of riskInfoUI.nonFragileList" class="row fragile align-items-center colMargin">
            <div class="col p-0">
                <label class="container m-0">
                    <span class="listLabel">{{nonFrag.display}}</span>
                    <input (click)="selectFrag(nonFrag, 'nonFragileList')" class="splitCheckBox" type="checkbox" [id]='nonFrag.key'> 
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class = "col-lg-3 col-md-4 col-5">
                <span *ngIf='checkIfChecked(nonFrag.key)'>
                    <div class="controlInput m-0 p-0">
                        <input class="splitInput" [inputControl]="nonFrag.control" pt-appPercentage [attr.name]="nonFrag.key">
                        <label class="controlPercentageLabel">
                          %
                        </label>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <div class="fragiles colMargin">
        <label class="listHeader mb-3">Fragile</label>
        <div *ngFor="let frag of riskInfoUI.fragileList" class="row fragile align-items-center colMargin">
            <div class="col p-0">
                <label class="container m-0">
                    <span class="listLabel">{{frag.display}}</span>
                    <input (click)="selectFrag(frag, 'fragileList')" class="splitCheckBox" type="checkbox" [id]='frag.key'> 
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class = "col-lg-3 col-md-4 col-5">
                <span *ngIf='checkIfChecked(frag.key)'>
                    <div class="controlInput m-0 p-0">
                        <input class="splitInput" [inputControl]="frag.control" pt-appPercentage [attr.name]="frag.key">
                        <label class="controlPercentageLabel">
                          %
                        </label>
                    </div>
                </span>
            </div>
        </div>
    </div>
    
    <div class ="d-flex justify-content-end align-items-center text" [ngClass]="{'ng-invalid' : riskInformation.get('riskInformationTotal')?.errors}" domControl>
        Total<span *ngIf="riskInformation.get('riskInformationTotal')?.errors" class="ps-1 smallText text-danger">(needs to add up to 100%)</span>
        <div class ="col-lg-2 col-md-3 col-4 pe-0">
            <div class="controlInput m-1 p-1">
                <input class="splitInput" [inputControl]="riskInformation.get('riskInformationTotal')" id='total' disabled="true" pt-appPercentage>
                <label class="controlPercentageLabel">
                  %
                </label>
            </div>
        </div>
    </div>

</div>


<div class="col-12 p-0">
    <div class="mt-5 mb-3 text">
        <div [ngClass]="{'hide' : riskInformation.get('appraisalAvailable')?.disabled}">
            <app-toggle [toggleControl]="riskInformation.get('appraisalAvailable')"
                toggleText="Current Appraisal Available from within last 36 months"></app-toggle>
        </div>
    </div>
</div>