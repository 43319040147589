import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-business-interruption-value',
  templateUrl: './business-interruption-value.component.html',
  styleUrls: ['./business-interruption-value.component.scss']
})
export class BusinessInterruptionValueComponent implements OnInit {
  @Input() businessInterruptionValue: UntypedFormControl;
  interruption = false;
  constructor(private cdr : ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.businessInterruptionValue.value) {
      this.interruption = true;
    }
  }

  toggleInterruption() {
    this.interruption = !this.interruption;
    this.interruption ? this.businessInterruptionValue.enable() : this.businessInterruptionValue.disable();
    this.cdr.detectChanges()
  }

}
