import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-required-describe',
  templateUrl: './coverage-required-describe.component.html',
  styleUrls: ['./coverage-required-describe.component.scss']
})
export class CoverageRequiredDescribeComponent {
  @Input() control: UntypedFormControl

  @Input() text: string

  constructor(public pUtil: PortalUtilService) { }

}
