import { Directive, ElementRef, Input, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pt-ngInvalid]'
})
export class DomControlDirective implements OnInit, OnDestroy {
  @Input() inputControl : UntypedFormControl;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(){
    this.handleValidity()
    this.inputControl.valueChanges.
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      this.handleValidity()
    });
  }

  handleValidity(){
    if(this.inputControl.invalid){
      this.renderer.setAttribute(this.el.nativeElement, 'domControl', '')
      this.renderer.addClass(this.el.nativeElement, 'ng-invalid');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'ng-invalid')
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }


}
