<div class='underscorePopup' *ngIf='message'>
    <span><h1 mat-dialog-title class="dialogTitle dialogText header">{{message.title}}</h1></span>
    <button mat-dialog-close class= "closeButton"><div class="xIcon"></div></button>    
    <div class="line mb-4 mt-4"></div>
    <mat-dialog-content>
        <div [ngClass]="{'popupFontSize' : message?.messages?.length > 4}">
            <h3 class="dialogText" *ngFor="let message of message.messages">
                {{message}}
            </h3>
            <h3 class="dialogText" *ngIf="message.errorDescription">
                {{message.errorDescription}}
            </h3>
        </div>
    </mat-dialog-content>
    <div class="line mb-3 mt-4"></div>
    <div class="d-flex justify-content-end mt-4 me-4">
        <span *ngIf='message.nextEndpoint'><button mat-button class= "roboto createButton" (click)="userCall()">Yes</button></span>
        <span *ngIf='message.nextEndpoint'><button mat-button class= "roboto declineButton me-3" (click)="closePopup()">No</button></span>
        <span *ngIf='!message.nextEndpoint'><button mat-button class= "roboto createButton" (click)="closePopup()">OK</button></span>
    </div>
</div>