import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../../models/product.type';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit, OnDestroy {
  @Input() parent: UntypedFormGroup
  @Input() periodData: PeriodData
  @Input() required: boolean
  @Input() startLabel: string
  @Input() endLabel: string

  public period = new UntypedFormGroup({
    startDate: new UntypedFormControl(''),
    endDate: new UntypedFormControl(''),
  });

  start = new Date(); end: Date

  unsub: Subject<boolean> = new Subject<boolean>()

  constructor(public pUtil: PortalUtilService, private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(this.required){
      this.period.get('startDate')!.setValidators(Validators.required)
      this.period.get('endDate')!.setValidators(Validators.required)
      this.period.updateValueAndValidity()
    }
    this.parent.addControl('period', this.period)
    this.onValueChanges()
    this.setUpContractDate()
  }

  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      const formType = this.pUtil.addSpace(params.get('prod'))
      const prod = new Product()
      if(!prod.typeInArrToCheck(formType, prod.supportedProducts.UCasualty.typeArr)){
        this.period.get('endDate')?.setValidators(Validators.required)
        this.period.get('startDate')?.setValidators(Validators.required)
      }
    });
  }

  onValueChanges(): void {
    this.period.get('startDate')?.valueChanges
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((changes: string) => {
      this.end = this.pUtil.returnISOToDate(changes);
      this.end.setFullYear(this.end.getFullYear() + 1)
      this.cdr.detectChanges();
    });
  }

  setUpContractDate() {
    if (this.periodData) {
      this.period.patchValue({
        endDate : this.periodData?.endDate,
        startDate: this.periodData?.startDate,
      }, {emitEvent: false, onlySelf: true})
   }
  }

  ngOnDestroy(): void {
    this.unsub.next(true)
    this.unsub.complete()
  }

}

export interface PeriodData {
  endDate: string,
  startDate: string,
}
