import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup,
  AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PortalUtilService } from 'portal';
import { Product } from 'projects/mcgill/src/app/contracts/shared-resources/models/product.type';

@Component({
  selector: 'app-user-surplus-lines',
  templateUrl: './user-surplus-lines.component.html',
  styleUrls: ['./user-surplus-lines.component.scss'],
  providers: [
       {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSurplusLinesComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserSurplusLinesComponent),
      multi: true
    }
  ]
})

export class UserSurplusLinesComponent implements OnInit, ControlValueAccessor, OnDestroy {
  public onTouched: () => void = () => {};
  public surplusLines: UntypedFormGroup = new UntypedFormGroup({
    surplusLineBrokerName: new UntypedFormControl('', [Validators.required]),
    licenseNo: new UntypedFormControl('', [Validators.required]),
    stateOfFiling: new UntypedFormControl('', [Validators.required]),
    stateOrCounty: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    lines: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    zipOrPostCode: new UntypedFormControl('', [Validators.required]),
  });
  fineArt = new UntypedFormControl();
  countries= []; states= [];
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    const prod = new Product();
    this.countries = prod.supportedProducts.UFineArt.data.addressInfo.countries;
    this.states = prod.supportedProducts.UFineArt.data.addressInfo.defaultAddress.loc;
  }

  writeValue(val: any): void {
    if(val){
      this.surplusLines.patchValue({
        surplusLineBrokerName: val?.surplusLineBrokerName,
        licenseNo: val?.licenseNo,
        stateOfFiling: val?.stateOfFiling,
        stateOrCounty: val?.stateOrCounty,
        city: val?.city,
        lines: val?.lines,
        country: val?.country,
        zipOrPostCode: val?.zipOrPostCode,
      });
      this.fineArt.setValue(true);
    } else {
      this.fineArt.setValue(false);
    }
  }

  registerOnChange(fn: any): void {
    this.surplusLines.valueChanges.pipe(
      takeUntil(this.unsub)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.surplusLines.disable() : this.surplusLines.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.surplusLines.valid || this.surplusLines.disabled ? null : { invalidForm: {valid: false, message: ''}};
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
