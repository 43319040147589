import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-insurance-responsible',
  templateUrl: './insurance-responsible.component.html',
  styleUrls: ['./insurance-responsible.component.scss']
})
export class InsuranceResponsibleComponent {
  @Input() insuranceResponsible: UntypedFormControl;
  arr = [
    {display: 'Borrower', value:'Borrower'},
    {display: 'Own Insurance', value:'Own Insurance'},
  ]
  constructor() { }

  setType(s: string) {
    this.insuranceResponsible.setValue(s);
  }

}
