import { Directive, ElementRef, Input, AfterViewInit, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pt-appCurrency]'
})
export class CurrencyDirective implements AfterViewInit, OnDestroy {
  @Input() inputControl: UntypedFormControl;
  unsub: Subject<boolean> = new Subject<boolean>();
  prevval = ""
  constructor(private el: ElementRef, private renderer: Renderer2) {
       fromEvent(el.nativeElement, 'input').
       pipe(
        takeUntil(this.unsub)
      )
       .subscribe(() => {
        this.tranformToCurrency(el.nativeElement.value)
      })
  }
  tranformToCurrency(val: string | number) {
    this.setFigure(val.toString().replace(/[^0-9.]+/g, ''))
  }
  setFigure(val: string) {
    const selectpos = this.el.nativeElement.selectionStart
    const prevcommas = [...this.prevval?.substring(0, selectpos)].filter(chr => chr === ",").length
    if (!val){
      this.inputControl.setValue(null)
      this.el.nativeElement.value = ''
      return
    }
    if(val.slice(-1) === '.'){
      val = this.returnAsCurrency(val.substring(0, val.indexOf('.')))
      this.el.nativeElement.value = val + '.'
    } else {
      val = this.returnAsCurrency(val)
      this.el.nativeElement.value = val
    }
    const delta = val.length - this.prevval.length
    this.prevval = val
    this.inputControl.setValue(parseFloat(val.toString().replace(/[^0-9.]+/g, '')))
    const newcommas = [...val.substring(0, selectpos + delta)].filter(chr => chr === ",").length

    console.log(newcommas, prevcommas)
    this.el.nativeElement?.setSelectionRange(selectpos - prevcommas + newcommas, selectpos - prevcommas + newcommas)
  }
  returnAsCurrency(value: string) {
    let dec = value.indexOf('.') > 0 ? value.length - (value.indexOf('.') + 1) : 0
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: dec <= 2 ? dec : 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value)).replace('$', '')
  }
  @HostListener('focusout', ['$event']) onFocus() {
    this.inputControl.markAsTouched()
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.inputControl.value || this.inputControl.value === 0) {
        this.tranformToCurrency(this.inputControl.value)
      }
    })
  }
  ngOnDestroy() {
    this.unsub.next(true)
    this.unsub.complete()
  }
}