import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService, ComponentCommunicationService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Enums } from '../../models/contract.model';
import { Product } from '../../models/product.type';

@Component({
  selector: 'app-additional-docs',
  templateUrl: './additional-docs.component.html',
  styleUrls: ['./additional-docs.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class AdditionalDocsComponent implements OnInit, OnDestroy {
  @Input() preForm: any;
  @Input() secName: string;
  @Input() docID: string;
  prod: string; enums = new Enums();
  attachValidationText: string; label: string;
  additionalInformation = new UntypedFormControl('');
  products = new Product();
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private activeRoute: ActivatedRoute, public parent: FormGroupDirective,
    private comm: ComponentCommunicationService, private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('additionalInformation', this.additionalInformation);
    this.additionalInformation.setValue(this.preForm.proposalForm?.additionalInformation);
    this.additionalInformation.setErrors({missingSchedules: true});
    this.getProduct();
  }

  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      this.prod = this.pUtil.addSpace(params.get('prod'));
      this.setLabel();
    });
    this.touchListener();
    
  }

  setLabel(){
    if(this.products.typeInArrToCheck(this.prod, this.products.supportedProducts.UTerrorism.typeArr)){
      this.label = "Please provide a Schedule of Values with your submission as a minimum plus any additional documentation to support your application including full details around losses identified above";
    } else if(this.products.typeInArrToCheck(this.prod, this.products.supportedProducts.UConstruction.typeArr)){
      this.label = "Please provide any additional documentation to support your application";
    } else {
      this.label = "Please provide any additional documentation to support your application including full details around losses identified above";
    }
  }

  hasLoss(count: number){
    this.checkValidity(count);
  }

  checkValidity(count?: number){
    if(this.products.typeInArrToCheck(this.prod, this.products.supportedProducts.UTerrorism.typeArr)){
      !count ? this.additionalInformation.setErrors({missingSchedules: true}) : this.additionalInformation.setErrors(null);
      this.parent.form.updateValueAndValidity();
    }
  }

  touchListener() {
    this.comm.markAllAsTouchedListener().pipe(
     takeUntil(this.unsub)
   ).subscribe((bool) => {
     if (bool) {
       this.attachValidationText = 'Please provide a Schedule of Values with your submission as a minimum.'
     }
   });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
