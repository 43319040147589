import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-business-contact-detail',
  templateUrl: './business-contact-detail.component.html',
  styleUrls: ['./business-contact-detail.component.scss']
})
export class BusinessContactDetailComponent implements OnInit {
  @Input() group: UntypedFormGroup
  @Input() data: { businessTel: string, emailAddress: string, contactPerson: string }

  businessTel = new UntypedFormControl('', [Validators.required])
  emailAddress = new UntypedFormControl('', [Validators.required])
  contactPerson = new UntypedFormControl('', [Validators.required])

  constructor(public pUtil : PortalUtilService) { }

  ngOnInit(): void {
    this.setupBusinessContactDetail()
  }

  setupBusinessContactDetail(){
    this.businessTel.setValue(this.data?.businessTel)
    this.emailAddress.setValue(this.data?.emailAddress)
    this.contactPerson.setValue(this.data?.contactPerson)

    this.group.addControl('emailAddress', this.emailAddress)
    this.group.addControl('contactPerson', this.contactPerson)
    this.group.addControl('businessTel', this.businessTel)
  }
}
