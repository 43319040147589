import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateProductTypeBaseComponent, PortalDataService, PortalUtilService } from 'portal';
import { Product } from '../../../shared-resources/models/product.type';

@Component({
  selector: 'app-risk-type-detail',
  templateUrl: './risk-type-detail.component.html',
  styleUrls: ['./risk-type-detail.component.scss'],
})

export class RiskTypeDetailComponent extends CreateProductTypeBaseComponent implements OnInit {
  products = new Product();
  availableProducts = [
    {display:'Cargo' , key: this.products.supportedProducts.UCargo.typeArr, id:'cargoContainer'},
    {display:'Terrorism' , key: this.products.supportedProducts.UTerrorism.typeArr, id:'terrorismContainer'},
    {display:'Fine Art' , key: this.products.supportedProducts.UFineArt.typeArr, id:'fineArtContainer'},
    {display:'Casualty' , key: this.products.supportedProducts.UCasualty.typeArr, id:'casualtyContainer'},
    {display:'Equipment Binder' , key: this.products.supportedProducts.UEquipmentBinder.typeArr, id:'equipmentBinderContainer'},
    {display:'Bourbon' , key: this.products.supportedProducts.UBourbon.typeArr, id:'bourbonContainer'},
    {display:'Construction' , key: this.products.supportedProducts.UConstruction.typeArr, id:'constructionContainer'},
  ]
  constructor(
    public router: Router,
    public portalDataService: PortalDataService,
    public pUtil: PortalUtilService,
  ) { super(router, portalDataService, pUtil) }

  ngOnInit(){
    super.ngOnInit();
  }

  navigate() {
    const productOptions = new Product().findProductType(this.riskType)
    if(productOptions?.newRiskOptions?.length > 0){
      this.router.navigate(['/contracts/additionalOptions'], { queryParams: { option: productOptions?.newRiskOptions[0], product: this.pUtil.rmvSpace(this.riskType)}})
    } else {
      this.router.navigate(['/contracts/updateContract', this.pUtil.rmvSpace(this.riskType), 'newRisk'])
    }
  }

}
