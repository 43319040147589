<div formGroupName="proposalForm">
    <div formGroupName="machineryAndEquipmentLimits"> 
        <div class="machineEquipment">
            <h1 class="header">5. Machine Equipment</h1>
            <div class="row colMargin">
                <div class="col-6 pe-1">
                    <div class="controlInput figureInput">
                        <app-amount formControlName="limitAverageAnyOneConveyance"></app-amount>
                        <label class="controlLabel">
                            Average any Conveyance
                        </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="controlInput figureInput">
                        <app-amount formControlName="limitMaximumValueAnyOneConveyance"></app-amount>
                        <label class="controlLabel">
                            Maximum any Conveyance
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>