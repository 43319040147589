import { BourbonFormComponent } from "../../contract-components/update-contract/bourbon-form/bourbon-form.component";
import { CargoComponent } from "../../contract-components/update-contract/cargo-form/cargo/cargo.component";
import { CasualtyFormComponent } from "../../contract-components/update-contract/casualty-form/casualty-form.component";
import { ConstructionFormComponent } from "../../contract-components/update-contract/construction-form/construction-form.component";
import { EquipmentBinderFormComponent } from "../../contract-components/update-contract/equipment-binder-form/equipment-binder-form.component";
import { FineArtFormComponent } from "../../contract-components/update-contract/fine-art-form/fine-art-form.component";
import { TerrorismFormComponent } from "../../contract-components/update-contract/terrorism-form/terrorism-form.component";
import { ScheduleOfValuesListBourbonComponent } from "../forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-list-bourbon/schedule-of-values-list-bourbon.component";
import { ScheduleOfValuesListFineArtComponent } from "../forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-list-fine-art/schedule-of-values-list-fine-art.component";
import { ScheduleOfValuesListComponent } from "../forms/table-of-schedule-items/schedule-of-values-detail/schedule-of-values-list/schedule-of-values-list.component";
import { Dropdowns } from "./contract.model";

export enum Countries {
    CANADA = 'Canada',
    USA = 'USA'
}

export class Product {
    //typeArr at 0 === active/current product type/key
    supportedProducts : any = {
        UCargo : {
            typeArr: [
                'Underscore Cargo Stock Throughput',
            ],
            cleanName : 'Cargo',
            data: {
                commissionToUserDisplayed: true,
                newRiskOptions : ['RiskCoverageDetail', 'PDFToContract'],
                platformQuoteHeaders : ['Premium', 'Deductibles'],
                prodQuoteDetails : {tria: true, commissionNotStandard: false, brokerageNotStandard: false, quoteSummary: true},
                basisOfValuation: {
                    arr :[
                        {display: 'Selling Price', value:'Selling Price'},
                        {display: 'Replacement Cost', value:'Replacement Cost'},
                    ],
                    header : "Please select the basis of valuation you would require in the event of a loss for your finished goods and ensure values provided at same",
                },
                lossExperience: {
                    riskTypes : ['Stock', 'Transit'],
                    lossTypes : ['Fire', 'Theft', 'CAT', 'Other', 'N/A'],
                },
                popupData: {
                    title:'CONFIRM',
                    message:['Are you sure you want to submit this?'],
                    icon:''
                },
                defaultCurrency: {
                    currencyData : ['USD','CAD'],
                    defaultCur: 'USD'
                },
                addressInfo: {
                    countries: [Countries.USA, Countries.CANADA],
                    defaultAddress: {
                        loc : Dropdowns.states,
                        placeholder1 : "State",
                        placeholder2 : "Zip"
                    },
                    USA: {
                        loc : Dropdowns.states,
                        placeholder1 : "State",
                        placeholder2 : "Zip",
                        defaultCurrency: "USD"
                    },
                    Canada: {
                        loc: Dropdowns.cargoProvince,
                        placeholder1 : "Province",
                        placeholder2 : "Postal Code",
                        defaultCurrency: "CAD"
                    }
                },
                icons: {
                    PDFToContract : 'cargoContainerPDF'
                },
                component: CargoComponent,
                scheduleOfValues: ScheduleOfValuesListComponent
            },
        },
        UBourbon : {
            typeArr: [
                'Underscore Bourbon',
            ],
            cleanName : 'Bourbon',
            data: {
                commissionToUserDisplayed: true,
                newRiskOptions : ['PDFToContract'],
                platformQuoteHeaders : ['Premium', 'DEDUCTIBLE CLAUSE'],
                prodQuoteDetails : {tria: true, commissionNotStandard: false, brokerageNotStandard: false, quoteSummary: true},
                basisOfValuation: {
                    arr :[
                        {display: 'Selling Price', value:'Selling Price'},
                        {display: 'Replacement Cost', value:'Replacement Cost'},
                    ],
                    header : "Please select the basis of valuation you would require in the event of a loss for your finished goods and ensure values provided at same",
                },
                lossExperience: {
                    riskTypes : ['Stock', 'Transit'],
                    lossTypes : ['Fire', 'Theft', 'CAT', 'Other', 'N/A'],
                },
                popupData: {
                    title:'CONFIRM',
                    message:['Are you sure you want to submit this?'],
                    icon:''
                },
                defaultCurrency: {
                    currencyData : ['USD','CAD'],
                    defaultCur: 'USD'
                },
                addressInfo: {
                    countries: [Countries.USA, Countries.CANADA],
                    defaultAddress: {
                        loc : Dropdowns.states,
                        placeholder1 : "State",
                        placeholder2 : "Zip"
                    },
                    USA: {
                        loc : Dropdowns.states,
                        placeholder1 : "State",
                        placeholder2 : "Zip",
                        defaultCurrency: "USD"
                    },
                    Canada: {
                        loc: Dropdowns.cargoProvince,
                        placeholder1 : "Province",
                        placeholder2 : "Postal Code",
                        defaultCurrency: "CAD"
                    }
                },
                icons: {
                    PDFToContract : 'bourbonPDF'
                },
                component: BourbonFormComponent,
                scheduleOfValues: ScheduleOfValuesListBourbonComponent
            },
        },
        UTerrorism: {
            typeArr: [
                'Underscore Terrorism',
            ],
            cleanName : 'Terrorism',
            data : {
                commissionToUserDisplayed: true,
                newRiskOptions : [],
                platformQuoteHeaders : ['Premium', 'Deductibles'],
                prodQuoteDetails : {tria: false, commissionNotStandard: false, brokerageNotStandard: false, quoteSummary: true},
                basisOfValuation: {
                    arr :[
                        {display: 'Selling Price', value:'Selling Price'},
                        {display: 'Replacement Cost', value:'Replacement Cost'},
                    ],
                    header : "Please select the basis of valuation you would require in the event of a loss for your finished goods and ensure values provided at same",
                },
                lossExperience: {
                    riskTypes : [],
                    lossTypes : ['Fire', 'Theft', 'CAT', 'Other', 'N/A'],
                },
                popupData: {
                    title:'A quick check ...', 
                    message:['Have you uploaded the Schedule of Values?', 'If you have, great, thanks! Otherwise, please return to Section 5 of the form to upload the attachments.'],
                    icon:'sovSubmitLogo'
                },
                defaultCurrency: {
                    currencyData : ['USD'],
                    defaultCur: 'USD'
                },
                addressInfo: {
                    countries: [Countries.USA],
                    defaultAddress: {
                        loc: Dropdowns.terrStates,
                        placeholder1 : "State",
                        placeholder2 : "Zip"
                    }
                },
                component: TerrorismFormComponent
            },
        },
        UFineArt: {
            typeArr: [
                'Underscore Fine Art',
            ],
            cleanName : 'Fine Art',
            data: {
                commissionToUserDisplayed: true,
                newRiskOptions : [],
                platformQuoteHeaders : ['Premium', 'Deductibles'],
                prodQuoteDetails : {tria: true, commissionNotStandard: false, brokerageNotStandard: false, quoteSummary: true},
                basisOfValuation: {
                    dealer :[
                        {display: 'Selling Price Less 20%', value:'Selling Price Less 20%'},
                        {display: 'Cost Plus 30%', value:'Cost Plus 30%'},
                    ],
                    collector :[
                        {display: 'Scheduled Value', value:'Scheduled Value'},
                        {display: 'Fair Market Value', value:'Fair Market Value'},
                    ],
                    header : "Please select the basis on which the values have been declared. Where both valuations have been provided please ensure it is the greater of the two that are inputted within this questionnaire",
                },
                lossExperience: {
                    riskTypes : [],
                    lossTypes : ['Theft', 'Accidental Damage', 'Fire', 'Water Damage', 'Transit', 'Other'],
                },
                popupData: {
                    title:'CONFIRM', 
                    message:['Are you sure you want to submit this?'],
                    icon:'',
                },
                defaultCurrency: {
                    currencyData : ['USD'],
                    defaultCur: 'USD'
                },
                addressInfo: {
                    countries: [Countries.USA],
                    defaultAddress: {
                        loc: Dropdowns.states,
                        placeholder1 : "State",
                        placeholder2 : "Zip"
                    }
                },
                component: FineArtFormComponent,
                scheduleOfValues: ScheduleOfValuesListFineArtComponent
            },
        },
        UCasualty: {
            typeArr: [
                'Underscore Casualty',
            ],
            cleanName : 'Casualty',
            data: {
                commissionToUserDisplayed: false,
                newRiskOptions : [],
                platformQuoteHeaders : ['Premium', 'Deductibles', 'LimitOfLiability', 'ENDORSEMENTS ATTACHED TO THIS POLICY'],
                prodQuoteDetails : {tria: false, commissionNotStandard: true, brokerageNotStandard: false, quoteSummary: true},
                popupData: {
                    title:'CONFIRM', 
                    message:['Are you sure you want to submit this?'],
                    icon:'',
                },
                defaultCurrency: {
                    currencyData : ['CAD','USD'],
                    defaultCur: 'CAD'
                },
                addressInfo: {
                    countries: [Countries.CANADA],
                    defaultAddress: {
                        loc: Dropdowns.province,
                        placeholder1 : "Province",
                        placeholder2 : "Postal Code"
                    }
                },
                component: CasualtyFormComponent
            },
        },
        UEquipmentBinder: {
            typeArr: [
                'Underscore Equipment Binder',
            ],
            cleanName : 'Equipment Binder',
            data: {
                commissionToUserDisplayed: false,
                newRiskOptions : [],
                platformQuoteHeaders : ['DEDUCTIBLES/ RETENTIONS:', 'DEPOSIT PREMIUM:', 'FLAT OR FULLY EARNED PREMIUMS/RATES:', 'PREMIUM ADJUSTMENT RATES:'],
                prodQuoteDetails : {tria: false, commissionNotStandard: true, brokerageNotStandard: true, quoteSummary: false},
                popupData: {
                    title:'CONFIRM', 
                    message:['Are you sure you want to submit this?'],
                    icon:'',
                },
                defaultCurrency: {
                    currencyData : ['CAD'],
                    defaultCur: 'CAD'
                },
                addressInfo: {
                    countries: [Countries.CANADA],
                    defaultAddress: {
                        loc: Dropdowns.province,
                        placeholder1 : "Province",
                        placeholder2 : "Postal Code"
                    }
                },
                lossExperience: {
                    equipmentTypes : ['Backhoe / Road Builder', 'Chipper', 'Drill Rig', 'Feller Buncher', 'Grade Bulldozer', 
                    'Hulls', 'Loader / Hoe Chucker', 'Processor', 'Skidder', 'Trucks & Trailers', 'Winch Cat', 'Yarder', 'Misc Equipment / tools', 'Other',],
                    lossTypes : ['Fire', 'Rollover', 'Collision / RTA', 'Theft/Vandalism/Sabotage', 'Hull', 'MTC', 'FFE', 'Other', 'Not specified'],
                },
                component: EquipmentBinderFormComponent
            },
        },
        UConstruction: {
            typeArr: [
                'Underscore Construction',
            ],
            cleanName : 'Construction',
            data: {
                commissionToUserDisplayed: false,
                newRiskOptions : [],
                platformQuoteHeaders : ['Premium', 'Deductibles'],
                prodQuoteDetails : {tria: true, commissionNotStandard: false, brokerageNotStandard: false, quoteSummary: true},
                popupData: {
                    title:'CONFIRM', 
                    message:['Are you sure you want to submit this?'],
                    icon:'',
                },
                defaultCurrency: {
                    currencyData : ['CAD'],
                    defaultCur: 'CAD'
                },
                addressInfo: {
                    countries: [Countries.CANADA],
                    defaultAddress: {
                        loc: Dropdowns.province,
                        placeholder1 : "Province",
                        placeholder2 : "Postal Code"
                    }
                },
                component: ConstructionFormComponent
            },
        },
    };

    mrcHeadingKeys = {
        "Premium" : 'Premium',
        "Deductibles" : 'Deductibles',
        "LimitOfLiability" : 'Limits of Liability',
        "ENDORSEMENTS ATTACHED TO THIS POLICY" : 'Endorsement',
        "DEPOSIT PREMIUM:" : 'DEPOSIT PREMIUM',
        "DEDUCTIBLES/ RETENTIONS:" : 'Deductibles',
        "FLAT OR FULLY EARNED PREMIUMS/RATES:" : 'FLAT OR FULLY EARNED PREMIUMS',
        "PREMIUM ADJUSTMENT RATES:" : 'PREMIUM ADJUSTMENT RATES',
        "DEDUCTIBLE CLAUSE": 'Deductibles'
    }

    userProductIncludesType(productArr: string[], typeArr: string[]) : boolean {
        return productArr?.filter((product : any) => {
            if(typeof product === 'object'){
              return typeArr.filter(prod => prod === product.name).length > 0
            } else {
              return typeArr.filter(prod => prod === product).length > 0
            }
        }).length > 0;
    }

    typeInArrToCheck(productType: string , typeArr: string[]) : boolean {
        return typeArr.includes(productType);
    }

    findProductType(productType: string){
        return this.findProduct(productType)?.data;
    }

    findProduct(productType: string){
        let product;
        for (let key in this.supportedProducts) {
            if(this.typeInArrToCheck(productType, this.supportedProducts[key].typeArr)){
                product = this.supportedProducts[key];
                break
            }
        }
        return product;
    }
}
