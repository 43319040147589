<div class="coverageList pt-5 colMargin d-flex">
    <div class="list">
        <div *ngFor="let figure of figures; let i=index">
            <div class="checkBox pb-3">
                <label class="container pointer">
                    <input (click)='selectFigure(figure.key)' name='lossOfUseFigure' class="splitCheckBox" type="radio" [id]="'Figure:'+figure.key" #inp> 
                    <span class="checkmark"></span>
                    <label class="pointer" [for]="'Figure:'+figure.key">{{figure.display}}</label>
                </label> 
            </div>
        </div>
        <div class="checkBox pb-3">
            <label class="container pointer">
                <input (click)='selectOther()' name='lossOfUseFigure' class="splitCheckBox" type="radio" id='lossOfUseOther' #inp> 
                <span class="checkmark"></span>
                <label class="pointer" for="lossOfUseOther">Other</label>
            </label> 
        </div>
    </div>
</div>

<div *ngIf="otherDetail" class="col-lg-6 col-md-7 col-sm-8 col-xs-12 col-xss-12">
    <div class="colMargin pt-3">
        <app-amount name="Amount" [required]="true" [formControl]="control"></app-amount>
    </div>
</div>

<label class="additionalPremText mt-4">*an additional premium will apply to limits above $20,000</label>