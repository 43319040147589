import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'pt-login-base',
  templateUrl: './login-base.component.html',
  styleUrls: ['./login-base.component.css']
})
export class LoginBaseComponent implements OnInit {

  hidden = true;
  isSupportedBrowser= true;

  constructor(
      public route: ActivatedRoute,
      public authService: AuthService,
      public location: Location
  ) { }

  ngOnInit(): void {
      this.checkBrowser();
      this.route.queryParams.pipe(
          first()
      ).subscribe(p => {
          // this param will be set when the openid provider redirects back to us.
          if(p['code']) {
              this.authService.finishLoginProcess(p['code'], p['state']);
          } else {
              this.authService.isLoggedIn.pipe(
                  first()
              ).subscribe((loggedIn: boolean) => {
                  if(loggedIn) {
                      this.authService.redirectAfterLogin();
                  } else {
                      this.hidden = false;
                  }
              });
          }
          // clear query params
          this.location.replaceState(this.location.path().split('?')[0], '');
      });
  }

  public checkBrowser(){
      console.log(window.navigator.userAgent)
      if (/MSIE|Trident/.test(window.navigator.userAgent) ) {
          this.isSupportedBrowser = false;
          alert("We're sorry but your browser isn't supported. To access Underscore please use a newer browser, like Chrome, Edge or Firefox.")
      }
  }

  public clickLoginButton() {
      this.authService.goToOpenIDLoginPage();
  }

}
