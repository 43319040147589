<div formGroupName="proposalForm">
    <div formGroupName="transitLimits">
        <h1 class="header">4. Transit Risk</h1>
        <h2 class="subHeader">4.1 Limits</h2>
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 ps-0 pe-4 colMargin mt-4 colMargin">
                <app-amount [required]="true" [name]="'Average value on any one conveyance'" 
                        formControlName="limitAverageAnyOneConveyance"></app-amount>
        </div>
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 ps-0 pe-4 colMargin mt-3 colMargin">
                <app-amount [required]="true" [name]="'Maximum value on any one conveyance'" 
                        formControlName="limitMaximumValueAnyOneConveyance"></app-amount>
        </div>
    </div>
</div>


