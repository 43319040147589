import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective, ControlContainer, UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';
import { merge, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Countries } from '../../../models/product.type';
import { SVTotalSales } from '../../../models/typing';

@Component({
  selector: 'app-sales1',
  templateUrl: './sales1.component.html',
  styleUrls: ['./sales1.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class Sales1Component implements OnInit, OnDestroy {
  @Input() preForm: any;
  @Input() set disabled(value: boolean) {
    if(value || value === false) {
      this.setState(value);
    }
  }
  countries = ['USA','Rest of the World'];
  unsub: Subject<boolean> = new Subject<boolean>();
  public optionOne = new UntypedFormGroup({
    percentageSubcontracted: new UntypedFormControl(''),
    sales : new UntypedFormArray([
    ]),
  });
  constructor(private parent: FormGroupDirective, private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('totalSalesRevenues') as UntypedFormGroup).addControl('optionOne', this.optionOne);
    this.setupSales();
    (this.optionOne?.get('sales') as UntypedFormArray)?.at(0)?.get('country')?.setValue(Countries.CANADA);
  }

  setupSales(){
    const totalSales = this.preForm.proposalForm?.totalSalesRevenues?.optionOne;
    if(totalSales){
      totalSales.sales.forEach ((sale: SVTotalSales) => {
        this.setupSaleItem(sale);
      });
    }
    while(this.getSalesControls().length < 2){
      this.addSales();
    }
    this.optionOne.get('percentageSubcontracted')?.patchValue(totalSales?.percentageSubcontracted);
  }

  setupSaleItem(sale: SVTotalSales){
    let control = this.addSales();
    control.patchValue({
      country: sale?.country,
      expiringEstimated: sale?.expiringEstimated,
      actualRevenue: sale?.actualRevenue,
      renewalEstimate: sale?.renewalEstimate
    })
  }

  addSales() {
    const salesArr = (this.optionOne.get('sales') as UntypedFormArray);
    const dis = this.optionOne.disabled;
    const newSale = new UntypedFormGroup({
      country: new UntypedFormControl({value: '', disabled: dis} , salesArr.length === 0 ? [Validators.required] : []),
      expiringEstimated: new UntypedFormControl({value: '', disabled: dis}, salesArr.length === 0 ? [Validators.required] : []),
      actualRevenue: new UntypedFormControl({value: '', disabled: dis}, salesArr.length === 0 ? [Validators.required] : []),
      renewalEstimate: new UntypedFormControl({value: '', disabled: dis}, salesArr.length === 0 ? [Validators.required] : []),
    });
    salesArr.push(newSale);
    this.onValueChanges();
    return newSale;
  }

  removeSales(i: number) {
    (this.optionOne.get('sales') as UntypedFormArray).removeAt(i);
    this.onValueChanges();
  }

  getSalesControls(){
    return (this.optionOne.get('sales') as UntypedFormArray).controls;
  }

  setState(disabled: boolean){
    disabled ? this.optionOne.disable() : this.optionOne.enable();
  }

  onValueChanges(): void {
    this.complete();
    this.unsub = new Subject<boolean>();
    const sales = this.optionOne?.get('sales') as UntypedFormArray;
    merge(...sales.controls.map((control: AbstractControl, index: number) =>
    control.valueChanges.pipe(map(value => ({ rowIndex: index, value })))))
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe(changes => {
      const sale = sales.at(changes.rowIndex);
      const cl = changes.value;
      if(changes.rowIndex > 0){
        this.checkIfRowRequired(cl, changes.rowIndex);
      }
    });
  }

  checkIfRowRequired(val: SVTotalSales, rowIndex: number){
    const control = (this.optionOne?.get('sales') as UntypedFormArray)?.at(rowIndex) as UntypedFormGroup;
    this.simpleObject(control.controls, val);
  }

  simpleObject(obj: any, val: SVTotalSales) {
    const required = this.pUtil.isEmpty(val, true);
    const isComplete = this.pUtil.isComplete(val, true);
    Object.keys(obj).map((key, index) => {
      required || isComplete || this.checkObject(obj[key]) ?
      obj[key].setErrors(null, {emitEvent: false, onlySelf: true}) :
      obj[key].setErrors({'needRowFilled': true},{emitEvent: false, onlySelf: true});
    });
  }

  checkObject(val: AbstractControl){
    return val?.value?.figure || val?.value?.figure === 0 || (typeof val?.value !== 'object' && val?.value)
  }

  complete() {
    this.unsub.next(true);
    this.unsub.complete();
  }
  
  ngOnDestroy() {
    this.complete();
  }

}
