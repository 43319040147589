<app-top-nav></app-top-nav>

<ng-container *ngIf="option === 'RiskCoverageDetail'">
    <div class="productOptions">
        <h2>Please be aware that should you choose to proceed you confirm that you do not require coverage for any of the following:</h2>
        <div class="mainContent">
            <div class="list mt-3" *ngIf="coverageList">
                <div *ngFor="let coverage of coverageList; let i=index">
                    <label class="pb-2 pt-1">{{coverage}}</label>
                </div>
            </div>
        </div>
        <div class="productOptionButtons">
            <button [routerLink]="['/contracts/riskType']" class="button largeButton me-3">BACK</button>
            <button (click)="navigate()" class="button largeButton primary">AGREE</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="option === 'PDFToContract'">
    <app-pdf-to-contract (navigateEmitter)="navigate()" [product]="product"></app-pdf-to-contract>
</ng-container>