<div class="ouraccordion" *ngIf="familyTree">
    <div class="accordionItem">
      <input type="checkbox" id="treeAccordionCheckbox">
      <div class="accordionText">
        <label for="treeAccordionCheckbox" class="accordionLabel pointer">Renewals History</label>
        <label for="treeAccordionCheckbox" class="accordionCaret pointer" id="treeAccordionCaret">&#x276F;</label>
      </div>
      <div class="accordionBody">
        <app-family-tree-level *ngIf="familyTree" [tree]="familyTree.children" [auxData]="familyTree.auxData"></app-family-tree-level>
      </div>
    </div>
</div>