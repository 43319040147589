<div class="d-flex align-items-center downloadButtonWrapper">
    <button title="Import a completed template file" type="button" (click)="openFile()" class="downloadButton">
        <span class="d-flex align-items-center justify-content-center" *ngIf="!spin">
            Import
            <div id="sovIcon"></div>
        </span>
        <i *ngIf="spin"><mat-spinner diameter="20"></mat-spinner></i>
    </button>
    <button title="Download a blank version of the template" class="downloadButton d-flex align-items-center justify-content-center" (click)="downloadSOV()">
        Template
        <div id="downloadIcon"></div>
    </button>
    <input #uploadButton type="file" style="display:none" (change)="fileChange($event)"
            accept=".xlsx, .xls, .csv">
</div>