<div class="d-flex col-12" [formGroup]="group">
    <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'emailAddress')}">
        <input class="controlFull" type="text" formControlName="emailAddress" placeholder="Email Address" domControl>
        <label class="controlLabel">
          E-mail Address*
        </label>
    </div>

    <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'contactPerson')}">
        <input class="controlFull" type="text" formControlName="contactPerson" placeholder="Contact Person" domControl>
        <label class="controlLabel">
          Contact Person*
        </label>
    </div>

    <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(group,'businessTel')}">
        <input class="controlFull" type="text" formControlName="businessTel" placeholder="Business Tel no" domControl>
        <label class="controlLabel">
          Business Tel*
        </label>
    </div>
</div>