import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PortalDataService } from '../../../../services/portal-data.service';
import { Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { PTEnvironment } from '../../../../model/typings';

@Component({
  selector: 'pt-admin-logo-set',
  templateUrl: './admin-logo-set.component.html',
  styleUrls: ['./admin-logo-set.component.css']
})
export class AdminLogoSetComponent implements OnInit {
  @Inject('env') private environment: PTEnvironment
  OB = Object;
  searchTermSubject = new Subject<string>()
  searchTerm = '';
  addresses : any[] = []
  corp: {[key:string]:any} = {};
  constructor(
    private sanitizer: DomSanitizer,
    private portalDataService: PortalDataService
    ) { }

  ngOnInit(): void {
    this.portalDataService.getRegisteredUsers().pipe(first()).subscribe((usersData: any) => {
      usersData.users.forEach((user: any) => {
        const company = user.email.split('@').pop();
        this.corp[company] = {};
      });
      this.addresses = Object.keys(this.corp);
      this.addresses.forEach((company)=>{
        this.portalDataService.getAdminCorpLogos(company).pipe(first()).subscribe((data: any) => {
          if (data.type === 'application/octet-stream') {
            const reader = new FileReader();
            //console.log(data);
            reader.readAsDataURL(data);
            reader.onload = () => {
              if(reader.result){
                this.corp[company].holdingfile = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
              }
            };
          }
        });
      });
    });

    this.searchTermSubject.pipe(
      debounceTime(100)
    ).subscribe((term: any) => {
      this.addresses =  Object.keys(this.corp).filter(key => key.includes(term));
    });
  }

  searchTermChange($event: any) {
    this.searchTermSubject.next(this.searchTerm)
  }

  chooseFile(event: any, email: string) {
    //console.log(event.target.files);
    this.corp[email].file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.corp[email].holdingfile = reader.result;
    };
  }

  setMissing(email: string) {
    if(this.corp?.[email].missing) {
      this.corp[email].missing = true;
    }
  }

  postLogo(email: string) {
    const formData: FormData = new FormData();
    formData.append('file', this.corp[email].file);
    this.portalDataService.postLogo(email, formData) .pipe(
    ).subscribe(() => {
      this.corp[email].file = undefined;
    });
  }
}

