import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PortalDataService } from '../../../../../services/portal-data.service';
import { PortalUtilService } from '../../../../../services/portal-util.service';

@Component({
  selector: 'pt-register-users-base',
  templateUrl: './register-users-base.component.html',
  styleUrls: ['./register-users-base.component.css']
})
export class RegisterUsersBaseComponent implements OnDestroy {
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService, public pUtil: PortalUtilService) { }

  registerUserOnSovapi(userdetails: any){
    this.portalDataService.registerNewUser(userdetails)
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) => {
      if(response.error){
        this.pUtil.errorPopup({status:'While trying to register user', message:response?.errorDescription});
      } else{
        alert("User has been registered");
      }
    });
  }

  ngOnDestroy(){
    this.unsub.next(true);
    this.unsub.complete();
  }

}
