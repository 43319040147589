import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ComponentCommunicationService, PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loss-experience-upload',
  templateUrl: './loss-experience-upload.component.html',
  styleUrls: ['./loss-experience-upload.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ],
})
export class LossExperienceUploadComponent implements OnInit, OnDestroy {
  @Input() secName: string;
  @Input() docID: string;
  hasLosses = new UntypedFormControl(false);
  attachValidationText: string;
  attachmentCount: number;
  @ViewChild('lossHistory') lossHistory: ElementRef<HTMLElement>;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public parent: FormGroupDirective, private comm: ComponentCommunicationService, private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('lossExperience', this.hasLosses);
    this.touchListener();
  }

  toggleIfLossExists(){
    if(this.hasLosses.value && this.attachmentCount){
      (this.lossHistory.nativeElement as HTMLInputElement).checked = true;
      this.pUtil.errorPopup({status:'Caution', message: 'Please remove loss history attachments before proceeding'});
    } else{
      this.hasLosses.setValue(!this.hasLosses.value);
    }
    this.checkValidity(this.attachmentCount);
  }

  hasLoss(count: number){
    this.attachmentCount = count;
    if(count && this.hasLosses.value === false){
      this.hasLosses.setValue(true);
    }
    this.checkValidity(count);
  }

  checkValidity(count: number){
    (!count && this.hasLosses.value === true) ?
    this.hasLosses.setErrors({missingLossHistory: true}) :
    this.hasLosses.setErrors(null);
    this.parent.form.updateValueAndValidity();
  }

  touchListener() {
    this.comm.markAllAsTouchedListener().pipe(
     takeUntil(this.unsub)
   ).subscribe((bool) => {
     if (bool) {
       this.attachValidationText = 'Please provide loss history for the past 5 years as an attachment.';
     }
   });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
