import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { PortalUtilService } from '../../../services/portal-util.service';

@Component({
  selector: 'pt-date-picker-base',
  templateUrl: './date-picker-base.component.html',
  styleUrls: ['./date-picker-base.component.css']
})

export class DatePickerBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dateControl: UntypedFormControl;
  @Input() date: string;
  matControl = new UntypedFormControl('');
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.setupDatePicker();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.date?.currentValue && !isNaN(changes.date.currentValue.getTime())) {
      this.matControl.setValue(changes.date.currentValue);
    }
  }

  onValueChanges(): void {
    this.matControl.valueChanges
    .pipe(
    )
    .subscribe((changes: Date) => {
      if(changes){
        this.dateControl.setValue(this.pUtil.returnDateStringUTC(changes));
      } else {
        this.dateControl.setValue('')
      }
      this.matControl.errors || (!this.matControl.value && this.dateControl.errors?.required) ? 
      this.dateControl.setErrors({wrongDate:true}) : this.dateControl.setErrors(null);
    });
  }

  setupDatePicker(){
    this.matControl.setValue(this.dateControl.value ?
    this.pUtil.returnISOToDate(this.dateControl.value) : this.dateControl.setValue(''));
    this.onValueChanges();
    this.dateControl.updateValueAndValidity();
  }

  touch(){
    this.dateControl.markAsTouched();
  }

  ngOnDestroy(){
    this.unsub.next(true);
    this.unsub.complete();
  }

}
