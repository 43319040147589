<div formGroupName="proposalForm">
    <div formGroupName="generalQuestions">
        <h1 class="header">7. General Questions</h1>
        <div class="questions colMargin">
            <div class="row">
                <div class="col-12 mb-4">
                    <p>Are any Waivers of subrogation provided to any third parties?</p>
                    <div class="d-flex">
                        <span>No</span>
                        <label class="switch me-3 ms-3">
                            <input (click)="waiverControl()" type="checkbox" [attr.checked]="generalQuestions.get('anyWaiversOfSubrogationDetails')?.value ? true : null">
                            <span class="slider"></span>
                        </label>
                        <span>Yes</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="controlInput">
                        <textarea id="informationBox" type="text" formControlName="anyWaiversOfSubrogationDetails" placeholder="Further Information..."></textarea>
                        <label class="controlLabel">
                            If yes, please provide further information below
                        </label>
                    </div>
                    <app-error-handler [control] = 'generalQuestions'></app-error-handler>
                </div>
            </div>
        </div>
    </div>
</div>