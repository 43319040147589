import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserAgreementPopupComponent } from '../../shared-resources/popups/user-agreement-popup/user-agreement-popup.component';
import { ContractListBaseComponent, PortalDataService, PortalUtilService, PTTerms, PTResponse, ContractsService } from 'portal';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})

export class ContractListComponent extends ContractListBaseComponent implements OnInit {

  constructor( public router: Router, public dialog: MatDialog,
     public pUtil: PortalUtilService, public portalDataService: PortalDataService,
     public contractsService: ContractsService
  ) { super(router, dialog, pUtil, portalDataService, contractsService) }

  ngOnInit() {
    this.checkIfTermsAccepted();
  }

  userAgreementPopup() {
    this.dialog.open(UserAgreementPopupComponent, {
      panelClass: 'userAgreement-dialog-container',
      autoFocus: false
    })
  }

  checkIfTermsAccepted(){
    this.portalDataService.termsAccepted()
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: PTTerms | PTResponse) => {
      if((response as PTResponse)?.error){
        this.userAgreementPopup();
      }
    });
  }
  
}
