<div *ngIf="optionOne.enabled" formGroupName="proposalForm" class="colMargin pb-5">
    <div formGroupName="totalSalesRevenues">
        <div formGroupName="optionOne">
            <div formArrayName="sales">
                <div class="salesRow operationResponsiveMargin" *ngFor="let sales of getSalesControls(); let i=index" [formGroupName] = "i">
                    <div [ngClass]="{'missingField' : sales?.get('country')?.errors?.needRowFilled}" class="totalSalesSelect">
                        <div class="controlInput customSelect">
                            <mat-select class="dropdown text" formControlName="country"  #country>
                                <mat-option *ngIf="i === 0" value="Canada">Canada</mat-option>
                                <mat-option [ngClass]="{'hide' : i === 0}" *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
                            </mat-select>
                            <label class="controlLabel">
                                Country<span *ngIf="i === 0">*</span>
                            </label>
                        </div>
                    </div>
                    <div [ngClass]="{'missingField' : sales?.get('expiringEstimated')?.errors?.needRowFilled}" class="totalSalesFigure">
                        <app-amount [required]='i === 0 ? true : false' [name]="'Expiring Estimated'" formControlName="expiringEstimated"></app-amount>
                    </div>
                    <div [ngClass]="{'missingField' : sales?.get('actualRevenue')?.errors?.needRowFilled}" class="totalSalesFigure">
                        <app-amount [required]='i === 0 ? true : false' [name]="'Actual Revenue'" formControlName="actualRevenue"></app-amount>
                    </div>
                    <div [ngClass]="{'missingField' : sales?.get('renewalEstimate')?.errors?.needRowFilled}" class="totalSalesFigure">
                        <app-amount [required]='i === 0 ? true : false' [name]="'Renewal Estimate'" formControlName="renewalEstimate"></app-amount>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-end">
                    <span *ngIf="getSalesControls().length > 1">
                        <button  class="button smallButton" (click)="removeSales(getSalesControls().length - 1)">Remove  -</button>
                    </span>
                    <span class="ms-1">
                        <button class="button largeButton" (click)="addSales()">Add  +</button>
                    </span>
                </div>
            </div>
            <div>
                <h5 class="boldListHeader pt-4">PERCENTAGE OF WORK SUBCONTRACTED</h5>
                <div class="conveyance">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-5 ps-0">
                        <div class="controlInput">
                            <input [inputControl]="optionOne.get('percentageSubcontracted')" pt-appPercentage>
                            <label class="controlPercentageLabel">
                                %
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>