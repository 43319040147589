import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-component-four',
  templateUrl: './coverage-component-four.component.html',
  styleUrls: ['./coverage-component-four.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class CoverageComponentFourComponent implements OnInit, OnChanges {
  @Input() preForm: any;
  @Input() typeArr: [string];
  @Input() set disabled(value: boolean) {
   if(value || value === false) {
     this.setState(value)
   }
  }
  underlyingInsurance = new UntypedFormArray([]);
  constructor(private parent: FormGroupDirective, public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('coverageRequired') as UntypedFormGroup)?.addControl('underlyingInsurance', this.underlyingInsurance);
    this.setupUnderlyingInsurance();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes?.typeArr){
      this.checkType();
    }
  }

  checkType(){
    this.getInsurance().filter((s) => {
      if(!this.typeArr.includes(s.get('type')?.value)) {
        s.get('type')?.setValue('');
      }
    })
  }

  setupUnderlyingInsurance(){
    const underlyingInsurance = this.preForm?.proposalForm?.coverageRequired?.underlyingInsurance;
    if(underlyingInsurance){
      underlyingInsurance.forEach((insurance: { insuranceLimit: string; type: string; insurer: string; }) => {
        const control = this.addInsuranceControl();
        control.patchValue({
          insuranceLimit: insurance?.insuranceLimit,
          type: this.typeArr.includes(insurance?.type) ? insurance?.type : '',
          insurer: insurance?.insurer,
        })
      });
    } else {
      for(let i=0; i < 2;i++){ this.addInsuranceControl() }
    }
  }

  getInsurance(){
    return this.underlyingInsurance.controls;
  }

  addInsuranceControl(){
    const formGroup = new UntypedFormGroup({
      insuranceLimit : new UntypedFormControl(),
      type : new UntypedFormControl('' , [Validators.required]),
      insurer : new UntypedFormControl('' , [Validators.required]),
    });
    this.underlyingInsurance.push(formGroup);
    return formGroup;
  }

  removeInsuranceControl(i: number) {
    this.underlyingInsurance.removeAt(i);
  }

  setState(disabled: boolean){
    disabled ? this.underlyingInsurance.disable() : this.underlyingInsurance.enable();
  }

}
