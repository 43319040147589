import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contract } from 'projects/portal/src/lib/model/models';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PortalDataService } from '../../../../../services/portal-data.service';

@Component({
  selector: 'pt-risk-management-base',
  templateUrl: './risk-management-base.component.html',
  styleUrls: ['./risk-management-base.component.css']
})
export abstract class RiskManagementBaseComponent implements OnInit, OnDestroy {
  contracts: Contract[] = [];
  search = false;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService) { }

  ngOnInit(): void {
    this.loadManagerRisks();
  }

  loadManagerRisks() {
    this.manageRisks();
    const time = interval(1 * 60 * 1000);
    time.pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      this.pollManagerRisks();
    });
  }

  manageRisks() {
    this.search = false;
    this.portalDataService.manageRisks()
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) => {
      this.contracts = response;
    });
  }

  searchRisk(searchParam: string){
    searchParam ? this.search = true : this.search = false;
    this.portalDataService.managerSearchForRisk(searchParam)
    .pipe(
      takeUntil(this.unsub),
    )
    .subscribe((response: any) => {
      this.contracts = response;
    });
  }

  abstract pollManagerRisks() : void;

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
