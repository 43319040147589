import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../models/product.type';

@Pipe({
  name: 'cleanProduct'
})
export class CleanProductPipe implements PipeTransform {
  prod = new Product();
  transform(data: string): string {
    return this.formatString(data);
  }
  formatString(s: string) {
    const cleanName = this.prod.findProduct(s)?.cleanName
    if(cleanName){
      return cleanName
    }
    return s
  }
  
}
