<div *ngIf='gui' class= 'userAgreement underscorePopup'>
    <div class= ''>
        <span><h3 mat-dialog-title class="dialogTitle">
            <span *ngFor='let content of gui.heading'>{{content}}<br></span>
        </h3></span>
    </div>
    <mat-dialog-content>
        <div class="col-lg-9 col-md-10 col-sm-11 col-center">
            <p class='content' *ngFor='let content of gui.body'>
                {{content}}
            </p>
            <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-xxs-1 content m-0 p-0">
                <div class='col mt-2' *ngFor='let footer of gui.footers'>
                    <div *ngIf="footer[1] ; else sec">
                        <span class="d-flex justify-content-center align-items-end footerInput">{{footer[0]}}</span>
                        <div class='footerLine'></div>
                        <span class="d-flex justify-content-center mt-1">{{footer[1]}}</span>
                    </div>
                    <ng-template #sec>
                        <div class='footerLine footerPadding'></div>
                        <span class="d-flex justify-content-center mt-1">{{footer[0]}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div class="d-flex justify-content-end mt-4 me-4">
        <button mat-button (click)="declineToQuote()" class="roboto declineButton me-3">Return to Previous Screen</button>
        <button mat-button (click)="acceptToQuote()" class= "roboto createButton">Acknowledge and Proceed</button>
    </div>
</div>