<div [formGroup]="amount" [ngClass]="{'ng-invalid' : amount?.invalid && !_disabled}" domControl>
    <div class="figure">
        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(amount,'figure') && !disabled}" class="controlInput figureInput">
            <span *ngIf="amount.get('currency')?.value !== '%'"><input pt-appCurrency [inputControl]="amount.get('figure')" [disabled]= 'disabled' class="amount" type="text" placeholder="Amount" #figure></span>
            <span *ngIf="amount.get('currency')?.value === '%'"><input pt-appPercentage [inputControl]="amount.get('figure')" [disabled]= 'disabled' class="amount" type="text" placeholder="Percentage"></span>
            <label class="controlLabel">
                <span *ngIf="name===0; else prev">Current Year</span>
                <ng-template #prev><span *ngIf="name===1; else two">Previous Year</span></ng-template>
                <ng-template #two><span *ngIf="name===2; else three">Two Years Ago</span></ng-template>
                <ng-template #three><span *ngIf="name===3; else four">Three Years Ago</span></ng-template>
                <ng-template #four><span *ngIf="name===4; else label">Four Years Ago</span></ng-template>
                <ng-template #label>{{name}}</ng-template>
                <span *ngIf='required'>*</span>
            </label>
        </div>
        <mat-select tabindex="-1" class="dropdown currency centerText" formControlName="currency">
            <mat-option *ngFor="let c of currency" [value]="c">{{c}}</mat-option>
        </mat-select>
    </div>
</div>
