import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ComponentCommunicationService, PortalUtilService } from 'portal';
import { Interest } from '../../../models/contract.model';

@Component({
  selector: 'app-policy-type-eq',
  templateUrl: './policy-type-eq.component.html',
  styleUrls: ['./policy-type-eq.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class PolicyTypeEQComponent implements OnInit {
  @Input() data: { type: string, typicalActiveMonths: string, usualAreaOfOperations: string }

  policyType : UntypedFormGroup = new UntypedFormGroup({
    type : new UntypedFormControl('', [Validators.required]),
    usualAreaOfOperations : new UntypedFormControl('', [Validators.required]),
    typicalActiveMonths : new UntypedFormControl('', [Validators.required]),
  });

  types = new Interest().type.equipmentBinder

  constructor(public parent: FormGroupDirective, public comm: ComponentCommunicationService, public pUtil : PortalUtilService) {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('policyType', this.policyType);
  }

  ngOnInit(): void {
    this.setupPolicyType();
  }

  setupPolicyType(){
    if(this.data){
      this.policyType.patchValue({
        type : this.data?.type,
        typicalActiveMonths : this.data?.typicalActiveMonths,
        usualAreaOfOperations : this.data?.usualAreaOfOperations
      })
      this.comm.setappPolicyType(this.data?.type)
    }
  }

  chooseInterest(type: string){
    this.policyType.get('type')?.setValue(type)
    this.comm.setappPolicyType(type)
  }

}
