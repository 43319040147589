import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { BasisOfValuationComponent } from '../../../shared-resources/forms/basis-of-valuation/basis-of-valuation.component';
import { ProductComponent } from '../update-contract.component';

@Component({
  selector: 'app-terrorism-form',
  templateUrl: './terrorism-form.component.html',
  styleUrls: ['./terrorism-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class TerrorismFormComponent implements AfterViewInit, ProductComponent {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;
  @ViewChild(BasisOfValuationComponent) basis: BasisOfValuationComponent;
  typeOfValue = false;
  occupancyType: UntypedFormControl;
  otherDetailCasualty: UntypedFormControl;
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef) {
  }
  
  ngAfterViewInit(){
    this.setupTerr();
    this.otherDetailCasualty = this.parent.form.get('proposalForm')?.get('overviewOfBusiness')?.get('otherDetail') as UntypedFormControl;
    this.occupancyType = this.parent.form.get('proposalForm')?.get('overviewOfBusiness')?.get('occupancyType') as UntypedFormControl;
    this.cdr.detectChanges();
  }

  setupTerr(){
    if(this.preForm?.proposalForm?.basisOfValuation){
      this.typeOfValue = true;
    } else {
      this.basis.basisOfValuation.disable();
    }
  }

  toggletypeOfValue(){
    if (this.typeOfValue === false) {
      this.basis.basisOfValuation.enable();
    } else {
      this.basis.basisOfValuation.disable();
    }
    this.typeOfValue = !this.typeOfValue;
  }

}
