import { Component, OnInit } from '@angular/core';
import { MonthlyReportBaseComponent, PortalDataService } from 'portal';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss']
})

export class MonthlyReportComponent extends MonthlyReportBaseComponent {
  constructor(public portalDataService: PortalDataService
  ) { super(portalDataService)}

}
