export interface Contract extends Document {
  contractName: string;
  updatedAt: string;
  rev: string;
  teamID: string;
  product: string;
  quotedIC: string|string[];
  declinedICs: string[];
  acceptedIC:string|undefined;
  acknowledgedICs: number;
  status: string;
  userEmail: string;
  lineage?: string[]
  downloadedAt?:Date
  unRead?:boolean
}

export interface Document {
  id: string;
  type: string;
  createdAt: string;
  userID: string;
}

export class CreatePost {
  contractName: string;
  teamID: string;
  product: string;

  constructor(contractName: string, teamID: string, product: string){
    this.contractName = contractName;
    this.teamID = teamID;
    this.product = product;
  }
}

export class modelUtils{
  public userAction = {
    save : 'Save',
    submit : 'Submit',
  }
  public riskTab = {
    all: {value:'', quantity:0},
    submitted: {value:'submitted', quantity:0},
    quoted: {value:'quoted', quantity:0},
    bound: {value:'bound', quantity:0},
    declined: {value:'declined', quantity:0},
    notTakenUp: {value:'not taken up', quantity:0},
    accepted: {value:'accepted', quantity:0},
    expired: {value:'expired', quantity:0},
  }
  public risk = {
    Quoted : 'Quoted',
    QuoteAccepted : 'Quote Accepted',
    QuoteDeclined : 'Not Taken Up',
    Bound : 'Bound',
    Expired: 'Expired'
  };
}