<div class="text-danger d-flex justify-content-end validation" 
*ngIf="control && control.errors && control.touched">
    <span *ngIf="control.errors.required"><span class="requiredText">required</span><span class="required">*</span></span>
    <span *ngIf="control.errors.pattern"><small>Please enter a valid phone number</small></span>
    <span *ngIf="control.errors.incorrectPercentage"><small>Percentage over 100</small></span>
</div>

<div class="text-danger d-flex justify-content-end validation" 
*ngIf="control && control.errors">
    <span *ngIf="control.errors.scheduleRequired"><span class="requiredText">required</span><span class="required">*</span></span>
    <span *ngIf="control.errors.scheduleOverLimit"><span class="requiredText">Value cannot be greater than USD 25,000,000</span><span class="required">*</span></span>
    <span *ngIf="control.errors.scheduleOver50"><span class="requiredText">Value cannot be greater than USD 50,000,000</span><span class="required">*</span></span>
</div>