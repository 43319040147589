import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SVLimit } from '../../../../models/typing';

@Component({
  selector: 'app-coverage-component-one',
  templateUrl: './coverage-component-one.component.html',
  styleUrls: ['./coverage-component-one.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class CoverageComponentOneComponent implements OnInit {
  @Input() preForm: any;
  coverage = new UntypedFormGroup({
    limit : new UntypedFormControl(),
    deductible : new UntypedFormControl(),
    targetPremium : new UntypedFormControl(),
    additionalLimits : new UntypedFormArray([]),
  });
  isAdditionalLimit = false;
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef) { }
  
  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('coverageRequired') as UntypedFormGroup)?.addControl('coverage', this.coverage);
    this.setupCoverage();
  }

  toggleAdditionalInfo(){
    this.isAdditionalLimit = !this.isAdditionalLimit;
    this.isAdditionalLimit ? this.coverage.get('additionalLimits')?.enable(): this.coverage.get('additionalLimits')?.disable();
    this.cdr.detectChanges()
  }

  setupCoverage(){
    const coverage = this.preForm?.proposalForm?.coverageRequired?.coverage;
    if(coverage){
      this.coverage.patchValue({
        limit: coverage?.limit,
        deductible: coverage?.deductible,
        targetPremium: coverage?.targetPremium,
      });
    }
    if(coverage?.additionalLimits){
      this.isAdditionalLimit = true;
      coverage.additionalLimits.forEach((c: SVLimit) => {
        const control = this.addLimitControl();
        control.patchValue({
          limit: c?.limit,
          deductible: c?.deductible,
          targetPremium: c?.targetPremium,
        })
      });
    } else {
      this.addLimitControl();
      this.coverage.get('additionalLimits')?.disable();
    }
  }

  getLimits(){
    return (this.coverage.get('additionalLimits') as UntypedFormArray).controls;
  }

  addLimitControl(){
    const formGroup = new UntypedFormGroup({
      limit : new UntypedFormControl(),
      deductible : new UntypedFormControl(),
      targetPremium : new UntypedFormControl(),
    });
    (this.coverage.get('additionalLimits') as UntypedFormArray).push(formGroup);
    return formGroup;
  }

  removeLimitControl(i: number) {
    (this.coverage.get('additionalLimits') as UntypedFormArray).removeAt(i);
  }
}
