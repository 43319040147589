import { Component, OnInit, forwardRef, OnDestroy, Input, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup,
  AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PortalUtilService, ComponentCommunicationService } from 'portal';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
  providers: [
       {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AmountComponent),
      multi: true
    }
  ]
})
export class AmountComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input() name: number;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() additionalType: string[];
  @ViewChild('figure') figureInput: ElementRef;
  currency: string[];
  _disabled = false;
  unsub: Subject<boolean> = new Subject<boolean>();
  public amount: UntypedFormGroup = new UntypedFormGroup({
      figure: new UntypedFormControl(''),
      currency: new UntypedFormControl(''),
    });

    constructor(public pUtil: PortalUtilService, private comm: ComponentCommunicationService, private cdr: ChangeDetectorRef) { }
    
    public onTouched: () => void = () => {};

    ngOnInit() {
      if (this.required) {
        this.amount.get('figure')?.setValidators(Validators.required);
      }
      this.touchListener();
      this.currencyArrChange();
      this.currencyTypeChange();
      this.cdr.detectChanges()
    }

    ngAfterViewInit(){
      if(this.additionalType?.length > 0){
        this.currency = this.currency.concat(this.additionalType);
      }
    }

    touchListener() {
      this.comm.markAllAsTouchedListener().pipe(
       takeUntil(this.unsub)
     ).subscribe((bool) => {
       if (bool) {
         this.amount.get('figure')?.markAsTouched();
       }
     });
    }

    writeValue(val: any): void {
      if (val) {
        this.amount.patchValue(val, { emitEvent: false });
        if(this.amount.get('figure')?.value){
          this.figureInput.nativeElement.value = new Intl.NumberFormat().format(val?.figure)
        }
      } 
      val?.figure || val?.figure === 0 ? this.setState(false) : this.setState(true);
      this.onValueChanges();
    }

    registerOnChange(fn: any): void {
      this.amount.valueChanges.pipe(
        takeUntil(this.unsub)
      ).subscribe(fn);
    }

    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
      this._disabled = isDisabled;
    }

    validate(c: AbstractControl): ValidationErrors | null {
      return this.amount.valid ? null : { invalidForm: {valid: false, message: ''}};
    }

    onValueChanges(): void {
      this.amount.get('figure')?.valueChanges.pipe(
        takeUntil(this.unsub)
      )
      .subscribe(val => {
          if (val || val === 0 && this.amount.get('currency')?.disabled) {
            this.setState(false);
          } else if (!val && val !== 0 && this.amount.get('currency')?.enabled) {
            this.setState(true);
          }
      });
    }

    setState(isDisabled: boolean): void {
      if (isDisabled) {
        this.amount.get('currency')?.disable();
      } else {
        this.amount.get('currency')?.enable();
      }
    }

    currencyTypeChange() {
      this.comm.defaultCurrencyTypeListener().
      pipe(
        takeUntil(this.unsub)
      )
      .subscribe((response) => {
        this.amount.get('currency')?.patchValue(response);
      });
    }

    currencyArrChange() {
      this.comm.defaultCurrencyArrListener().
      pipe(
        takeUntil(this.unsub)
      )
      .subscribe((response) => {
        this.currency = response;
      });
    }

    ngOnDestroy() {
      this.unsub.next(true);
      this.unsub.complete();
    }

}
