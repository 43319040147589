<div formGroupName="proposalForm">
    <div formGroupName="coverageRequired">
        <div *ngIf='toggleInformation' class="pt-5">
            <app-neutral-toggle [toggleInformation]="toggleInformation?.previouslyDeclinedCoverage"></app-neutral-toggle>
            <ng-container *ngIf='toggleInformation?.previouslyDeclinedCoverage?.listOfEnabledToggles?.includes("previouslyDeclinedCoverage")'>
                <div class="col-lg-8 col-md-9 col-sm-10 col-xs-12 col-xxs-12 pt-5">
                    <div class="colMargin">
                        <div class="controlInput" [ngClass]="{'requiredColor' : pUtil.checkIfError(coverageRequired,'previouslyDeclinedCoverage')}">
                            <textarea id="informationBox" type="text" formControlName="previouslyDeclinedCoverage" placeholder="Further Information..."></textarea>
                            <label class="controlLabel">
                                Explain
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>