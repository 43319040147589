import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { PTEnvironment } from '../model/typings';
import { BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import { tap, map, first } from 'rxjs/operators';
import { PortalDataService } from './portal-data.service';
import { combineLatest } from 'rxjs-compat/operator/combineLatest';

@Injectable({
  providedIn: 'root'
})
  
export class MessagesService {
  
  private userMessages:Map<string, SVComments> = new Map()
  userMessagesSubject = new BehaviorSubject<Map<string, SVComments>>(this.userMessages)

  constructor(
    @Inject('env') private environment: PTEnvironment,
    private _http: HttpClient,
    private zone: NgZone,
    private dataService: PortalDataService
  ) {
  }
  async receiveMessageMaybe(id:string, comments:SVComments): Promise<boolean>{
    // if(this.userMessages.has(id)){
    //   return this.userMessages.get(id)?.addFromRWComment(comment) ?? false

    // } else {
    //   return this.getMessagesForRisk(id).pipe(first(), map((comm: SVComments) =>{
    //     // this.userMessages[id] = comm
    //     return this.receiveMessageMaybe(id, comment)
    //   })).toPromise()
    // }
    this.userMessages.set(id, comments)
    this.userMessagesSubject.next(this.userMessages)
    return true
  }
  sendMessage(id:string, message:string): void{
    this.dataService.postMessage(id, message).pipe(
    ).subscribe((res)=>{
      // the problem is that we won't have the sent from userid...
      // we also don't know which user is uwr and which is broker
      // maybe add portal, broker, and mcgill to SVComment (yes this is a good idea)
      
      // add to existing messages
      if(!this.userMessages.get(id)){
        this.userMessages.set(id, new SVComments(id))
      }
      SVComments.addMessage(this.userMessages.get(id),
        {
          isoTime: new Date().toISOString(),
          message,
          sender:"unknown",
          sendingParty:"underscore",
          id:res.commentID
        }
      )
      this.userMessagesSubject.next(this.userMessages)
    })
  }
  getMessagesForRisk(id:string): Observable<SVComments>{
    return this.dataService.getMessages(id).pipe(tap(comm => {
      this.userMessages.set(id, comm)
    }))
  }
}

interface RWComment {
  // apnsData: {body: "abcabca", category: "NEW_COMMENT", channels: ["palermo_ALL"],…}
  associatedRootID: string
  // channels: ["blackpool_CARGOTHROUGHPUT", "palermo_ALL"]
  comment: string//"abcabca"
  createdAt: string
  // provenance: {dataHash: "bebbb005228e034409e359f2c9b81a0c125990639788875ef8bfd82fbfb0ef70",…}
  type: string
  updatedAt: string
  user: string
  userID: string
  // _attachments: null
  _id: string
  _rev: string

  _seq: number
}

export type Message = {
  isoTime:string,
  message:string,
  sender:string,
  sendingParty:"underscore"|"broker"|"underwriter"
  id: string
}
export class SVComments{
  id: string
  type: string
  messages: Message[]
  // static messageFromRWC(rwcomment:RWComment, sendingParty = "unknown"): Message{
  //   if(!rwcomment._id) {
  //       throw new Error("missing id, cannot add message")
  //   }
  //   return {
  //       isoTime: rwcomment.createdAt,
  //       message: rwcomment.comment,
  //       sender: rwcomment.userID,
  //       sendingParty,
  //       id: rwcomment._id
  //   }
  // }
  unRead?:boolean
  constructor(id:string){
    this.id = id
    this.type = "SVComment"
    this.messages = []
  }
  static addMessage(svComm: any, message: Message): boolean{
    if(!svComm.messages.find((msg:Message )=> msg.id === message.id)){
        svComm.messages.push(message)
        return true
    }
    return false 
  }
}