<div class="salesRow pt-3 pb-4">
    <div class="totalSalesSelect d-flex align-items-center operationLabel">
        Totals
    </div>
    <div class="totalSalesFigure d-flex align-items-center">
        <mat-error class="ms-3" *ngIf="totals.get('expiringProjected')?.value !== 100 && totals.get('expiringProjected')?.value">must add up to 100%</mat-error>
        <div class="operationsTotal ms-auto">
            <div [ngClass]="{'requiredColor' : totals.get('expiringProjected')?.value !== 100 && totals.get('expiringProjected')?.value}" class="controlInput">
                <input class="splitInput" [inputControl]="totals.get('expiringProjected')" id='total' pt-appPercentage disabled="true">
                <label class="controlPercentageLabel">
                    %
                </label>
            </div>
        </div>
    </div>
    <div class="totalSalesFigure d-flex align-items-center">
        <mat-error class="ms-3" *ngIf="totals.get('expiringActual')?.value !== 100 && totals.get('expiringActual')?.value">must add up to 100%</mat-error>
        <div class="operationsTotal ms-auto">
            <div [ngClass]="{'requiredColor' : totals.get('expiringActual')?.value !== 100 && totals.get('expiringActual')?.value}" class="controlInput">
                <input class="splitInput" [inputControl]="totals.get('expiringActual')" id='total' pt-appPercentage disabled="true">
                <label class="controlPercentageLabel">
                    %
                </label>
            </div>
        </div>
    </div>
    <div class="totalSalesFigure d-flex align-items-center">
        <mat-error class="ms-3" *ngIf="totals.get('renewalProjected')?.value !== 100 && totals.get('renewalProjected')?.value">must add up to 100%</mat-error>
        <div class="operationsTotal ms-auto">
            <div [ngClass]="{'requiredColor' : totals.get('renewalProjected')?.value !== 100 && totals.get('renewalProjected')?.value}" class="controlInput">
                <input class="splitInput" [inputControl]="totals.get('renewalProjected')" id='total' pt-appPercentage disabled="true">
                <label class="controlPercentageLabel">
                    %
                </label>
            </div>
        </div>
    </div>
</div>