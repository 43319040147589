import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PTUser } from 'projects/portal/src/lib/model/typings';
import { PortalDataService } from '../../../../../services/portal-data.service';
import { PortalUtilService } from '../../../../../services/portal-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pt-update-users-base',
  templateUrl: './update-users-base.component.html',
  styleUrls: ['./update-users-base.component.css']
})
export class UpdateUsersBaseComponent implements OnInit, OnDestroy {
  @Input() selectedUsers: PTUser[];
  @Input() userdata: any;
  updateUserDetails = new UntypedFormGroup ({
  });
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public portalDataService: PortalDataService, public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.getSelectedUserEmailsArray();
  }

  getSelectedUserEmailsArray(){
    this.selectedUsers.map((user) => {
      const email = new UntypedFormControl(user.email);
      (this.updateUserDetails.get('emails') as UntypedFormArray).push(email);
    });
  }

  updateSelectedUserDetails(){
    this.portalDataService.updateUserDetails(this.updateUserDetails.value)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      if(response.error){
        this.pUtil.errorPopup({status:'While trying to update details', message:response?.errorDescription});
      } else{
        alert("User details have been updated");
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}
