<div class="sticky">
  <app-top-nav [navType]="'home'"></app-top-nav>
</div>
<div class="container-fluid pb-2 pe-0 ps-0 m-0 listContainer">
    <div class="headerSection">
      <div class="row pb-4">
        <div class="col-8">
          <h1 class="homepageHeader ps-4">
            Welcome to Underscore,<br>
            the Digital Insurance Platform from McGill and Partners
          </h1>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <button class="createRiskButton" (click)="navigateToForm()">
            <div class="riskButtonContent">
              Create a New Quote
              <div id="addIcon"></div>
            </div>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="homepageSubheader">
            Your Risks
          </h2>
        </div>
      </div>
    </div>
    <app-contract-list-detail></app-contract-list-detail>
</div>

<div class="poweredBy text mb-5">
  <label class="p-0 m-0">powered by</label>
  <div class="ms-4" [routerLink]="['/contracts']" id= "mLogo">
  </div>
</div>