<div formGroupName="proposalForm">
    <div class="transitRiskDropDown col-6 ps-0">
        <h2 class="subHeader">4.2 Incoming Values</h2>
        <button class="triskButton" [ngClass]="{'triskButtonToggle' : toggle === false}" (click)="controlIncomingValues()"></button>
    </div>
    <span id="incoming">
        <app-transit-risk [data]='preForm?.proposalForm?.transitRiskIncoming' formControlName='transitRiskIncoming'
        [type]='"incoming"'
        ></app-transit-risk>
    </span>
</div>