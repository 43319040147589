import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService, PortalDataService, TopNavBaseComponent, ContractsService } from 'portal';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})

export class TopNavComponent extends TopNavBaseComponent implements OnInit {
  constructor(public router: Router, public auth: AuthService,
    public sanitizer: DomSanitizer, public portalDataService: PortalDataService,
    public contractsService: ContractsService
  ) {super(router, auth, sanitizer, portalDataService, contractsService)}

  ngOnInit(): void {
    super.ngOnInit();
  }

}
