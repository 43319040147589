<div formGroupName="proposalForm">
    <div formGroupName="overviewOfBusiness">
        <div formGroupName="fineArtDetail">
            <div formGroupName="catLimitRequired">
                <h2 class="ps-0 subHeader colMargin">CATASTROPHE LIMIT REQUIRED</h2>
                <div class="colMargin pb-4">

                    <div class="pt-3">
                        <p class="text col-md-9 col-12 ps-0">
                            Please enter the limit you would require for Earthquake losses whilst at locations within California
                        </p>
                        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0">
                            <app-amount [required]="true" [name]="'Earthquake'" formControlName="earthquakeLimit"></app-amount>
                        </div>
                    </div>

                    <div class="pt-4">
                        <p class="text col-md-9 col-12 ps-0">
                            Please enter the limit you would require for Windstorm losses whilst at locations within Texas,
                            Louisiana, Florida, Mississippi, Alabama, Georgia, South Carolina and North Carolina
                        </p>
                        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 p-0">
                            <app-amount [required]="true" [name]="'Windstorm'" formControlName="windstormLimit"></app-amount>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="ps-0 subHeader colMargin">Please Select Deductible Required</h2>
            <div class="colMargin">
                <div class="col-lg-3 col-md-4 col-xs-6 col-xxs-12 p-0">
                    <div [ngClass]="{'requiredColor' : pUtil.checkIfError(fineArt,'deductibleRequired')}" class="controlInput customSelect">
                        <mat-select formControlName="deductibleRequired" domControl>
                          <mat-option *ngFor="let figure of figures" [value]="figure">{{returnFigure(figure)}}</mat-option>
                        </mat-select>
                        <label class="controlLabel">
                            Deductible Required*
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
