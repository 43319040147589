import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CoverageList, Enums } from '../../../shared-resources/models/contract.model';
import { UtilService } from 'projects/mcgill/src/app/app-services/util.service';
import { PortalUtilService, ComponentCommunicationService, ContractListDetailBaseComponent, modelUtils, ContractsService} from 'portal';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-contract-list-detail',
  templateUrl: './contract-list-detail.component.html',
  styleUrls: ['./contract-list-detail.component.scss']
})

export class ContractListDetailComponent extends ContractListDetailBaseComponent implements OnInit {
  @ViewChild('contract') set content(content: ElementRef) {
    if(content && !this.infoDoc){
      this.infoDoc = content.nativeElement
      this.cdr.detectChanges()
    }
  }
  infoDoc : HTMLElement
  dropdownArray =  new CoverageList().dropdownArray.calls;
  risks = new modelUtils().riskTab;
  product = new Enums();
  constructor(public router: Router, public dialog: MatDialog, public util: UtilService,
    public comm: ComponentCommunicationService, public pUtil: PortalUtilService,
    public contractsService: ContractsService, @Inject(DOCUMENT) public document: Document, private cdr : ChangeDetectorRef
  ) { super(router, dialog, comm, pUtil, contractsService)}

  ngOnInit(): void {
    super.ngOnInit();
  }
  
}
