<div id="termsAndConditions" class="agreementText">
    <p class="bold">
        Please read these terms carefully before entering the Underscore web portal (“Underscore”). By using Underscore,
        you confirm your agreement to be bound by these terms. Before being provided with access to Underscore for the
        first time you are required to click “Agree and Proceed” to indicate your agreement of these terms. If you do not agree
        these terms you will not be able to use Underscore.
    </p>
    <p>
        Underscore is accessible via a private website which is hosted by Whitespace Software Limited, and owned and
        controlled by McGill and Partners Services Ltd, a company incorporated in England and Wales, under registration
        number 11877412, with a registered office at Suite 806 Lloyd's 1 Lime Street, London, England, EC3M 7HA
        (“MGP Services”). Underscore is made available to you by McGill and Partners Ltd (“McGill and Partners”),
        an affiliate of MGP Services. McGill and Partners is a company authorised and regulated by the Financial
        Conduct Authority, under firm reference number 927379, to carry on insurance and reinsurance distribution
        activities. Access to Underscore is provided to Authorised Users only and is subject to these terms and
        conditions of use (the “Terms”).
    </p>
    
    <ol>
        <li><span class="liHeader">DEFINITIONS AND INTERPRETATION</span>
            <ol>
                <li>In these Terms the following expressions have the following meanings:
                    <p>“Affiliates” means any company (be it a subsidiary, sister or parent corporate entity) within the McGill and Partners group of companies.</p>
                    <p>“Authorised User”, “you” or “your” means those producing insurance brokers authorised by McGill and Partners to access Underscore, together with those of such person’s employees, agents, contractors, representatives, or other designated users to whom a USERID and Password has been issued);</p>
                    <p>“Business Day” means any day on which clearing banks in England are generally open for business, excluding Saturdays, Sundays and public holidays;</p>
                    <p>“Business Hour” means 09:00 to 17:00 United Kingdom Time, Monday to Friday;</p>
                    <p>“Client(s)” means any of your existing and/or prospective clients in the United States who wish to place insurance risks with UK market insurers and in respect of which you shall nominate Authorised Users to have access to Underscore for use in connection with the placing of such risks;</p>
                    <p>“Client Data” means any data, information, content or other materials (included but not limited to files maintained or stored on Underscore) which is inputted onto Underscore by Authorised Users (which may also include information provided to UK insurers for the purposes of underwriting your Client(s) Insurance Policy(ies);</p>
                    <p>“Cookie” means a small file consisting of letters and numbers, downloaded onto an Authorised User's computer, that allows Underscore to remember the said Authorised User's identity and other preferences when interacting with Underscore;</p>
                    <p>“Commencement Date” means the date that access to Underscore is granted to you;</p>
                    <p>"Confidential Information" means any information or data which is disclosed by your Client(s) and/or any Authorised Users or otherwise comes into your possession either directly or indirectly as a result of your access to Underscore and which is of a confidential nature;</p>
                    <p>"Documentation" means all instructions and other materials made available by McGill and Partners which describe the functionality or assist with the use of Underscore (including but not limited to any minimum system requirements);</p>
                    <p>"Insurance Policy" means any insurance contract which you wish to place and manage on behalf of your Clients through Underscore;</p>
                    <p>"Intellectual Property Rights" means copyright, patents, trademarks, trade names, logos, trade secrets, registered designs, database rights, know-how, rights in domain names, moral rights, software source code, underlying technology, graphics and all other legal and beneficial intellectual and industrial property and similar rights of all types under the laws of any governmental authority in any jurisdiction no matter what such rights may be known as in any particular country in the world and including all renewals, applications and registrations relating to any of the foregoing;</p>
                    <p>"Underscore" the online broking system owned and controlled by McGill and Partners, that allows selected producing insurance brokers to place insurance risks on behalf of their Client(s) with duly authorised insurers in the UK;</p>
                    <p>"USERID and Password" means the unique username and/or code provided by McGill and Partners to each Authorised User to enable the Authorised Users to access Underscore; and</p>
                </li>
                <li>Words denoting the singular include the plural, words denoting any gender include every gender, words denoting persons include bodies corporate and unincorporated and references to the whole include the part and, in each case, vice versa. Headings shall not affect interpretation of these Terms.</li>
                <li>A reference to a statutory provision, regulation, guideline, code of practice or handbook provision includes a reference to such statutory provision, regulation, guideline, code of practice or handbook provision as modified or re-enacted from time to time and any subordinate legislation made with respect to the foregoing, in each case, whether before or after the date of this Agreement.</li>
                <li>These terms apply to your use of Underscore only. All other services shall be governed by the terms of our Client TOBA issued to you or, where applicable, any more specific terms of business agreement that we may have in place to govern our provision of placement services to you for your Client(s).</li>
            </ol>
        </li>
        <li><span class="liHeader">ACCESS TO UNDERSCORE</span>
            <ol>
                <li>In order to access Underscore you will be given a USERID and Password and be granted a limited, non-assignable licence to access Underscore subject to these Terms. Save with McGill and Partners' prior written consent on each and every occasion, you agree not to disclose your USERID and Password to any person or permit Underscore to be used by any person other than an Authorised User. You agree to take all reasonable precautions to keep your USERID and Passwords confidential at all times, not to disclose your USERID and Password to any other person, prevent the loss or theft of your USERID and Passwords or the unauthorised access to Underscore and/or use of Underscore. You agree to inform McGill and Partners immediately by contacting Compliance@mcgillpartners.com if you become aware of any breach of confidentiality, breach of security, unauthorised use or disclosure or loss or theft of any of your USERID and Password. You agree to indemnify McGill and Partners on demand for all and any loss or damage resulting from use of your USERID and Password by any unauthorised person or the unauthorised access to Underscore.</li>
                <li>You agree not to:
                    <ol>
                        <li>use Underscore or any information obtained from it for any purpose other than for the placing of insurance risks in the UK on behalf of your Client(s) and/or for the administration of any Insurance Policy(ies) in accordance with these Terms;</li>
                        <li>use Underscore in any way that disrupts, interferes with or restricts the use of Underscore, or access or attempt to access unauthorised areas or data contained or probe, scan or test the vulnerability of Underscore or use any devices, software or routine to interfere with the proper working of Underscore;</li>
                        <li>reverse engineer, decompile, copy, distribute, disseminate, sub-licence, modify, translate or adapt any software or other code or scripts relating to Underscore or knowingly post or transmit any information that contains a virus, worm, Trojan horse or other harmful or disruptive components (“Malicious Codes”) to Underscore. You will not attack our site via a denial-of-service attack or a distributed denial-of service attack. You shall ensure that the systems via which Authorised Users access Underscore are properly protected against Malicious Codes at all times. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them; and</li>
                        <li>licence, sell, lease or otherwise transfer or grant any third party who is not an Authorised User access to Underscore.</li>
                    </ol>
                </li>
                <li>MGP may suspend the operation of, or make changes or enhancements to Underscore or disable your access to Underscore (which may include deactivating or changing any USERID and Passwords) at any time without notice for any reason, including, without limitation, for maintenance purposes, or due to a breach or suspected breach of security or due to a breach of these Terms by you. McGill and Partners will use its reasonable endeavours to keep any period of temporary suspension to a minimum and shall (where reasonably practicable) notify Authorised Users of any changes or enhancements in advance. McGill and Partners will not be liable to you or the Client for loss arising out of or in connection with any such changes or suspension.</li>
                <li>If there is a breach of these Terms caused as a result of you printing off, copying or downloading any part of our site, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</li>
            </ol>
        </li>
        <li><span class="liHeader">YOUR RESPONSIBILITIES</span>
            <ol>
                <li>You are responsible for ensuring that all Authorised Users who access our site on your behalf, at your direction or through your internet connection are aware of these Terms and other applicable terms and conditions, and that they comply with them.</li>
                <li>You shall ensure that you have undertaken appropriate “Know Your Client” and sanctions checks on your Client(s) before you seek insurance for such Client(s) via Underscore. By using Underscore to arrange any Insurance Policy(ies) for your Client(s), you warrant that such Client(s) insurance requirements do not touch on any sanctioned territories, individuals or organisations.</li>
                <li>You shall ensure that all material provided to Underscore by you or your Client(s) is, to the best of your and your Client(s)’s knowledge and belief (having made all reasonable enquiries) accurate, complete, and up-to-date and contains nothing offensive, defamatory, unlawful or which is in breach of the Intellectual Property Rights or other rights of any third party.</li>
                <li>You shall be responsible for reading the terms and conditions of each Insurance Policy made available to your Client(s) via Underscore. By placing an order within Underscore, the Authorised User expressly accepts the suitability of cover for the risk insured.</li>
                <li>You and (to the extent applicable) your Clients are responsible for providing and maintaining the following equipment:
                    <ol>
                        <li>all hardware and software (including virus protection software) necessary to access Underscore as indicated by MGP in the Documentation;</li>
                        <li>access to the internet with sufficient quality and bandwidth for use of Underscore; and</li>
                        <li>all other related equipment and services necessary to access and use Underscore.</li>
                    </ol>
                </li>
                <li>Nothing in these Terms shall override the terms of any Insurance Policy or be construed so as to affect the legal rights and obligations of the parties to any such Insurance Policy.</li>
                <li>For the avoidance of doubt, nothing in this Agreement will be construed so as to alter the duty of utmost good faith which applies to the placement of any Insurance Policy(ies) in the UK (or the equivalent provisions in your jurisdiction) and/or the duty of disclosure owed by your Client(s), which requires prospective and existing insureds to disclose all circumstances material to the risk proposed to insurers during the initial placement, at any variation and at each renewal of Insurance Policy(ies). By accessing Underscore, you confirm that you have informed your Client(s) of their disclosure obligations and the potential consequences of any material non-disclosure.</li>
                <li>You understand and agree that if you seek an Insurance Policy covering any territory which requires locally licensed paper, it is your responsibility to ensure the cover provided is suitable for your Client’s needs/ requirements in all territories. You further acknowledge and agree that you are responsible for affixing any required surplus lines notice(s) to the contract document(s) before providing them to your Client(s) where applicable.</li>
            </ol>
        </li>
        <li><span class="liHeader">COOKIES</span>
            <p>McGill and Partners will use Cookies to allow Underscore to remember who you and your Client(s) are and to distinguish you from other Underscore users.</p>
        </li>
        <li><span class="liHeader">BACK-UP AND SECURITY</span>BACK-UP AND SECURITY
            <ol>
                <li>On Underscore, McGill and Partners will develop a standard, secure, server configuration for all types and operating systems. This configuration shall ensure that all computer servers are implemented in a manner which ensures minimal exposure to unauthorised activities such as virus attacks, fraudulent use of systems hosted on server and exposure to hackers. Security related patches and hot fixes will be applied to all servers in a reasonable timescale from their release. Notwithstanding the above, we do not and cannot guarantee with absolute certainty that our site will be secure or free from bugs or viruses.</li>
                <li>McGill and Partners will take appropriate security measures typically used in the data storage industry to protect the hardware and software used to store the Client Data. McGill and Partners will utilise user account authentication and firewall protection to protect the Client Data, follow appropriate backup procedures to maintain the Client Data and will follow customary storage methods and procedures for securely storing copies of the Client Data.</li>
            </ol>
        </li>
        <li><span class="liHeader">TERM AND TERMINATION</span>
            <ol>
                <li>Access to Underscore will commence immediately upon acceptance of these terms and conditions of use or on the specified Commencement Date and will be valid for the duration of your required access to Underscore. Should your access be terminated then access to Underscore will be withdrawn from the date of termination.</li>
                <li>McGill and Partners may, upon giving reasonable notice, withdraw, terminate or suspend access to Underscore at any time and without liability to you or your Client(s).</li>
            </ol>
        </li>
        <li><span class="liHeader">CONSEQUENCES OF TERMINATION</span>
            <ol>
                <li>Upon termination of or suspension of access to Underscore:
                    <ol>
                        <li>You shall no longer have access to Underscore;</li>
                        <li>You shall be entitled to one copy of the Client Data in Microsoft Office or text format;</li>
                        <li>the Documentation and all other papers, materials, licenses, information and other property provided by McGill and Partners shall immediately be returned; and</li>
                        <li>subject to payment of McGill and Partners’ reasonable fees, McGill and Partners will provide you and/or your Client(s) with reasonable assistance in the migration of Client Data to an alternative portal or other database.</li>
                    </ol>
                </li>
                <li>Termination or suspension of access to Underscore, for whatever reason, shall not affect any terms which are expressed or required to survive or operate in the event of the termination and shall not prejudice or affect the rights of either party against the other party in respect of any breach of these Terms. Termination shall not affect the validity of your Client(s)’s Insurance Policy(ies) arranged through Underscore or your obligations thereunder.</li>
            </ol>
        </li>
        <li><span class="liHeader">LIMITATION OF LIABILITY</span>
            <ol>
                <li>You agree that your use of Underscore is on an "as is" basis and at your own risk and, save as set out below, you waive any rights you have or may have against McGill and Partners in respect of your use of Underscore. Save as set out in these Terms, McGill and Partners make no representations, and so far as is permitted by law, exclude all warranties, conditions and guarantees, express or implied, whether oral or in writing, regarding the availability, completeness, quality and performance of Underscore.</li>
                <li>McGill and Partners do not warrant that access to Underscore and use of Underscore will be uninterrupted, error free or virus free, but McGill and Partners shall use reasonable endeavours to make Underscore available 99.8% of 24/7. McGill and Partners shall not be liable for any failure to provide Underscore or to comply with any other obligation under these Terms if such failure arises from any event beyond their reasonable control, including, without limitation, failure of any telecommunications operator or of Whitespace Software Limited. Underscore may contain links to other internet sites or other external sources that are not maintained by McGill and Partners and McGill and Partners are not responsible for, have no control over and do not therefore endorse the content of any such sites.</li>
                <li>McGill and Partners disclaims any and all liability resulting from or related to the events set out in clause 8.2. McGill and Partners shall not be liable to you or any other third party for any claims or damages that may be suffered by you or any such third party in relying on the data on Underscore including, without limitation, any and all losses or damages of any and every nature, resulting from the loss of data, inability to access the internet, or inability to transmit or receive information, caused by, or resulting from, delays, non-delivery, service interruptions and/or use of or reliance on any content displayed on Underscore.</li>
                <li>McGill and Partners disclaims any and all liability for loss suffered by your Client(s) as a result of the placing of Insurance Policy(ies) with insurers on Underscore. Information obtained (whether directly or indirectly) by you for your Client(s) on Underscore does not constitute advice or recommendations by McGill and Partners (including but not limited to the suitability of any Insurance Policy(ies) for your Client(s)) and cannot be relied upon as such.</li>
                <li>McGill and Partners shall not be liable for any indirect or consequential loss, nor for any indirect or direct loss of profits, anticipated profits, revenue, goodwill, contracts, anticipated savings, business opportunities, business interruption and loss of information or data (whether direct or indirect) and, in each case, whether arising from negligence, breach of contract or otherwise and whether or not McGill and Partners were advised of the possibility of such loss by you or by your Client(s). Subject to the foregoing, McGill and Partners’ total aggregate liability howsoever arising from or in connection with the use of Underscore shall be limited to USD100,000.</li>
                <li>Nothing in these Terms shall operate to exclude or limit either party's liability for death or personal injury caused by its negligence or the negligence of its personnel acting in the course of their employment or for fraud or for any other type of liability which it is not lawfully permissible to exclude or limit.</li>
                <li>The parties agree that this clause fairly apportions risk between the parties.</li>
                <li>The provisions of this clause 8 shall continue to apply notwithstanding the termination or expiry of this Agreement.</li>
            </ol>
        </li>
        <li><span class="liHeader">INDEMNITY</span>
            <p>
                You shall indemnify McGill and Partners, its Affiliates and their respective officers, directors, shareholders, employees, agents and contractors against all and any reasonably mitigated losses, costs or expenses incurred by McGill and Partners or its Affiliates arising out of any claim made against McGill and Partners or its Affiliates by any third party arising out of or in connection with your use of Underscore.
            </p>
        </li>
        <li><span class="liHeader">CONFIDENTIALITY</span>
            <ol>
                <li>You agree not to use or disclose any Confidential Information, to the extent it comes to you through use of Underscore, other than as strictly necessary to perform your obligations or exercise your rights pursuant to these Terms, provided that this provision shall not apply to Confidential Information:
                    <ol>
                        <li>which you can prove was already in your possession at the date it was received or which you obtain from some other person with good legal title to the same or which is independently developed by or for you; or</li>
                        <li>which comes into the public domain otherwise than through your default or negligence; or</li>
                        <li>which you are required to disclose by law or by the order of any competent court, regulator or professional body with jurisdiction over you.</li>
                    </ol>
                </li>
            </ol>
        </li>
        <li><span class="liHeader">DATA PROTECTION</span>
            <ol>
                <li>If you provide any information which constitutes ‘personal data’ (including any ‘sensitive personal data’) via Underscore, such information shall be treated in accordance with applicable data protection legislation.</li>
                <li>You acknowledge and agree that information provided via Underscore will be transferred out of the US, and you confirm that you have all required consents from your Client(s) to such transfer.</li>
            </ol>
        </li>
        <li><span class="liHeader">INTELLECTUAL PROPERTY</span>
            <ol>
                <li>McGill and Partners retains all Intellectual Property Rights in and associated with Underscore and nothing in this Agreement shall be taken to grant, assign, transfer to or vest any rights to or in you or your Client(s) in respect of any such Intellectual Property Rights.</li>
                <li>You and/or your Client(s) retain all Intellectual Property Rights in and associated with the Client Data and hereby grant McGill and Partners, any Affiliate and Authorised Users a non-exclusive, non-transferrable licence to use the Client Data solely in connection with the provision of Underscore services to the Client.</li>
            </ol>
        </li>
        <li><span class="liHeader">SYSTEM SUPPORT</span>
            <p>
                For system support queries such as system performance, non-availability, bugs or system related errors you should contact your usual McGill and Partners contact. Such support will be available during Business Days and Business Hours only.
            </p>
        </li>
        <li><span class="liHeader">DISCLAIMER</span>
            <ol>
                <li>As an Authorised User, it is your responsibility to ensure that the information and insurance documentation transacted by you within the system is accurate and complete and complies with the terms, clauses, conditions and limitations of the underlying Insurance Policy(ies).</li>
                <li>McGill and Partners does not accept any liability for any act, error or omission on your part in relation to the issuance of any information and insurance documentation transacted by you via Underscore.</li>
                <li>As Authorised User(s), you are responsible for ensuring that any materials you place on Underscore do not infringe any applicable laws, regulations or third party rights (such as material which is obscene, indecent, pornographic, seditious, offensive, defamatory, threatening, liable to incite racial hatred, menacing, blasphemous or in breach of any third party intellectual property rights (“Inappropriate Content”). You acknowledge that McGill and Partners shall not be responsible for monitoring the content of Underscore or materials you place on Underscore. Notwithstanding the foregoing, McGill and Partners reserves the right to remove content from Underscore where it reasonably suspects such content is Inappropriate Content.</li>
                <li>McGill and Partners does not control the flow of data to or from internet service providers, telecommunications providers and other portions of the internet. Such flow depends in large part on the performance of internet services provided or controlled by third parties. At times, actions or inactions caused by such third parties may produce situations in which a party's connections to the internet (or portions thereof) may be impaired or disrupted and McGill and Partners cannot guarantee that such events will not occur or that they can be fixed.</li>
            </ol>
        </li>
        <li><span class="liHeader">DISCLOSURE</span>
            <p>
                McGill and Partners shall, as the London broker of record on placements arranged using Underscore, earn
                commission from insurers on such placements. McGill and Partners may also earn fees from insurers for hosting
                and/or enabling them to receive risks using Underscore.
            </p>
        </li>
        <li><span class="liHeader">GENERAL</span>
            <ol>
                <li>These Terms, as amended from time to time, constitute the entire agreement between the parties in relation to your use of Underscore and supersede all previous agreements, arrangements, undertakings or proposals, whether written or oral, between the parties in relation thereto. Each party acknowledges that it has not relied upon and shall have no remedy in respect of any statement, condition, representation or warranty other than as expressly set out in these Terms, unless made fraudulently.</li>
                <li>McGill and Partners reserves the right to amend these Terms from time to time without notice in so far as the amendment is non-material, is required to reflect changes in the functionality of the system or is required to implement changes in any law or regulation applicable to this Agreement. In all other respects, if McGill and Partners amends the Terms it will notify you of the amendments before they become effective.</li>
                <li>No waiver of any breach of these Terms shall be considered to be a waiver of any subsequent breach of the same or any other breach of these Terms. If any provision of these terms, not being of a fundamental nature, is held by any competent authority to be invalid or unenforceable, in whole or in part, the validity of the other provisions of these Terms and the remainder of the provision in question shall not be affected.</li>
                <li>Nothing in these Terms shall be construed as constituting a partnership between the parties or as constituting either party as the agent of the other for any purpose whatsoever, except as expressly set out in these Terms. Save for the Affiliates, a person who is not a party to these Terms has no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any of these Terms, but this clause 14.4 does not affect a right or remedy of a third party which exists or is available apart from that Act.</li>
                <li>These Terms are personal to you and you therefore agree not to assign, subcontract or otherwise transfer any of your obligations under these Terms without the prior written consent of McGill and Partners, such consent not to be unreasonably withheld or delayed.</li>
            </ol>
        </li>
        <li><span class="liHeader">GOVERNING LAW AND JURISDICTION</span>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of England and Wales and you hereby submit to the exclusive jurisdiction of the courts of England and Wales.
            </p>
        </li>
    </ol>
</div>