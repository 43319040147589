export class CoverageList {
  public dropdownArray = {
    calls: [
    ],
  };
  public transit = {
    splits: [
      {label: 'Africa:', loc: 'africa'}, {label: 'Asia and Far East:', loc: 'asia'}, {label: 'Australia / New Zealand:', loc: 'australia'},
      {label: 'Europe (incl. Russia):', loc: 'europe'}, {label: 'Middle East:', loc: 'middleEast'},
      {label: 'Central America (incl. Caribbean):', loc: 'centralAmerica'}, {label: 'North American Continent:', loc: 'northAmerica'},
      {label: 'South American Continent:', loc: 'southAmerica'},
    ],
  };
  public list = [
    'Pharmaceuticals', 'Cars', 'Items under their own power', 'Arms and Ammunition', 'Mobile Phones, Tablets and Laptops',
    'Retail Stores', 'Vineyards', 'Hay, Plants and Flowers', 'Tobacco', 'Coal', 'Direct reduced Iron', 'Sawmills'
  ];
}

export class Interest {
  public fineArtTypes = {dealer:'Dealer',collector:'Collector'};
  public type = {
    interest: [
      {key: 'electronics', display: 'Electronics', value: 'Electronics'},
      {key: 'metal', display: 'Base Metals', value: 'Base Metals'},
      {key: 'clothing', display: 'Clothing & Textiles', value: 'Clothing & Textiles'},
      {key: 'lumber', display: 'Lumber', value: 'Lumber'},
      {key: 'machinery', display: 'Machinery', value: 'Machinery'},
      {key: 'product', display: 'Oil', value: 'Oil'},
      {key: 'grain', display: 'Grains & Soft Commodities', value: 'Grains & Soft Commodities'},
      {key: 'airplane', display: 'Aviation Spares', value: 'Aviation Spares'},
      {key: 'beverage', display: 'Alcohol & Beverages', value: 'Alcohol & Beverages'},
      {key: 'perishable', display: 'Perishable Goods', value: Interest.Perishable},
      {key: 'medical', display: 'Medical / Scientific Equipment', value: 'Medical / Scientific Equipment'},
      {key: 'constructionEquipment', display: 'Construction Equipment', value: 'Construction equipment'},
      {key: 'other', display: 'Other', value: 'Other'},
    ],
    occupancy: [
      {key: 'aviation', display: 'Aviation', value: 'Aviation'},
      {key: 'civil', display: 'Civil Construction', value: 'Civil Construction'},
      {key: 'general', display: 'General Industrial', value: 'General Industrial'},
      {key: 'gov', display: 'Government / Diplomatic', value: 'Government / Diplomatic'},
      {key: 'hotel', display: 'Hotel / Leisure', value: 'Hotel / Leisure'},
      {key: 'utilities', display: 'Utilities / Telecoms', value: 'Utilities / Telecoms'},
      {key: 'museum', display: 'Museum', value: 'Museum'},
      {key: 'rail', display: 'Rail / Bridges / Roads', value: 'Rail / Bridges / Roads'},
      {key: 'offices', display: 'Real estate / Offices', value: 'Real estate / Offices'},
      {key: 'retail', display: 'Retail', value: 'Retail'},
      {key: 'hospital', display: 'Hospital', value: 'Hospital'},
      {key: 'other', display: 'Other', value: 'Other'},
    ],
    casualtyIndustry: [
      {key: 'casualtyRoofing', display: 'Roofing Contractors', value: 'Roofing Contractors'}
    ],
    fineArt: [
      {key: 'fineArtDealer', display: 'Dealer', value: this.fineArtTypes.dealer},
/*       {key: 'fineArtCollector', display: 'Collector', value: this.fineArtTypes.collector},*/    
    ],
    equipmentBinder: [
      {key: 'CFP', display: 'CFP', value: 'CFP'},
      {key: 'TLA', display: 'TLA', value: 'TLA'},
      {key: 'EFP', display: 'EFP', value: 'EFP'},
    ],
    typeOfProject: [
      {key: 'residential', display: 'Residential', value: 'Residential'},
      {key: 'commercial', display: 'Commercial', value: 'Commercial'},
      {key: 'maintenanceWorks', display: 'Maintenance Works', value: 'MaintenanceWorks'},
    ],
    constructionMaterial: [
      {key: 'frame', display: 'Frame', value: Interest.Frame.value},
      {key: 'nonCombustible', display: 'Non-Combustible', value: Interest.NonCombustible.value},
      {key: 'fireResistive', display: 'Fire Resistive', value: Interest.FireResistive.value},
    ],
  }
  static Frame = {value: 'Frame', threshold: 2500000}
  static NonCombustible = {value: 'NonCombustible', threshold: 10000000}
  static FireResistive = {value: 'FireResistive', threshold: 10000000}
  static Perishable = 'Perishable Goods'
}

export class StyledButtons {
  static Renovation = 'Renovation'
  data = {
    project: [
      {display:'New Construction', value:'NewConstruction'},
      {display:'Renovation', value:StyledButtons.Renovation}
    ],
    constructionMaterial:[
      {display:'Protected', value:'Protected'},
      {display:'Semi-protected', value:'SemiProtected'},
      {display:'Non-protected', value:'NonProtected'}
    ]
  }
}

export class Enums {
  public cargoType = {
    StockOnly : 'Stock Only',
    TransitOnly : 'Transit Only',
    Both : 'Both',
    EquipmentBinder : 'EquipmentBinder'
  };
  public policyType = {
    ul : {display:'Umbrella Liability',value:'Umbrella Liability'},
    effl : {display:'Excess Follow Form Liability',value:'Excess Follow Form Liability'},
    pcgl : {display:'Primary Commercial General Liability',value:'Primary Commercial General Liability'},
  }
}

export class ZipCodes{
  public zip = new Map([
    ['New York City,', 'United States of America - 10001 through to 10029, 10036, 10038, 10044, 10048, 10111, 10128'],
    ['Chicago,', 'United States of America - 60601 through to 60611'],
    ['Washington DC,', 'United States of America - 20001, 20004, 20005, 20006, 20024, 20036, 20037, 20373, 56901, 56920'],
    ['San Francisco,', 'United States of America - 94102, 94103, 94104, 94105, 94107, 94108, 94109, 94110, 94111, 94112, 94114, 94115, 94116, 94117, 94118, 94121, 94122, 94123, 94124, 94127, 94129, 94131, 94132, 94133, 94134'],
  ]);
}

export class Dropdowns {
  static states = [ 'AL - Alabama','AK - Alaska','AZ - Arizona','AR - Arkansas',
  'CA - California','CO - Colorado','CT - Connecticut',
  'DE - Delaware','DC - District of Columbia',
  'FL - Florida',
  'GA - Georgia',
  'HI - Hawaii',
  'ID - Idaho','IL - Illinois','IN - Indiana','IA - Iowa',
  'KS - Kansas','KY - Kentucky',
  'LA - Louisiana',
  'ME - Maine','MD - Maryland','MA - Massachusetts','MI - Michigan','MN - Minnesota','MS - Mississippi','MO - Missouri','MT - Montana',
  'NE - Nebraska','NV - Nevada','NH - New Hampshire','NJ - New Jersey','NM - New Mexico','NY - New York','NC - North Carolina','ND - North Dakota',
  'OH - Ohio','OK - Oklahoma','OR - Oregon',
  'PA - Pennsylvania',
  'RI - Rhode Island',
  'SC - South Carolina','SD - South Dakota',
  'TN - Tennessee','TX - Texas',
  'UT - Utah',
  'VT - Vermont','VA - Virginia',
  'WA - Washington','WV - West Virginia','WI - Wisconsin','WY - Wyoming'
  ]
  static province = [
    'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland & Labrador',
    'NW Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'
  ]
  static terrStates = [...Dropdowns.states, 'PR - Puerto Rico'].sort();
  static cargoProvince = [
    'AB - Alberta', 'BC - British Columbia', 'MB - Manitoba', 'NB - New Brunswick',
    'NL - Newfoundland and Labrador', 'NS - Nova Scotia', 'ON - Ontario',
    'PE - Prince Edward Island', 'QC - Quebec', 'SK - Saskatchewan', 'YT - Yukon'
  ]
}
