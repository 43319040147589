<div class='userAgreement underscorePopup' *ngIf="userDetails$ | async as user">
    <h1 mat-dialog-title class="dialogTitle">Underscore - User Agreement
        <ng-container *ngIf="prod.userProductIncludesType(user.products, prod.supportedProducts.UEquipmentBinder.typeArr)">
            - Wilson M Beck Forestry Equipment Binder
        </ng-container>
    </h1>
    <mat-dialog-content>
        <div class="content text">
            <div>
                <ng-container *ngIf="prod.userProductIncludesType(user.products, prod.supportedProducts.UEquipmentBinder.typeArr); else casualtyAgreement">
                    <app-user-agreement-equipment-binder></app-user-agreement-equipment-binder>
                </ng-container>

                <ng-template #casualtyAgreement>
                    <ng-container *ngIf="prod.userProductIncludesType(user.products, prod.supportedProducts.UCasualty.typeArr); else defaultUserAgreement">
                        <app-user-agreement-casualty></app-user-agreement-casualty>
                    </ng-container>
                </ng-template>

                <ng-template #defaultUserAgreement>
                    <app-user-agreement-text></app-user-agreement-text>
                </ng-template>
            </div>
        </div>
    </mat-dialog-content>
    <div class="d-flex justify-content-end mt-4 me-4">
        <button mat-button (click)="logout()" class="roboto declineButton me-3">LOGOUT</button>
        <button mat-button mat-dialog-close (click)="acceptTerms()" class= "roboto createButton">AGREE AND PROCEED</button>
    </div>
</div>

