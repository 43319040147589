import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService, RiskService } from 'portal';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sov-get-json-from-file',
  templateUrl: './sov-get-json-from-file.component.html',
  styleUrls: ['./sov-get-json-from-file.component.scss']
})
export class SovGetJsonFromFileComponent implements OnInit {
  @Input() preForm: any;
  @ViewChild('uploadButton') uploadButton: ElementRef<HTMLElement>
  @Output() setupSchedule = new EventEmitter<any>()
  product: string
  spin: boolean

  constructor(private riskService: RiskService, private activeRoute: ActivatedRoute, private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    this.getProduct()
  }

  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      this.product = this.pUtil.addSpace(params.get('prod'));
    });
  }

  openFile() {
    this.uploadButton.nativeElement.click()
  }

  fileChange(event: Event) {
    const fileList = (<HTMLInputElement>event.target).files;
    if (fileList && fileList.length > 0) {
      const file: File = fileList[0]
      this.spin = true;
      this.riskService.getJSONFromFile(file, this.product, 'SOV')
      .pipe(
        tap((response: any) => {
          if(response && !response?.error){
            (this.uploadButton.nativeElement as HTMLInputElement).value = ''
            //this.filename = file.name
          } else {
            this.pUtil.errorPopup({status:'While trying to extract data', message:response?.errorDescription})
          }
        }),
        finalize(() => this.spin = false)
      ).subscribe((resp) => {
        if(resp && !resp?.error){
          this.setupSchedule.emit(resp)

          const importedParts : string[] = this.preForm?.proposalForm?.importedParts
          if(!importedParts?.includes('SOV')){
            importedParts ? importedParts.push('SOV') : this.preForm.proposalForm.importedParts = ['SOV']
          }
        }
      })
    }
  }

  downloadSOV(){
    this.riskService.downloadTemplate(this.product, 'SOV', 'xlsx')
  }

}
