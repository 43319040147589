<div class="sticky">
    <app-top-nav [navType]="'form'"></app-top-nav>
    <span [ngClass]="{'hide' : !status}" *ngIf="form && preForm"><app-default-currency [preForm]='preForm' [form]='form'></app-default-currency></span>
    <span *ngIf="(preForm?.status === riskStatus.Quoted || preForm?.status === riskStatus.Bound || preForm?.status === riskStatus.QuoteAccepted || preForm?.status === riskStatus.QuoteDeclined) && preForm"><app-download-risk [preForm]='preForm' [secName]="preForm?.status"></app-download-risk></span>
    <div class="progressContainer">
      <div class="progress-container p-0 m-0"></div>
      <div class="progress-bar" id="progressBar"></div>
    </div>
    <span *ngIf ="lastSaveVerbose" class="lastSave text">Last Saved: {{lastSaveVerbose}} </span>
</div>
<span *ngIf="preForm?.status === riskStatus.Quoted || preForm?.status === riskStatus.QuoteAccepted || preForm?.status === riskStatus.QuoteDeclined"><app-platform-risk-detail [preForm]='preForm'></app-platform-risk-detail></span>
<span *ngIf="preForm?.status === riskStatus.Bound"><app-confirmed-risk-detail [preForm]='preForm' [docID]='docID'></app-confirmed-risk-detail></span>

<ng-container *ngIf="prod.typeInArrToCheck(formType, prod.supportedProducts.UEquipmentBinder.typeArr) && preForm?.status">
  <pt-chat-ui [docID]='docID'></pt-chat-ui>
</ng-container>

<div class="container-fluid hidden">
  <div class="row">
    <div class="col-xxl-8 col-xl-10 col-lg-12 col-center form">
      <label *ngIf="preForm?.status === riskStatus.Expired" class="expiredRisk mt-5 p-3">
        This quote has now expired. To request a new quote please return to the home page and clone the expired quote as a new risk using the ellipses menu.
      </label>
      <app-family-tree *ngIf='contractDetails' [contractDetails]='contractDetails'></app-family-tree>
      <form [formGroup]="form" id='form' [ngClass]="{'platform' : !status}">
        <div appChooseProd></div>
        <div *ngIf ="status">
          <div class="col-12 pt-4 d-flex justify-content-end">
            <button name="save" class="button largeButton mb-5 me-4"  (click)="sendForm(userAction.save)" [disabled]='!form?.get("contractName")?.value || submitting || saving'>
              <span *ngIf="!saving">Save</span>
              <i *ngIf="saving"><mat-spinner diameter="27"></mat-spinner></i>
            </button>
            <button name="submit" class="button smallButton mb-5"  (click)="sendForm(userAction.submit)" [ngClass]="{'hide' : !form.valid}" [disabled]="!canSubmit || submitting">
              <span *ngIf="!submitting">Submit for Quote</span>
              <i *ngIf="submitting"><mat-spinner diameter="27"></mat-spinner></i>
            </button>
            <button name="incompleteData" class="button smallButton mb-5 me-4" [ngClass]="{'hide' : form.valid}" (click)="invalidControlScroll()">Show Incomplete Data</button>
          </div>
        </div>
      </form>
    </div>
</div>