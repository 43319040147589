import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ComponentCommunicationService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Enums } from '../../../shared-resources/models/contract.model';
import { Countries } from '../../../shared-resources/models/product.type';
import { ProductComponent } from '../update-contract.component';

@Component({
  selector: 'app-bourbon-form',
  templateUrl: './bourbon-form.component.html',
  styleUrls: ['./bourbon-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})

export class BourbonFormComponent implements OnInit, ProductComponent, OnDestroy {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;
  cargoType = new UntypedFormControl('');
  type = new Enums().cargoType;
  surplusRiskDisabled = false;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef,
    private comm: ComponentCommunicationService) {
  }

  ngOnInit(): void {
    this.handleCargoSetup();
  }

  handleCargoSetup() {
    this.preForm?.proposalForm?.cargoType ?
    this.cargoType.setValue(this.preForm?.proposalForm?.cargoType) :
    this.cargoType.setValue(this.type.Both);
    this.addCargoControl();
    this.insuredAddressListener();
  }

  transitSetStatus(disabled: boolean) {
    const prop = this.parent.form.get('proposalForm');
    setTimeout(() => {
      if (disabled && prop?.get('transitRiskOutgoing')?.enabled) {
        prop?.get('transitLimits')?.disable();
        prop?.get('transitRiskOutgoing')?.disable();
        prop?.get('transitRiskIncoming')?.disable();
      } else if (prop?.get('transitRiskOutgoing')?.disabled) {
        prop?.get('transitLimits')?.enable();
        prop?.get('transitRiskOutgoing')?.enable();
        prop?.get('transitRiskIncoming')?.enable();
      }
    });
  }

  setCargoType(cargo: string) {
    if (this.cargoType.value !== cargo) {
      this.cargoType.setValue(cargo);
      cargo === this.type.StockOnly ? this.transitSetStatus(true) :
      this.transitSetStatus(false);
    }
  }

  addCargoControl(){
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('cargoType', this.cargoType);
    if (this.preForm?.proposalForm?.cargoType === this.type.StockOnly) {
      this.transitSetStatus(true);
    }
  }

  insuredAddressListener(){
    this.comm.insuredAddressListener()
    .pipe(
      takeUntil(this.unsub)
    ).subscribe((insuredAddress: string) => {
      if(insuredAddress === Countries.CANADA){
        this.surplusRiskDisabled = true;
      } else {
        this.surplusRiskDisabled = false
      }
    })
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.unsubscribe();
  }

}
