import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Enums } from '../../../shared-resources/models/contract.model';
import { ProductComponent } from '../update-contract.component';

@Component({
  selector: 'app-casualty-form',
  templateUrl: './casualty-form.component.html',
  styleUrls: ['./casualty-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})

export class CasualtyFormComponent implements AfterViewInit, AfterContentInit, OnDestroy, ProductComponent {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;
  form: UntypedFormGroup;
  policyType = new UntypedFormControl(); casualtyType: UntypedFormControl; otherDetailCasualty: UntypedFormControl; isMember: UntypedFormControl;
  enums = new Enums();
  salesType: number; option:number;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, private cdr : ChangeDetectorRef) {
    this.form = parent.form;
  }

  ngAfterViewInit(){
    this.otherDetailCasualty = this.form.get('proposalForm')?.get('overviewOfBusiness')?.get('otherDetail') as UntypedFormControl;
    this.casualtyType = this.form.get('proposalForm')?.get('overviewOfBusiness')?.get('casualtyIndustrySector') as UntypedFormControl;
    this.afterViewSetup();
  }

  ngAfterContentInit(){
    setTimeout(() => {
      this.isMember = this.form.get('proposalForm')?.get('totalSalesRevenues')?.get('optionTwo')?.get('isMemberOfListedOrg') as UntypedFormControl;
    })
  }

  afterViewSetup(){
    if(!this.casualtyType.value){
      this.casualtyType.setValue('Roofing Contractors')
    }
    this.toggleCasualty(this.policyType.value, this.casualtyType.value);
    this.listenToPolicyTypeChanges();
    this.listenToCasualtyTypeChanges();
  }

  listenToPolicyTypeChanges(){
    this.policyType.valueChanges
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((change) => {
      this.toggleCasualty(change, this.casualtyType.value)
    });
  }

  listenToCasualtyTypeChanges(){
    this.casualtyType.valueChanges
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((change) => {
      this.toggleCasualty(this.policyType.value, change)
    });
  }

  setMember(s: boolean) {
    this.isMember.setValue(s);
  }

  toggleCasualty(policy: string, casualty: string){
    if(policy === this.enums.policyType.effl.value){
      this.salesType = 1;
      this.option = 4;
    }
    if(policy === this.enums.policyType.ul.value){
      this.salesType = 1;
      this.option = 3;
    }
    if(policy === this.enums.policyType.pcgl.value && casualty !== "Roofing Contractors"){
      this.salesType = 1;
      this.option = 1;
    }
    if(policy === this.enums.policyType.pcgl.value && casualty === "Roofing Contractors"){
      this.salesType = 2;
      this.option = 2;
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(){
    this.unsub.next(true);
    this.unsub.complete();
  }
}
