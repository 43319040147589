<div class="d-flex justify-content-end mt-3 me-4">
    <input type='text' placeholder="Search for risks" #s/>
    <button (click)='searchRisk(s.value)'>Search</button>
    <button class="ms-1" (click)='manageRisks()'>All risks</button>
</div>
  
<div class="col mt-3">
    <app-monthly-report></app-monthly-report>
</div>
  
<div class="listContainer">
    <app-admin-risk-list [contracts]='contracts'></app-admin-risk-list>
</div>