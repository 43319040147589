import { Component, OnInit, ElementRef, ChangeDetectorRef, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Enums } from '../../shared-resources/models/contract.model';
import { MatDialog } from '@angular/material/dialog';
import { ComponentCommunicationService, modelUtils, PortalDataService, PortalUtilService, RiskService, UpdateContractBaseComponent } from 'portal';
import { Product } from '../../shared-resources/models/product.type';
import { ChooseProdComponentDirective } from '../../shared-resources/directives/choose-prod-component.directive';

@Component({
  selector: 'app-update-contract',
  templateUrl: './update-contract.component.html',
  styleUrls: ['./update-contract.component.scss']
})
export class UpdateContractComponent extends UpdateContractBaseComponent implements OnInit {
  model = new Enums();
  riskStatus = new modelUtils().risk;
  prod = new Product();
  componentRef : any
  @ViewChild(ChooseProdComponentDirective, {static: true}) appChooseProd!: ChooseProdComponentDirective;
  constructor(public activeRoute: ActivatedRoute, public portalDataService: PortalDataService, public router: Router,
    public el: ElementRef, public cdr: ChangeDetectorRef, public comm : ComponentCommunicationService, public dialog: MatDialog,
    public pUtil: PortalUtilService, private componentFactoryResolver: ComponentFactoryResolver, public riskService: RiskService) 
    { super(activeRoute, portalDataService, router, el, cdr, comm, dialog, pUtil, riskService)}

  ngOnInit(){
    super.ngOnInit();
  }

  getSubmitPopupData(){
    const prod = new Product();
    let data = prod.findProductType(this.pUtil.addSpace(this.formType));
    return data?.popupData;
  }

  beforeHandleJsonSave(formData: any, riskData: any){
    if (riskData?.scheduleOfValues && formData?.proposalForm?.cargoType !== this.model.cargoType.TransitOnly) {
      formData.proposalForm.scheduleOfValues = riskData?.scheduleOfValues;
    }
    if(riskData?.importedParts){
      formData.proposalForm.importedParts = riskData.importedParts
    }
    return formData;
  }

  loadComponent() {
    if(!this.componentRef){
      const component : Type<any> = new Product().findProductType(this.formType).component
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const viewContainerRef = this.appChooseProd.viewContainerRef;
      this.componentRef = viewContainerRef.createComponent<ProductComponent>(componentFactory);
    }

    this.componentRef.instance.docID = this.docID
    this.componentRef.instance.platformStatus = this.status
    this.componentRef.instance.preForm = this.preForm
    this.componentRef.instance.productType = this.formType
    this.cdr.detectChanges();
  }
}

export interface ProductComponent {
  docID: any;
  platformStatus: any;
  preForm: any;
  productType: any;
}