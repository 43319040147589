<div *ngIf="quoteIDs" class="offset-xxl-2 offset-xl-1 col-xxl-8 col-xl-10 col-lg-12 platformSub">
    <div class="platformSubCol">
        <div class="quoteToggleSection">
            <div class="quoteLogos">
                <div class="quoteLogoWrapper">
                    <div class="col-center" id="quoteLogo"></div>
                </div>
                <div *ngIf="quoteIDs.quotes.length <= 1" class="pointer doubleArrowUpWrapper">
                    <div *ngIf="!detail" (click) = "toggleDetail()" class="col-center" id="doubleArrowDown"></div>
                    <div *ngIf="detail" (click) = "toggleDetail()" class="col-center" id="doubleArrowUp"></div>
                </div>
            </div>
        </div>
        <div *ngIf="quoteIDs.quotes.length <= 1" class="quoteInfo d-flex" [ngClass]="{'justify-content-end': preForm.status !== riskStatus.Quoted && !prodQuoteDetails.quoteSummary}">
            <ng-container *ngIf="prodQuoteDetails.quoteSummary; else seeBelowForExtraInfo">
                <div class="quoteWrapper d-flex justify-content-around">
                    <div class="nowrap quotePremWrapper me-3" name="premium">
                        <div class="d-flex">
                            <div class="ms-auto" id="billIcon">
                            </div>
                            <label>Quoted Premium</label>
                        </div>
                        <span class="quoteAmount medium"> {{prem}} </span>
                        <div *ngIf="prodQuoteDetails.tria && triaUSD" class="d-flex">
                            <div class="ms-auto" id="billIcon">
                            </div>
                            <label>Additional TRIA Premium</label>
                        </div>
                        <span class="quoteAmount medium"> {{triaUSD}} </span>
                    </div>
                    <div class="nowrap quoteDeducWrapper" name="deductible">
                        <div class="d-flex">
                            <div id="mailIcon">
                            </div>
                            <label>Deductible Clause</label>
                        </div>
                        <span [innerHtml]="deduc" class="quoteAmount ms-3"></span>
                    </div>
                </div>
            </ng-container>
            <ng-template #seeBelowForExtraInfo>
                <ng-container *ngIf="preForm.status === riskStatus.Quoted">
                    <div class="rates singleQuoteRates">
                        <ng-container *ngTemplateOutlet="ratesControlsTwo; context:{$implicit: ratesControls}"></ng-container>
                    </div>
                </ng-container>
            </ng-template>
            <div *ngIf ='!util.expired(preForm) || preForm.status !== riskStatus.Quoted' class="quoteButtons d-flex justify-content-end">
                <button (click)='rejectQuote("reject")' [ngClass]="{'primary' : preForm.status === riskStatus.QuoteDeclined}" [disabled]="preForm.status !== riskStatus.Quoted" class="d-flex justify-content-center align-items-center quoteButton">
                    <span *ngIf="spin !== 'reject'">REJECT QUOTE</span>
                    <i *ngIf="spin === 'reject'"><mat-spinner diameter="27"></mat-spinner></i>
                </button>
                <button (click)='checkIfUserCanAcceptQuote()' [ngClass]="{'primary' : preForm.status === riskStatus.QuoteAccepted}" [disabled]="getButtonStatus()" class="d-flex justify-content-center align-items-center quoteButton">
                    <span *ngIf="spin !== 'accept'">ACCEPT QUOTE</span>
                    <i *ngIf="spin === 'accept'"><mat-spinner diameter="27"></mat-spinner></i>
                </button>
                <ng-container *ngIf="triaCheck()">
                    <div class="tria">
                        <label class="triaButton">
                            <span class="me-4">Include additional TRIA coverage</span>
                            <input pt-appBoolControl name="tria" type="radio"  value="yes" [boolControl]="tria">
                        </label>
                        <label class="triaButton">
                            <span class="me-4">Decline additional TRIA coverage</span>
                            <input pt-appBoolControl name="tria" type="radio"  value="no" [boolControl]="tria">
                        </label>
                    </div>
                </ng-container>
                <ng-container *ngIf="prodQuoteDetails.quoteSummary && prodQuoteDetails.commissionNotStandard && preForm.status === riskStatus.Quoted">
                    <div class="tria">
                        <label class="triaButton">
                            <span class="me-4">Commission is Standard</span>
                            <input pt-appBoolControl name="commissionNotStandard" type="radio"  value="yes" [boolControl]="ratesControl.get('rateControl')">
                        </label>
                        <label class="triaButton">
                            <span class="me-4">Commission is not Standard</span>
                            <input pt-appBoolControl name="commissionNotStandard" type="radio"  value="no" [boolControl]="ratesControl.get('rateControl')">
                        </label>
                    </div>
                </ng-container>
                <ng-container *ngIf="ratesControl.get('rateControl')?.value === false &&
                                prodQuoteDetails.quoteSummary && prodQuoteDetails.commissionNotStandard
                                && preForm.status === riskStatus.Quoted">
                    <div class="casualtyRates singleQuote">
                        <ng-container *ngTemplateOutlet="commissionOnly"></ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="quoteIDs.quotes.length > 1" class="quotesInfo">
            <div class="multipleQuotes">
                <h1 class="mb-5 p-0">Multiple Quotes</h1>
                <div class="ms-4 me-4">
                    <tbody>
                        <ng-container *ngFor="let quote of quoteIDs.quotes let i=index">
                            <tr class="spacer" *ngIf="i > 0"></tr>
                            <tr class="align-middle">
                                <th class="p-2 align-middle leftcell" [ngClass]="{'rejected':isQuoteRejected(quote.quoteID), 'accepted':isQuoteAccepted(quote.quoteID)}" scope="row"><h3 class="m-0 pe-4">Quote {{i + 1}}</h3></th>
                                <td class="p-2 align-middle" [ngClass]="{'rejected':isQuoteRejected(quote.quoteID), 'accepted':isQuoteAccepted(quote.quoteID)}">
                                    <div>
                                        <h5 class="m-0 p-0"><strong>Premium: </strong>
                                            {{pUtil.getDecimalFigureFormat(quote.premium.figure, quote.premium.currency)}}
                                        </h5>
                                        <h5 class="m-0 p-0"><strong>Deductible: </strong>
                                            {{pUtil.getDecimalFigureFormat(quote.deductibles[0]?.figure, quote.deductibles[0]?.currency)}}
                                        </h5>
                                    </div>
                                </td>
                                <td class="p-2 align-middle" [ngClass]="{'rejected':isQuoteRejected(quote.quoteID), 'accepted':isQuoteAccepted(quote.quoteID)}">
                                    <div *ngIf="preForm">
                                        <app-download-button [preForm]="preForm" [quoteID]="quote.quoteID"></app-download-button>
                                    </div>
                                </td>
                                <td class="p-2 align-middle rightcell" [ngClass]="{'rejected':isQuoteRejected(quote.quoteID), 'accepted':isQuoteAccepted(quote.quoteID)}" *ngIf="!(isQuoteRejected(quote.quoteID)||isQuoteAccepted(quote.quoteID)); else managedMessage">
                                    <div class="quoteButtons">
                                        <button (click)="rejectQuote('reject' + i, quote.quoteID)"  class="d-flex justify-content-center align-items-center quoteButton" [disabled]="preForm.status !== riskStatus.Quoted">
                                            <span *ngIf="spin !== 'reject' + i">REJECT QUOTE</span>
                                            <i *ngIf="spin === 'reject' + i"><mat-spinner diameter="27"></mat-spinner></i>
                                        </button>
                                        <button (click)="acceptQuote('accept' + i, tria, ratesControl, quote.quoteID)" class="d-flex justify-content-center align-items-center quoteButton" [disabled]="preForm.status !== riskStatus.Quoted || commissionNotStandard()">
                                            <span *ngIf="spin !== 'accept' + i">ACCEPT QUOTE</span>
                                            <i *ngIf="spin === 'accept' + i"><mat-spinner diameter="27"></mat-spinner></i>
                                        </button>
                                    </div>
                                </td>
                                <ng-template #managedMessage>
                                    <td class="p-2 align-middle rightcell rejected medium" [ngClass]="{'rejected':isQuoteRejected(quote.quoteID), 'accepted':isQuoteAccepted(quote.quoteID)}">
                                        Quote {{ isQuoteAccepted(quote.quoteID) ? "Accepted" : "Not Taken Up" }}
                                    </td>
                                </ng-template>
                            </tr>
                        </ng-container>
                    </tbody>
                    <ng-container *ngIf="preForm.status === riskStatus.Quoted">
                        <div class="rates casualtyRates multipleQuote">
                            <ng-container *ngTemplateOutlet="ratesControlsTwo; context:{$implicit: commissionOnly}"></ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf='detail'>
    <app-quote-pane [preForm]="preForm"></app-quote-pane>
</ng-container>


<ng-template #commissionOnly>
    <div class="controlInput figureInput">
        <input pt-appPercentage [inputControl]="ratesControl?.get('rates')?.get('commission')">
        <label class="controlPercentageLabel">
            %
        </label>
        <label class="controlLabel">
            Commission*
        </label>
    </div>
    <label class="wmbCommission">WMB total commissions are 21.75%. Please enter a lesser amount if you would like to nett down the premium</label>
    <mat-error *ngIf="this.ratesControl.invalid">
        Total commission exceeded
    </mat-error>
</ng-template>

<ng-template #ratesControls>
    <div class="controlInput figureInput">
        <input pt-appPercentage [inputControl]="ratesControl?.get('rates')?.get('commission')">
        <label class="controlPercentageLabel">
            %
        </label>
        <label class="controlLabel">
            Commission*
        </label>
    </div>

    <div class="controlInput figureInput">
        <input pt-appPercentage [inputControl]="ratesControl?.get('rates')?.get('brokerage')">
        <label class="controlPercentageLabel">
            %
        </label>
        <label class="controlLabel">
            Brokerage*
        </label>
    </div>
</ng-template>

<ng-template #ratesControlsTwo let-context>
    <div class="ratesWrapper">
        <label class="text mb-1">Is commission standard?</label>
        <label class="triaButton ms-1">
            <span class="text">Yes</span>
            <input pt-appBoolControl name="commissionNotStandard" type="radio"  value="yes" [boolControl]="ratesControl.get('rateControl')">
        </label>
        <label class="triaButton ms-1">
            <span class="text">No</span>
            <input pt-appBoolControl name="commissionNotStandard" type="radio"  value="no" [boolControl]="ratesControl.get('rateControl')">
        </label>
    </div>

    <ng-container *ngIf="ratesControl.get('rateControl')?.value === false">
        <div class="controls ms-5 mt-2">
            <ng-container [ngTemplateOutlet]="context"></ng-container>
        </div>
    </ng-container>
</ng-template>