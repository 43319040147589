import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-component-three',
  templateUrl: './coverage-component-three.component.html',
  styleUrls: ['./coverage-component-three.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class CoverageComponentThreeComponent implements OnInit {
  @Input() preForm: any;
  @Input() set disabled(value: boolean) {
    if(value || value === false) {
      this.setState(value)
    }
  }
  coverageDates = new UntypedFormGroup({
    employeeBenefit : new UntypedFormControl('' , [Validators.required]),
    fWLiability : new UntypedFormControl(''),
    eAndOLiability : new UntypedFormControl(''),
  });
  constructor(private parent: FormGroupDirective, public pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('coverageRequired') as UntypedFormGroup)?.addControl('coverageDates', this.coverageDates);
    this.setupDates();
  }

  setupDates(){
    const dates = this.preForm?.proposalForm?.coverageRequired?.coverageDates;
    if(dates){
      this.coverageDates.patchValue({
        employeeBenefit : dates?.employeeBenefit,
        fWLiability: dates?.fWLiability,
        eAndOLiability: dates?.eAndOLiability,
      });
    }
  }

  setState(disabled: boolean){
    disabled ? this.coverageDates.disable() : this.coverageDates.enable();
  }

}
