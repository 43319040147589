<ng-container *ngIf = 'preForm'>
    <section pt-appDisableAll [disableAll] = '!platformStatus' class="formSection" id="section1">

        <app-insured-address [preForm]='preForm' [productType]='productType'></app-insured-address>
        <app-business-overview [preForm]='preForm'></app-business-overview>
        <ng-container *ngIf="parent.form.get('proposalForm')?.get('overviewOfBusiness')">
            <app-business-detail [overviewOfBusiness]="parent.form.get('proposalForm')?.get('overviewOfBusiness')"
             [data]='preForm?.proposalForm?.overviewOfBusiness'></app-business-detail>
        </ng-container>
        <app-policy-type-eq [data]='preForm?.proposalForm?.policyType'></app-policy-type-eq>
        <app-coverage-required-eq [preForm]='preForm'></app-coverage-required-eq>
        <app-equipment-schedule [preForm]='preForm'></app-equipment-schedule>
        <app-loss-experience-array [secName]='"6. LOSS EXPERIENCE"' [preForm]='preForm' [productType]='productType' [cargoType]='cargoType'></app-loss-experience-array>
        <ng-container *ngIf="parent.form.get('proposalForm')?.get('coverageRequired')">
            <app-insurers-declined-to-renew [coverageRequired]="parent.form.get('proposalForm')?.get('coverageRequired')"
            [preForm]='preForm'></app-insurers-declined-to-renew>
        </ng-container>
        <ng-container *ngIf="parent.form.get('proposalForm')?.get('policyType') && 
                            parent.form.get('proposalForm')?.get('coverageRequired')?.get('lossOfUseHigherLimit')">
            <app-suggested-rating [policyType]="parent.form.get('proposalForm')?.get('policyType')?.get('type').value" [preForm]='preForm'
                                    [covLossOfUse]="parent.form.get('proposalForm')?.get('coverageRequired')?.get('lossOfUseHigherLimit')?.value"></app-suggested-rating>
        </ng-container>
        <app-disclaimer [platformStatus]='platformStatus'></app-disclaimer>
    </section>
</ng-container>