<div class="offset-xxl-2 offset-xl-1 col-xxl-8 col-xl-10 col-lg-12 pb-2">
    <div class="detail">
        <div class="premiumButtons">
            <ng-container *ngIf='paneData'>
                <ng-container *ngFor="let pane of paneData">
                    <button (click)='premium(pane.header)' class="premiumButton pButton d-flex" [ngClass]="{'active' : show === pane.header}">
                        <div id="billIcon"></div>
                        {{prod.mrcHeadingKeys?.[pane.header] ?? pane.header}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf='paneData'>
            <ng-container *ngFor="let pane of paneData">
                <div *ngIf="show === pane.header" class="detailText text">
                    <span *ngFor="let text of pane.text">
                        <p>{{text}}</p>
                    </span>  
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>