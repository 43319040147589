<app-top-nav></app-top-nav>
<div class="productOptions">
    <h1>Select the type of quote required:</h1>
    <div class="mainContent mt-5 mb-5">
        <div class="row">
            <ng-container *ngFor="let prod of availableProducts let i=index">
                <div class="col-md-4 col-sm-6 col-xs-12" *ngIf='products.userProductIncludesType(productList, prod.key)'>
                    <div class="item" (click)="chooseRisk(prod.key[0])" [ngClass]="{'active' : riskType === prod.key[0]}">
                        <div [id]= "prod.id">
                        </div>
                        <label>
                            {{prod.display}}
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="productOptionButtons">
        <button [routerLink]="['/contracts']" class="button largeButton me-3">BACK</button>
        <button [disabled]="!riskType" (click)='navigate()' class="button smallButton primary">CONTINUE</button>
    </div>
</div>