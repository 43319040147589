import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-information-display-popup',
  templateUrl: './information-display-popup.component.html',
  styleUrls: ['./information-display-popup.component.scss']
})
export class InformationDisplayPopupComponent implements OnInit {
  message: any;
  constructor(public ref: MatDialogRef<InformationDisplayPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.message = this.data;
  }

  closePopup() {
    this.ref.close();
  }
}
