import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormGroupDirective, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-machine-equipment-limits',
  templateUrl: './machine-equipment-limits.component.html',
  styleUrls: ['./machine-equipment-limits.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class MachineEquipmentLimitsComponent implements OnInit {
  private form: UntypedFormGroup;
  @Input() preForm: any;
  constructor(private parent: FormGroupDirective) {
    this.form = this.parent.form;
  }

  ngOnInit(): void {
    const machineryAndEquipmentLimits = new UntypedFormGroup({
      limitAverageAnyOneConveyance: new UntypedFormControl(''),
      limitMaximumValueAnyOneConveyance: new UntypedFormControl(''),
    });
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('machineryAndEquipmentLimits', machineryAndEquipmentLimits);
    this.setupMachineLimits(machineryAndEquipmentLimits);
  }

  setupMachineLimits(form: UntypedFormGroup) {
    const machineLimits = this.preForm.proposalForm?.machineryAndEquipmentLimits;
    if (machineLimits) {
      form.patchValue({
        limitAverageAnyOneConveyance: machineLimits?.limitAverageAnyOneConveyance,
        limitMaximumValueAnyOneConveyance: machineLimits?.limitMaximumValueAnyOneConveyance,
    });
    }
  }

}
