import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ControlContainer, FormGroupDirective, Validators } from '@angular/forms';
import { PortalUtilService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Product } from '../../models/product.type';

@Component({
  selector: 'app-insured-address',
  templateUrl: './insured-address.component.html',
  styleUrls: ['./insured-address.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class InsuredAddressComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() productType: string;
  public form: UntypedFormGroup;
  insuredAddress = new UntypedFormControl('');
  contractName = new UntypedFormControl('' , [Validators.required]);
  product = new Product()
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, public pUtil: PortalUtilService) { }
  @Input() preForm: any;
  ngOnInit() {
    this.form = this.parent.form;
    this.form.addControl('contractName', this.contractName);
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('insuredAddress', this.insuredAddress);
    this.insuredAddress.setValue(this.preForm?.proposalForm?.insuredAddress);
  }

  ngAfterViewInit(){
    this.onValueChanges();
    this.contractName.setValue(this.preForm?.contractName);
  }

  onValueChanges(){
    this.contractName.valueChanges
    .pipe(
      takeUntil(this.unsub)
    ).subscribe((val:string) => {
      val?.length > 75 && !this.product.typeInArrToCheck(this.productType, this.product.supportedProducts.UEquipmentBinder.typeArr)
      ? this.contractName.setErrors({contractNameTooLong: true}) : delete this.contractName?.errors?.['contractNameTooLong'];
    })
  }

  ngOnDestroy(){
    this.unsub.next(true);
    this.unsub.complete();
  }
}

