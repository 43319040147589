<section #chat>
    <div class="chatWrapper">
        <div class="chatboxButton" [ngClass]="{'on' : chatToggle, 'spaceBetween': chatToggle, 'flexEnd': !chatToggle}" (click)='toggleChat()'>
            <label id="chatLabel" [ngClass]="{'on' : chatToggle}">Underwriter Messages</label>
            <span id="notification" *ngIf="messages?.unRead"></span>
            <img class="imgLogo" alt="Chatbox">
        </div>
        <div id="chatboxWrapper" [ngClass]="{'on' : chatToggle}">
            <div class="chatBox">
                <div class="commentDisplayWrapper customScroll" id="commentDisplay">
                    <div class="commentDisplay">
                        <div *ngFor="let message of messages?.messages ?? []">
                            <div class="commentList d-flex flex-column" [ngClass]="{'flexStart': message?.sendingParty !== 'underscore', 'flexEnd': message.sendingParty === 'underscore'}">
                                <div class="commentGroup">
                                    <div class="d-flex pb-1 pe-1 ps-1" [ngClass]="{'order-2': message?.sendingParty !== 'underscore', 'order-1': message.sendingParty === 'underscore'}">
                                        <span class="commentTime">{{getTime(message?.isoTime)}}</span>
                                        <span class="commentDate ps-2">{{getDate(message?.isoTime)}}</span>
                                    </div>
                                    <div class="comment" [ngClass]="{'userComment' : message.sendingParty === 'underscore'}" [ngClass]="{'order-1': message?.sendingParty !== 'underscore', 'order-2': message.sendingParty === 'underscore'}">
                                        {{message.message}}
                                    </div>
                                </div>
                                <div class="commentUser">{{message.sendingParty === 'underscore' ? 'you' : message.sendingParty}}</div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="commentBoxWrapper">
                    <div class="commentBox d-flex">
                        <textarea class="customScroll" [formControl]='chatBox'></textarea>
                        <button class="button" (click)="messageSend()" [disabled]='chatBox.value?.trim()?.length < 1'>
                            <div class="sendCommentLabel">SEND</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>