import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService, PTDisplayVal } from 'portal';
import { Product } from '../../models/product.type';

@Component({
  selector: 'app-basis-of-valuation',
  templateUrl: './basis-of-valuation.component.html',
  styleUrls: ['./basis-of-valuation.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class BasisOfValuationComponent implements OnInit, OnChanges {
  @Input() preForm: any;
  @Input() basisOfValuationArray: string;
  basisOfValuation = new UntypedFormControl('' , [Validators.required]);
  basisOfValuationArr: PTDisplayVal[];
  header: string;

  constructor(private parent: FormGroupDirective, private activeRoute: ActivatedRoute,
    private pUtil: PortalUtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('basisOfValuation', this.basisOfValuation);
    this.basisOfValuation.setValue(this.preForm?.proposalForm?.basisOfValuation);
  }
  
  ngOnChanges(changes: SimpleChanges){
    if(changes?.basisOfValuationArray?.currentValue){
      if(changes?.basisOfValuationArray?.previousValue){
        this.basisOfValuation.reset();
      }
      this.setBasisArr(changes?.basisOfValuationArray?.currentValue);
    }
  }

  setType(s: string) {
    this.basisOfValuation.setValue(s);
  }

  setBasisArr(value?: string){
    this.activeRoute.paramMap.subscribe(params => {
      const prod = new Product();
      let data = prod.findProductType(this.pUtil.addSpace(params.get('prod')));
      this.basisOfValuationArr = data?.basisOfValuation?.[value?.toLocaleLowerCase() || ''];
      this.header = data?.basisOfValuation?.header;
    });
  }


}
