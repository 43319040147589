import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { PortalDataService, UserListDisplayBaseComponent } from 'portal';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent extends UserListDisplayBaseComponent implements OnInit {
  constructor(public portalDataService: PortalDataService, public router: Router
  ) { super(portalDataService, router) }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
