<div class="schedule">
    <app-top-nav></app-top-nav>
    <div class="scheduleOfValues">
        <form [formGroup]='form'>
            <span appChooseProd></span>
        </form>
    </div>
    <div class="scheduleButtons pb-2">
        <button (click)='handleRoute()' class="button largeButton">Back</button>
        <button [disabled] = "!form.valid" *ngIf="!status" (click)='saveSchedule()' class="button largeButton primary">Save</button>
    </div>
</div>