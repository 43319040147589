import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ComponentCommunicationService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Enums } from '../../../../shared-resources/models/contract.model';
import { Countries } from '../../../../shared-resources/models/product.type';
import { CargoSchedule } from '../../../../shared-resources/models/sov.model';
import { ProductComponent } from '../../update-contract.component';

@Component({
  selector: 'app-cargo',
  templateUrl: './cargo.component.html',
  styleUrls: ['./cargo.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})

export class CargoComponent implements OnInit, AfterViewInit, ProductComponent, OnDestroy {
  @Input() preForm: any;
  @Input() platformStatus: boolean;
  @Input() docID: string;
  @Input() productType: string;
  cargoType = new UntypedFormControl('');
  otherDetail: UntypedFormControl;
  interestType: UntypedFormControl;
  type = new Enums().cargoType;
  surplusRiskDisabled = false;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef,
    private comm: ComponentCommunicationService) {
  }

  ngAfterViewInit(){
    this.interestType = this.parent.form.get('proposalForm')?.get('overviewOfBusiness')?.get('interestType') as UntypedFormControl;
    this.otherDetail = this.parent.form.get('proposalForm')?.get('overviewOfBusiness')?.get('otherDetail') as UntypedFormControl;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.handleCargoSetup();
  }

  handleCargoSetup() {
    this.preForm?.proposalForm?.cargoType ?
    this.cargoType.setValue(this.preForm?.proposalForm?.cargoType) :
    this.cargoType.setValue(this.type.Both);
    CargoSchedule.cargoScheduleOptions.get('cargoType')?.setValue(this.cargoType.value)
    this.addCargoControl();
    this.insuredAddressListener();
  }

  transitSetStatus(disabled: boolean) {
    const prop = this.parent.form.get('proposalForm');
    setTimeout(() => {
      if (disabled && prop?.get('transitRiskOutgoing')?.enabled) {
        prop?.get('transitLimits')?.disable();
        prop?.get('transitRiskOutgoing')?.disable();
        prop?.get('transitRiskIncoming')?.disable();
      } else if (prop?.get('transitRiskOutgoing')?.disabled) {
        prop?.get('transitLimits')?.enable();
        prop?.get('transitRiskOutgoing')?.enable();
        prop?.get('transitRiskIncoming')?.enable();
      }
    });
  }

  setCargoType(cargo: string) {
    if (this.cargoType.value !== cargo) {
      CargoSchedule.cargoScheduleOptions.get('cargoType')?.setValue(cargo)
      this.cargoType.setValue(cargo);
      cargo === this.type.StockOnly ? this.transitSetStatus(true) :
      this.transitSetStatus(false);
    }
  }

  addCargoControl(){
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('cargoType', this.cargoType);
    if (this.preForm?.proposalForm?.cargoType === this.type.StockOnly) {
      this.transitSetStatus(true);
    }
  }

  insuredAddressListener(){
    this.comm.insuredAddressListener()
    .pipe(
      takeUntil(this.unsub)
    ).subscribe((insuredAddress: string) => {
      if(insuredAddress === Countries.CANADA){
        this.surplusRiskDisabled = true;
      } else {
        this.surplusRiskDisabled = false
      }
    })
  }

  ngOnDestroy() {
    CargoSchedule.cargoScheduleOptions.get('cargoType')?.reset()
    this.unsub.next(true);
    this.unsub.unsubscribe();
  }

}
