<app-top-nav></app-top-nav>
<div class="d-flex justify-content-end mt-3 me-4">
    <button (click)="redirectToAdmin('Admin Page')">Back to admin page</button>
</div>
<div class="ps-3 pb-3">
    <button (click)="redirectToAdmin('User Accounts')">Back to user list</button>
</div>
<div *ngIf="userdata" class="ps-3 pe-4 pt-4 pb-4">
    <h3><strong>User details...</strong></h3>

    <div *ngIf="selectedUsers.length">
        <app-update-details-form [selectedUsers]="selectedUsers" [userdata]="userdata"></app-update-details-form>
    </div>

    <div class="pt-5 col-xl-5 col-lg-6 col-md-7 col-sm-8 col-12 col-center">
        <app-user-product-cva [formControl]='productsControl' [email]='email'></app-user-product-cva>
    </div>
</div>

