<div formGroupName="proposalForm">
    <div formGroupName="coverageRequired">
        <div formGroupName="coverage" class="col-12 p-0">
            <div class='d-flex coverageRequiredCol' name="coverageRequired">
                <div><app-amount [required]="true" [name]="'Limit'" formControlName="limit"></app-amount></div>
                <div><app-amount [required]="true" [name]="'Deductible'" formControlName="deductible"></app-amount></div>
                <div><app-amount [required]="true" [name]="'Target Premium'" formControlName="targetPremium"></app-amount></div>
            </div>
            <div class="mt-5 mb-3 text">
                <p class="mb-3">Do you require additional options?</p>
                <div class="d-flex">
                    <span>No</span>
                    <label class="switch me-3 ms-3">
                        <input (click)="toggleAdditionalInfo()" type="checkbox" [attr.checked]="isAdditionalLimit ? true : null">
                        <span class="slider"></span>
                    </label>
                    <span>Yes</span>
                </div>
            </div>
            <div *ngIf="isAdditionalLimit" formArrayName='additionalLimits'>
                <div class="d-flex coverageRequiredColArr" *ngFor="let limit of getLimits(); let i=index" [formGroupName] = "i">
                    <app-amount [required]="true" [name]="'Limit'" formControlName="limit"></app-amount>
                    <app-amount [required]="true" [name]="'Deductible'" formControlName="deductible"></app-amount>
                    <app-amount [required]="true" [name]="'Target Premium'" formControlName="targetPremium"></app-amount>
                </div>
            </div>
            <div *ngIf="isAdditionalLimit" class="col-12 d-flex justify-content-end">
                <span *ngIf="getLimits().length > 1">
                    <button  class="button smallButton" (click)="removeLimitControl(getLimits().length - 1)">Remove  -</button>
                </span>
                <span class="ms-1">
                    <button class="button largeButton" (click)="addLimitControl()">Add  +</button>
                </span>
            </div>
        </div>
    </div>
</div>