import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, FormControlName, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ComponentCommunicationService } from 'portal';
import * as _ from 'lodash';

@Component({
  selector: 'app-coverage-required-eq',
  templateUrl: './coverage-required-eq.component.html',
  styleUrls: ['./coverage-required-eq.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class CoverageRequiredEQComponent implements OnInit {
  @Input() preForm: any;

  coverageRequired = new UntypedFormGroup({
    transportationByWater : new UntypedFormControl('', [Validators.required]),
    bargingNotInProtectedWater : new UntypedFormGroup({
      area : new UntypedFormControl('', [Validators.required]),
      annualFrequency : new UntypedFormControl('', [Validators.required]),
    }),
    bargingNotOnFreshwater : new UntypedFormGroup({
      area : new UntypedFormControl('', [Validators.required]),
      annualFrequency : new UntypedFormControl('', [Validators.required]),
    }),
    usedByApplicant : new UntypedFormControl('', [Validators.required]),
    maintenanceScheduleBasis : new UntypedFormControl('', [Validators.required]),
    inHouseMechanics : new UntypedFormGroup({
      condition : new UntypedFormControl('', [Validators.required]),
      cleaningFrequency : new UntypedFormControl('', [Validators.required]),
    }),
    motorTruckCargo : new UntypedFormGroup({
      estimatedNoOfSending : new UntypedFormControl('', [Validators.required]),
      avgValuesPerSending : new UntypedFormControl('', [Validators.required]),
      maxValuesPerSending : new UntypedFormControl('', [Validators.required]),
    }),
    inBoardFireSuppressionOnHarvesters : new UntypedFormControl('', [Validators.required]),
    otherPortableFireExtinguishersInboard : new UntypedFormControl('', [Validators.required]),
/*     lossOfUse : new FormControl('', [Validators.required]), */
    coverageForForestFirefighting : new UntypedFormControl('', [Validators.required]),
    coverageForLogs : new UntypedFormArray([], [Validators.required]),
    lossOfUseHigherLimit : new UntypedFormControl('', [Validators.required]),
    relatedWatercraftDescription : new UntypedFormControl('', [Validators.required]),
    logsWhileOnLand : new UntypedFormControl('', [Validators.required]),
  });

  converageRequiredCloned: UntypedFormGroup;
  allText = new CoverageRequiredText()
  policyType: string = 'CFP';
  listOfEnabledToggles : string[] = [];
  toggleInformation : any = {}
  setupComplete = false
  
  constructor(private comm: ComponentCommunicationService, private parent: FormGroupDirective, private cdr: ChangeDetectorRef) {
    (this.parent.form.get('proposalForm') as UntypedFormGroup).addControl('coverageRequired', this.coverageRequired);
    this.converageRequiredCloned = _.cloneDeep(this.coverageRequired)
  }

  ngOnInit(): void {
    this.policyTypeListener()
    this.setupCoverageRequired();
  }

  setupCoverageRequired(){
    const coverageRequired = this.preForm.proposalForm?.coverageRequired
    if(coverageRequired){
      this.coverageRequired.patchValue({
        transportationByWater : coverageRequired?.transportationByWater,
        bargingNotInProtectedWater : ({
          area : coverageRequired?.bargingNotInProtectedWater?.area,
          annualFrequency : coverageRequired?.bargingNotInProtectedWater?.annualFrequency
        }),
        bargingNotOnFreshwater : ({
          area : coverageRequired?.bargingNotOnFreshwater?.area,
          annualFrequency : coverageRequired?.bargingNotOnFreshwater?.annualFrequency
        }),
        usedByApplicant : coverageRequired?.usedByApplicant,
        maintenanceScheduleBasis : coverageRequired?.maintenanceScheduleBasis,
        inHouseMechanics : ({
          condition : coverageRequired?.inHouseMechanics?.condition,
          cleaningFrequency : coverageRequired?.inHouseMechanics?.cleaningFrequency,
        }),
        motorTruckCargo : ({
          estimatedNoOfSending : coverageRequired?.motorTruckCargo?.estimatedNoOfSending,
          avgValuesPerSending : coverageRequired?.motorTruckCargo?.avgValuesPerSending,
          maxValuesPerSending : coverageRequired?.motorTruckCargo?.maxValuesPerSending,
        }),
        inBoardFireSuppressionOnHarvesters : coverageRequired?.inBoardFireSuppressionOnHarvesters,
        otherPortableFireExtinguishersInboard : coverageRequired?.otherPortableFireExtinguishersInboard,
        lossOfUse : coverageRequired?.lossOfUse,
        coverageForForestFirefighting : coverageRequired?.coverageForForestFirefighting,
        lossOfUseHigherLimit : coverageRequired?.lossOfUseHigherLimit,
        relatedWatercraftDescription : coverageRequired?.relatedWatercraftDescription,
        logsWhileOnLand : coverageRequired?.logsWhileOnLand,
      })
    }
    if (coverageRequired?.coverageForLogs){
      for(const log of coverageRequired.coverageForLogs){
        (this.coverageRequired.get('coverageForLogs') as UntypedFormArray).push(new UntypedFormControl(log))
      }
    }
    this.setupToggleInformation()
    this.setupComplete = true
  }

  policyTypeListener(){
    this.comm.appPolicyTypeListener().subscribe((type: string) => {
      this.policyType = type
      this.setCoverageState(type)
      this.cdr.detectChanges()
    })
  }

  setupToggleInformation(){
    for (const control in this.coverageRequired.controls){
      this.toggleInformation[control] = {
      text : this.allText?.getText()?.[control]?.text,
      listOfEnabledToggles: this.listOfEnabledToggles,
      childControlName: control,
      onlyBool: this.allText?.getText()?.[control]?.onlyBool,
      data : this.preForm.proposalForm?.coverageRequired?.[control],
      parentGroup : this.coverageRequired,
      clonedParentGroup: this.converageRequiredCloned
      }
    }
  }

  setCoverageState(type: string){
    const list = this.allText.getList(type)
    list.enabled.forEach((item) => {
      const control = this.coverageRequired.get(item)
      if(control?.disabled){
        control.enable()
      }
    })

    list.disabled.forEach((item) => {
      const control = this.coverageRequired.get(item)
      if(control?.enabled){
        control.disable()
      }
    })
  }

}

export class CoverageRequiredText {
  private static covList = {
    transportationByWater : {text: 'Is Equipment subject to Transportation by Water?'},
    bargingNotInProtectedWater : {text:'Is barging done outside of Protected waters?'},
    usedByApplicant : {text:'Is Equipment used solely by the Applicant?', onlyBool: true},
    maintenanceScheduleBasis : {text:'Are Maintenance & Overhauls done on a Scheduled Basis?'},
    inHouseMechanics : {text:'Does Applicant have own mechanic/employees & mechanical shop?'},
    inBoardFireSuppressionOnHarvesters : {text:'Do Harvest and/or Feller Bunchers units carry automatic systems in board?'},
    otherPortableFireExtinguishersInboard : {text:'Does other equipment have portable fire extinguishers on board?', onlyBool: true},
    lossOfUse: {text:'Do you require coverage for Loss of Use (Rental Reimbursement)', onlyBool: true},
    coverageForForestFirefighting : {text:'Do you require coverage for Own Forest Fire Fighting Expenses?', onlyBool: true},
    coverageForLogs : {text:'Do you require coverage for Logs?'},
    motorTruckCargo : {text:'Do you require Motor Truck Cargo?'},
    bargingNotOnFreshwater : {text:'Is barging done other than on lakes and rivers?'},
    lossOfUseHigherLimit: {text:'Loss of Use (Rental Reimbursement) - $20,000 limit included in the policy, complete the following if higher limit required'},
    relatedWatercraftDescription : {text:'Do you Own/Lease/Rent Watercraft that you use in conjunction with your Logging Operations? i.e. Boom Boat, Side Winder, Crew Boat etc.'},
    logsWhileOnLand: {text:'Logs While on Land?'},
  }

  getText(){
    return CoverageRequiredText.covList;
  }

  public getList(type: string) : {list: string, enabled: string[], disabled: string[]}{
    const types = {
      CFP: {list:'listOne', enabled:['bargingNotInProtectedWater', 'coverageForForestFirefighting', 'coverageForLogs'], disabled:['bargingNotOnFreshwater', 'relatedWatercraftDescription', 'logsWhileOnLand']},
      TLA: {list:'listOne', enabled:['bargingNotInProtectedWater', 'coverageForForestFirefighting', 'coverageForLogs'], disabled:['bargingNotOnFreshwater', 'relatedWatercraftDescription', 'logsWhileOnLand']},
      EFP: {list:'listTwo', enabled:['bargingNotOnFreshwater', 'relatedWatercraftDescription', 'logsWhileOnLand'], disabled:['bargingNotInProtectedWater', 'coverageForForestFirefighting', 'coverageForLogs']}
    }
    return types?.[type]
  }

}