import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pt-appBoolControl]'
})
export class BoolControlDirective implements OnInit, OnDestroy {
  @Input() boolControl: UntypedFormControl;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private el: ElementRef) {
    fromEvent(el.nativeElement, 'input').
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      let bool;
      el.nativeElement.value === 'yes' ? bool = true : bool = false;
      this.boolControl.setValue(bool);
   });
 }

 ngOnInit() {
   setTimeout(() => {
    const el = this.el.nativeElement;
    if (this.boolControl.value) {
     el.value === 'yes' ? el.checked = true : el.checked = false;
    } else if (this.boolControl.value === false) {
     el.value === 'no' ? el.checked = true : el.checked = false;
    }
   })
 }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
