<ng-container *ngIf = 'preForm'>
    <section pt-appDisableAll [disableAll] = '!platformStatus' class="formSection" id="section1">
        <app-insured-address [preForm]='preForm' [productType]='productType'></app-insured-address>
        <app-surplus-risk [productType]='productType' [disabled]='surplusRiskDisabled' [preForm]='preForm'></app-surplus-risk>
        <app-business-overview [preForm]='preForm'></app-business-overview>
        <span *ngIf='interestType && otherDetail'><app-interest-type-detail [otherDetail]='otherDetail' 
            [interestType]='interestType'></app-interest-type-detail></span>
        <app-sales-revenues [preForm]='preForm'></app-sales-revenues>
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 ps-0 pe-4 colMargin pb-1 pt-5 buttonWrapper tableButtonPadding">
            <h2 class="ps-0 subHeader">Type of Coverage Required</h2>
            <div class="tableButtons btn-group text">
              <button (click)= 'setCargoType(type.TransitOnly)' 
                    [ngClass]="{'active' : cargoType.value === type.TransitOnly}">{{type.TransitOnly}}</button>
              <button (click)= 'setCargoType(type.StockOnly)' 
                    [ngClass]="{'active' : cargoType.value === type.StockOnly}">{{type.StockOnly}}</button>
              <button (click)= 'setCargoType(type.Both)' 
                    [ngClass]="{'active' : cargoType.value === type.Both}">{{type.Both}}</button>
            </div>
        </div>
        <div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 ps-0 pe-4 colMargin buttonWrapper tableButtonPadding">
            <app-basis-of-valuation [preForm]='preForm' basisOfValuationArray='arr'></app-basis-of-valuation>
        </div>
        <div [ngClass]="{'hide' : cargoType.value === type.StockOnly}">
            <app-transit-limits [preForm]='preForm'></app-transit-limits>
            <app-transit-risk-incoming [preForm]='preForm'></app-transit-risk-incoming>
            <app-transit-risk-outgoing [preForm]='preForm'></app-transit-risk-outgoing>
        </div>
        <div id="schedule" [ngClass]="{'hide' : cargoType.value === type.TransitOnly}">
            <app-schedule-of-values [productType]='productType' sectionHeader='5. Stock/ Storage Risk' [preForm]='preForm'></app-schedule-of-values>
        </div>
        <app-loss-experience-array [secName]='"6. LOSS EXPERIENCE"' [cargoType]='cargoType.value' [preForm]='preForm' [productType]='productType'></app-loss-experience-array>
        <app-additional-docs [docID]='docID' [secName]='"7. ADDITIONAL INFORMATION"' [preForm]='preForm'></app-additional-docs>
        <app-disclaimer [platformStatus]='platformStatus'></app-disclaimer>
    </section>
</ng-container>