<table mat-table [dataSource]="dataSource"
      matSort matSortActive="updatedAt" matSortDirection="desc"
      class="matTable mb-5">
        
        <!-- Contract name Column -->
        <ng-container matColumnDef="contractName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Name</th>
          <td mat-cell *matCellDef="let contract"> {{contract.contractName}} </td>
        </ng-container>

        <!-- Risk Type Column -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Type</th>
          <td mat-cell *matCellDef="let contract"> {{contract.product | cleanProduct}} </td>
        </ng-container>

        <ng-container matColumnDef="UID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>UID</th>
          <td mat-cell *matCellDef="let contract"> {{contract.id}} </td>
        </ng-container>
      
        
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let contract"> 
            <pt-icon-status-switcher [status]="contract?.status?.toLowerCase()"></pt-icon-status-switcher>
          </td>
        </ng-container>

        <!-- Creation date Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
          <td mat-cell *matCellDef="let contract"> {{contract.createdAt | portalDatePipe:''}} </td>
        </ng-container>
    
        <!-- Last updated Column -->
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Update</th>
          <td mat-cell *matCellDef="let contract"> {{contract.updatedAt | portalDatePipe:'update':true}} </td>
        </ng-container>

        <!-- User email Column -->
        <ng-container matColumnDef="userEmail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>User Email</th>
            <td mat-cell *matCellDef="let contract"> {{contract.userEmail}} </td>
        </ng-container>

        <!-- Option Column -->
        <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let contract">
              <span *ngIf="contract?.acknowledgedICs > ((contract?.quotedIC || 0) && (AR.isArray(contract?.quotedIC) ? contract?.quotedIC?.length : 1)) || contract?.status?.toLowerCase()?.includes('submitted /')">
                <button (click)='investigateRisk(contract)' class="matTableButton">Manage</button>
                
              </span>
            </td>
        </ng-container>

        <!-- Quotes Column -->
        <ng-container matColumnDef="listOfQuotes">
          <th mat-header-cell *matHeaderCellDef>Risk named: {{currentContract.contractName}} belonging to {{currentContract.userEmail}}</th>
          <td mat-cell *matCellDef="let contract">
            <div *ngIf="contract.id; else elseBlock">
              {{contract?.id}}
              <br>
              {{contract?.uw}}
              <br>
              {{contract?.date}}
            </div>
            <ng-template #elseBlock>
              {{contract}}
            </ng-template>
          </td>
        </ng-container>

        <!-- Unlock Column -->
        <ng-container matColumnDef="unlockRisk">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let quoteID; let i = index">
            <button *ngIf="currentContract?.quotedIC !== quoteID?.id ? quoteID.id : quoteID && !(AR.isArray(currentContract?.quotedIC) && currentContract?.quotedIC?.includes(quoteID?.id ? quoteID.id : quoteID))" [disabled]='unlocking !== null' (click)='unlock(quoteID,i)' class="matTableButton d-flex justify-content-center align-items-center">
              <span *ngIf="unlocking !== i">Unlock</span>
              <i *ngIf="unlocking === i"><mat-spinner diameter="22"></mat-spinner></i>
            </button>
          </td>
        </ng-container>

        <tr
           mat-header-row 
           class="contracts-header-row" 
           *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr 
            mat-row 
            *matRowDef="let row; columns: displayedColumns;"
            class="contracts-row"
        ></tr>
</table>

<mat-paginator [pageSizeOptions]="[100, 150, 200]" showFirstLastButtons></mat-paginator>