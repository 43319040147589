import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PTMessage } from '../../model/typings';

@Component({
  selector: 'pt-submit-check-popup',
  templateUrl: './submit-check-popup.component.html',
  styleUrls: ['./submit-check-popup.component.css']
})

export class SubmitCheckPopupComponent {

  constructor(public ref: MatDialogRef<SubmitCheckPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: PTMessage) {
    ref.disableClose = true;
  }

  accept(){
    this.ref.close({accept: true});
  }

  reject(){
    this.ref.close({accept: false});
  }

}
