import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Enums } from '../../../shared-resources/models/contract.model';
import { UtilService } from 'projects/mcgill/src/app/app-services/util.service';
import { TerrorismAgreementPopupComponent } from '../../../shared-resources/popups/terrorism-agreement-popup/terrorism-agreement-popup.component';
import { PortalDataService, PortalUtilService, ComponentCommunicationService, QuotedRiskBaseComponent, modelUtils, PTFigure } from 'portal';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Countries, Product } from '../../../shared-resources/models/product.type';

@Component({
  selector: 'app-platform-risk-detail',
  templateUrl: './platform-risk-detail.component.html',
  styleUrls: ['./platform-risk-detail.component.scss']
})

export class PlatformRiskDetailComponent extends QuotedRiskBaseComponent implements OnInit {
  deduc: SafeHtml;
  tria = new UntypedFormControl();
  ratesControl = new UntypedFormGroup ({ rateControl: new UntypedFormControl(), rates: new UntypedFormGroup({commission: new UntypedFormControl(), brokerage: new UntypedFormControl()})})
  triaUSD: string | undefined; enum = new Enums();
  riskStatus = new modelUtils().risk;
  prod = new Product();
  prodQuoteDetails: {tria: boolean, commissionNotStandard: boolean, brokerageNotStandard: boolean, quoteSummary: boolean}
  constructor(public util: UtilService, public sanitizer: DomSanitizer, public portalDataService: PortalDataService,
    public router: Router, public dialog: MatDialog, public comm: ComponentCommunicationService,
    public pUtil: PortalUtilService
  ) { super(portalDataService, router, dialog, comm, pUtil) }

  ngOnInit(): void {
    this.prodQuoteDetails = this.prod.findProductType(this.preForm.product).prodQuoteDetails
    if (this.prod.typeInArrToCheck(this.preForm.product, this.prod.supportedProducts.UEquipmentBinder.typeArr)) {
      this.detail = true
    }
    if(this.prod.typeInArrToCheck(this.preForm.product, this.prod.supportedProducts.UCasualty.typeArr)){
      super.setupQuote(true)
      this.casualtyRatesListener()
    } else {
      super.setupQuote(false)
    }
  }

  setupFigures(figures: PTFigure[], lineItems: any[]) {
    if (figures && this.preForm.proposalForm.insuredAddress.country !== Countries.CANADA) {
      this.triaUSD = this.pUtil.getFigure(figures, 'TRIA AP') || this.pUtil.getFigure(figures, 'DEDUCTIBLE2') || this.pUtil.getFigure(figures, 'PREMIUMTRIA');
    }

    this.handleDeductibleSetup(figures, lineItems);
  }

  handleDeductibleSetup(arr: PTFigure[], lineItems: any[]) {
    this.deduc = this.sanitizer.bypassSecurityTrustHtml(this.util.safeDeducHTML(arr, this.preForm, lineItems));
  }

  checkIfUserCanAcceptQuote() {
    if (this.prod.typeInArrToCheck(this.preForm.product, this.prod.supportedProducts.UTerrorism.typeArr)) {
      this.terrorismAgreementPopup();
    } else {
      this.acceptQuote('accept', this.tria, this.ratesControl);
    }
  }

  terrorismAgreementPopup() {
    const dialogRef = this.dialog.open(TerrorismAgreementPopupComponent, {
      panelClass: 'userAgreement-dialog-container',
      width: '70vw',
      maxWidth: '70vw',
      data: this.preForm.id,
      autoFocus: false
    });
    dialogRef.afterClosed()
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe((response: { accept: boolean }) => {
        if (response.accept) {
          super.acceptQuote('accept');
        }
      });
  }

  getButtonStatus() {
    if(this.preForm.status !== this.riskStatus.Quoted){
      return true
    }
    if(this.prodQuoteDetails.tria === false && this.prodQuoteDetails.commissionNotStandard === false){
      return false
    }
    if(this.prodQuoteDetails.tria){
      return !this.tria.value && this.tria.value !== false && this.triaCheck()
    }
    if(this.prodQuoteDetails.commissionNotStandard){
      return this.commissionNotStandard()
    }
    return false
  }

  triaCheck() {
    return this.preForm.status === this.riskStatus.Quoted &&
      this.prodQuoteDetails.tria &&
      this.preForm.proposalForm.insuredAddress.country !== Countries.CANADA
  }

  commissionNotStandard(){
    const rates = this.ratesControl.get('rates')
    return (!rates?.get('commission')?.value || (!rates?.get('brokerage')?.value) && !this.prod.typeInArrToCheck(this.preForm.product, this.prod.supportedProducts.UCasualty.typeArr))
    && this.ratesControl.get('rateControl')?.value !== true
    || this.ratesControl.invalid === true
  }

  casualtyRatesListener(){
    const commissionUpTo = 21.75
    this.ratesControl?.valueChanges.
    pipe(
      takeUntil(this.unsub)
    )
    .subscribe(val => {
      val.rates?.commission > commissionUpTo && val.rateControl === false ?
        this.ratesControl.get('rates')?.get('commission')?.setErrors({commissionNotPermitted: true}) :
        this.ratesControl.get('rates')?.get('commission')?.setErrors(null)
    });
  }

}
