import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { PortalUtilService, ComponentCommunicationService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
  providers: [
       {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperationsComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OperationsComponent),
      multi: true
    }
  ]
})
export class OperationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() name: string;
  @Input() type: string;
  @Input() required: boolean;
  @Input() withPerc: boolean;
  public operation: UntypedFormGroup = new UntypedFormGroup({
    expiringProjected: new UntypedFormControl(''),
    expiringActual: new UntypedFormControl(''),
    renewalProjected: new UntypedFormControl(''),
  });
  unsub: Subject<boolean> = new Subject<boolean>();

 constructor(private comm: ComponentCommunicationService, public pUtil: PortalUtilService) { }

  ngOnInit() {
    this.handleValidator();
    this.touchListener();
  }

  ngAfterViewInit(){
    this.operation.updateValueAndValidity();
  }

  public onTouched: () => void = () => {};

  handleValidator(){
    if(this.required && this.type === 'percentage'){
      this.operation.get('expiringProjected')?.setValidators(Validators.required);
      this.operation.get('expiringActual')?.setValidators(Validators.required);
      this.operation.get('renewalProjected')?.setValidators(Validators.required);
    }
  }

  touchListener() {
    this.comm.markAllAsTouchedListener().pipe(
     takeUntil(this.unsub)
   ).subscribe((bool) => {
     if (bool) {
       this.operation.markAllAsTouched();
     }
   });
  }

  writeValue(val: any): void {
    if (val) {
      this.operation.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.operation.valueChanges.pipe(
      takeUntil(this.unsub)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.operation.disable() : this.operation.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.operation.valid ? null : { invalidForm: {valid: false, message: ''}};
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
