import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { UtilService } from 'projects/mcgill/src/app/app-services/util.service';

@Component({
  selector: 'app-coverage-component-two',
  templateUrl: './coverage-component-two.component.html',
  styleUrls: ['./coverage-component-two.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class CoverageComponentTwoComponent implements OnInit, AfterViewInit{
  @Input() preForm: any;
  @Input() set disabled(value: boolean) {
    if(value || value === false) {
      this.setState(value)
    }
  }
  endorsementOptionArr = [
    {key:"Difference in Conditions Difference in Deductibles", display: "Difference in Conditions Difference in Deductibles"},
    {key:"Excess Automobile Liability Policy", display: "Excess Automobile Liability Policy"},
    {key:"Faulty Workmanship Extension", display: "Faulty Workmanship Extension"},
    {key:"Goods on Hook Extension", display: "Goods on Hook Extension"},
    {key:"Manufacturer's Errors and Omissions Liability Extension", display: "Manufacturer's Errors and Omissions Liability Extension"},
    {key:"Rip and Tear Extension", display: "Rip and Tear Extension"},
  ];
  endorsementOption = new UntypedFormArray([]);
  constructor(private parent: FormGroupDirective, public util: UtilService) { }

  ngOnInit(): void {
    (this.parent.form.get('proposalForm')?.get('coverageRequired') as UntypedFormGroup)?.addControl('endorsementOption', this.endorsementOption);
  }

  ngAfterViewInit(){
    this.setupEndorsementOption();
  }

  setupEndorsementOption(){
    const options : [string] = this.preForm?.proposalForm?.coverageRequired?.endorsementOption;
    if(options){
      options.map((opt) => {
        if(document?.getElementById(opt) as HTMLInputElement){
          (document.getElementById(opt) as HTMLInputElement).checked = true;
          if(!(this.endorsementOption.value as Array<string>).includes(opt)){
            this.endorsementOption.push(new UntypedFormControl(opt));
          }
        }
      })
    }
  }

  selectEndorsement(endorsement: string){
    const c = new UntypedFormControl(endorsement);
    (this.endorsementOption.value as Array<string>).includes(endorsement) ? this.deleteEndorsement(endorsement) : this.endorsementOption.push(c);
  }

  deleteEndorsement(endorsement: string){
    (this.endorsementOption.value as Array<string>).filter((s,index) => {
      if(endorsement === s){
        this.endorsementOption.removeAt(index);
      }
    })
  }

  setState(disabled: boolean){
    disabled ? this.endorsementOption.disable() : this.endorsementOption.enable();
  }



}
