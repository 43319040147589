import { Component, Input } from '@angular/core';

@Component({
  selector: 'pt-icon-status-switcher',
  templateUrl: './icon-status-switcher.component.html',
  styleUrls: ['./icon-status-switcher.component.css']
})

export class IconStatusSwitcherComponent {
  @Input() status?: string;
  @Input() pipeRequired?: boolean;
  constructor() {}
}
