<div class="coverageList pt-5 colMargin d-flex">
    <div class="list">
        <div *ngFor="let log of logs; let i=index">
            <div class="checkBox pb-3">
                <label class="container pointer">
                    <input (click)='selectLogType(log.key, inp)' class="splitCheckBox" type="checkbox" [id]='log.key' #inp> 
                    <span class="checkmark"></span>
                    <label class="pointer" [for]="log.key">{{log.display}}</label>
                </label> 
            </div>
        </div>
    </div>
</div>