<div class="underscorePopup" *ngIf='message'>
    <span><h1 mat-dialog-title class="dialogTitle dialogText header">{{message.header}}</h1></span>
    <div class="line mb-4 mt-4"></div>
    <mat-dialog-content>
        <div class="dialogText dialogTitle" *ngFor = 'let inf of message.information; let i=index'>
            <ng-container *ngIf="inf.name;else default">
                {{inf | cleanProdComm}}
            </ng-container>
            <ng-template #default>
                {{inf | json}}
            </ng-template>
        </div>
    </mat-dialog-content>
    <div class="line mb-3 mt-4"></div>
    <span class="d-flex justify-content-end mt-4 me-4">
        <button mat-button class= "roboto createButton" (click)="closePopup()">OK</button>
    </span>
</div>