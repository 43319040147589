import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PTPortalSaveComplete, PTPortalSaveForm } from '../model/typings';

@Injectable({
  providedIn: 'root'
})

export class ComponentCommunicationService {
  private defaultCurrencyArr = new ReplaySubject<string[]>(1);
  private defaultCurrencyType = new ReplaySubject<string>(1);
  private defaultCurreny = new ReplaySubject<string>(1);
  private insuredAddress = new Subject<string>();
  private downloadMRC = new Subject<boolean>();
  private canSubmit = new Subject<boolean>();
  private pdfJson = new Subject<string>();
  private directURL = new Subject<PTPortalSaveForm>();
  private markAllAsTouched = new Subject<boolean>();
  private formSave = new Subject<PTPortalSaveComplete>();
  private appPolicyType = new ReplaySubject<string>(1);

  constructor() {}

  appPolicyTypeListener(): Observable<any> {
    return this.appPolicyType.asObservable();
  }

  setappPolicyType(type: string) {
    this.appPolicyType.next(type);
  }

  defaultCurrenyListener(): Observable<any> {
    return this.defaultCurreny.asObservable();
  }

  setDefaultCurreny(currency: string) {
    this.defaultCurreny.next(currency);
  }

  insuredAddressListener(): Observable<any> {
    return this.insuredAddress.asObservable();
  }

  setInsuredAddress(country: string) {
    this.insuredAddress.next(country);
  }

  markAllAsTouchedListener(): Observable<boolean> {
    return this.markAllAsTouched.asObservable();
  }
  
  setMarkAllAsTouched(data: boolean) {
    this.markAllAsTouched.next(data);
  }

  defaultCurrencyTypeListener(): Observable<string> {
    return this.defaultCurrencyType.asObservable();
  }

  setCurrencyType(data: string) {
    this.defaultCurrencyType.next(data);
  }

  defaultCurrencyArrListener(): Observable<string[]> {
    return this.defaultCurrencyArr.asObservable();
  }

  setCurrencyArr(data: string[]) {
    this.defaultCurrencyArr.next(data);
  }

  downloadMRCListener(): Observable<boolean> {
    return this.downloadMRC.asObservable();
  }

  setDownloadMRC(data: boolean) {
    this.downloadMRC.next(data);
  }

  canSubmitListener(): Observable<boolean> {
    return this.canSubmit.asObservable();
  }

  setSubmit(data: boolean) {
    this.canSubmit.next(data);
  }

  downloadAsPdfListener(): Observable<string> {
    return this.pdfJson.asObservable();
  }

  setJsonToDownload(data: string) {
    this.pdfJson.next(data);
  }

  saveFormListener(): Observable<PTPortalSaveForm> {
    return this.directURL.asObservable();
  }

  saveForm(data?: PTPortalSaveForm) {
    this.directURL.next(data);
  }

  saveCompleteListener(): Observable<PTPortalSaveComplete> {
    return this.formSave.asObservable();
  }

  saveComplete(data?: PTPortalSaveComplete) {
    this.formSave.next(data);
  }

}
