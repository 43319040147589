<div class="relative" [ngClass]="{'ng-invalid' : basisOfValuation?.errors}" domControl>
    <h2 class="ps-0 subHeader">Basis of Valuation Required</h2>
    <p class="text pt-2 pb-2">{{header}}</p>
    <mat-error class="typeValueError" *ngIf="basisOfValuation.errors">
        *
    </mat-error>
    <div *ngIf="basisOfValuationArr" class="tableButtons btn-group text">
        <button *ngFor = 'let c of basisOfValuationArr; let i=index' (click)= 'setType(c.value)'
        [ngClass]="{'active' : basisOfValuation.value === c.value}">{{c.display}}</button>
    </div>
</div>
