import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, ControlContainer, FormGroupDirective, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-transit-risk-incoming',
  templateUrl: './transit-risk-incoming.component.html',
  styleUrls: ['./transit-risk-incoming.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class TransitRiskIncomingComponent implements OnInit {

  private form: UntypedFormGroup;
  incomingValues = true;
  toggle = true;
  @Input() preForm: any;
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    const transitRiskIncoming = new UntypedFormControl('');
    this.form = this.parent.form;
    (this.form.get('proposalForm') as UntypedFormGroup).addControl('transitRiskIncoming', transitRiskIncoming);
  }

  controlIncomingValues(){
    this.incomingValues = !this.incomingValues;
    const section = document?.getElementById('incoming');
    if(section){
      this.incomingValues ? section.style.display = 'block' : section.style.display = 'none';
      this.toggle = !this.toggle;
    }
  }
}




