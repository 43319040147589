<div class="level" [ngClass]="{'root customScroll' : !nottoplevel}">
  <!-- <div *ngIf="toplevel">{{
    i===0 ? "&#x2566;" : 
    i===parents().length -1 ? "&#x255A;": 
    "&#x2560;"}}</div> -->
  <div  *ngFor="let parent of parents(); let i = index"  class="item" [class.root]="!nottoplevel" [class.haschildren]="!!tree[parent]">
    <div class="node">
      <pt-icon-status-switcher [status]="docFromId(parent)?.status"></pt-icon-status-switcher>
      
      <span>{{docFromId(parent)?.contractName}}</span>
      <div class="fromto">{{periodFormat(docFromId(parent))}}</div>

      <span class="viewRisk" *ngIf="docID === parent; else viewRisk">This Risk</span>
      <ng-template #viewRisk>
        <a class="viewRisk" [routerLink]="(['/contracts/updateContract', pUtil.rmvSpace(docFromId(parent).product) , parent])"
          [queryParams]="{ status: docFromId(parent)?.status }" (click)="clickContract(parent, docFromId(parent))">View Risk</a>
      </ng-template>

    </div>
    <app-family-tree-level *ngIf="tree[parent]" [tree]="tree[parent]" [nottoplevel]="true" [auxData]="auxData"></app-family-tree-level>
  </div>
</div>
<!-- <div class="level">
  <div  *ngFor="let parent of parents(); let i = index"  class="item" [class.child]="nottoplevel" [class.haschildren]="!!tree[parent]">
    <div class="node">This is a node</div>
    <app-family-tree-level *ngIf="tree[parent]" [tree]="tree[parent]" [nottoplevel]="true"></app-family-tree-level>
  </div>
</div> -->