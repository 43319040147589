<div [ngClass]="{'mt-5' : label}" [attr.name]="id || 'AdditionalInformation'">
    <div class="col-xs-7 col-xxs-10 d-flex flex-column p-0 pb-1 text colMargin">
        <label>{{label}}</label>
        <div class="d-flex align-items-center" [ngClass]="{'mt-5' : label}">
            <button [disabled]="!parent.form.get('contractName')?.value || spin" type="button" (click)="openFile()" class="button largeButton">
                <span class *ngIf="!spin">Upload</span>
                <i *ngIf="spin"><mat-spinner diameter="20"></mat-spinner></i>
            </button>
            <input #uploadButton type="file" style="display:none" (change)="fileChange($event)">
        </div>
        <div *ngIf="attachments?.files?.length > 0 && !dontHandleAttachments" class="pt-5">
            <div *ngFor="let file of attachments?.files" class="d-flex align-items-center">
                <div class="docIcon"></div>
                <label class="p-0 m-0 overflow-hidden me-2">{{file.name}}</label>
                <div (click)="deleteAttachment(file.name)"class="xIcon ms-auto pointer"></div>
            </div>
        </div>
    </div>
</div>