import { Component, OnInit, ViewChild } from '@angular/core';
import { PortalDataService, RiskManagementBaseComponent } from 'portal';
import { AdminRiskListComponent } from './admin-risk-list/admin-risk-list.component';

@Component({
  selector: 'app-admin-risk-management-functions',
  templateUrl: './admin-risk-management-functions.component.html',
  styleUrls: ['./admin-risk-management-functions.component.scss']
})

export class AdminRiskManagementFunctionsComponent extends RiskManagementBaseComponent implements OnInit {
  @ViewChild(AdminRiskListComponent) arc: { displayedColumns: string[]; };
  constructor(public portalDataService: PortalDataService
  ) { super(portalDataService) }

  ngOnInit(): void {
    super.ngOnInit();
  }

  pollManagerRisks(){
    if(this.arc.displayedColumns.includes('contractName') && !this.search){
      this.manageRisks();
    }
  }

}
