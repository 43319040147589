import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Contract } from '../model/models';
import { PTFigure, PTMessage } from '../model/typings';
import { ErrorPopupComponent } from '../popups/error-popup/error-popup.component';

@Injectable({
  providedIn: 'root'
})

export class PortalUtilService {

  constructor(public dialog: MatDialog) { }

  static itemExist(arr:{}, item: string){
    return arr?.[item] || arr?.[item] === 0 || arr?.[item] === false
  }

  static flattenObject(obj: any): any {
    if(typeof obj !== "object"){
        return obj
    } else if (Array.isArray(obj)) {
        return obj.map((item:any) => PortalUtilService.flattenObject(item))
    }
    return Object.keys(obj).reduce<any>((acc, key) => {
        if (
            typeof obj[key] !== "object" ||
            (obj[key]?.figure !== undefined && obj[key]?.currency !== undefined)
        ) {
            acc[key] = obj[key]
            return acc
        } else if (Array.isArray(obj[key])) {
            acc[key] = obj[key].map((item:any) => PortalUtilService.flattenObject(item))
            return acc
        } else {
            const flattened = PortalUtilService.flattenObject(obj[key])
            Object.keys(flattened).forEach(
                (subkey) => {acc[key + "." + subkey] = flattened[subkey]}
            )
            return acc
        }
    }, {})
  }

    errorPopup(message: PTMessage){
      this.dialog.open(ErrorPopupComponent, {
        data: message,
        panelClass: 'custom-dialog-container',
        autoFocus: false
      });
    }

    // delete null and empty values
    extractJSON(obj: any): {} {
      for (const i in obj) {
        if (obj.hasOwnProperty(i)) {
          if (!obj[i] && obj[i] !== false && obj[i] !== 0) {
            delete obj[i];
          } else {
            if (Array.isArray(obj[i]) || typeof obj[i] === 'object') {
              this.extractJSON(obj[i]);
              // delete null objects
              if (typeof obj[i] === 'object' && Object.keys(obj[i]).length === 0) {
                delete obj[i];
              }
              // if array cleanup falsy values
              if (Array.isArray(obj[i])) {
                obj[i] = obj[i].filter((prop: any) => prop );
              }
            } else if (obj[i] === '') {
              delete obj[i];
            }
          }
        }
      }
      return obj;
    }

  combine(json: any, contractDetails: Contract): {} {
    json = this.extractJSON(json);
    for (const contractProp in contractDetails) {
      if (contractDetails[contractProp] !== null) {
        const prop = contractDetails[contractProp];
        if (contractProp === 'updatedAt') {
          json[contractProp] = this.formattedDate();
        }
        if (contractProp !== 'contractName') {
          json[contractProp] = prop;
        }
      }
    }
    json.channels = [];
    return json;
  }

  combineDefault(to: any, from : any): {} {
    to = this.extractJSON(to);
    for (const control in from) {
      if (from[control] !== null) {
        to[control] = from[control];
      }
    }
    return to;
  }

  public findControlWithError(group: UntypedFormGroup | AbstractControl, control: string) : string {
    if(control){
      return control;
    }
    for (const c in (group as UntypedFormGroup).controls) {
      const abstractControl = (group as UntypedFormGroup).controls[c];
      if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
        if(abstractControl?.errors && !control && !abstractControl?.disabled) {
          control = '[domControl*="' + c + '"]';
        } else {
          control = this.findControlWithError(abstractControl,control);
        }
      } else if (abstractControl?.errors && !control && !abstractControl?.disabled){
        return '[domControl*="' + c + '"]';
      }
    }
    return control
  }

  isComplete(obj: any, isComplete: boolean): boolean {
    if (isComplete === false) {
      return isComplete;
    }
    for (const i in obj) {
      if (i !== 'currency') {
        if (Array.isArray(obj[i]) || typeof obj[i] === 'object') {
          isComplete = this.isComplete(obj[i], isComplete);
        }
        if (!obj[i]) {
          return isComplete = false;
        }
      }
    }
    return isComplete;
  }

  isEmpty(obj: any, isEmpty: boolean): boolean {
    if (isEmpty === false) {
      return isEmpty;
    }
    for (const i in obj) {
      if (i !== 'currency') {
        if (Array.isArray(obj[i]) || typeof obj[i] === 'object') {
          isEmpty = this.isEmpty(obj[i], isEmpty);
        }
        if ((obj[i] || obj[i] === false) && typeof obj[i] !== 'object') {
          return isEmpty = false;
        }
      }
    }
    return isEmpty;
  }

  checkIfError(group: UntypedFormGroup, control: string) {
    if (group.get(control)?.errors && group.get(control)?.touched) {
      return true;
    } else {
      return false;
    }
  }

  checkIfErrorArr(arr: AbstractControl[], at: number) {
    if (arr?.[at]?.errors && arr?.[at]?.touched) {
      return true;
    } else {
      return false;
    }
  }

  checkIfErrorControl(control: UntypedFormControl){
    return control?.errors && control?.touched
  }

  getDecimalFigureFormat(figure: number, currency: string){
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(figure) + ' ' + currency;
  }

  getFigure(arr: any[], identifier: string){
    for (let a of arr){
      if (a.identifier.includes(identifier) && a.figure) {
        return this.getDecimalFigureFormat(a.figure, a.currency);
      }
    }
  }

  retrieveAllDeductibles(arr: PTFigure[]){
    return arr.filter(deduc => deduc?.identifier.includes('QUOTE_DEDUCTIBLE'))
  }

  rmvSpace(uri: string | undefined | null): string {
    uri ? uri = uri.replace(/\s/g, '-') : uri = '';
    return uri;
  }

  addSpace(uri: string | undefined | null): string {
    uri ? uri = uri.replace(new RegExp('-', 'g'), ' ') : uri = '';
    return uri;
  }

  returnDateStringUTC(date : Date){
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)).toISOString();
  }

  returnISOToDate(iso: string){
    const date = new Date (iso);
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }

  formattedDate(): string {
    const date = new Date();
    const myFormattedDate = date.getDate() + '-' + (date.getMonth() + 1)
    + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() ;
    return myFormattedDate;
  }

  prodAllowed(prodName: string, userProducts: any[]): boolean {
    return userProducts.filter((product : any) => {
      if(typeof product === 'object'){
        return prodName === product.name;
      } else {
        return userProducts.includes(prodName);
      }
    }).length > 0;
  }

  prodDetails(userProducts: any[], prodName: string) {
    for (const prod of userProducts) {
        if (typeof prod === 'object' && prod.name === prodName) {
            return prod
        }
        else if(typeof prod === 'string' && prod === prodName){
          return undefined
        }
    }
    return undefined
  }

  arrayToObject(arr:any[], identifier: string){
    const initialValue = {};
    return arr.reduce((obj, item) => {
      return {
        ...obj,
        [item[identifier]]: item,
      };
    }, initialValue);
  }
}
