import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PortalDataService } from '../../services/portal-data.service';
import { PortalUtilService } from '../../services/portal-util.service';

@Component({
  selector: 'pt-clone-as-draft-popup',
  templateUrl: './clone-as-draft-popup.component.html',
  styleUrls: ['./clone-as-draft-popup.component.css']
})
export class CloneAsDraftPopupComponent implements OnInit, OnDestroy {
  cloneRiskForm = new UntypedFormGroup({
    atch: new UntypedFormControl(),
    contractName: new UntypedFormControl(),
    isRenewal: new UntypedFormControl(),
    extendExpiringDate: new UntypedFormControl({value:null ,disabled: true}),
  })
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private portalDataService: PortalDataService, private pUtil: PortalUtilService, @Inject(MAT_DIALOG_DATA) public data: any, public ref: MatDialogRef<CloneAsDraftPopupComponent>) { }

  ngOnInit(): void {
    this.cloneRiskForm?.get('contractName')?.setValue(this.data.contract.contractName)
  }

  cloneRisk(){
    const payLoad = this.pUtil.extractJSON(this.cloneRiskForm.value)
    this.portalDataService.cloneAsDraft(this.data.contract.id, payLoad)
    .pipe(
      takeUntil(this.unsub)
    ).subscribe((response: any) => {
      if(response.error){
        this.pUtil.errorPopup({status:'Error when cloning', message:response?.errorDescription});
      }
      this.ref.close(response);
    })
  }

  isRenewal(renewal: boolean){
    if(renewal){
      this.cloneRiskForm.get('isRenewal')?.setValue(true)
      this.cloneRiskForm.get('extendExpiringDate')?.enable()
    } else {
      this.cloneRiskForm.get('isRenewal')?.setValue(false)
      this.cloneRiskForm.get('extendExpiringDate')?.disable()
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
