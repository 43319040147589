import { Component, OnInit, forwardRef, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ControlValueAccessor,
  NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormArray, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilService, ComponentCommunicationService, PTAddress } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Countries, Product } from '../../../models/product.type';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
  {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => AddressComponent),
   multi: true
 },
  {
   provide: NG_VALIDATORS,
   useExisting: forwardRef(() => AddressComponent),
   multi: true
 }
]
})
export class AddressComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() data: any;
  @Input() type: string;
  countries: string[]= []; states: string[] = []; formType: string; prod = new Product();
  addressInfo : any;
  public address: UntypedFormGroup = new UntypedFormGroup({
    stateOrCounty: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    lines: new UntypedFormArray([
      this.initLine(),
    ]),
    country: new UntypedFormControl('', [Validators.required]),
    zipOrPostCode: new UntypedFormControl('', [Validators.required]),
  });
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private activeRoute: ActivatedRoute, private comm: ComponentCommunicationService,
    public pUtil: PortalUtilService) { }

  public onTouched: () => void = () => {};

  ngOnInit() {
    this.touchListener();
  }

  getProduct() {
    this.activeRoute.paramMap.subscribe(params => {
      this.formType = this.pUtil.addSpace(params.get('prod'));
      this.setState(this.formType);
    });
  }

  setState(prodType: string, country?: string){
    const p = new Product().findProductType(prodType);
    this.countries = p?.addressInfo?.countries;
    if (this.type === 'surplus') {
      this.countries = this.countries.filter((v: string) => v !== Countries.CANADA)
    }
    this.addressInfo = p?.addressInfo?.[country ?? this.address?.get('country')?.value] ?? p?.addressInfo?.defaultAddress;
    this.states =  this.addressInfo?.loc;
    if(!this.states.includes(this.address?.get('stateOrCounty')?.value)){
      this.address?.get('stateOrCounty')?.reset();
    }
    if(this.addressInfo?.defaultCurrency && this.type === 'insured'){
      this.comm.setDefaultCurreny(this.addressInfo?.defaultCurrency)
      this.comm.setInsuredAddress(this.address.get('country')?.value)
    }
  }

  writeValue(val: any): void {
    if(val){
      this.setUpAddress(val);
    }
    this.getProduct();
  }

  registerOnChange(fn: any): void {
    this.address.valueChanges.pipe(
      takeUntil(this.unsub)
    )
    .subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.address.disable() : this.address.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.address.valid ? null : { invalidForm: {valid: false, message: ''}};
  }

  touchListener() {
    this.comm.markAllAsTouchedListener().pipe(
      takeUntil(this.unsub)
    ).subscribe((bool: boolean) => {
      if (bool) {
        this.address.markAllAsTouched();
      }
    });
  }

  initLine() {
    const line = new UntypedFormControl('', [Validators.required]);
    return line;
  }

  getLineArr() {
    return this.address.get('lines') as UntypedFormArray;
  }

  addLine() {
    this.getLineArr().push(this.initLine());
  }

  removeLine(i: number) {
    this.getLineArr().removeAt(i);
  }

  getLines() {
    return this.getLineArr().controls;
  }

  setUpAddress(insuredAddress: PTAddress) {
    this.address.patchValue({
        stateOrCounty: insuredAddress?.stateOrCounty,
        city: insuredAddress?.city,
        zipOrPostCode: insuredAddress?.zipOrPostCode,
        country: insuredAddress?.country,
    });
    if (insuredAddress?.lines) {
      (this.address.get('lines') as UntypedFormArray).at(0).setValue(insuredAddress?.lines?.[0]);
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}
