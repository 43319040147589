    <div class="col-12 disclaimer">
        <div class="content text">
            <h1 class="disclaimerHeader">Important:</h1>
                <div>
                    <p>
                        Please note that the above questions are provided by McGill and Partners to help identify the types of
                        information that we would expect a prudent Insurer (not necessarily the Insurer in question) to take into
                        account when deciding whether or not to accept a risk and the terms and price to be applied if so.
                    </p>
                    <p>
                        The questions we have asked are not exhaustive and do not in any way alter or otherwise limit a policyholder’s
                        (and its broker’s) duty to give a fair presentation of the risk. Under English law the duty to give a fair
                        presentation means that the policyholder (and its insurance broker) must disclose every material circumstance
                        which it knows or ought to know or, failing that, give the Insurer sufficient information to put a prudent
                        Insurer on notice to conduct enquiries. Failure to do so could allow the Insurer to avoid cover, amend the terms
                        or, if it would have charged additional premium, reduce any claim payments proportionately. If there is any
                        further material information that you consider may affect the decision of an Insurer as to whether to accept
                        the risk or on what terms to do so, it is important that you advise above or separately attach such information
                        in your response to this questionnaire.
                    </p>
                    <p>
                        By submitting this questionnaire, the accuracy and completeness of all answers, statements and/or information
                        is the policyholder’s own responsibility. It is critical that all information provided is complete and accurate.
                    </p>
                    <p>
                        The duty of disclosure and the consequences of its breach may vary from that described above, depending upon what
                        law(s) is applicable to the insurance. If you are unsure whether a piece of information should be disclosed,
                        we recommend you disclose it.
                    </p>
                </div>

            <h1 class="disclaimerHeader pt-3">Disclaimer:</h1>
                <p>
                    Irrespective of the law applicable to your policy, please note that it is incumbent upon you to procure that the 
                    insured declares as material information any potential that it, any other person or entity with a direct or indirect
                    interest in the proposed insurance contract (including but not limited to any named/additional insured or loss payee),
                    and/or any moveable goods or property falling under the proposed insurance contract may have sanctions and/or export
                    control implications. This is to ensure that insurers are able to handle the risk and assess whether there may be
                    barriers to the transfer of funds through the banking system. Please note that if any sanctions or export control
                    regime applies to any policy arranged through the Underscore system, it may be impossible as a matter of applicable
                    law and/or regulation for the policy to be processed and/or for such policy to respond to any claims.
                </p>
            <div>
                
            </div>
            <div class="d-flex justify-content-end">
                <div class="checkBox pt-5">
                    <label class="container">
                        <input (click)='readAndAgreed()' class="splitCheckBox" type="checkbox" id = "disclaimer"> 
                        <span class="checkmark"></span>
                        I HAVE READ AND AGREE
                    </label> 
                </div>
            </div>
        </div>        
    </div>
