<div class="casualtyType interestType colMargin ps-3">
    <h3 class="listHeader pt-3">Industry Sector:</h3>
    <div class="col-12 p-0 businessOverviewWrapper" [ngClass]="{'ng-invalid' : casualtyType?.errors}" domControl>
        <mat-error class="typeError" *ngIf="casualtyType.errors">
            *
        </mat-error>
        <div class="row m-0 row-cols-lg-5 row-cols-3 pt-4">
            <div class="col interest text" *ngFor="let item of casualtyIndustry"
                (click)="chooseIndustry(item.value)" [ngClass]="{'active' : casualtyType.value === item.value}">
                    <div id={{item.key}}>
                    </div>
                    <label class="interestLabel nowrap">{{item.display}}</label>
            </div>
        </div>
    </div>
    <div *ngIf="casualtyType.value === 'Other'">
        <div class ="col-lg-6 col-md-8 col-xs-10 col-xxs-10 pe-0 mt-4">
            <div [ngClass]="{'requiredColor' : otherDetail.errors}" class="controlInput">
                <input [formControl]="otherDetail" class="controlFull" type="text" placeholder="Other?" domControl>
                <label class="controlLabel labelBackground">
                  Please give Details*
                </label>
            </div> 
        </div>
    </div>
</div>