<div class="col-lg-6 col-md-8 col-xs-10 col-xxs-12 ps-0 pe-4 colMargin m-0 colMargin">
    <div [formGroup]="transitRisk"> 
        <div class="transitRisk">
            <h3 class="listHeader pt-4 pb-2">International</h3>
            <div class="conveyance">
                <div class="row">
                    <div class="col-8 d-flex align-items-center text">
                        % Containerised*
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(transitRisk,'internationalContainerised')}" class="controlInput">
                            <input [inputControl]="transitRisk.get('internationalContainerised')" pt-appPercentage pt-ngInvalid>
                            <label class="controlPercentageLabel">
                              %
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <app-amount [required]='true' [name]="'Annual values in Transit'"
                    formControlName="internationalActualValues"></app-amount>
            <div class="conveyance">
                <div class="row">
                    <div class="col-8 d-flex align-items-center text">
                        % of which shipped at Insured's risk of loss*
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(transitRisk,'internationalPercentageAtInsuredsRisk')}" class="controlInput">
                            <input [inputControl]="transitRisk.get('internationalPercentageAtInsuredsRisk')" pt-appPercentage pt-ngInvalid>
                            <label class="controlPercentageLabel">
                              %
                            </label>
                        </div>
                    </div>
                </div>
            </div>
    
            <h3 class="listHeader pt-5 pb-2">Domestic</h3>
            <div class="conveyance">
                <div class="row">
                    <div class="col-8 d-flex align-items-center text">
                        % Containerised*
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(transitRisk,'domesticContainerised')}" class="controlInput">
                            <input [inputControl]="transitRisk.get('domesticContainerised')" pt-appPercentage pt-ngInvalid>
                            <label class="controlPercentageLabel">
                              %
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <app-amount [required]='true' [name]="'Annual values in Transit'"
                    formControlName="domesticActualValues"></app-amount>
            <div class="conveyance">
                <div class="row">
                    <div class="col-8 d-flex align-items-center text">
                        % of which shipped at Insured's risk of loss*
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{'requiredColor' : pUtil.checkIfError(transitRisk,'domesticPercentageAtInsuredsRisk')}" class="controlInput">
                            <input [inputControl]="transitRisk.get('domesticPercentageAtInsuredsRisk')" pt-appPercentage pt-ngInvalid>
                            <label class="controlPercentageLabel">
                              %
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ms-3 mt-3 text" formGroupName="internationalSplit">
            <span class="smallText">Where shipments are international, please provide a split of values as follows</span>
            <h3 class="listHeader mt-1">
                <span *ngIf='type === "outgoing"'>Destined to:</span>
                <span *ngIf='type === "incoming"'>Emanating From:</span>
            </h3>

            <div *ngFor="let split of splits" class="row splitTable pb-2">
                <div class="col-10 p-0">
                    <label class="container">
                        {{split.label}}
                        <input (click) ="isChecked(loc)" class="splitCheckBox" type="checkbox" value={{split.loc}} [attr.checked]="loc.checked || filterLoc(split.loc).get('percentage')?.value ? true : null" #loc> 
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class = "col-2 p-0">
                    <span *ngIf = "loc.checked">
                        <div class="controlInput m-0 p-0">
                            <input  class="splitInput" [inputControl]="filterLoc(split.loc).get('percentage')" pt-appPercentage [name]="split.label">
                            <label class="controlPercentageLabel">
                              %
                            </label>
                        </div>
                    </span>
                </div>
            </div>
            <div class="row splitTable pt-3" [ngClass]="{'hide' : !total.value, 'ng-invalid' : transitRisk.get('internationalSplit')?.errors}" domControl>
                <div class="col-10 p-0 d-flex justify-content-end">
                    <label class="me-2">
                        Total
                    </label>
                </div>
                <div class="col-2 p-0">
                    <div class="controlInput m-0 p-0">
                        <input class="splitInput"  [inputControl]="total" id='total' pt-appPercentage disabled="true">
                        <label class="controlPercentageLabel">
                            %
                        </label>
                    </div>
                    <app-error-handler [control] = 'transitRisk.get("internationalSplit")'></app-error-handler>
                </div>
            </div>
        </div>    
    </div>
</div>