import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PortalDataService } from 'portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SVTerrUserAgreement } from '../../models/typing';

@Component({
  selector: 'app-terrorism-agreement-popup',
  templateUrl: './terrorism-agreement-popup.component.html',
  styleUrls: ['./terrorism-agreement-popup.component.scss']
})
export class TerrorismAgreementPopupComponent implements OnInit, OnDestroy {

  gui: SVTerrUserAgreement;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(public ref: MatDialogRef<TerrorismAgreementPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private portalDataService: PortalDataService) {
                ref.disableClose = true;
  }

  ngOnInit(): void {
    this.getTriaPdf(this.data);
  }

  getTriaPdf(id: string){
    this.portalDataService.triaPDF(id)
    .pipe(
      takeUntil(this.unsub)
    )
    .subscribe((response: any) => {
      this.gui = response;
    },
    error => {this.declineToQuote()}
    )
  }
  
  acceptToQuote() {
    this.ref.close({accept: true});
  }

  declineToQuote(){
    this.ref.close({accept: false});
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
