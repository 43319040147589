import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PortalDataService } from 'portal';
import { fromEvent, Subject } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ScheduleInfo, sovChangesListener } from '../models/sov.model';

@Directive({
  selector: '[pt-appSovListener]'
})
export class SovListenerDirective implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() scheduleItem: ScheduleInfo;
  unsub: Subject<boolean> = new Subject<boolean>();
  constructor(private el: ElementRef, private portalDataService: PortalDataService) {
    fromEvent(el.nativeElement, 'input').
    pipe(
      debounceTime(250),
      distinctUntilChanged(),
      takeUntil(this.unsub)
    )
    .subscribe(() => {
      this.sovChangesListenerHandle(el.nativeElement.value)
   });
 }

 sovChangesListenerHandle(input:string){
  if(this.scheduleItem.changeListener){
    if(this.scheduleItem.changeToValidate === sovChangesListener.ZipTesting){
      this.portalDataService.validateZipCodes([input]).pipe(takeUntil(this.unsub)).subscribe((x) => {
        x[input] === true || !input ? delete this.control?.errors?.['nonValidZip'] : this.control?.setErrors({nonValidZip: 'ZIP not valid'})
        if(this.control.errors && Object?.keys(this.control?.errors)?.length === 0){
          this.control.setErrors(null)
        }
      })
    }
  }
}

 ngOnInit() {
   setTimeout(() => {
    this.sovChangesListenerHandle(this.control.value)
   })
 }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

}
