import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PortalUtilService } from 'portal';

@Component({
  selector: 'app-coverage-required-barging',
  templateUrl: './coverage-required-barging.component.html',
  styleUrls: ['./coverage-required-barging.component.scss']
})
export class CoverageRequiredBargingComponent {
  @Input() group: UntypedFormGroup

  constructor(public pUtil: PortalUtilService) { }
}
