<div class="d-flex justify-content-end mt-3 me-4">
    <input type='text' placeholder="Search For Company" [(ngModel)]="searchTerm" (keyup)="searchTermChange($event)"/>
</div>

<div class="col-lg-7 col-md-9 col-xs-11 col-xxs-12 col-center pt-5">
  <h1 class="pb-2">Please select and confirm company logos</h1>
  <div class="listContainer">
    <table class="table logotable">
        <thead>
          <tr>
            <th scope="col">Logo</th>
            <th scope="col">Email</th>
            <th scope="col">Upload</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let address of addresses">
            <td class="d-flex">
                <img *ngIf="corp[address].holdingfile; else imagemissingtext" [src]="corp[address].holdingfile" alt="not set">
                <ng-template #imagemissingtext>
                    <div class="alert alert-warning flex-basis" role="alert">
                        No Logo Set
                    </div>
                </ng-template>
            </td>
            <td>@{{address}}</td>
            <td><input type="file" (change)="chooseFile($event, address)">
                <input *ngIf="corp[address].file" type="button" value="Confirm" (click)="postLogo(address)"></td>
          </tr>
        </tbody>
      </table>
  </div>
</div>