<div class="ps-3 pb-3 d-flex">
    <div *ngIf="userAccountFunction === userAccountFunctions.userPage" class="d-flex">
        <button (click)="updateUserFunction(userAccountFunctions.registerUser)">Register user</button>
        <div *ngIf="selectedUsers.length > 0">
            <button (click)="resendInvitation()">Resend invitation</button>
            <button (click)="updateUserFunction(userAccountFunctions.editUserDetails)">Update about details</button>
            <button (click)="updateUserFunction(userAccountFunctions.editUserProducts)">Update product details</button>
        </div>
    </div>
    <ng-container *ngIf="userAccountFunction !== userAccountFunctions.userPage">
        <button (click)="updateUserFunction(userAccountFunctions.userPage)">Back to user list</button>
    </ng-container>
</div>

<ng-container *ngIf="userAccountFunction === userAccountFunctions.userPage">
    <app-user-list (userListEmitter)="updateUsers($event)"></app-user-list>
</ng-container>

<ng-container *ngIf="userAccountFunction === userAccountFunctions.registerUser">
    <app-new-user-form></app-new-user-form>
</ng-container>

<ng-container *ngIf="userAccountFunction === userAccountFunctions.editUserDetails">
    <app-update-details-form [selectedUsers] = "selectedUsers"></app-update-details-form>
</ng-container>

<ng-container *ngIf="userAccountFunction === userAccountFunctions.editUserProducts">
    <app-update-products-on-multiple-users [selectedUsers] = "selectedUsers"></app-update-products-on-multiple-users>
</ng-container>