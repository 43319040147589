<div class="underscorePopup">
    <span><h1 mat-dialog-title class="dialogTitle dialogText header">Files available to download</h1></span>
    <div class="line mb-4 mt-4"></div>
    <mat-dialog-content>
        <div class="downloadButtonWrapper d-flex justify-content-between align-items-center pb-4 px-4">
            <span class="dialogText">Contract</span>
            <button [disabled]="isDisabled" class="downloadButton" (click)="mrcDownload()">
                <span class="d-flex" *ngIf="spin !== 'mrc'">DOWNLOAD<div id="downloadIcon"></div></span>
                <i *ngIf="spin === 'mrc'"><mat-spinner diameter="27"></mat-spinner></i>
            </button>
        </div>

        <div *ngFor="let dwnld of availableDownloads; let i=index">
            <div class="downloadButtonWrapper d-flex justify-content-between align-items-center pb-4 px-4">
                <span class="dialogText">{{spaceBeforeCap(dwnld)}}</span>
                <button [disabled]="isDisabled" class="downloadButton" (click)="additionalFileDownload(dwnld)">
                    <span class="d-flex" *ngIf="spin !== dwnld">DOWNLOAD<div id="downloadIcon"></div></span>
                    <i *ngIf="spin === dwnld"><mat-spinner diameter="27"></mat-spinner></i>
                </button>
            </div>
        </div>

    </mat-dialog-content>
    <div class="line mb-3 mt-4"></div>

    <span class="d-flex justify-content-end mt-4 me-4">
        <button mat-dialog-close mat-button class= "roboto createButton">Back</button>
    </span>
</div>