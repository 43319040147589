import { Pipe, PipeTransform } from '@angular/core';
import { PortalUtilService } from '../../services/portal-util.service';

@Pipe({
  name: 'portalDatePipe'
})
export class DatePipe implements PipeTransform {
  constructor(private pUtil: PortalUtilService) {
  }

  transform(dateString: string, type: string, handleTimeZone?: boolean): string {
    if(handleTimeZone && dateString){
      return this.formattedDate(new Date(dateString), type)
    }
    if(dateString){
      const date = this.pUtil.returnISOToDate(dateString)
      return this.formattedDate(date, type)
    }
    return 'missing value'
  }

  formattedDate(date: Date, type: string): string {
    let myFormattedDate = `${date.getFullYear()}-${this.pad((date.getMonth() + 1))}-${this.pad(date.getDate())}`
    if (type) {
      myFormattedDate +=  ` ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`
    }
    return myFormattedDate
  }

  pad(n: string | number) {
    return String('0' + n).slice(-2)
  }

}
